import * as Api from "../api/api";
import { Action, Reducer } from 'redux';
import { ApplicationState } from './';
import * as CrudeStore from "./Crude";

export const key: string = "supplierPayment";

export type SupplierPaymentState = CrudeStore.EntityCrudeState<Api.SupplierPaymentModel, Api.SupplierPaymentFilter>
    & SupplierPaymentOwnState;

interface SupplierPaymentOwnState {
    isOpen: boolean;
}

export const configuration: CrudeStore.CrudeStoreConfiguration<Api.SupplierPaymentModel, Api.SupplierPaymentFilter> = {
    key: key,
    storeSelector: x => x.supplierPayment,
    idSelector: x => x.supplierPaymentId,
    requestEntitiesFetch: (model, options) => {
        let api = new Api.SupplierPaymentApi();
        return api.getEntities(model, options);
    },
    requestCreateFetch: (model, options) => {
        let api = new Api.SupplierPaymentApi();
        return api.create(model, options);
    },
    requestUpdateFetch: (model, options) => {
        let api = new Api.SupplierPaymentApi();
        return api.update(model, options);
    },
    requestDeleteFetch: (model, options) => {
        let api = new Api.SupplierPaymentApi();
        return api._delete(model, options);
    },
    requestBulkUpdateFetch: (models, options) => {
        let api = new Api.SupplierPaymentApi();
        return api.bulkUpdate(models, options);
    },
    requestSubmitCreateFetch: (models, options) => {
        let api = new Api.SupplierPaymentApi();
        return api.submitCreate(models, options);
    },
    requestSubmitUpdateFetch: (models, options) => {
        let api = new Api.SupplierPaymentApi();
        return api.submitUpdate(models, options);
    },
    requestDependencies: []
}

export const requestEntities = (requestTime: number,
    dispatch: (action: CrudeStore.KnownAction<Api.SupplierPaymentModel, Api.SupplierPaymentFilter>) => void,
    getState: () => ApplicationState) => CrudeStore.requestEntities(configuration, requestTime, dispatch, getState);

interface SupplierPaymentOpenDialog {
    type: "SUPPLIERPAYMENT_OPEN_DIALOG"
}
interface SupplierPaymentCloseDialog {
    type: "SUPPLIERPAYMENT_CLOSE_DIALOG"
}

type KnownAction = SupplierPaymentOpenDialog | SupplierPaymentCloseDialog;

export const actionCreators = {
    ...CrudeStore.getActionCreators<Api.SupplierPaymentModel, Api.SupplierPaymentFilter>(configuration),
    supplierPaymentOpenDialog: () => <SupplierPaymentOpenDialog>{ type: "SUPPLIERPAYMENT_OPEN_DIALOG" },
    supplierPaymentCloseDialog: () => <SupplierPaymentCloseDialog>{ type: "SUPPLIERPAYMENT_CLOSE_DIALOG" }
};


const unloadedState: SupplierPaymentState = {
    ...CrudeStore.unloadedState,
    isOpen: false
};

export const ownReducer: Reducer<SupplierPaymentState> = (state: SupplierPaymentState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SUPPLIERPAYMENT_OPEN_DIALOG":
            return {
                ...state,
                isOpen: true
            };
        case "SUPPLIERPAYMENT_CLOSE_DIALOG":
            return {
                ...state,
                isOpen: false
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

const crudeReducer = CrudeStore.getReducer(key);
export const reducer = (state: SupplierPaymentState, action: Action) => crudeReducer(ownReducer(state, action), action);