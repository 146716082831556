import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import {
    Field,
    WrappedFieldArrayProps
} from 'redux-form';
import { productSubFamiliesValidation } from '../utils/validation';
import { productSubFamiliesHelp } from '../utils/entityHelp';
import { FieldHelp } from './FieldHelp';
import { DataType } from './Home';
import StoresSelection from './StoresSelection';


type ProductSubFamiliesProps = ProductSubFamiliesOwnProps
    & WrappedFieldArrayProps<Api.ProductSubFamilyModel>;

export interface ProductSubFamiliesOwnProps {
    storeItemId: number;
    disabled?: boolean;
    stores: { [id: number]: Api.StoreModel };
    subFamilies: { [id: number]: Api.SubFamilyModel };
    families: { [id: number]: Api.FamilyModel };
    productSubFamilies: Array<Api.ProductSubFamilyModel>;
    allowedStores: Array<number>;
    dataType: DataType
}

export default class ProductSubFamilies extends React.Component<ProductSubFamiliesProps, {}> {
    public render() {
        return (<div>
            {this.props.fields.map((member, index) => !this.props.productSubFamilies[index]
                || ((this.props.dataType === "Global"
                    && this.props.productSubFamilies[index].productSubFamilyStores.length !== 0)
                    || (this.props.dataType === "Specific"
                && this.props.productSubFamilies[index].productSubFamilyStores.length === 0))
                ? <div  key={index}></div>
                : <div key={index}
                style={{ marginBottom: 5, 
                    display: this.props.productSubFamilies[index]
                                && this.props.productSubFamilies[index].productSubFamilyStores.length
                                && !this.props.productSubFamilies[index].productSubFamilyStores
                                    .some(x => this.props.allowedStores
                                        .some(y => y === x.storeId))
                                ? "none"
                                : "block" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <Field className="form-control"
                                validate={productSubFamiliesValidation["subFamilyId"]}
                                name={`${member}.subFamilyId`}
                                disabled={this.props.disabled}
                                component={FormField.getSelectField(
                                    _.sortBy(_.sortBy(_.values(this.props.subFamilies), x => x.order), x => this.props.families[x.familyId].order)
                                        .map(x => ({
                                            label: this.props.families[x.familyId].name + " - " + x.name,
                                            value: x.subFamilyId
                                        })))} />
                        </div>
                    </div>
                    <div style={{ flex: "0 0 50px" }}>
                        <FieldHelp help={productSubFamiliesHelp()["order"]}>
                            <Field className="form-control input-sm"
                                disabled={this.props.disabled}
                                style={{ width: 50 }}
                                validate={productSubFamiliesValidation["order"]}
                                name={`${member}.order`}
                                component={FormField.NumberField} />
                        </FieldHelp>
                    </div>
                    {this.props.dataType === "Specific"
                        && <div style={{ flex: "1" }}>
                            <div style={{ marginLeft: 3 }}>
                                <FieldHelp help={productSubFamiliesHelp()["productSubFamilyStores"]}>
                                    <Field
                                        validate={productSubFamiliesHelp["productSubFamilyStores"]}
                                        disabled={this.props.disabled}
                                        name={`${member}.productSubFamilyStores`}
                                        component={(props) => <StoresSelection
                                            {...props}
                                            stores={_.values(this.props.stores)
                                                .filter(x => this.props.allowedStores.some(y => y === x.storeId))}
                                            value={props.input.value}
                                            storeIdsDisabled={[]}
                                            onChange={(value) => props.input.onChange(value)}
                                        />} />
                                </FieldHelp>
                            </div>
                        </div>}
                    <div style={{ flex: "0 0 auto" }}>
                        <button
                            type="button"
                            disabled={this.props.disabled}
                            style={{ marginLeft: 5 }}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    disabled={this.props.disabled}
                    className="btn btn-secondary btn-sm"
                    onClick={() => this.props.fields.push({
                        productId: this.props.storeItemId,
                        productSubFamilyStores: this.props.dataType === "Specific"
                            ? [{ storeId: this.props.allowedStores[0] }]
                            : []
                    })}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}