import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from './FormField';
import { diff } from "deep-object-diff";
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector
} from 'redux-form';
import { priceNameValidation } from "../utils/validation";
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import { FormType } from '../store/Crude';
import { CSSProperties } from 'react';

type PriceNameFromProps = InjectedFormProps
    & FormField.FormProps
    & PriceNameFormExternalProps
    & PriceNameFromOwnProps;

interface PriceNameFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.PriceNameModel) => any;
}

interface PriceNameFromOwnProps {
    disabled?: boolean;
    formType: FormType;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
}

const typeOptions: Array<{ label: string; value: Api.PriceNameModelTypeEnum }> = [
    { label: "Normal", value: "Normal" },
    { label: "Offre", value: "Offer" }
];

class PriceNameFrom extends React.Component<PriceNameFromProps, {}> {
    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create";
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{"Nom:"}</label>
                    <div className="col-sm-8" style={this.getFieldStyle("name")}>
                        <Field className="form-control"
                            validate={priceNameValidation["name"]}
                            name="name"
                            disabled={this.props.disabled
                                || this.globalFieldDisabled}
                            component={FormField.renderField}
                            type="text" />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{"Type:"}</label>
                    <div className="col-sm-8" style={this.getFieldStyle("type")}>
                        <Field className="form-control"
                            validate={priceNameValidation["type"]}
                            name="type"
                            disabled={this.props.disabled
                                || this.globalFieldDisabled}
                            component={FormField.getSelectField(typeOptions)} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType !== "Create"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = reduxForm({
    form: 'createPriceName',
    destroyOnUnmount: false,
    enableReinitialize: true
})(PriceNameFrom) as any;
const createSelector = formValueSelector('createPriceName')

let updateForm = reduxForm({
    form: 'updatePriceName',
    destroyOnUnmount: false,
    enableReinitialize: true
})(PriceNameFrom) as any;
const updateSelector = formValueSelector('updatePriceName')

let submissionForm = reduxForm({
    form: 'submissionPriceName',
    destroyOnUnmount: false,
    enableReinitialize: true
})(PriceNameFrom) as any;
const submissionSelector = formValueSelector('submissionPriceName')

export const CreatePriceNameForm = connect((state: ApplicationState) => {
    return {
        formType: "Create",
        account: state.account.currentUser.account
    } as PriceNameFromOwnProps;
})(createForm as any) as any as React.ComponentClass<PriceNameFormExternalProps>;

export const UpdatePriceNameForm = connect((state: ApplicationState) => {
    return {
        formType: "Update",
        disabled: !((state.priceName.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
                .some(x => x.priceNameId === state.priceName.selectedEntityId)),
        initialValues: state.priceName.entities[state.priceName.selectedEntityId],
        account: state.account.currentUser.account
    } as PriceNameFromOwnProps;
})(updateForm as any) as any as React.ComponentClass<PriceNameFormExternalProps>;

export const SubmissionPriceNameForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        formType: "Submission",
        account: state.account.currentUser.account,
        initialValues: JSON.parse(submission.content),
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.priceName.entities[submission.priceNameId])
            : {}
    } as PriceNameFromOwnProps;
})(submissionForm as any) as any as React.ComponentClass<PriceNameFormExternalProps>;