import * as Api from "../api/api";
import * as _ from "lodash";
import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import { change } from 'redux-form';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from "../utils/utils";
import Notifications from 'react-notification-system-redux';

export interface EntityCrudeState<TEntity, TEntityFilter> {
    isLoading: boolean;
    requestTime?: number;
    loadedTime?: number;
    createSourceId?: number;
    selectedEntityId?: number;
    entities: { [id: number]: TEntity };
    filter: TEntityFilter;
    create: RequestState;
    updateStates: { [id: number]: RequestState };
    deleteStates: { [id: number]: RequestState };
    bulkUpdate: RequestState;
}

export type FormType = "Create" | "Update" | "Submission";

interface RequestState {
    isLoading: boolean;
    requestTime?: number;
}

interface RequestEntities<TEntity> {
    type: "REQUEST_ENTITIES";
    payload: { key: string; requestTime: number }
}
export interface ReceiveEntities<TEntity, TEntityFilter> {
    type: "RECEIVE_ENTITIES";
    payload: { key: string; requestTime: number; entities: { [id: number]: TEntity } }
    error?: any;
}

export interface RequestCreateEntity<TEntity> {
    type: "REQUEST_CREATE_ENTITY";
    payload: { key: string; requestTime: number }
}
export interface ReceiveCreateEntity<TEntity> {
    type: "RECEIVE_CREATE_ENTITY";
    payload: { key: string; requestTime: number; id: number; entity: TEntity }
    error?: any;
}

export interface RequestUpdateEntity<TEntity> {
    type: "REQUEST_UPDATE_ENTITY";
    payload: { key: string; id: number; requestTime: number }
}
export interface ReceiveUpdateEntity<TEntity> {
    type: "RECEIVE_UPDATE_ENTITY";
    payload: { key: string; id: number; requestTime: number; entity: TEntity }
    error?: any;
}

interface RequestDeleteEntity {
    type: "REQUEST_DELETE_ENTITY";
    payload: { key: string; id: number; requestTime: number }
}
interface ReceiveDeleteEntity {
    type: "RECEIVE_DELETE_ENTITY";
    payload: { key: string; id: number; requestTime: number }
    error?: any;
}

interface RequestBulkUpdateEntity<TEntity> {
    type: "REQUEST_BULK_UPDATE_ENTITY";
    payload: { key: string; requestTime: number }
}
interface ReceiveBulkUpdateEntity<TEntity> {
    type: "RECEIVE_BULK_UPDATE_ENTITY";
    payload: {
        key: string; entities: { [id: number]: TEntity}; requestTime: number }
    error?: any;
}

interface RequestSubmitCreateEntity {
    type: "REQUEST_SUBMIT_CREATE_ENTITY";
    payload: { key: string; requestTime: number }
}
export interface ReceiveSubmitCreateEntity {
    type: "RECEIVE_SUBMIT_CREATE_ENTITY";
    payload: { key: string; requestTime: number; submission: Api.SubmissionModel }
    error?: any;
}

interface RequestSubmitUpdateEntity {
    type: "REQUEST_SUBMIT_UPDATE_ENTITY";
    payload: { key: string; id: number; requestTime: number }
}
export interface ReceiveSubmitUpdateEntity {
    type: "RECEIVE_SUBMIT_UPDATE_ENTITY";
    payload: { key: string; id: number; requestTime: number; submission: Api.SubmissionModel }
    error?: any;
}

interface UpdateEntityFilter<TEntityFilter> { type: "UPDATE_ENTITY_FILTER"; payload: { key: string; filter: TEntityFilter } }
export interface SelectUpdateEntity { type: "SELECT_UPDATE_ENTITY"; payload: { key: string; id: number } }
export interface SelectCreateSource { type: "SELECT_CREATE_SOURCE"; payload: { key: string; id: number } }

export type KnownAction<TEntity, TEntityFilter> = RequestEntities<TEntity> | ReceiveEntities<TEntity, TEntityFilter>
    | RequestCreateEntity<TEntity> | ReceiveCreateEntity<TEntity>
    | RequestUpdateEntity<TEntity> | ReceiveUpdateEntity<TEntity>
    | UpdateEntityFilter<TEntityFilter> | RequestDeleteEntity
    | ReceiveDeleteEntity | SelectUpdateEntity | SelectCreateSource
    | RequestBulkUpdateEntity<TEntity> | ReceiveBulkUpdateEntity<TEntity>
    | RequestSubmitCreateEntity | ReceiveSubmitCreateEntity
    | RequestSubmitUpdateEntity | ReceiveSubmitUpdateEntity
    ;

interface RequestEntitiesModel { }
interface RequestCreateModel<TEntity> { model: TEntity }
interface RequestUpdateModel<TEntity> { model: TEntity }
interface RequestBulkUpdateModel<TEntity> { models: Array<TEntity> }
interface RequestDeleteModel { id: number }

type RequestEntitiesActionCreator<TEntity, TEntityFilter> = (requestTime: number) => AppThunkAction<KnownAction<TEntity, TEntityFilter>>;;

export interface CrudeStoreConfiguration<TEntity, TEntityFilter> {
    key: string;
    storeSelector: (state: ApplicationState) => EntityCrudeState<TEntity, TEntityFilter>;
    idSelector: (entity: TEntity) => number;
    requestEntitiesFetch: (a: RequestEntitiesModel, b: any) => Promise<Array<TEntity>>;
    requestCreateFetch: (a: RequestCreateModel<TEntity>, b: any) => Promise<TEntity>;
    requestUpdateFetch: (a: RequestUpdateModel<TEntity>, b: any) => Promise<TEntity>;
    requestDeleteFetch: (a: RequestDeleteModel, b: any) => Promise<void>;
    requestBulkUpdateFetch: (a: RequestBulkUpdateModel<TEntity>, b: any) => Promise<Array<TEntity>>;
    requestSubmitCreateFetch: (a: RequestCreateModel<TEntity>, b: any) => Promise<Api.SubmissionModel>;
    requestSubmitUpdateFetch: (a: RequestUpdateModel<TEntity>, b: any) => Promise<Api.SubmissionModel>;
    //Ex: Families for subfamilies
    requestDependencies: Array<(
        requestTime: number,
        dispatch: (action: KnownAction<TEntity, TEntityFilter>) => void,
        getState: () => ApplicationState) => Promise<any>>;
}

export interface ActionCreators<TEntity, TEntityFilter> {
    updaterFilter: (model: TEntityFilter) => UpdateEntityFilter<TEntityFilter>;
    selectUpdateEntity: (id: number) => SelectUpdateEntity;
    selectCreateSource: (id: number) => SelectCreateSource;
    requestEntities: RequestEntitiesActionCreator<TEntity, TEntityFilter>;
    requestCreate: (requestTime: number, model: TEntity) => AppThunkAction<KnownAction<TEntity, TEntityFilter>>;
    requestUpdate: (requestTime: number, model: TEntity) => AppThunkAction<KnownAction<TEntity, TEntityFilter>>;
    requestDelete: (requestTime: number, id: number) => AppThunkAction<KnownAction<TEntity, TEntityFilter>>;
    requestBulkUpdate: (requestTime: number, updates: { [id: number]: object }) => AppThunkAction<KnownAction<TEntity, TEntityFilter>>;
    requestSubmitCreate: (requestTime: number, model: TEntity) => AppThunkAction<KnownAction<TEntity, TEntityFilter>>;
    requestSubmitUpdate: (requestTime: number, model: TEntity) => AppThunkAction<KnownAction<TEntity, TEntityFilter>>;
    updateFormValue: (form: string, member: string, value: number) => AppThunkAction<KnownAction<TEntity, TEntityFilter>>;
}

export const requestEntities = <TEntity, TEntityFilter>(
    configuration: CrudeStoreConfiguration<TEntity, TEntityFilter>,
    requestTime: number,
    dispatch: (action: KnownAction<TEntity, TEntityFilter>) => void,
    getState: () => ApplicationState): Promise<any> => {
    if (requestTime === configuration.storeSelector(getState()).requestTime)
        return Promise.resolve();

    //Opti
    if (configuration.storeSelector(getState()).loadedTime
        && (new Date().getTime() - configuration.storeSelector(getState()).loadedTime) * 1000 < 60)
        return Promise.resolve();

    //remote filter, not used for now
    let filter = {} as any;
    let task = new Promise((resolve, reject) => {
        Promise.all(configuration.requestDependencies.map(x => x(requestTime, dispatch, getState)))
            .then(() => {
                configuration.requestEntitiesFetch({
                    model: filter
                }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                    .then(entities => {
                        dispatch({
                            type: "RECEIVE_ENTITIES",
                            payload: {
                                requestTime: requestTime,
                                key: configuration.key,
                                entities: _.keyBy(entities, x => configuration.idSelector(x))
                            },
                        });
                        resolve();
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
            .catch(error => {
                reject(error);
            })
    }).catch(error => {
        dispatch({
            type: "RECEIVE_ENTITIES",
            payload: {
                requestTime: requestTime, key: configuration.key,
                entities: configuration.storeSelector(getState()).entities,
            },
            error: error
        });
        console.error(error);
        dispatch(Notifications.error({
            title: 'Erreur',
            message: 'Erreur lors de la récupération des entités',
            position: 'tr'
        }) as any);
    });

    addTask(task);
    dispatch({ type: "REQUEST_ENTITIES", payload: { key: configuration.key, requestTime: requestTime } });
    return task;
}

export const getActionCreators = <TEntity, TEntityFilter>(
    configuration: CrudeStoreConfiguration<TEntity, TEntityFilter>
): ActionCreators<any, any> => ({
        updaterFilter: (model: TEntityFilter) => <UpdateEntityFilter<TEntityFilter>>{
            type: "UPDATE_ENTITY_FILTER", payload: { filter: model, key: configuration.key }
        },
        selectUpdateEntity: (id: number) => <SelectUpdateEntity>{ type: "SELECT_UPDATE_ENTITY", payload: { id: id, key: configuration.key } },
        selectCreateSource: (id: number) => <SelectCreateSource>{ type: "SELECT_CREATE_SOURCE", payload: { id: id, key: configuration.key } },
        requestEntities: (requestTime: number): AppThunkAction<KnownAction<TEntity, TEntityFilter>> => (dispatch, getState) => {
            return requestEntities(configuration, requestTime, dispatch, getState);
        },
        requestCreate: (requestTime: number, model: TEntity): AppThunkAction<KnownAction<TEntity, TEntityFilter>> => (dispatch, getState) => {
            if (requestTime === configuration.storeSelector(getState()).create.requestTime)
                return Promise.reject("Already did");

            let task = configuration.requestCreateFetch({
                model: model
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(entity => {
                    dispatch({
                        type: "RECEIVE_CREATE_ENTITY",
                        payload: { requestTime: requestTime, key: configuration.key, id: configuration.idSelector(entity), entity: entity },
                    });
                    dispatch(Notifications.success({
                        title: 'Succès',
                        message: "L'objet a bien été créé",
                        position: 'tr'
                    }) as any);
                })
                .catch(error => {
                    dispatch({
                        type: "RECEIVE_CREATE_ENTITY",
                        payload: { requestTime: requestTime, key: configuration.key, id: -1, entity: null },
                        error: error
                    });
                    console.error(error);
                    dispatch(Notifications.error({
                        title: 'Erreur',
                        message: 'Erreur lors de la création',
                        position: 'tr'
                    }) as any);
                });

            addTask(task);
            dispatch({ type: "REQUEST_CREATE_ENTITY", payload: { key: configuration.key, requestTime: requestTime } });
            return task;
        },
        requestUpdate: (requestTime: number, model: TEntity): AppThunkAction<KnownAction<TEntity, TEntityFilter>> => (dispatch, getState) => {
            if (configuration.storeSelector(getState()).updateStates[configuration.idSelector(model)]
                && requestTime === configuration.storeSelector(getState()).updateStates[configuration.idSelector(model)].requestTime)
                return Promise.reject("Already did");

            let task = configuration.requestUpdateFetch({
                model: model
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(entity => {
                    dispatch({
                        type: "RECEIVE_UPDATE_ENTITY",
                        payload: { requestTime: requestTime, key: configuration.key, id: configuration.idSelector(model), entity: entity },
                    });
                    dispatch(Notifications.success({
                        title: 'Succès',
                        message: "Vos modifications ont été sauvegardé",
                        position: 'tr'
                    }) as any);
                })
                .catch(error => {
                    dispatch({
                        type: "RECEIVE_UPDATE_ENTITY",
                        payload: {
                            requestTime: requestTime, key: configuration.key,
                            id: configuration.idSelector(model), entity: configuration.storeSelector(getState()).entities[configuration.idSelector(model)]
                        },
                        error: error
                    });
                    console.error(error);
                    dispatch(Notifications.error({
                        title: 'Erreur',
                        message: 'Erreur lors de la sauvegarde',
                        position: 'tr'
                    }) as any);
                });

            addTask(task);
            dispatch({ type: "REQUEST_UPDATE_ENTITY", payload: { key: configuration.key, requestTime: requestTime, id: configuration.idSelector(model) } });
            return task;
        },
        requestDelete: (requestTime: number, id: number): AppThunkAction<KnownAction<TEntity, TEntityFilter>> => (dispatch, getState) => {
            if (configuration.storeSelector(getState()).deleteStates[id]
                && requestTime === configuration.storeSelector(getState()).deleteStates[id].requestTime)
                return Promise.reject("Already did");

            let task = configuration.requestDeleteFetch({
                id: id
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(() => {
                    dispatch({
                        type: "RECEIVE_DELETE_ENTITY",
                        payload: { requestTime: requestTime, key: configuration.key, id: id },
                    });
                    dispatch(Notifications.success({
                        title: 'Succès',
                        message: "L'objet a été supprimé",
                        position: 'tr'
                    }) as any);
                })
                .catch(error => {
                    dispatch({
                        type: "RECEIVE_DELETE_ENTITY",
                        payload: { requestTime: requestTime, key: configuration.key, id: id },
                        error: error
                    });
                    console.error(error);
                    dispatch(Notifications.error({
                        title: 'Erreur',
                        message: 'Erreur lors de la suppression',
                        position: 'tr'
                    }) as any);
                });

            addTask(task);
            dispatch({ type: "REQUEST_DELETE_ENTITY", payload: { key: configuration.key, requestTime: requestTime, id: id } });
            return task;
        },
        requestBulkUpdate: (requestTime: number, updates: { [id: number]: object }): AppThunkAction<KnownAction<TEntity, TEntityFilter>> => (dispatch, getState) => {
            if (requestTime === configuration.storeSelector(getState()).bulkUpdate.requestTime)
                return Promise.reject("Already did");

            let entities = configuration.storeSelector(getState()).entities;
            let models = _.keys(updates).map(x => ({
                ...(entities[parseInt(x)] as any),
                ...updates[parseInt(x)]
            }));

            let task = configuration.requestBulkUpdateFetch({
                models: models
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(entities => {
                    dispatch({
                        type: "RECEIVE_BULK_UPDATE_ENTITY",
                        payload: {
                            requestTime: requestTime,
                            key: configuration.key,
                            entities: _.keyBy(entities, x => configuration.idSelector(x))
                        },
                    });
                    dispatch(Notifications.success({
                        title: 'Succès',
                        message: 'Vos modifications ont été sauvegardé',
                        position: 'tr'
                    }) as any);
                })
                .catch(error => {
                    let entities = configuration.storeSelector(getState()).entities;
                    dispatch({
                        type: "RECEIVE_BULK_UPDATE_ENTITY",
                        payload: {
                            requestTime: requestTime,
                            key: configuration.key,
                            //respond with pre update entities
                            entities: _.keyBy(models.map(x => entities[configuration.idSelector(x)]), x => configuration.idSelector(x))
                        },
                        error: error
                    });
                    console.error(error);
                    dispatch(Notifications.error({
                        title: 'Erreur',
                        message: 'Erreur lors de la sauvegarde',
                        position: 'tr'
                    }) as any);
                });

            addTask(task);
            dispatch({ type: "REQUEST_BULK_UPDATE_ENTITY", payload: { key: configuration.key, requestTime: requestTime } });
            return task;
        },
        requestSubmitCreate: (requestTime: number, model: TEntity): AppThunkAction<KnownAction<TEntity, TEntityFilter>> => (dispatch, getState) => {
            if (requestTime === configuration.storeSelector(getState()).create.requestTime)
                return Promise.reject("Already did");

            let task = configuration.requestSubmitCreateFetch({
                model: model
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(submission => {
                    dispatch({
                        type: "RECEIVE_SUBMIT_CREATE_ENTITY",
                        payload: { requestTime: requestTime, key: configuration.key, submission: submission },
                    });
                    dispatch(Notifications.success({
                        title: 'Succès',
                        message: "Votre de demande de création a bien été prise en compte",
                        position: 'tr'
                    }) as any);
                })
                .catch(error => {
                    dispatch({
                        type: "RECEIVE_SUBMIT_CREATE_ENTITY",
                        payload: { requestTime: requestTime, key: configuration.key, submission: null },
                        error: error
                    });
                    console.error(error);
                    dispatch(Notifications.error({
                        title: 'Erreur',
                        message: 'Erreur lors de la demande de création',
                        position: 'tr'
                    }) as any);
                });

            addTask(task);
            dispatch({ type: "REQUEST_SUBMIT_CREATE_ENTITY", payload: { key: configuration.key, requestTime: requestTime } });
            return task;
        },
        requestSubmitUpdate: (requestTime: number, model: TEntity): AppThunkAction<KnownAction<TEntity, TEntityFilter>> => (dispatch, getState) => {
            if (configuration.storeSelector(getState()).updateStates[configuration.idSelector(model)]
                && requestTime === configuration.storeSelector(getState()).updateStates[configuration.idSelector(model)].requestTime)
                return Promise.reject("Already did");

            let task = configuration.requestSubmitUpdateFetch({
                model: model
            }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
                .then(submission => {
                    dispatch({
                        type: "RECEIVE_SUBMIT_UPDATE_ENTITY",
                        payload: {
                            requestTime: requestTime, key: configuration.key,
                            submission: submission, id: configuration.idSelector(model)
                        },
                    });
                    dispatch(Notifications.success({
                        title: 'Succès',
                        message: "Votre demande de modification a bien été prise en compte",
                        position: 'tr'
                    }) as any);
                })
                .catch(error => {
                    dispatch({
                        type: "RECEIVE_SUBMIT_UPDATE_ENTITY",
                        payload: {
                            requestTime: requestTime, key: configuration.key,
                            submission: null, id: configuration.idSelector(model)
                        },
                        error: error
                    });
                    console.error(error);
                    dispatch(Notifications.error({
                        title: 'Erreur',
                        message: 'Erreur lors de la demande de modification',
                        position: 'tr'
                    }) as any);
                });

            addTask(task);
            dispatch({
                type: "REQUEST_SUBMIT_UPDATE_ENTITY", payload: {
                    key: configuration.key,
                    requestTime: requestTime,
                    id: configuration.idSelector(model)
                }
            });
            return task;
        },
        updateFormValue: (form: string, member: string, value: number): AppThunkAction<KnownAction<TEntity, TEntityFilter>> => (dispatch, getState) => {
            dispatch(change(form, member, value) as any);
        }
});

export const unloadedState: EntityCrudeState<any, any> = {
    create: {
        isLoading: false
    },
    bulkUpdate: {
        isLoading: false
    },
    updateStates: {},
    deleteStates: {},
    entities: {},
    filter: {},
    isLoading: false
}

export const getReducer = (key: string): Reducer<EntityCrudeState<any, any>> => (state: EntityCrudeState<any, any>, incomingAction: Action) => {
    const action = incomingAction as KnownAction<any, any>;
    //I'd rather have this discriminator in the type name but i don't know if it's possible
    if (!action || !action.payload || action.payload.key !== key)
        return state || unloadedState;

    switch (action.type) {
        case "REQUEST_ENTITIES":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_ENTITIES":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                entities: action.payload.entities,
                isLoading: false,
                loadedTime: action.payload.requestTime
            };
        case "REQUEST_CREATE_ENTITY":
            return {
                ...state,
                create: {
                    ...state.create,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_ENTITY":
            return {
                ...state,
                entities: action.error
                    ? state.entities
                    : {
                        ...state.entities,
                        [action.payload.id]: action.payload.entity
                    },
                create: {
                    ...state.create,
                    isLoading: action.payload.requestTime === state.create.requestTime
                        ? true : state.create.isLoading
                },
                createSourceId: null
            };
        case "REQUEST_UPDATE_ENTITY":
            return {
                ...state,
                updateStates: {
                    ...state.updateStates,
                    [action.payload.id]: {
                        ...state.updateStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_UPDATE_ENTITY":
            if (state.updateStates[action.payload.id]
                && action.payload.requestTime !== state.updateStates[action.payload.id].requestTime)
                return state;

            return {
                ...state,
                entities: action.error
                    ? state.entities
                    : {
                        ...state.entities,
                        [action.payload.id]: action.payload.entity
                    },
                updateStates: {
                    ...state.updateStates,
                    [action.payload.id]: {
                        ...state.updateStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            }
        case "REQUEST_DELETE_ENTITY":
            return {
                ...state,
                deleteStates: {
                    ...state.deleteStates,
                    [action.payload.id]: {
                        ...state.deleteStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_DELETE_ENTITY":
            if (state.deleteStates[action.payload.id]
                && action.payload.requestTime !== state.deleteStates[action.payload.id].requestTime)
                return state;

            let entitiesAfterDelete = {
                ...state.entities
            };
            if (!action.error) {
                delete entitiesAfterDelete[action.payload.id];
            }
            return {
                ...state,
                entities: entitiesAfterDelete,
                deleteStates: {
                    ...state.deleteStates,
                    [action.payload.id]: {
                        ...state.deleteStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "REQUEST_BULK_UPDATE_ENTITY":
            return {
                ...state,
                bulkUpdate: {
                    ...state.bulkUpdate,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_BULK_UPDATE_ENTITY":
            return {
                ...state,
                entities: action.error
                    ? state.entities
                    : {
                    ...state.entities,
                    ...action.payload.entities
                },
                bulkUpdate: {
                    ...state.bulkUpdate,
                    isLoading: action.payload.requestTime === state.bulkUpdate.requestTime
                        ? true : state.bulkUpdate.isLoading
                }
            };
        case "UPDATE_ENTITY_FILTER":
            return {
                ...state,
                filter: action.payload.filter
            };
        case "SELECT_UPDATE_ENTITY":
            return {
                ...state,
                selectedEntityId: action.payload.id
            };
        case "SELECT_CREATE_SOURCE":
            return {
                ...state,
                createSourceId: action.payload.id
            };
        case "REQUEST_SUBMIT_CREATE_ENTITY":
            return {
                ...state,
                create: {
                    ...state.create,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_SUBMIT_CREATE_ENTITY":
            return {
                ...state,
                create: {
                    ...state.create,
                    isLoading: action.payload.requestTime === state.create.requestTime
                        ? true : state.create.isLoading
                },
                createSourceId: null
            };
        case "REQUEST_SUBMIT_UPDATE_ENTITY":
            return {
                ...state,
                updateStates: {
                    ...state.updateStates,
                    [action.payload.id]: {
                        ...state.updateStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_SUBMIT_UPDATE_ENTITY":
            if (state.updateStates[action.payload.id]
                && action.payload.requestTime !== state.updateStates[action.payload.id].requestTime)
                return state;

            return {
                ...state,
                updateStates: {
                    ...state.updateStates,
                    [action.payload.id]: {
                        ...state.updateStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            }
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};