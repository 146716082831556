import * as React from "react";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as ExternalProductStore from "../store/ExternalProduct";
import * as TabsStore from "../store/Tabs";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import * as ReactTable from 'react-table';
import ImportCSV from './ImportCSV';

type ExternalProductsProps = ExternalProductsOwnProps
    & ExternalProductStore.ExternalProductState
    & typeof TabsStore.actionCreators
    & typeof ExternalProductStore.actionCreators;

interface ExternalProductsOwnProps {
    account: Api.AccountModel;
    units: { [id: number]: Api.UnitModel };
    selectedTab?: string;
}

type ExternalProductColumn = ReactTable.Column
    & { accessor: (d: Api.ExternalProductModel) => any; }

class ExternalProducts extends React.Component<ExternalProductsProps, {}> {
    componentWillMount() {
       this.props.requestExternalProducts(new Date().getTime());
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Produits Externes</h2>
                <div style={{textAlignLast: 'end', padding: 10}}>
                    <button className="btn btn-primary btn-lg"
                        disabled = {this.props.isLoading}
                        onClick={(e) => {
                            this.props.exportExternalProductsCsv();
                            e.preventDefault();
                            e.stopPropagation();}
                        }>
                            Export csv
                            <i className="glyphicon glyphicon-save" style={{marginLeft: '5%'}}></i>
                    </button>
                </div>
                <ReactTable.default
                style={{
                    overflow: "auto",
                    fontWeight: 'bold'
                 } }
                    pageSize={20}
                    showPageSizeOptions={false}
                    
                    columns={[
                        {
                            id: "remoteId",
                            accessor: (d) => d.remoteId,
                            Header: "Id"
                        },
                        {
                            id: "name",
                            accessor: (d) => d.name,
                            Header: "Nom FR"                           
                        },
                        {
                            id: "externalProductLanguageName",
                            accessor: (d) => d,
                            Header: "Nom NL",
                            Cell: row => <div>{row.value.externalProductLanguageNames[0].name}</div>
                        },
                        {
                            id: "stability",
                            Header: "Stabilité",
                            accessor: (d) => d.stability
                        },
                        {
                            id: "expirityDays",
                            Header: "Jours date",
                            accessor: (d) => d.expirityDays
                        },
                        {
                            id : "practicUnit",
                            Header: "Unité pratique",
                            accessor: d => <div>{this.props.units[d.practicUnit] != undefined ? this.props.units[d.practicUnit].name : ""}</div>,
                        },
                        {
                            id : "volumeUnitId",
                            Header: "Unité de vente",
                            accessor: d => <div>{this.props.units[d.volumeUnitId] != undefined ? this.props.units[d.volumeUnitId].name : ""}</div>,
                        },
                        {
                            id: "conversionFactor",
                            Header: "Facteur de conversion",
                            accessor: (d) => d.conversionFactor
                        },
                        {
                            id: "productBarcodes",
                            Header: "EAN",
                            accessor: (d) => d.productBarcodes[0].value
                        },
                        {
                            id: "depth",
                            Header: "Profondeur (mm)",
                            accessor: (d) => d.depth
                        },
                        {
                            id: "height",
                            Header: "Hauteur (mm)",
                            accessor: (d) => d.height
                        },
                        {
                            id: "width",
                            accessor: (d) => d.width,
                            Header: "Largeur (mm)"
                        },
                        {
                            id: "deliveryType",
                            accessor: (d) => d.deliveryType === "Locker" ? "Casier" : "Normal",
                            Header: "Livraison"
                        },
                    ] as Array<ExternalProductColumn>}
                    data={_.values(this.props.externalProducts)} />
                    <div style={{ textAlign: "center", marginTop: 50 }}>
                        <ImportCSV
                            isLoading = {this.props.importDataState.isLoading}
                            uploadFile = {(file) => this.props.importData(new Date().getTime(), file )}
                            updateData = {()=> this.props.requestExternalProducts(new Date().getTime())}/>
                    </div>
            </div>
        );
    }
}


export default connect((state: ApplicationState) => ({
    ...state.externalProducts,
    account: state.account.currentUser.account,
    units: state.unit.entities,
} as ExternalProductsOwnProps), { ...TabsStore.actionCreators, ...ExternalProductStore.actionCreators } as any)(ExternalProducts);