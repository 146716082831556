import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import {
    Field,
    WrappedFieldArrayProps
} from 'redux-form';
import {
    menuStoreValidation,
} from "../utils/validation";
import { menuStoreHelp } from "../utils/entityHelp";
import { FieldHelp } from './FieldHelp';
import { DataType } from './Home';

type MenuStoresProps = MenuStoresOwnProps
    & WrappedFieldArrayProps<Api.MenuStoreModel>;

export interface MenuStoresOwnProps {
    menuId: number;
    menuStores: Array<Api.MenuStoreModel>;
    canAccessAllStores: boolean;
    allowedStores: Array<number>;
    stores: { [id: number]: Api.StoreModel };
    disabled?: boolean;
}

export default class MenuStores extends React.Component<MenuStoresProps, {}> {

    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => <div key={index}
                    style={{
                        marginBottom: 5,
                        display: this.props.menuStores
                            && this.props.menuStores[index]
                            && (this.props.canAccessAllStores
                            || this.props.allowedStores
                            .some(x => x === this.props.menuStores[index].storeId))
                            ? "block"
                            : "none"
                    }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: "0 0 auto" }}>
                            <div style={{ marginLeft: 3 }}>
                                <FieldHelp help={menuStoreHelp()["hmiVisibility"]}>
                                    <Field
                                        disabled={this.props.disabled}
                                        validate={menuStoreValidation["hmiVisibility"]}
                                        name={`${member}.hmiVisibility`}
                                        label={"Visible: "}
                                        checkedValue={"Visible" as Api.MenuModelHmiVisibilityEnum}
                                        unCheckedValue={"Hide" as Api.MenuModelHmiVisibilityEnum}
                                        component={FormField.CheckBoxField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            <div style={{ marginLeft: 6 }}>
                                <FieldHelp help={menuStoreHelp()["storeId"]}>
                                    <Field className="form-control"
                                        validate={menuStoreValidation["storeId"]
                                            .concat(this.props.canAccessAllStores
                                                ? []
                                                : [FormField.required])}
                                        disabled={this.props.disabled}
                                        name={`${member}.storeId`}
                                        component={FormField.getSelectField(
                                            (this.props.canAccessAllStores
                                                ? [ { label: "--- TOUS ---", value: null } ]
                                                    : []).concat(
                                            _.values(this.props.stores)
                                                    .filter(x => this.props.allowedStores.some(y => y === x.storeId))
                                                    .map(x => ({ label: x.name, value: x.storeId }))))} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div style={{ flex: "0 0 auto" }}>
                            <button
                                type="button"
                                disabled={this.props.disabled}
                                style={{ marginLeft: 5 }}
                                className="btn btn-secondary btn-sm"
                                title="Remove"
                                onClick={() => this.props.fields.remove(index)}>
                                <i className="glyphicon glyphicon-remove"></i>
                            </button>
                        </div>
                    </div>
                </div>)}
                <FormField.FieldErrors {...this.props.meta} />
                <div style={{ textAlign: "center" }}>
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        disabled={this.props.disabled}
                        onClick={() => this.props.fields.push({
                            menuId: this.props.menuId,
                            hmiVisibility: "Visible",
                            smartPhoneClientVisibility: "Visible",
                            storeId: this.props.canAccessAllStores
                                ? null
                                : (this.props.allowedStores.length
                                    ? this.props.allowedStores[0]
                                    : 0),
                        } as Api.MenuStoreModel)}>
                        <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                    </button>
                </div>
            </div>
        );
    }
}