import * as React from "react";
import * as _ from "lodash";
import * as DataGridsStore from "../store/DataGrids";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import ReactDataGrid from "react-data-grid";
import Tooltip from "./Tooltip";
import CheckBox from "./CheckBox";
import { createSelector } from "reselect";

type DataGridProps<TEntity extends object> = DataGridsStore.DataGridState
    & DataGridOwnProps<TEntity>
    & DataGridConnectedProps
    & typeof DataGridsStore.actionCreators;

interface DataGridConnectedProps {
    dataGrids: DataGridsStore.DataGridsState;
}

interface DataGridOwnProps<TEntity extends object> {
    gridKey: string;
    data: Array<TEntity>;
    columns: Array<ReactDataGrid.Column>;
    selectableColumns?: Array<string>;
    hideButtons?: boolean;
    minWidthPerColumns?: number;
    idSelector: (entity: object) => number;
    onSelectRow?: (id: number) => void;
    updateValidation?: (updates: { [id: number]: object }) => { [id: number]: { [prop: string]: Array<string> } };
    onUpdateRows?: (edits: { [id: number]: object }) => Promise<any>;
}

const heightOptions = [
    { label: "Petite", value: 350 },
    { label: "Moyenne", value: 700 },
    { label: "Grande", value: 1400 }
];

interface RowRendererProps {
    row: { _id: number, _errors: { [prop: string]: Array<string> } }
}

class RowRenderer extends React.Component<RowRendererProps, {}> {
    row: ReactDataGrid.Row;

    public render() {
        return (_.values(this.props.row._errors).length > 0
            ? <Tooltip placement={"bottom"}
                overlay={<div className="text-danger">
                {_.keys(this.props.row._errors)
                    .map(x => <div>{x}: {this.props.row._errors[x].join(", ")}</div>)}
            </div>}>
                <div style={{
                    borderTop: "1px solid red",
                    borderBottom: "1px solid red"
                }}>
                    <ReactDataGrid.Row ref={node => this.row = node} {...this.props} />
                </div>
            </Tooltip> 
            : <div>
                <ReactDataGrid.Row ref={node => this.row = node} {...this.props} />
            </div>);
    }
}

class DataGrid<TEntity extends object> extends React.Component<DataGridProps<TEntity>, {}> {
    _columns: Array<ReactDataGrid.Column> = [];
    _sortedData: Array<TEntity> = [];
    _grid: ReactDataGrid;

    containerRef: HTMLDivElement;

    onColumnsUpdate(props: DataGridProps<TEntity>) {
        this._columns = props.columns
            .filter(x => !props.dataGrids[props.gridKey]
                || !props.dataGrids[props.gridKey].columnStates[x.key]
                || props.dataGrids[props.gridKey].columnStates[x.key].visible)
            .map(x => ({
                resizable: true,
                sortable: true,
                width: props.dataGrids[props.gridKey]
                    && props.dataGrids[props.gridKey].columnStates[x.key]
                    ? props.dataGrids[props.gridKey].columnStates[x.key].width
                    : undefined,
                ...x
            }));
    }

    onDataUpdate(props: DataGridProps<TEntity>) {
        this._sortedData = DataGridsStore.applySorting(props.data,
            (props.dataGrids[props.gridKey] || DataGridsStore.unloadedGridState).columnStates) as Array<TEntity>;
    }

    componentWillMount() {
        this.onColumnsUpdate(this.props);
        this.onDataUpdate(this.props);
    }

    componentDidMount() {

    }

    componentWillReceiveProps(nextProps: DataGridProps<TEntity>) {
        this._grid.setState({ selected: {} });
        if (this.props.columns !== nextProps.columns
            || this.props.dataGrids[this.props.gridKey] !== nextProps.dataGrids[nextProps.gridKey]) {
            this.onColumnsUpdate(nextProps);
        }
        if (this.props.data !== nextProps.data
            || this.props.dataGrids[this.props.gridKey] !== nextProps.dataGrids[nextProps.gridKey]) {
            this.onDataUpdate(nextProps);
        }
    }

    get dataGridState(): DataGridsStore.DataGridState {
        return this.props.dataGrids[this.props.gridKey]
            || DataGridsStore.unloadedGridState;
    }

    rowGetter(index: number): object {
        let row = {
            ...(this._sortedData[index] as any),
            ...this.dataGridState.editStates[this.props.idSelector(this._sortedData[index])]
        };
        row._id = this.props.idSelector(row);
        row._errors = this.dataGridState.validationErrors[row._id];
        return row;
    }

    getUpdateValidation(updates: { [id: number]: object }): { [id: number]: { [prop: string]: Array<string> } } {
        return this.props.updateValidation
            ? this.props.updateValidation(updates)
            : {};
    }

    public render() {
        return (
            <div>
                {this.props.selectableColumns &&
                    (<div style={{ borderTop: "2px solid #e7eaec" }}>
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {this.props.selectableColumns
                            .filter(x => this.props.columns.some(y => y.key === x))
                            .map(x => {
                            let isVisible = (!this.dataGridState.columnStates[x]
                                || this.dataGridState.columnStates[x].visible)
                                ? true : false;
                            return <div key={x} style={{ margin: 5 }}>
                                <CheckBox
                                    label={this.props.columns.find(y => y.key === x).name}
                                    value={isVisible}
                                    onChange={(value) => this.props.updateColumnVisible(this.props.gridKey, x, value)} />
                        </div>
                        })}
                    </div>
                    </div>)}
                <div ref={(ref) => this.containerRef = ref} style={{ overflowX: "auto" }}>
                    <ReactDataGrid
                        ref={grid => this._grid = grid}
                        rowGetter={(i) => this.rowGetter(i)}
                        rowsCount={this._sortedData.length}
                        minWidth={this.containerRef
                            && this.props.minWidthPerColumns * this.props.columns.length < this.containerRef.clientWidth
                            ? this.containerRef.clientWidth
                            : this.props.minWidthPerColumns * this.props.columns.length}
                        columns={this._columns}
                        rowRenderer={RowRenderer}
                        enableCellSelect={true}
                        minHeight={this.dataGridState.minHeight}
                        onColumnResize={(columnId, width) =>
                            this.props.updateColumnWidth(this.props.gridKey, this._columns[columnId].key, width)}
                        onGridSort={(columnId, sortValue) =>
                            this.props.updateColumnSort(this.props.gridKey, columnId, sortValue)}
                        onGridRowsUpdated={(e) => {
                            this.props.updateRows(
                                this.props.gridKey,
                                this._sortedData
                                    .filter((x, i) => e.fromRow <= i && i <= e.toRow)
                                    .map(x => this.props.idSelector(x)),
                                e.updated);
                            if (this.dataGridState.submitFailed) {
                                this.props.setValidationErrors(this.props.gridKey,
                                    this.getUpdateValidation(this.dataGridState.editStates));
                            }
                        }}
                    />
                </div>
                {!this.props.hideButtons
                    && <div style={{ display: "flex", flexDirection: "row-reverse", padding: 5 }}>
                        <button className="btn btn-primary"
                            style={{ flex: "0 0 auto" }}
                            disabled={_.values(this.dataGridState.editStates).filter(x => x).length === 0}
                            onClick={(e) => {
                                let validationErrors = this.getUpdateValidation(this.dataGridState.editStates);
                                if (_.keys(validationErrors).length > 0) {
                                    this.props.setValidationErrors(this.props.gridKey, validationErrors);
                                    this.props.setSubmitFail(this.props.gridKey);
                                } else {
                                    this.props.setSubmitSuccess(this.props.gridKey);
                                    this.props.onUpdateRows(this.dataGridState.editStates)
                                        .then(() => this.props.resetEdit(this.props.gridKey))
                                }
                            }}>Sauvegarder</button>
                        <button className="btn btn-primary"
                            style={{ marginRight: 10, flex: "0 0 auto" }}
                            disabled={_.values(this.dataGridState.editStates).filter(x => x).length === 0}
                            onClick={(e) => this.props.resetEdit(this.props.gridKey)}>Reset</button>
                        <div style={{ flex: "0 0 auto", marginRight: 10, width: 150, paddingTop: 3 }}>

                        </div>
                    </div>}
            </div>
        );
    }
}

const dataSelector = (state: ApplicationState, props: DataGridOwnProps<any>) => props.data;

const columnsSelector = (state: ApplicationState, props: DataGridOwnProps<any>) => state.dataGrids[props.gridKey]
    ? (state.dataGrids[props.gridKey].columnStates || {})
    : {};

const processedDataSelector = createSelector([dataSelector, columnsSelector], (data, columnStates) => {
    return DataGridsStore.applySorting(data, columnStates);
});

//If pure the buttons state don't update properly
export default connect((state: ApplicationState) => ({
    dataGrids: state.dataGrids,
}), DataGridsStore.actionCreators)(DataGrid as any) as any as React.ComponentClass<DataGridOwnProps<any>>;