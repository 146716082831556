import * as React from 'react';

interface CheckBoxProps {
    label?: string;
    value: boolean;
    labelStyle?: React.CSSProperties;
    labelPosition?: "Left" | "Right";
    disabled?: boolean;
    onChange: (value: boolean) => void;
    style?: any;
}

export default class CheckBox extends React.Component<CheckBoxProps, {}> {
    constructor(props) {
        super(props)
    }

    onClick(e: React.MouseEvent<any>) {
        if (!this.props.disabled)
            this.props.onChange(!this.props.value);

        e.stopPropagation();
    }

    render() {
        return <div className="form-control-static"
            style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center"
            }}>
            {this.props.label
                && this.props.labelPosition === "Left"
                && <div
                    onClick={(e) => this.onClick(e)}
                    style={{
                        marginRight: 6,
                        cursor: !this.props.disabled ? "pointer" : "not-allowed",
                        ...this.props.labelStyle
                    }}>{this.props.label}</div>}
            <div style={{
                width: 20,
                height: 20,
                marginLeft: 2,
                alignSelf: "center",
                textAlign: "center",
                backgroundColor: "lightgray",
                cursor: !this.props.disabled ? "pointer" : "not-allowed",
                ...this.props.style
            }}
                onClick={(e) => this.onClick(e)}>
                {this.props.value &&
                    <i className="glyphicon glyphicon-ok"></i>}
            </div>
            {this.props.label
                && this.props.labelPosition !== "Left"
                && <div onClick={(e) => this.onClick(e)}
                    style={{
                        marginLeft: 6,
                        cursor: !this.props.disabled ? "pointer" : "not-allowed",
                        ...this.props.labelStyle
                    }}>{this.props.label}</div>}
        </div>;
    }
}