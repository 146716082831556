import * as Api from '../api/api';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import { RouteComponentProps } from 'react-router-dom';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Image from "./Image";
import Tooltip from "./Tooltip";
import { canCrude } from '../utils/userRights';
import * as _ from 'lodash';
import * as AccountStore from "../store/Account";


type HomeProps = RouteComponentProps<{}>
    & HomeOwnProps
    & AccountStore.AccountState
    & typeof AccountStore.actionCreators;


interface HomeOwnProps {
    account: Api.AccountModel;
    allowedStores: { [id: number]: Api.StoreModel };
}

export type DataType = "Global" | "Specific";

class Home extends React.Component<HomeProps, {}> {
    get disabledExtProducts() : number{
        if (this.props.account.accountRoles
            .some(y => y.role.name === "Maintenance"
                || y.role.name === "Master")){
            return 1;       
        }
        else if(_.values(this.props.allowedStores).find(x => x.storeType == "Ximiti"))
            return 2;
        else
            return 3;
    }

    public render() {
        return <div style={{
            ...Styles.colors.main1,
            padding: 50, textAlign: "center",
            display: "flex", flexDirection: "column",
            height: "100%"
        }}>
            <div style={{ order: 0, flex: "0 0 auto" }}>
                <h1>LGAP v3.0</h1>
            </div>
            <div style={{ order: 1, flex: 1, padding: "5% 20%" }}>
                <div style={{
                    width: "100%", height: "100%",
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gridTemplateRows: "repeat(4, 1fr)",
                    padding: 20, gridGap: 10,
                    border: "2px solid black", borderRadius: 20,
                    ...Styles.colors.main2
                }}>
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "1 / 2", gridColumn: "1 / 2" }}>
                        <Module to={"/currencies"}
                            title={"Devises"}
                            imageSrc={"/images/currencies.png"} />
                    </div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "1 / 2", gridColumn: "2 / 3" }}>
                        <Module to={"/vats"}
                            title={"Taux TVA"}
                            imageSrc={"/images/vats.png"} />
                    </div>
                    }
                    <div style={{ gridRow: "1 / 2", gridColumn: "3 / 4" }}>
                        <Module to={"/units"}
                            title={<span>Unit&#233;s</span>}
                            imageSrc={"/images/units.png"} />
                    </div>
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "2 / 3", gridColumn: "1 / 2" }}>
                        <Module to={"/families"}
                            title={"Rayons de produit"}
                            imageSrc={"/images/families.png"} />
                    </div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "2 / 3", gridColumn: "2 / 3" }}>
                        <Module to={"/subfamilies"}
                            title={"Sous-familles de produit"}
                            imageSrc={"/images/subFamilies.png"} />
                    </div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "2 / 3", gridColumn: "3 / 4" }}>
                        <Module to={"/suppliermains"}
                            title={"Source d'appro"}
                            imageSrc={"/images/supplierMains.png"} />
                    </div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "2 / 3", gridColumn: "4 / 5" }}>
                        <Module to={"/suppliersubs"}
                            title={"Branche/franchise de source d'appro"}
                            imageSrc={"/images/supplierSubs.png"} />
                    </div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "3 / 4", gridColumn: "1 / 2" }}>
                        <Module to={"/pricenames"}
                            title={"Nom de tarif"}
                            imageSrc={"/images/priceNames.png"} />
                    </div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "3 / 4", gridColumn: "2 / 3" }}>
                        <Module to={"/menucategories"}
                            title={<span>Cat&#233;gories de menu</span>}
                            imageSrc={"/images/menuCategories.png"} />
                    </div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "3 / 4", gridColumn: "3 / 4" }}>
                        <Module to={"/menus"}
                            title={"Menus"}
                            imageSrc={"/images/menus.png"} />
                    </div> 
                    }              
                    {this.disabledExtProducts == 1 || this.disabledExtProducts == 2 ?
                    <div style={{ gridRow: "4 / 5", gridColumn: "1 / 2" }}>
                        <Module to={"/products"}
                            title={"Produits"}
                            imageSrc={"/images/products.png"} />
                    </div>
                    : 
                    <div></div>
                    }
                    {this.disabledExtProducts == 1 || this.disabledExtProducts == 3 ?
                    <div style={{ gridRow: "4 / 5", gridColumn: "1 / 2" }}>
                        <Module to={"/externalproducts"}
                            title={"Produits Externes"}
                            imageSrc={"/images/products.png"} />
                    </div>
                    :
                    <div></div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={{ gridRow: "4 / 5", gridColumn: "2 / 3" }}>
                        {canCrude(this.props.account)
                            && <Module to={"/transfer"}
                            title={"Transfert"}
                            imageSrc={"/images/transfer.png"} />}
                        
                    </div>
                    }
                    <div style={{ gridRow: "4 / 5", gridColumn: "3 / 4" }}>
                        {canCrude(this.props.account) && (this.disabledExtProducts == 1 || this.disabledExtProducts == 2)
                            && <Module to={"/submission"}
                                title={"Demandes de modifications"}
                                imageSrc={"/images/submission.png"} />}
                    </div>
                </div>
            </div>
        </div>;
    }
}

interface ModuleProps {
    title: string | JSX.Element;
    imageSrc: string;
    to: string;
}
class Module extends React.Component<ModuleProps, {}> {
    public render() {
        return (
            <Tooltip containerStyle={{
                position: "absolute",
                top: 0, left: 0,
                bottom: 0, right: 0
            }}
                overlay={<div>{this.props.title}</div>}>
                <NavLink exact to={this.props.to}
                    style={{
                        display: "block",
                        width: "100%", height: "100%",
                    }}>
                    <Image fit={"contain"} style={{}} src={this.props.imageSrc} />
                </NavLink>
            </Tooltip>
        );
    }
}

export default connect((state: ApplicationState) => ({
    account: state.account.currentUser
        ? state.account.currentUser.account : null,
        allowedStores: AccountStore.allowedStores(state),
} as HomeOwnProps), AccountStore.actionCreators ,null)(Home);