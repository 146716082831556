import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { getDefaultHeaders } from "../utils/utils";
import { ApplicationState, AppThunkAction } from ".";

export interface TagState {
    isloading: boolean;
    requestTime?: number;
    entities: { [id: number]: Api.TagModel };
    createState: {
        isloading: boolean;
        requestTime?: number;
    }
}

interface RequestTagEntities {
    type: "REQUEST_TAG_ENTITIES";
    payload: { requestTime: number; }
}
interface ReceiveTagEntities {
    type: "RECEIVE_TAG_ENTITIES";
    payload: { requestTime: number; entities?: { [id: number]: Api.TagModel } };
    error?: any;
}

interface RequestCreateTag {
    type: "REQUEST_CREATE_TAG";
    payload: { requestTime: number; }
}
interface ReceiveCreateTag {
    type: "RECEIVE_CREATE_TAG";
    payload: { requestTime: number; entity?: Api.TagModel };
    error?: any;
}

export type KnownAction = RequestTagEntities
    | ReceiveTagEntities
    | RequestCreateTag
    | ReceiveCreateTag
    ;

export const requestTagEntities = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    let api = new Api.TagApi();

    let fetchTask = api.getEntities({
        credentials: "same-origin",
        headers: getDefaultHeaders(getState())
    })
        .then(entities => {
            dispatch({
                type: "RECEIVE_TAG_ENTITIES",
                payload: { requestTime: requestTime, entities: entities }
            });
            return entities;
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_TAG_ENTITIES",
                payload: { requestTime: requestTime },
                error: err
            });
        })

    dispatch({ type: "REQUEST_TAG_ENTITIES", payload: { requestTime: requestTime } });
    return fetchTask;
}

export const actionCreators = {
    requestTagEntities: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return requestTagEntities(requestTime, dispatch, getState);
    },
    requestCreateTag: (requestTime: number, model: Api.TagModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.TagApi();

        let fetchTask = api.create({
            model: model
        }, {
            credentials: "same-origin",
            headers: getDefaultHeaders(getState())
        })
            .then(entity => {
                dispatch({
                    type: "RECEIVE_CREATE_TAG",
                    payload: { requestTime: requestTime, entity: entity }
                });
                return entity;
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_CREATE_TAG",
                    payload: { requestTime: requestTime },
                    error: err
                });
            })

        dispatch({ type: "REQUEST_CREATE_TAG", payload: { requestTime: requestTime } });
        return fetchTask;
    }
};

export const unloadedState: TagState = {
    isloading: false,
    entities: {},
    createState: {
        isloading: false
    }
};


export const reducer: Reducer<TagState> = (state: TagState, incomingAction: Action) => {
    let action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_TAG_ENTITIES":
            return {
                ...state,
                isloading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_TAG_ENTITIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                entities: action.error
                    ? state.entities
                    : action.payload.entities,
                isloading: false
            };
        case "REQUEST_CREATE_TAG":
            return {
                ...state,
                createState: {
                    ...state.createState,
                    isloading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_TAG":
            if (action.payload.requestTime !== state.createState.requestTime)
                return state;

            return {
                ...state,
                createState: {
                    ...state.createState,
                    isloading: false,
                },
                entities: {
                    ...state.entities,
                    [action.payload.entity.tagId]: action.payload.entity
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};