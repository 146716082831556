let allTexts = {};

export const loadTexts = (texts: { [key: string]: string }) => {
    allTexts = texts;
}

export const getText = (key: LangKey, params?: any): string => {
    let text = allTexts[key] || "";
    if (params) {
        Object.keys(params).forEach(param => {
            text = text.replace(new RegExp("%{" + param + "}", "g"), params[param]);
        });
    }
    return text;
}

export const getAllTexts = (): { [key: string]: string } => {
    return allTexts;
}

type LangKey = "HmiStartSale" | "HmiRetrieveOrder" | "GenExpirityNone"
    | "GenExpirityNormal" | "GenExpirityDdm"
    | "GenExpirityEggs" | "GenStabilityStable" | "GenStabilityUnstable"
    | "GenUsername" | "GenPassword" | "GenLogin";