import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import {
    Field, reduxForm, FormProps,
    formValueSelector, FieldArray,
    WrappedFieldArrayProps
} from 'redux-form';
import { productMenuCategoriesValidation } from '../utils/validation';
import { productMenuCategoriesHelp } from '../utils/entityHelp';
import { FieldHelp } from './FieldHelp';
import { DataType } from './Home';
import StoresSelection from './StoresSelection';
import { availableMenuCategories } from '../store/MenuCategory';


type ProductMenuCategoriesProps = ProductMenuCategoriesOwnProps
    & WrappedFieldArrayProps<Api.ProductMenuCategoryModel>;

export interface ProductMenuCategoriesOwnProps {
    storeItemId: number;
    disabled?: boolean;
    productMenuCategories: Array<Api.ProductMenuCategoryModel>
    menuCategories: { [id: number]: Api.MenuCategoryModel }
    stores: { [id: number]: Api.StoreModel };
    allowedStores: Array<number>;
    dataType: DataType
}

export default class ProductMenuCategories extends React.Component<ProductMenuCategoriesProps, {}> {
    public render() {
        return (<div>
            {this.props.fields.map((member, index) => !this.props.productMenuCategories[index]
                || ((this.props.dataType === "Global"
                    && this.props.productMenuCategories[index].productMenuCategoryStores.length !== 0)
                    || (this.props.dataType === "Specific"
                && this.props.productMenuCategories[index].productMenuCategoryStores.length === 0))
                ? <div key={index}></div>
                : <div key={index}
                style={{ marginBottom: 5,
                    display: this.props.productMenuCategories[index]
                                && this.props.productMenuCategories[index].productMenuCategoryStores.length
                                && !this.props.productMenuCategories[index].productMenuCategoryStores
                                    .some(x => this.props.allowedStores
                                        .some(y => y === x.storeId))
                                ? "none"
                                : "block" }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <Field className="form-control"
                                validate={productMenuCategoriesValidation["menuCategoryId"]}
                                name={`${member}.menuCategoryId`}
                                disabled={this.props.disabled}
                                    component={FormField.getSelectField(
                                        _.sortBy(_.values(availableMenuCategories(this.props.menuCategories, this.props.allowedStores)), x => x.name)
                                        .map(x => ({ label: x.name, value: x.menuCategoryId })))} />
                        </div>
                    </div>
                    {this.props.dataType === "Specific"
                        && <div style={{ flex: "1" }}>
                            <div style={{ marginLeft: 3 }}>
                            <FieldHelp help={productMenuCategoriesHelp()["productMenuCategoryStores"]}>
                                    <Field
                                    validate={productMenuCategoriesValidation["productMenuCategoryStores"]}
                                        disabled={this.props.disabled}
                                        name={`${member}.productMenuCategoryStores`}
                                        component={(props) => <StoresSelection
                                            {...props}
                                            stores={_.values(this.props.stores)
                                                .filter(x => this.props.allowedStores.some(y => y === x.storeId))}
                                            value={props.input.value}
                                            storeIdsDisabled={[]}
                                            onChange={(value) => props.input.onChange(value)}
                                        />} />
                                </FieldHelp>
                            </div>
                        </div>}
                    <div style={{ flex: "0 0 auto" }}>
                        <button
                            type="button"
                            disabled={this.props.disabled}
                            style={{ marginLeft: 5 }}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    disabled={this.props.disabled}
                    className="btn btn-secondary btn-sm"
                    onClick={() => this.props.fields.push({
                        productId: this.props.storeItemId,
                        productMenuCategoryStores: this.props.dataType === "Specific"
                            ? [{ storeId: this.props.allowedStores[0] }]
                            : []
                    })}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}