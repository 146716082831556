import * as Api from "../api/api";
import * as _ from "lodash";
import { ApplicationState } from './';
import * as CrudeStore from "./Crude";

export const key: string = "menuCategory";

export type MenuCategoryState = CrudeStore.EntityCrudeState<Api.MenuCategoryModel, Api.MenuCategoryFilter>;

export const availableMenuCategories = (menuCatagories: { [id: number]: Api.MenuCategoryModel }, allowedStores: Array<number>): Array<Api.MenuCategoryModel> => {
    return _.filter(menuCatagories, x => x.menuCategoryStores.length === 0
        || x.menuCategoryStores.some(y => allowedStores.some(z => z === y.storeId)));
}

export const configuration: CrudeStore.CrudeStoreConfiguration<Api.MenuCategoryModel, Api.MenuCategoryFilter> = {
    key: key,
    storeSelector: x => x.menuCategory,
    idSelector: x => x.menuCategoryId,
    requestEntitiesFetch: (model, options) => {
        let api = new Api.MenuCategoryApi();
        return api.getEntities(model, options);
    },
    requestCreateFetch: (model, options) => {
        let api = new Api.MenuCategoryApi();
        return api.create(model, options);
    },
    requestUpdateFetch: (model, options) => {
        let api = new Api.MenuCategoryApi();
        return api.update(model, options);
    },
    requestDeleteFetch: (model, options) => {
        let api = new Api.MenuCategoryApi();
        return api._delete(model, options);
    },
    requestBulkUpdateFetch: (models, options) => {
        let api = new Api.MenuCategoryApi();
        return api.bulkUpdate(models, options);
    },
    requestSubmitCreateFetch: (models, options) => {
        let api = new Api.MenuCategoryApi();
        return api.submitCreate(models, options);
    },
    requestSubmitUpdateFetch: (models, options) => {
        let api = new Api.MenuCategoryApi();
        return api.submitUpdate(models, options);
    },
    requestDependencies: []
}

export const requestEntities = (requestTime: number,
    dispatch: (action: CrudeStore.KnownAction<Api.MenuCategoryModel, Api.MenuCategoryFilter>) => void,
    getState: () => ApplicationState) => CrudeStore.requestEntities(configuration, requestTime, dispatch, getState);

export const actionCreators = CrudeStore.getActionCreators<Api.MenuCategoryModel, Api.MenuCategoryFilter>(configuration);

export const reducer = CrudeStore.getReducer(key);