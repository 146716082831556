import * as React from 'react';

interface LoaderProps {
    isVisible: boolean;
    top?: number;
    size?: number;
    style?: any;
}
interface LoaderState {
}

const loaderStyle = {
    position: "absolute" as any,
    left: "50%"
};

export default class Loader extends React.Component<LoaderProps, LoaderState> {
    constructor(props) {
        super(props)
    }

    get style(): any {
        return {
            ...loaderStyle,
            top: this.props.top || 100,
            width: this.size,
            height: this.size,
            marginLeft: -(this.size / 2),
            marginTop: -(this.size / 2),
            ...this.props.style || {}
        };
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: LoaderProps) {
    }

    get visibleProps(): any {
        return {
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: "rgba(113, 112, 110, 0.5)"
        }
    }

    get hiddenProps(): any {
        return {
            display: 'none'
        }
    }

    get size(): number {
        return this.props.size || 120;
    }

    render() {
        return <div style={this.props.isVisible ? this.visibleProps : this.hiddenProps}>
            {this.props.isVisible && <img style={this.style} src={"/images/loader.svg"} />}
        </div>
    }
}