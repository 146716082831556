import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import {
    Field, reduxForm, FormProps,
    formValueSelector, FieldArray,
    WrappedFieldArrayProps
} from 'redux-form';
import { productVatsValidation } from '../utils/validation';
import { productVatsHelp } from '../utils/entityHelp';
import { FieldHelp } from './FieldHelp';

type ProductVatsProps = ProductVatsOwnProps
    & WrappedFieldArrayProps<Api.ProductSubFamilyModel>;

export interface ProductVatsOwnProps {
    storeItemId: number;
    disabled?: boolean;
    updateDisabled?: boolean;
    productVats: Array<Api.ProductVatModel>;
    vats: { [id: number]: Api.VatModel }
    countries: { [id: number]: Api.CountryModel }
}

export default class ProductVats extends React.Component<ProductVatsProps, {}> {
    public render() {
        return (<div>
            {this.props.fields.map((member, index) => <div key={index}
                style={{ marginBottom: 5 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <FieldHelp help={productVatsHelp()["countryId"]}>
                                <Field className="form-control"
                                    validate={productVatsValidation["countryId"]}
                                    name={`${member}.countryId`}
                                    disabled={this.props.disabled
                                        || (this.props.productVats[index]
                                            && this.props.productVats[index].productVatId
                                            && this.props.updateDisabled)}
                                    component={FormField.getSelectField(
                                        _.values(this.props.countries)
                                            .map(x => ({ label: x.name, value: x.countryId })))} />
                            </FieldHelp>
                        </div>
                    </div>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <Field className="form-control"
                                validate={productVatsValidation["vatId"]}
                                name={`${member}.vatId`}
                                disabled={this.props.disabled
                                    || (this.props.productVats[index]
                                        && this.props.productVats[index].productVatId
                                        && this.props.updateDisabled)}
                                component={FormField.getSelectField(
                                    _.values(this.props.vats)
                                        .map(x => ({ label: x.name, value: x.vatId })))} />
                        </div>
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                        <button
                            type="button"
                            disabled={this.props.disabled
                                || this.props.updateDisabled}
                            style={{ marginLeft: 5 }}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    disabled={this.props.disabled}
                    className="btn btn-secondary btn-sm"
                    onClick={() => this.props.fields.push({
                        productId: this.props.storeItemId
                    })}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}