import * as Api from '../api/api';
import * as React from 'react';
import * as FormField from './FormField';
import * as CrudeStore from '../store/Crude';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector
} from 'redux-form';
import { supplierPaymentValidation } from "../utils/validation";
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import { diff } from 'deep-object-diff';

type SupplierPaymentFormProps = InjectedFormProps
    & FormField.FormProps
    & SupplierPaymentFormOwnProps
    & SupplierPaymentFormExternalProps;

interface SupplierPaymentFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.SupplierPaymentModel) => any;
}

interface SupplierPaymentFormOwnProps {
    disabled?: boolean;
    formType: CrudeStore.FormType;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
}

class SupplierPaymentForm extends React.Component<SupplierPaymentFormProps, {}> {
    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create";
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{"Nom:"}</label>
                    <div className="col-sm-8" style={this.getFieldStyle("name")}>
                        <Field className="form-control"
                            validate={supplierPaymentValidation["name"]}
                            name="name"
                            disabled={this.props.disabled
                                || this.globalFieldDisabled}
                            component={FormField.renderField}
                            type="text" />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType !== "Create"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = reduxForm({
    form: 'createSupplierPayment',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SupplierPaymentForm) as any;
const createSelector = formValueSelector('createSupplierPayment')

let updateForm = reduxForm({
    form: 'updateSupplierPayment',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SupplierPaymentForm) as any;
const updateSelector = formValueSelector('updateSupplierPayment')

let submissionForm = reduxForm({
    form: 'submissionSupplierPayment',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SupplierPaymentForm) as any;
const submissionSelector = formValueSelector('submissionSupplierPayment')

export const CreateSupplierPaymentForm = connect((state: ApplicationState) => {
    return {
        formType: "Create",
        account: state.account.currentUser.account
    } as SupplierPaymentFormOwnProps;
})(createForm as any) as any as React.ComponentClass<SupplierPaymentFormExternalProps>;

export const UpdateSupplierPaymentForm = connect((state: ApplicationState) => {
    return {
        formType: "Update",
        disabled: !((state.supplierPayment.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
                .some(x => x.supplierPaymentId === state.supplierPayment.selectedEntityId)),
        initialValues: state.supplierPayment.entities[state.supplierPayment.selectedEntityId],
        account: state.account.currentUser.account
    } as SupplierPaymentFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<SupplierPaymentFormExternalProps>;

export const SubmissionSupplierPaymentForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        formType: "Submission",
        initialValues: JSON.parse(submission.content),
        account: state.account.currentUser.account,
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.supplierPayment.entities[submission.supplierPaymentId])
            : {}
    } as SupplierPaymentFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<SupplierPaymentFormExternalProps>;