import * as Api from "../api/api";
import { Action, Reducer } from 'redux';
import { ApplicationState } from './';
import * as CrudeStore from "./Crude";

export const key: string = "supplierDelivery";

export type SupplierDeliveryState = CrudeStore.EntityCrudeState<Api.SupplierDeliveryModel, Api.SupplierDeliveryFilter>
    & SupplierDeliveryOwnState;

interface SupplierDeliveryOwnState {
    isOpen: boolean;
}

export const configuration: CrudeStore.CrudeStoreConfiguration<Api.SupplierDeliveryModel, Api.SupplierDeliveryFilter> = {
    key: key,
    storeSelector: x => x.supplierDelivery,
    idSelector: x => x.supplierDeliveryId,
    requestEntitiesFetch: (model, options) => {
        let api = new Api.SupplierDeliveryApi();
        return api.getEntities(model, options);
    },
    requestCreateFetch: (model, options) => {
        let api = new Api.SupplierDeliveryApi();
        return api.create(model, options);
    },
    requestUpdateFetch: (model, options) => {
        let api = new Api.SupplierDeliveryApi();
        return api.update(model, options);
    },
    requestDeleteFetch: (model, options) => {
        let api = new Api.SupplierDeliveryApi();
        return api._delete(model, options);
    },
    requestBulkUpdateFetch: (models, options) => {
        let api = new Api.SupplierDeliveryApi();
        return api.bulkUpdate(models, options);
    },
    requestSubmitCreateFetch: (models, options) => {
        let api = new Api.SupplierDeliveryApi();
        return api.submitCreate(models, options);
    },
    requestSubmitUpdateFetch: (models, options) => {
        let api = new Api.SupplierDeliveryApi();
        return api.submitUpdate(models, options);
    },
    requestDependencies: []
}

export const requestEntities = (requestTime: number,
    dispatch: (action: CrudeStore.KnownAction<Api.SupplierDeliveryModel, Api.SupplierDeliveryFilter>) => void,
    getState: () => ApplicationState) => CrudeStore.requestEntities(configuration, requestTime, dispatch, getState);

interface SupplierDeliveryOpenDialog {
    type: "SUPPLIERDELIVERY_OPEN_DIALOG"
}
interface SupplierDeliveryCloseDialog {
    type: "SUPPLIERDELIVERY_CLOSE_DIALOG"
}

type KnownAction = SupplierDeliveryOpenDialog | SupplierDeliveryCloseDialog;

export const actionCreators = {
    ...CrudeStore.getActionCreators<Api.SupplierDeliveryModel, Api.SupplierDeliveryFilter>(configuration),
    supplierDeliveryOpenDialog: () => <SupplierDeliveryOpenDialog>{ type: "SUPPLIERDELIVERY_OPEN_DIALOG" },
    supplierDeliveryCloseDialog: () => <SupplierDeliveryCloseDialog>{ type: "SUPPLIERDELIVERY_CLOSE_DIALOG" }
};

const unloadedState: SupplierDeliveryState = {
    ...CrudeStore.unloadedState,
    isOpen: false
};

export const ownReducer: Reducer<SupplierDeliveryState> = (state: SupplierDeliveryState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SUPPLIERDELIVERY_OPEN_DIALOG":
            return {
                ...state,
                isOpen: true
            };
        case "SUPPLIERDELIVERY_CLOSE_DIALOG":
            return {
                ...state,
                isOpen: false
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

const crudeReducer = CrudeStore.getReducer(key);
export const reducer = (state: SupplierDeliveryState, action: Action) => crudeReducer(ownReducer(state, action), action);