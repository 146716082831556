import * as React from "react";
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import * as ImageStore from '../store/Image';

type ImageUploadProps = ImageUploadOwnProps
    & ImageStore.ImageState
    & typeof ImageStore.actionCreators;

interface ImageUploadOwnProps {
    fieldKey: string;
    value: number;
    onChange: (imageId: number) => void;
    height?: number;
    disabled?: boolean;
    acceptProp?: string;
}

class ImageUpload extends React.Component<ImageUploadProps, {}> {
    fileInput: HTMLInputElement;

    get imageUploadState(): ImageStore.ImageUploadState {
        return this.props.imageStates[this.props.fieldKey]
            || ImageStore.unloadedUploadState;
    }

    public render() {
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    {this.props.value && <div style={{ border: "1px solid" }}>
                        <img height={this.props.height || 34} src={"/api/Image/GetImageContent?imageId=" + this.props.value} />
                    </div>}
                    {this.props.value && <button className="btn btn-xs btn-secondary"
                        type={"button"}
                        disabled={this.props.disabled}
                        style={{ marginLeft: 5 }}
                        onClick={(e) => {
                            this.props.onChange(null);
                            e.preventDefault();
                        }}>
                        <i className="glyphicon glyphicon-remove"></i>
                    </button>}
                    {!this.props.value && !this.imageUploadState.isLoading && <input
                        type="file"
                        accept= { this.props.acceptProp && this.props.acceptProp }
                        style={{ order: 0, flex: "0 0 auto" }}
                        ref={(input) => this.fileInput = input} />}
                    {this.imageUploadState.isLoading
                        && <img style={{ order: 0, flex: "0 0 auto" }}
                        src={"/images/loader.svg"} height={this.props.height || 34} />}
                    {!this.props.value && <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        disabled={this.imageUploadState.isLoading
                            || this.props.disabled}
                        onClick={(e) => {
                            ((this.props.requestCreateImage(new Date().getTime(),
                                this.props.fieldKey,
                                this.fileInput.files.item(0)) as any) as Promise<any>)
                                .then(() => {
                                    this.props.onChange(this.imageUploadState.imageId);
                                });
                            e.preventDefault();
                            e.stopPropagation();
                        }}
                        style={{ order: 1, flex: "0 0 auto" }}>
                        <i className="glyphicon glyphicon-cloud-upload"></i>
                    </button>}
                </div>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => state.image,
    ImageStore.actionCreators)(ImageUpload as any) as any as React.ComponentClass<ImageUploadOwnProps>;