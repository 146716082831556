import * as Account from './Account';
import * as Tabs from './Tabs';
import * as Seed from './Seed';
import * as Family from './Family';
import * as SubFamily from './SubFamily';
import * as Vat from './Vat';
import * as Unit from './Unit';
import * as SupplierMain from './SupplierMain';
import * as SupplierSub from './SupplierSub';
import * as Menu from './Menu';
import * as MenuCategory from './MenuCategory';
import * as PriceName from './PriceName';
import * as Product from './Product';
import * as ExternalProduct from './ExternalProduct';
import * as Promotion from './Promotion';
import * as Currency from './Currency';
import * as SupplierAdvert from './SupplierAdvert';
import * as SupplierDelivery from './SupplierDelivery';
import * as SupplierPayment from './SupplierPayment';
import * as Image from './Image';
import * as DataGridsStore from './DataGrids';
import * as TransferStore from './Transfer';
import * as UserHub from './UserHub';
import * as Submission from './Submission';
import * as Tag from './Tag';
import { reducer as reduxForm } from 'redux-form';
import { reducer as notifications } from 'react-notification-system-redux';

// The top-level state object
export interface ApplicationState {
    account: Account.AccountState;
    seed: Seed.SeedState;
    family: Family.FamilyState;
    subFamily: SubFamily.SubFamilyState;
    vat: Vat.VatState;
    unit: Unit.UnitState;
    supplierMain: SupplierMain.SupplierMainState;
    supplierSub: SupplierSub.SupplierSubState;
    menu: Menu.MenuState;
    menuCategory: MenuCategory.MenuCategoryState;
    priceName: PriceName.PriceNameState;
    product: Product.ProductState;
    promotion: Promotion.PromotionState;
    currency: Currency.CurrencyState;
    supplierAdvert: SupplierAdvert.SupplierAdvertState;
    supplierDelivery: SupplierDelivery.SupplierDeliveryState;
    supplierPayment: SupplierPayment.SupplierPaymentState;
    transfer: TransferStore.TransferState;
    userHub: UserHub.UserHubState;
    submission: Submission.SubmissionState;
    image: Image.ImageState;
    tag: Tag.TagState;
    form: typeof reduxForm;
    notifications: typeof notifications;
    tabs: Tabs.TabsState;
    dataGrids: DataGridsStore.DataGridsState;
    externalProducts: ExternalProduct.ExternalProductState;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    account: Account.reducer,
    seed: Seed.reducer,
    family: Family.reducer,
    subFamily: SubFamily.reducer,
    vat: Vat.reducer,
    unit: Unit.reducer,
    supplierMain: SupplierMain.reducer,
    supplierSub: SupplierSub.reducer,
    menu: Menu.reducer,
    menuCategory: MenuCategory.reducer,
    priceName: PriceName.reducer,
    product: Product.reducer,
    promotion: Promotion.reducer,
    currency: Currency.reducer,
    supplierAdvert: SupplierAdvert.reducer,
    supplierDelivery: SupplierDelivery.reducer,
    supplierPayment: SupplierPayment.reducer,
    transfer: TransferStore.reducer,
    userHub: UserHub.reducer,
    submission: Submission.reducer,
    image: Image.reducer,
    tag: Tag.reducer,
    form: reduxForm,
    notifications: notifications,
    tabs: Tabs.reducer,
    dataGrids: DataGridsStore.reducer,
    externalProducts: ExternalProduct.reducer
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
