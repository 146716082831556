import * as Api from "../api/api";
import { Action, Reducer } from 'redux';
import { ApplicationState } from './';
import * as CrudeStore from "./Crude";

export const key: string = "supplierAdvert";

export type SupplierAdvertState = CrudeStore.EntityCrudeState<Api.SupplierAdvertModel, Api.SupplierAdvertFilter>
    & SupplierAdvertOwnState;

interface SupplierAdvertOwnState {
    isOpen: boolean;
}

export const configuration: CrudeStore.CrudeStoreConfiguration<Api.SupplierAdvertModel, Api.SupplierAdvertFilter> = {
    key: key,
    storeSelector: x => x.supplierAdvert,
    idSelector: x => x.supplierAdvertId,
    requestEntitiesFetch: (model, options) => {
        let api = new Api.SupplierAdvertApi();
        return api.getEntities(model, options);
    },
    requestCreateFetch: (model, options) => {
        let api = new Api.SupplierAdvertApi();
        return api.create(model, options);
    },
    requestUpdateFetch: (model, options) => {
        let api = new Api.SupplierAdvertApi();
        return api.update(model, options);
    },
    requestDeleteFetch: (model, options) => {
        let api = new Api.SupplierAdvertApi();
        return api._delete(model, options);
    },
    requestBulkUpdateFetch: (models, options) => {
        let api = new Api.SupplierAdvertApi();
        return api.bulkUpdate(models, options);
    },
    requestSubmitCreateFetch: (models, options) => {
        let api = new Api.SupplierAdvertApi();
        return api.submitCreate(models, options);
    },
    requestSubmitUpdateFetch: (models, options) => {
        let api = new Api.SupplierAdvertApi();
        return api.submitUpdate(models, options);
    },
    requestDependencies: []
}

export const requestEntities = (requestTime: number,
    dispatch: (action: CrudeStore.KnownAction<Api.SupplierAdvertModel, Api.SupplierAdvertFilter>) => void,
    getState: () => ApplicationState) => CrudeStore.requestEntities(configuration, requestTime, dispatch, getState);

interface SupplierAdvertOpenDialog {
    type: "SUPPLIERADVERT_OPEN_DIALOG"
}
interface SupplierAdvertCloseDialog {
    type: "SUPPLIERADVERT_CLOSE_DIALOG"
}

type KnownAction = SupplierAdvertOpenDialog | SupplierAdvertCloseDialog;

export const actionCreators = {
    ...CrudeStore.getActionCreators<Api.SupplierAdvertModel, Api.SupplierAdvertFilter>(configuration),
    supplierAdvertOpenDialog: () => <SupplierAdvertOpenDialog>{ type: "SUPPLIERADVERT_OPEN_DIALOG" },
    supplierAdvertCloseDialog: () => <SupplierAdvertCloseDialog>{ type: "SUPPLIERADVERT_CLOSE_DIALOG" }
};

const unloadedState: SupplierAdvertState = {
    ...CrudeStore.unloadedState,
    isOpen: false
};

export const ownReducer: Reducer<SupplierAdvertState> = (state: SupplierAdvertState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "SUPPLIERADVERT_OPEN_DIALOG":
            return {
                ...state,
                isOpen: true
            };
        case "SUPPLIERADVERT_CLOSE_DIALOG":
            return {
                ...state,
                isOpen: false
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

const crudeReducer = CrudeStore.getReducer(key);
export const reducer = (state: SupplierAdvertState, action: Action) => crudeReducer(ownReducer(state, action), action);