import * as React from "react";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as SupplierMainStore from "../store/SupplierMain";
import * as SupplierAdvertStore from "../store/SupplierAdvert";
import * as SupplierDeliveryStore from "../store/SupplierDelivery";
import * as SupplierPaymentStore from "../store/SupplierPayment";
import * as CrudeStore from "../store/Crude";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import { CreateSupplierMainForm, UpdateSupplierMainForm } from "./SupplierMainForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { validateGridUpdates, supplierMainValidation } from "../utils/validation";
import { canCrude } from "../utils/userRights";

type SuppliersProps = SuppliersOwnProps
    & CrudeStore.EntityCrudeState<Api.SupplierModel, Api.SubFamilyFilter>
    & typeof SupplierMainStore.actionCreators
    & typeof SupplierAdvertStore.actionCreators
    & typeof SupplierDeliveryStore.actionCreators
    & typeof SupplierPaymentStore.actionCreators
    & typeof TabsStore.actionCreators;

interface SuppliersOwnProps {
    units: { [id: number]: Api.UnitModel }
    account: Api.AccountModel;
    helpHtml: string;
}
const name = "supplierMains";
const Table: new () => EntityTable<Api.SupplierModel> = EntityTable as any;

class SupplierMains extends React.Component<SuppliersProps, {}> {

    update(props: SuppliersProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps: SuppliersProps) {
        //this.update(nextProps);
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Sources</h2>
                <CrudeLayout
                    name={name}
                    createForm={<div style={{ maxWidth: 1000 }}>
                        <h2>Créer</h2>
                        <CreateSupplierMainForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            openSupplierAdvert={() => this.props.supplierAdvertOpenDialog()}
                            openSupplierDelivery={() => this.props.supplierDeliveryOpenDialog()}
                            openSupplierPayment={() => this.props.supplierPaymentOpenDialog()}
                            closeSupplierAdvert={() => this.props.supplierAdvertCloseDialog()}
                            closeSupplierDelivery={() => this.props.supplierDeliveryCloseDialog()}
                            closeSupplierPayment={() => this.props.supplierPaymentCloseDialog()}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 1000 }}>
                        <h2>Editer</h2>
                        <UpdateSupplierMainForm
                            actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            openSupplierAdvert={() => this.props.supplierAdvertOpenDialog()}
                            openSupplierDelivery={() => this.props.supplierDeliveryOpenDialog()}
                            openSupplierPayment={() => this.props.supplierPaymentOpenDialog()}
                            closeSupplierAdvert={() => this.props.supplierAdvertCloseDialog()}
                            closeSupplierDelivery={() => this.props.supplierDeliveryCloseDialog()}
                            closeSupplierPayment={() => this.props.supplierPaymentCloseDialog()}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    help={<div>
                        <div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />
                    </div>}
                    table={<Table
                        gridKey={name}
                        account={this.props.account}
                        isLoading={this.props.isLoading}
                        data={this.props.entities}
                        idPropertyName={"supplierId"}
                        updateValidation={(updates) =>
                            validateGridUpdates(updates, supplierMainValidation, this.props.entities)}
                        onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                            new Date().getTime(),
                            updates) as any}
                        onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                        onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                        onRequestUpdate={(id) => {
                            this.props.selectUpdateEntity(id);
                            this.props.selectTab(name, "update");
                        }}
                        columns={[
                        {
                            key: "name",
                            name: "Nom",
                            editable: true,
                        },
                        {
                            key: "deliveryFeeFix",
                            name: "Livraison fix.",
                            editable: true,
                        },
                        {
                            key: "deliveryFeeVar",
                            name: "Livraison var.",
                            editable: true,
                        },
                        {
                            key: "deliveryFeeVarUnitId",
                            name: "Livraison var. unite",
                            editable: true,
                        }
                        ]} />} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.supplierMain,
    units: state.unit.entities,
    account: state.account.currentUser.account,
    helpHtml: state.seed.seed.supplierHelp
} as SuppliersOwnProps), {
    ...SupplierAdvertStore.actionCreators,
    ...SupplierDeliveryStore.actionCreators,
    ...SupplierPaymentStore.actionCreators,
    ...TabsStore.actionCreators,
    ...SupplierMainStore.actionCreators,
} as any)(SupplierMains);