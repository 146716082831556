import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector,
} from 'redux-form';

type ProductFilterFormProps = InjectedFormProps
    & FormField.FormProps
    & ProductFilterFormOwnProps;

interface ProductFilterFormOwnProps {
    families: { [id: number]: Api.FamilyModel };
    subFamilies: { [id: number]: Api.SubFamilyModel }
    supplierMains: { [id: number]: Api.SupplierModel }
    supplierSubs: { [id: number]: Api.SupplierModel }
    familyId: number;
}

const formGroupStyle = {
    margin: 10
};

class ProductFilterForm extends React.Component<ProductFilterFormProps, {}> {
    public render() {
        return (
            <FormField.BaseForm {...this.props} className="form-inline">
                <div className="form-group mb-2" style={formGroupStyle}>
                    <label className="control-label">Nom:</label>
                    <Field name="productName"
                        className="form-control input-sm"
                        containerStyle={{ width: 150, display: "inline-block" }}
                        style={{ width: 150 }}
                        component={FormField.renderField}
                        type="text" />
                </div>
                <div className="form-group mb-2" style={formGroupStyle}>
                    <Field name="includeComments"
                        label={"rechercher les commentaires"}
                        containerStyle={{ width: 150, display: "inline-block" }}
                        component={FormField.CheckBoxField} />
                </div>
                <div className="form-group mb-2" style={formGroupStyle}>
                    <label className="control-label">Rayon:</label>
                    <Field name="familyId"
                        style={{ width: 150, display: "inline-block" }}
                        component={FormField.getSelectField(
                            [{ label: "--- TOUTES ---", value: null }]
                                .concat(_.values(this.props.families)
                                .map(x => ({ label: x.name, value: x.familyId }))))} />
                </div>
                <div className="form-group mb-2" style={formGroupStyle}>
                    <label className="control-label">Sous famille:</label>
                    <Field name="subFamilyId"
                        style={{ width: 150, display: "inline-block" }}
                        component={FormField.getSelectField(
                            [{ label: "--- TOUTES ---", value: null }]
                                .concat(_.values(this.props.subFamilies)
                                    .filter(x => x.familyId === this.props.familyId)
                                    .map(x => ({ label: x.name, value: x.subFamilyId }))))} />
                </div>
                <div className="form-group mb-2" style={formGroupStyle}>
                    <label className="control-label">Source:</label>
                    <Field name="supplierId"
                        style={{ width: 150, display: "inline-block" }}
                        component={FormField.getSelectField(
                            [{ label: "--- TOUTES ---", value: null }]
                                .concat(_.values(this.props.supplierMains)
                                    .map(x => ({ label: x.name, value: x.supplierId }))))} />
                </div>
                <div className="form-group mb-2" style={formGroupStyle}>
                    <button className="btn btn-primary btn-sm"
                        disabled={this.props.pristine}
                        type="submit">Filtrer</button>
                </div>
            </FormField.BaseForm>
        );
    }
}

let form = reduxForm({
    form: 'filterProduct',
    destroyOnUnmount: false,
    enableReinitialize: true
})(ProductFilterForm) as any;
const formSelector = formValueSelector('filterProduct')

export default connect((state: ApplicationState) => {
    return {
        families: state.family.entities,
        subFamilies: state.subFamily.entities,
        supplierMains: state.supplierMain.entities,
        supplierSubs: state.supplierSub.entities,
        familyId: formSelector(state, "familyId"),
        initialValues: state.product.filter
    } as ProductFilterFormOwnProps;
})(form) as any