import * as React from "react";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as FamilyStore from "../store/Family";
import * as SubFamilyStore from "../store/SubFamily";
import * as CrudeStore from "../store/Crude";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import { CreateSubFamilyForm, UpdateSubFamilyForm } from "./SubFamilyForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { DropDownFamilyEditor } from "./DataGridEditor";
import { validateGridUpdates, subFamilyValidation } from "../utils/validation";
import { canCrude } from "../utils/userRights";

type SubFamiliesProps = SubFamiliesOwnProps
    & CrudeStore.EntityCrudeState<Api.SubFamilyModel, Api.SubFamilyFilter>
    & typeof SubFamilyStore.actionCreators
    & typeof TabsStore.actionCreators;

interface SubFamiliesOwnProps {
    families: { [id: number]: Api.FamilyModel }
    account: Api.AccountModel;
    helpHtml: string;
}

const name = "subFamilies";
const Table: new () => EntityTable<Api.SubFamilyModel> = EntityTable as any;

class SubFamilies extends React.Component<SubFamiliesProps, {}> {

    update(props: SubFamiliesProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps: SubFamiliesProps) {
        //this.update(nextProps);
    }

    get familyIdOptions(): Array<{ id: string; value: string; text: string; title: string }> {
        return _.values(this.props.families)
            .map(x => ({
                id: x.familyId.toString(),
                value: x.familyId.toString(),
                text: x.name,
                title: x.name
            }));
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Sous-familles</h2>
                <CrudeLayout
                    name={name}
                    createForm={<div style={{ maxWidth: 800 }}>
                        <h2>Créer</h2>
                        <CreateSubFamilyForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 800 }}>
                        <h2>Editer</h2>
                        <UpdateSubFamilyForm
                            actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    help={<div>
                        <div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />
                    </div>}
                    table={<Table
                        gridKey={name}
                        account={this.props.account}
                        isLoading={this.props.isLoading}
                        data={this.props.entities}
                        idPropertyName={"subFamilyId"}
                        onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                            new Date().getTime(),
                            updates) as any}
                        onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                        onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                        onRequestUpdate={(id) => {
                            this.props.selectUpdateEntity(id);
                            this.props.selectTab(name, "update");
                        }}
                        updateValidation={(updates) =>
                            validateGridUpdates(updates, subFamilyValidation, this.props.entities)}
                        columns={[
                        {
                            key: "familyId",
                            name: "Rayon",
                            editable: true,
                            editor: <DropDownFamilyEditor />,
                            formatter: ({ value }) => <div>{this.props.families[value]
                                && this.props.families[value].name}</div>
                        },
                        {
                            key: "name",
                            name: "Nom",
                            editable: true,
                        },
                        {
                            key: "imageId",
                            name: "Image",
                            editable: false,
                            formatter: ({ value }) => <img height={30} src={"/api/Image/GetImageContent?imageId=" + value} />
                        },
                        {
                            key: "order",
                            name: "Ordre",
                            editable: true,
                        },
                        {
                            key: "subFamilyCustoms",
                            name: 'Customisations',
                            editable: false,
                            formatter: ({ value }) => <div>{value.length}</div>
                        }]} />}/>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.subFamily,
    families: state.family.entities,
    account: state.account.currentUser.account,
    helpHtml: state.seed.seed.subFamilyHelp
} as SubFamiliesProps), { ...SubFamilyStore.actionCreators, ...TabsStore.actionCreators } as any)(SubFamilies);