import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import * as Download from "downloadjs";
import * as MimeTypes from "mime-types";
import Moment from 'moment';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';

export interface TransferState {
    storeId: number,
    downloadXml: {
        isLoading: boolean;
        requestTime?: number;
    };
    downloadImages: {
        isLoading: boolean;
        requestTime?: number;
    };
    notifyModerators: {
        isLoading: boolean;
        requestTime?: number;
        sentTime?: number;
    };
    downloadProductPrices: {
        isLoading: boolean;
        requestTime?: number;
    };
}

interface RequestV6Xml {
    type: 'REQUEST_V6_XML';
    payload: { requestTime: number; }
}
interface ReceiveV6Xml {
    type: 'RECEIVE_V6_XML';
    payload: { requestTime: number; }
    error?: any;
}

interface RequestImagesZip {
    type: 'REQUEST_IMAGES_ZIP';
    payload: { requestTime: number; }
}
interface ReceiveImagesZip {
    type: 'RECEIVE_IMAGES_ZIP';
    payload: { requestTime: number; }
    error?: any;
}

//interface RequestDownloadProductPrices {
//    type: 'REQUEST_DOWNLOAD_PRODUCT_PRICES';
//    payload: { requestTime: number; }
//}
//interface ReceiveDownloadProductPrices {
//    type: 'RECEIVE_DOWNLOAD_PRODUCT_PRICES';
//    payload: { requestTime: number; }
//    error?: any;
//}

interface RequestNotifyModerators {
    type: 'REQUEST_NOTIFY_MODERATORS';
    payload: { requestTime: number; }
}
interface ReceiveNotifyModerators {
    type: 'RECEIVE_NOTIFY_MODERATORS';
    payload: { requestTime: number; }
    error?: any;
}

interface TransferUpdateStoreId {
    type: 'TRANSFER_UPDATE_STOREID';
    payload: { value: number; }
}

export type KnownAction = RequestV6Xml | ReceiveV6Xml
    | RequestImagesZip | ReceiveImagesZip
    | TransferUpdateStoreId | RequestNotifyModerators
    | ReceiveNotifyModerators
    ;
    //| RequestDownloadProductPrices
    //| ReceiveDownloadProductPrices;

export const actionCreators = {
    updateStoreId: (value: number) => <TransferUpdateStoreId>{
        type: "TRANSFER_UPDATE_STOREID", payload: { value: value }
    },
    requestV6Xml: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (requestTime === getState().transfer.downloadXml.requestTime)
            return Promise.reject("Already did");

        let api = new Api.TransferApi();
        let task = api.generateV6Xml({
            storeId: getState().transfer.storeId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_V6_XML", payload: { requestTime: requestTime } });
                let fileName = "Export_V6_" + Moment().format("YYYY_MM_DD") + ".xml";
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            }).catch(err => {
                dispatch({ type: "RECEIVE_V6_XML", payload: { requestTime: requestTime }, error: err });
            });

        addTask(task);
        dispatch({ type: "REQUEST_V6_XML", payload: { requestTime: requestTime } });
        return task;
    },
    requestImagesZip: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (requestTime === getState().transfer.downloadImages.requestTime)
            return Promise.reject("Already did");

        let api = new Api.TransferApi();
        let task = api.downloadImages({
            storeId: getState().transfer.storeId
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(response => response.blob())
            .then(blob => {
                dispatch({ type: "RECEIVE_IMAGES_ZIP", payload: { requestTime: requestTime } });
                let fileName = "Images_" + Moment().format("YYYY_MM_DD") + ".zip";
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            }).catch(err => {
                dispatch({ type: "RECEIVE_IMAGES_ZIP", payload: { requestTime: requestTime }, error: err });
            });

        addTask(task);
        dispatch({ type: "REQUEST_IMAGES_ZIP", payload: { requestTime: requestTime } });
        return task;
    },
    requestNotifyModerators: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (requestTime === getState().transfer.notifyModerators.requestTime)
            return Promise.reject("Already did");

        let api = new Api.TransferApi();
        let task = api.notifyModerators({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(() => {
                dispatch({ type: "RECEIVE_NOTIFY_MODERATORS", payload: { requestTime: requestTime } });
                dispatch(Notifications.success({
                    title: 'Succès',
                    message: "Un email a bien été envoyer à l'équipe LGAP",
                    position: 'tc'
                }) as any);
            }).catch(err => {
                dispatch({ type: "RECEIVE_NOTIFY_MODERATORS", payload: { requestTime: requestTime }, error: err });

                if (err && err.status == 400) {
                    dispatch(Notifications.error({
                        title: 'Erreur',
                        message: "Vous n'avez pas de demandes en cours",
                        position: 'tc'
                    }) as any);
                }
                else if (err && err.status == 503) {
                    dispatch(Notifications.error({
                        title: 'Erreur',
                        message: "Erreur lors de l'envoie de l'email",
                        position: 'tc'
                    }) as any);
                }
                else {
                    dispatch(Notifications.error({
                        title: 'Erreur',
                        message: "Erreur inconnue",
                        position: 'tc'
                    }) as any);
                }
            });

        addTask(task);
        dispatch({ type: "REQUEST_NOTIFY_MODERATORS", payload: { requestTime: requestTime } });
        return task;
    }
    //requestDownloadProductPrices: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    //    if (requestTime === getState().transfer.downloadImages.requestTime)
    //        return Promise.reject("Already did");

    //    let api = new Api.TransferApi();
    //    let task = api.apiTransferDownloadProductPricesGet(
    //        { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
    //        .then(response => response.blob())
    //        .then(blob => {
    //            dispatch({
    //                type: "RECEIVE_DOWNLOAD_PRODUCT_PRICES",
    //                payload: { requestTime: requestTime }
    //            });
    //            let fileName = "Prix_Produits_" + Moment().format("YY_MM_DD") + ".csv";
    //            return Download(blob,
    //                fileName,
    //                MimeTypes.lookup(fileName) || "text/plain");
    //        }).catch(err => {
    //            dispatch({
    //                type: "RECEIVE_DOWNLOAD_PRODUCT_PRICES",
    //                payload: { requestTime: requestTime },
    //                error: err
    //            });
    //        });

    //    addTask(task);
    //    dispatch({
    //        type: "REQUEST_DOWNLOAD_PRODUCT_PRICES",
    //        payload: { requestTime: requestTime }
    //    });
    //    return task;
    //},
};

const unloadedState: TransferState = {
    storeId: 1,
    downloadImages: {
        isLoading: false
    },
    downloadXml: {
        isLoading: false
    },
    notifyModerators: {
        isLoading: false
    },
    downloadProductPrices: {
        isLoading: false
    }
};

export const reducer: Reducer<TransferState> = (state: TransferState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_V6_XML':
            return {
                ...state,
                downloadXml: {
                    ...state.downloadXml,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case 'RECEIVE_V6_XML':
            if (action.payload.requestTime !== state.downloadXml.requestTime)
                return state;

            return {
                ...state,
                downloadXml: {
                    ...state.downloadXml,
                    isLoading: false
                }
            };
        case 'REQUEST_IMAGES_ZIP':
            return {
                ...state,
                downloadImages: {
                    ...state.downloadImages,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case 'RECEIVE_IMAGES_ZIP':
            if (action.payload.requestTime !== state.downloadImages.requestTime)
                return state;

            return {
                ...state,
                downloadImages: {
                    ...state.downloadImages,
                    isLoading: false
                }
            };
        case 'REQUEST_NOTIFY_MODERATORS':
            return {
                ...state,
                notifyModerators: {
                    ...state.notifyModerators,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case 'RECEIVE_NOTIFY_MODERATORS':
            if (action.payload.requestTime !== state.notifyModerators.requestTime)
                return state;

            return {
                ...state,
                notifyModerators: {
                    ...state.notifyModerators,
                    isLoading: false,
                    sentTime: action.error
                        ? action.payload.requestTime
                        : state.notifyModerators.sentTime
                }
            };
        //case 'REQUEST_DOWNLOAD_PRODUCT_PRICES':
        //    return {
        //        ...state,
        //        downloadProductPrices: {
        //            ...state.downloadProductPrices,
        //            isLoading: true,
        //            requestTime: action.payload.requestTime
        //        }
        //    };
        //case 'RECEIVE_DOWNLOAD_PRODUCT_PRICES':
        //    if (action.payload.requestTime !== state.downloadProductPrices.requestTime)
        //        return state;

        //    return {
        //        ...state,
        //        downloadProductPrices: {
        //            ...state.downloadProductPrices,
        //            isLoading: false
        //        }
        //    };
        case "TRANSFER_UPDATE_STOREID":
            return {
                ...state,
                storeId: action.payload.value
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};