import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import * as CrudeStore from '../store/Crude';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector, FieldArray,
    WrappedFieldArrayProps
} from 'redux-form';
import {
    supplierMainValidation,
    supplierDeliverySuppliers,
    supplierPaymentSuppliers,
    supplierAdvertSuppliers,
    supplierFamilyRotationValidation,
    supplierVolumeDiscountValidation,
    supplierDiscountValidation
} from "../utils/validation";
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import {
    supplierHelp,
    supplierFamilyHelp,
    supplierVolumeDiscountHelp
} from '../utils/entityHelp';
import DialogModel from './DialogModal';
import SupplierAdverts from './SupplierAdverts';
import SupplierDeliveries from './SupplierDeliveries';
import SupplierPayments from './SupplierPayments';
import { FieldHelp } from './FieldHelp';
import { diff } from 'deep-object-diff';

type SupplierMainFormProps = InjectedFormProps
    & FormField.FormProps
    & SupplierMainFormOwnProps
    & SupplierMainFormExternalProps;

interface SupplierMainFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.SupplierModel) => any;

    openSupplierAdvert: () => void;
    closeSupplierAdvert: () => void;

    openSupplierDelivery: () => void;
    closeSupplierDelivery: () => void;

    openSupplierPayment: () => void;
    closeSupplierPayment: () => void;
}

interface SupplierMainFormOwnProps {
    supplierId: number;
    units: { [id: number]: Api.UnitModel };
    currencies: { [id: number]: Api.CurrencyModel };
    storeGroups: { [id: number]: Api.StoreGroupModel };
    supplierAdverts: { [id: number]: Api.SupplierAdvertModel };
    supplierDeliveries: { [id: number]: Api.SupplierDeliveryModel };
    supplierPayments: { [id: number]: Api.SupplierPaymentModel };
    supplierAdvertIsOpen: boolean;
    supplierPaymentIsOpen: boolean;
    supplierDeliveryIsOpen: boolean;
    disabled?: boolean;
    formType: CrudeStore.FormType;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
}

const formTitleStyle = {
    textAlign: "center" as any,
    marginBottom: 40
};

const buttonDialog = {
    position: "absolute" as any,
    top: 0, right: 0,
    paddingTop: 2,
    fontSize: 18, height: 30
}

class SupplierMainForm extends React.Component<SupplierMainFormProps, {}> {
    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create";
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <h3 className="h3" style={formTitleStyle}>Informations source</h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Nom:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("name")}>
                                <Field className="form-control"
                                    validate={supplierMainValidation["name"]}
                                    name="name"
                                    component={FormField.renderField}
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Frais livraison fixe:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("deliveryFeeFix")}>
                                <FieldHelp help={supplierHelp()["deliveryFeeFix"]}>
                                    <Field className="form-control"
                                        validate={supplierMainValidation["deliveryFeeFix"]}
                                        name="deliveryFeeFix"
                                        disabled={this.props.disabled
                                            || this.globalFieldDisabled}
                                        component={FormField.NumberField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Frais livraison variable:"}</label>
                            <div className="col-sm-4" style={this.getFieldStyle("deliveryFeeVar")}>
                                <FieldHelp help={supplierHelp()["deliveryFeeVar"]}>
                                    <Field className="form-control"
                                    validate={supplierMainValidation["deliveryFeeVar"]}
                                        name="deliveryFeeVar"
                                        disabled={this.props.disabled
                                            || this.globalFieldDisabled}
                                    component={FormField.NumberField} />
                                </FieldHelp>
                            </div>
                            <label className="col-sm-1 control-label ">{"par"}</label>
                            <div className="col-sm-3" style={this.getFieldStyle("deliveryFeeVarUnitId")}>
                                <FieldHelp help={supplierHelp()["deliveryFeeVarUnitId"]}>
                                    <Field className="form-control"
                                        validate={supplierMainValidation["deliveryFeeVarUnitId"]}
                                        name="deliveryFeeVarUnitId"
                                        disabled={this.props.disabled
                                            || this.globalFieldDisabled}
                                        component={FormField.getSelectField(_.values(this.props.units)
                                            .map(x => ({
                                                label: x.code,
                                                value: x.unitId
                                            })))} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Remise:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("supplierDiscounts")}>
                                <FieldArray
                                    name="supplierDiscounts"
                                    component={SupplierDiscounts}
                                    validate={supplierMainValidation["supplierDiscounts"]}
                                    props={{
                                        supplierId: this.props.supplierId,
                                        storeGroups: this.props.storeGroups,
                                        disabled: this.props.disabled
                                            || this.globalFieldDisabled
                                    } as SupplierDiscountsOwnProps} />
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" style={{ position: "relative", paddingRight: 35 }}>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label">{"Moyens de paiement:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("supplierPaymentSuppliers")}>
                                <FieldArray
                                    name="supplierPaymentSuppliers"
                                    component={SupplierPaymentSuppliers}
                                    validate={supplierPaymentSuppliers["supplierPaymentSuppliers"]}
                                    props={{
                                        supplierId: this.props.supplierId,
                                        supplierPayments: this.props.supplierPayments,
                                        disabled: this.props.disabled
                                            || this.globalFieldDisabled
                                    } as SupplierPaymentSuppliersOwnProps} />
                            </div>
                        </div>
                        <button className="btn btn-primary btn-xs"
                            type={"button"}
                            style={buttonDialog}
                            onClick={(e) => {
                                this.props.openSupplierPayment();
                                e.preventDefault();
                            }}>
                            <i className="glyphicon glyphicon-edit"></i>
                        </button>
                        <DialogModel contentLabel={"Moyens de paiement"}
                            width={1000}
                            contentStyle={{ top: 0, bottom: 0 }}
                            isOpen={this.props.supplierPaymentIsOpen}
                            onRequestClose={() => this.props.closeSupplierPayment()}>
                            <SupplierPayments />
                        </DialogModel>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6" style={{ position: "relative", paddingRight: 35 }}>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label">{"Points de livraison:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("supplierDeliverySuppliers")}>
                                <FieldArray
                                    name="supplierDeliverySuppliers"
                                    component={SupplierDeliverySuppliers}
                                    validate={supplierDeliverySuppliers["supplierDeliverySuppliers"]}
                                    props={{
                                        supplierId: this.props.supplierId,
                                        supplierDeliveries: this.props.supplierDeliveries,
                                        disabled: this.props.disabled
                                            || this.globalFieldDisabled
                                    } as SupplierDeliverySuppliersOwnProps} />
                            </div>
                        </div>
                        <button className="btn btn-primary btn-xs"
                            type={"button"}
                            style={buttonDialog}
                            onClick={(e) => {
                                this.props.openSupplierDelivery();
                                e.preventDefault();
                            }}>
                            <i className="glyphicon glyphicon-edit"></i>
                        </button>
                        <DialogModel contentLabel={"Points de livraison"}
                            width={1000}
                            contentStyle={{ top: 0, bottom: 0 }}
                            isOpen={this.props.supplierDeliveryIsOpen}
                            onRequestClose={() => this.props.closeSupplierDelivery()}>
                            <SupplierDeliveries />
                        </DialogModel>
                    </div>
                    <div className="col-md-6" style={{ position: "relative", paddingRight: 35 }}>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Publicité sur le lieu de vente:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("supplierAdvertSuppliers")}>
                                <FieldArray
                                    name="supplierAdvertSuppliers"
                                    component={SupplierAdvertSuppliers}
                                    validate={supplierAdvertSuppliers["supplierAdvertSuppliers"]}
                                    props={{
                                        supplierId: this.props.supplierId,
                                        supplierAdverts: this.props.supplierAdverts,
                                        disabled: this.props.disabled
                                            || this.globalFieldDisabled
                                    } as SupplierAdvertSuppliersOwnProps} />
                            </div>
                        </div>
                        <button className="btn btn-primary btn-xs"
                            type={"button"}
                            style={buttonDialog}
                            onClick={(e) => {
                                this.props.openSupplierAdvert();
                                e.preventDefault();
                            }}>
                            <i className="glyphicon glyphicon-edit"></i>
                        </button>
                        <DialogModel contentLabel={"Publicité sur le lieu de vente"}
                            width={1000}
                            contentStyle={{ top: 0, bottom: 0 }}
                            isOpen={this.props.supplierAdvertIsOpen}
                            onRequestClose={() => this.props.closeSupplierAdvert()}>
                            <SupplierAdverts />
                        </DialogModel>
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{"Rotation de famille:"}</label>
                    <div className="col-sm-8" style={this.getFieldStyle("supplierFamilyRotations")}>
                        <FieldArray
                            name="supplierFamilyRotations"
                            component={SupplierFamilyRotations}
                            validate={supplierFamilyRotationValidation["supplierFamilyRotations"]}
                            props={{
                                supplierId: this.props.supplierId,
                                disabled: this.props.disabled
                                        || this.globalFieldDisabled
                            } as SupplierFamilyRotationsOwnProps} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{"Remise volume:"}</label>
                    <div className="col-sm-8" style={this.getFieldStyle("supplierVolumeDiscounts")}>
                        <FieldArray
                            name="supplierVolumeDiscounts"
                            component={SupplierVolumeDiscounts}
                            validate={supplierVolumeDiscountValidation["supplierVolumeDiscounts"]}
                            props={{
                                supplierId: this.props.supplierId,
                                currencies: this.props.currencies,
                                storeGroups: this.props.storeGroups,
                                units: this.props.units,
                                disabled: this.props.disabled
                                    || this.globalFieldDisabled
                            } as SupplierVolumeDiscountsOwnProps} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType !== "Create"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

type SupplierDiscountsProps = SupplierDiscountsOwnProps
    & WrappedFieldArrayProps;

export interface SupplierDiscountsOwnProps {
    disabled?: boolean
    supplierId: number;
    storeGroups: { [id: number]: Api.StoreGroupModel }
}

export class SupplierDiscounts extends React.Component<SupplierDiscountsProps, {}> {
    public render() {
        return (<div>
            {this.props.fields.map((member, index) => <div key={index}
                style={{ marginBottom: 5 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <Field className="form-control"
                                validate={supplierDiscountValidation["value"]}
                                disabled={this.props.disabled}
                                name={`${member}.value`}
                                component={FormField.NumberField} />
                        </div>
                    </div>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <Field className="form-control"
                                validate={supplierDiscountValidation["storeGroupId"]}
                                disabled={this.props.disabled}
                                name={`${member}.storeGroupId`}
                                component={FormField.getSelectField(_.values(this.props.storeGroups)
                                    .map(x => ({ label: x.name, value: x.storeGroupId })))} />
                        </div>
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                        <button
                            type="button"
                            style={{ marginLeft: 5 }}
                            disabled={this.props.disabled}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    disabled={this.props.disabled}
                    onClick={() => this.props.fields.push({
                        supplierId: this.props.supplierId
                    })}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}


type SupplierPaymentSuppliersProps = SupplierPaymentSuppliersOwnProps
    & WrappedFieldArrayProps;

export interface SupplierPaymentSuppliersOwnProps {
    supplierId: number;
    disabled?: boolean;
    supplierPayments: { [id: number]: Api.SupplierPaymentModel }
}

export class SupplierPaymentSuppliers extends React.Component<SupplierPaymentSuppliersProps, {}> {
    public render() {
        return (<div>
            {this.props.fields.map((member, index) => <div key={index}
                style={{ marginBottom: 5 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <Field className="form-control"
                                validate={supplierPaymentSuppliers["supplierPaymentId"]}
                                name={`${member}.supplierPaymentId`}
                                disabled={this.props.disabled}
                                component={FormField.getSelectField(
                                    _.values(this.props.supplierPayments)
                                        .map(x => ({ label: x.name, value: x.supplierPaymentId })))} />
                        </div>
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                        <button
                            type="button"
                            disabled={this.props.disabled}
                            style={{ marginLeft: 5 }}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    disabled={this.props.disabled}
                    className="btn btn-secondary btn-sm"
                    onClick={() => this.props.fields.push({
                        supplierId: this.props.supplierId
                    })}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}

type SupplierAdvertSuppliersProps = SupplierAdvertSuppliersOwnProps
    & WrappedFieldArrayProps;

export interface SupplierAdvertSuppliersOwnProps {
    supplierId: number;
    disabled?: boolean;
    supplierAdverts: { [id: number]: Api.SupplierAdvertModel }
}

export class SupplierAdvertSuppliers extends React.Component<SupplierAdvertSuppliersProps, {}> {
    public render() {
        return (<div>
            {this.props.fields.map((member, index) => <div key={index}
                style={{ marginBottom: 5 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <Field className="form-control"
                                validate={supplierAdvertSuppliers["supplierAdvertId"]}
                                name={`${member}.supplierAdvertId`}
                                disabled={this.props.disabled}
                                component={FormField.getSelectField(
                                    _.values(this.props.supplierAdverts)
                                        .map(x => ({ label: x.name, value: x.supplierAdvertId })))} />
                        </div>
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                        <button
                            type="button"
                            disabled={this.props.disabled}
                            style={{ marginLeft: 5 }}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    disabled={this.props.disabled}
                    className="btn btn-secondary btn-sm"
                    onClick={() => this.props.fields.push({
                        supplierId: this.props.supplierId
                    })}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}

type SupplierDeliverySuppliersProps = SupplierDeliverySuppliersOwnProps
    & WrappedFieldArrayProps;

export interface SupplierDeliverySuppliersOwnProps {
    supplierId: number;
    disabled?: boolean;
    supplierDeliveries: { [id: number]: Api.SupplierDeliveryModel }
}

export class SupplierDeliverySuppliers extends React.Component<SupplierDeliverySuppliersProps, {}> {
    public render() {
        return (<div>
            {this.props.fields.map((member, index) => <div key={index}
                style={{ marginBottom: 5 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <Field className="form-control"
                                validate={supplierDeliverySuppliers["supplierDeliveryId"]}
                                name={`${member}.supplierDeliveryId`}
                                disabled={this.props.disabled}
                                component={FormField.getSelectField(
                                    _.values(this.props.supplierDeliveries)
                                        .map(x => ({ label: x.name, value: x.supplierDeliveryId })))} />
                        </div>
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                        <button
                            type="button"
                            disabled={this.props.disabled}
                            style={{ marginLeft: 5 }}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    disabled={this.props.disabled}
                    className="btn btn-secondary btn-sm"
                    onClick={() => this.props.fields.push({
                        supplierId: this.props.supplierId
                    })}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}

type SupplierFamilyRotationsProps = SupplierFamilyRotationsOwnProps
    & WrappedFieldArrayProps;

export interface SupplierFamilyRotationsOwnProps {
    supplierId: number;
    disabled?: boolean
}

const periodTypeOptions: Array<{ label: string, value: Api.SupplierFamilyRotationModelDayOfWeekEnum }> = [
    { label: "Lundi", value: "Monday" }, { label: "Mardi", value: "Thursday" },
    { label: "Mercredi", value: "Wednesday" }, { label: "Jeudi", value: "Tuesday" },
    { label: "Vendredi", value: "Friday" }, { label: "Samedi", value: "Saturday" },
    { label: "Dimanche", value: "Sunday" }, { label: "Tous les jours", value: "Everyday" },
    { label: "5 jours par semaine", value: "FiveAWeek" }, { label: "6 jours par semaine", value: "SixAWeek" }
]

export class SupplierFamilyRotations extends React.Component<SupplierFamilyRotationsProps, {}> {
    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => <div key={index}
                    style={{ marginBottom: 5, padding: 5, border: "1px solid black" }}>
                    <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: 5, borderRadius: 10 }}>
                        <button
                            type="button"
                            disabled={this.props.disabled}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                    <div className="row">
                        <label className="control-label col-md-2">Famille: </label>
                        <div className="col-md-4">
                            <FieldHelp help={supplierFamilyHelp()["family"]}>
                                <Field className="form-control"
                                validate={supplierFamilyRotationValidation["family"]}
                                disabled={this.props.disabled}
                                    name={`${member}.family`}
                                type={"text"}
                                    component={FormField.renderField} />
                            </FieldHelp>
                        </div>
                        <label className="control-label col-md-2">Période: </label>
                        <div className="col-md-4">
                            <FieldHelp help={supplierFamilyHelp()["dayOfWeek"]}>
                                <Field className="form-control"
                                validate={supplierFamilyRotationValidation["dayOfWeek"]}
                                disabled={this.props.disabled}
                                    name={`${member}.dayOfWeek`}
                                component={FormField.getSelectField(periodTypeOptions)} />
                            </FieldHelp>
                        </div>
                    </div>
                    <div className="row">
                        <label className="control-label col-md-2">Nb de jours: </label>
                        <div className="col-md-4">
                            <Field className="form-control"
                                validate={supplierFamilyRotationValidation["period"]}
                                disabled={this.props.disabled}
                                name={`${member}.period`}
                                component={FormField.NumberField} />
                        </div>
                        <label className="control-label col-md-2">Préavis: </label>
                        <div className="col-md-4">
                            <FieldHelp help={supplierFamilyHelp()["priorNotice"]}>
                                <Field className="form-control"
                                validate={supplierFamilyRotationValidation["priorNotice"]}
                                disabled={this.props.disabled}
                                name={`${member}.priorNotice`}
                                component={FormField.NumberField} />
                            </FieldHelp>
                        </div>
                    </div>
                </div>)}
                <FormField.FieldErrors {...this.props.meta} />
                <div style={{ textAlign: "center" }}>
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        disabled={this.props.disabled}
                        onClick={() => this.props.fields.push({
                            supplierId: this.props.supplierId
                        })}>
                        <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                    </button>
                </div>
            </div>
            );
    }
}

type SupplierVolumeDiscountsProps = SupplierVolumeDiscountsOwnProps
    & WrappedFieldArrayProps;

export interface SupplierVolumeDiscountsOwnProps {
    supplierId: number;
    currencies: { [id: number]: Api.CurrencyModel };
    units: { [id: number]: Api.UnitModel };
    storeGroups: { [id: number]: Api.StoreGroupModel };
    disabled?: boolean;
}

export class SupplierVolumeDiscounts extends React.Component<SupplierVolumeDiscountsProps, {}> {
    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => <div key={index}
                    style={{ marginBottom: 5, padding: 5, border: "1px solid black", borderRadius: 10 }}>
                    <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: 5 }}>
                        <button
                            type="button"
                            className="btn btn-secondary btn-sm"
                            disabled={this.props.disabled}
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                    <div className="row" style={{ marginBottom: 5 }}>
                        <label className="control-label col-md-2">Volume: </label>
                        <div className="col-md-4">
                            <FieldHelp help={supplierVolumeDiscountHelp()["volume"]}>
                                <Field className="form-control"
                                    validate={supplierVolumeDiscountValidation["volume"]}
                                    name={`${member}.volume`}
                                    disabled={this.props.disabled}
                                    component={FormField.NumberField} />
                            </FieldHelp>
                        </div>
                        <label className="control-label col-md-2">Montant: </label>
                        <div className="col-md-4" style={{ display: "flex", flexDirection: "row", alignItems: "stretch" }}>
                            <div style={{ flex: 1 }}>
                                <Field className="form-control"
                                    validate={supplierVolumeDiscountValidation["amount"]}
                                    name={`${member}.amount`}
                                    disabled={this.props.disabled}
                                    component={FormField.NumberField} />
                            </div>
                            <div style={{ flex: 1 }}>
                                <Field className="form-control"
                                    validate={supplierVolumeDiscountValidation["currencyId"]}
                                    name={`${member}.currencyId`}
                                    disabled={this.props.disabled}
                                    component={FormField.getSelectField(_.values(this.props.currencies)
                                        .map(x => ({ label: x.code, value: x.currencyId })))} />
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <label className="control-label col-md-2">Unit: </label>
                        <div className="col-md-2">
                            <FieldHelp help={supplierVolumeDiscountHelp()["unitId"]}>
                                <Field className="form-control"
                                    validate={supplierVolumeDiscountValidation["unitId"]}
                                    name={`${member}.unitId`}
                                    disabled={this.props.disabled}
                                    component={FormField.getSelectField(_.values(this.props.units)
                                        .map(x => ({ label: x.name, value: x.unitId })))} />
                            </FieldHelp>
                        </div>
                        <label className="control-label col-md-2">Montant: </label>
                        <div className="col-md-2">
                            <FieldHelp help={supplierVolumeDiscountHelp()["currencyUnitId"]}>
                                <Field className="form-control"
                                    validate={supplierVolumeDiscountValidation["currencyUnitId"]}
                                    name={`${member}.currencyUnitId`}
                                    disabled={this.props.disabled}
                                    component={FormField.getSelectField([{ label: " --- ", value: null }]
                                        .concat(_.values(this.props.currencies)
                                            .map(x => ({ label: x.code, value: x.currencyId }))))} />
                            </FieldHelp>
                        </div>
                        <label className="control-label col-md-2">Magasin: </label>
                        <div className="col-md-2">
                            <Field className="form-control"
                                validate={supplierVolumeDiscountValidation["storeGroupId"]}
                                name={`${member}.storeGroupId`}
                                disabled={this.props.disabled}
                                component={FormField.getSelectField([{ label: " --- ", value: null }]
                                    .concat(_.values(this.props.storeGroups)
                                        .map(x => ({ label: x.name, value: x.storeGroupId }))))} />
                        </div>
                    </div>
                </div>)}
                <FormField.FieldErrors {...this.props.meta} />
                <div style={{ textAlign: "center" }}>
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        disabled={this.props.disabled}
                        onClick={() => this.props.fields.push({
                            supplierId: this.props.supplierId
                        })}>
                        <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                    </button>
                </div>
            </div>
        );
    }
}

let createForm = reduxForm({
    form: 'createSupplierMain',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SupplierMainForm) as any;
const createSelector = formValueSelector('createSupplierMain')

let updateForm = reduxForm({
    form: 'updateSupplierMain',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SupplierMainForm) as any;
const updateSelector = formValueSelector('updateSupplierMain')

let submissionForm = reduxForm({
    form: 'submissionSupplierMain',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SupplierMainForm) as any;
const submissionSelector = formValueSelector('submissionSupplierMain')

export const CreateSupplierMainForm = connect((state: ApplicationState) => {
    return {
        units: state.unit.entities,
        currencies: state.currency.entities,
        storeGroups: state.seed.seed.storeGroups,
        formType: "Create",
        account: state.account.currentUser.account,
        supplierId: createSelector(state, "supplierId"),
        supplierAdverts: state.supplierAdvert.entities,
        supplierDeliveries: state.supplierDelivery.entities,
        supplierPayments: state.supplierPayment.entities,
        supplierAdvertIsOpen: state.supplierAdvert.isOpen,
        supplierPaymentIsOpen: state.supplierPayment.isOpen,
        supplierDeliveryIsOpen: state.supplierDelivery.isOpen
    } as SupplierMainFormOwnProps;
})(createForm as any) as any as React.ComponentClass<SupplierMainFormExternalProps>;

export const UpdateSupplierMainForm = connect((state: ApplicationState) => {
    return {
        units: state.unit.entities,
        currencies: state.currency.entities,
        storeGroups: state.seed.seed.storeGroups,
        formType: "Update",
        disabled: !((state.supplierMain.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
            .some(x => x.supplierMainId === state.supplierMain.selectedEntityId)),
        initialValues: state.supplierMain.entities[state.supplierMain.selectedEntityId],
        account: state.account.currentUser.account,
        supplierId: updateSelector(state, "supplierId"),
        supplierAdverts: state.supplierAdvert.entities,
        supplierDeliveries: state.supplierDelivery.entities,
        supplierPayments: state.supplierPayment.entities,
        supplierAdvertIsOpen: state.supplierAdvert.isOpen,
        supplierPaymentIsOpen: state.supplierPayment.isOpen,
        supplierDeliveryIsOpen: state.supplierDelivery.isOpen
    } as SupplierMainFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<SupplierMainFormExternalProps>;

export const SubmissionSupplierMainForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        units: state.unit.entities,
        currencies: state.currency.entities,
        storeGroups: state.seed.seed.storeGroups,
        formType: "Submission",
        initialValues: JSON.parse(submission.content),
        account: state.account.currentUser.account,
        supplierId: submissionSelector(state, "supplierId"),
        supplierAdverts: state.supplierAdvert.entities,
        supplierDeliveries: state.supplierDelivery.entities,
        supplierPayments: state.supplierPayment.entities,
        supplierAdvertIsOpen: state.supplierAdvert.isOpen,
        supplierPaymentIsOpen: state.supplierPayment.isOpen,
        supplierDeliveryIsOpen: state.supplierDelivery.isOpen,
        fieldInformations: submission.type === "Update"
            ? _.mapValues(diff(JSON.parse(submission.content), state.supplierMain.entities[submission.supplierMainId]), x => true)
            : {}
    } as SupplierMainFormOwnProps;
})(submissionForm as any) as any as React.ComponentClass<SupplierMainFormExternalProps>;