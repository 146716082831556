import * as React from "react";
import Moment from "moment";
import * as Api from "../api/api";
import * as SubmissionStore from "../store/Submission";
import * as ReactTable from "react-table";
import Loader from "./Loader";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { SubmissionProductForm } from "./ProductForm";
import { SubmissionFamilyForm } from "./FamilyForm";
import { SubmissionSubFamilyForm } from "./SubFamilyForm";
import { SubmissionPromotionForm } from "./PromotionForm";
import { SubmissionSupplierMainForm } from "./SupplierMainForm";
import { SubmissionSupplierSubForm } from "./SupplierSubForm";
import { SubmissionCurrencyForm } from "./CurrencyForm";
import { SubmissionMenuForm } from "./MenuForm";
import { SubmissionMenuCategoryForm } from "./MenuCategoryForm";
import { SubmissionPriceNameForm } from "./PriceNameForm";
import { SubmissionUnitForm } from "./UnitForm";
import { SubmissionVatForm } from "./VatForm";
import SubmissionDenyDialog from "./SubmissionDenyDialog";
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";

type SubmissionsProps = SubmissionsOwnProps
    & SubmissionStore.SubmissionState
    & typeof SubmissionStore.actionCreators
    ;

interface SubmissionsOwnProps {
    helpHtml: string;
}

type Column = ReactTable.Column
    & { accessor: (d: Api.SubmissionModel) => any };

const actionStyle = { cursor: "pointer" };
const tabHeadStyle: React.CSSProperties = { padding: "5px 20px" };

class Submissions extends React.Component<SubmissionsProps, {}> {

    get selectedSubmission(): Api.SubmissionModel {
        return this.props.submissions
            .find(x => x.submissionId === this.props.selectedId);
    }

    getSubmissionType(type: Api.SubmissionModelEntityTypeEnum): string {
        switch (type) {
            case "Currency":
                return "Devise";
            case "Family":
                return "Rayon";
            case "Menu":
                return "Menu";
            case "MenuCategory":
                return "Catégorie menu";
            case "PriceName":
                return "Nom de tarif";
            case "Product":
                return "Produit";
            case "Promotion":
                return "Promotion";
            case "SubFamily":
                return "Sous-famille";
            case "SupplierMain":
                return "Source";
            case "SupplierSub":
                return "Sous-source";
            case "Unit":
                return "Unité";
            case "Vat":
                return "TVA";
            default:
                return "";
        }
    }

    getSubmissionMode(type: Api.SubmissionModelTypeEnum): string {
        switch (type) {
            case "Create":
                return "Création";
            case "Update":
                return "Modification";
            case "Delete":
                return "Suppression";
            default:
                return "";
        }
    }

    componentWillMount() {
        this.props.requestSubmissions(new Date().getTime());
    }

    public render() {
        return (
            <div>
                <Loader isVisible={this.props.isLoading || this.props.isLoadingEntities} size={200} />
                <ConnectedTabs name={"submissions"}>
                    <TabLink to="submissions">
                        <div style={tabHeadStyle}>Demandes</div>
                    </TabLink>
                    <TabLink to="help">
                        <div style={tabHeadStyle}>Aide</div>
                    </TabLink>
                    <TabContent for="submissions">
                        <div style={{ padding: 10 }}>
                            <h2>Demandes non traitées</h2>
                            <div>
                                <ReactTable.default
                                    pageSize={6}
                                    showPageSizeOptions={false}
                                    columns={[
                                        {
                                            id: "submissionId",
                                            accessor: (d) => d.submissionId,
                                            Header: "Id"
                                        },
                                        {
                                            id: "type",
                                            accessor: (d) => d.type,
                                            Header: "Type",
                                            Cell: row => {
                                                let type = row.value as Api.SubmissionModelTypeEnum;
                                                return <div>
                                                    {this.getSubmissionMode(type)}
                                                </div>
                                            }
                                        },
                                        {
                                            id: "userUsername",
                                            accessor: (d) => d.userUsername,
                                            Header: "Auteur"
                                        },
                                        {
                                            id: "entityType",
                                            accessor: (d) => d.entityType,
                                            Header: "Entité",
                                            Cell: row => {
                                                let type = row.value as Api.SubmissionModelEntityTypeEnum;
                                                return <div>
                                                    {this.getSubmissionType(type)}
                                                </div>
                                            }
                                        },
                                        {
                                            id: "creationDate",
                                            accessor: (d) => d.creationDate,
                                            Header: "Date",
                                            Cell: row => <div>{Moment(row.value).format("DD/MM/YYYY")}</div>
                                        },
                                        {
                                            id: "actions1",
                                            accessor: (d) => d.submissionId,
                                            Cell: row => <div>
                                                <a style={{ ...actionStyle,
                                                    ...(row.value === this.props.selectedId
                                                        ? { textDecoration: "underline", color: "#005aa7" }
                                                        : {  })
                                                }}
                                                    onClick={(e) =>
                                                        this.props.selectSubmission(row.value)}>détails</a>
                                            </div>
                                        },
                                        {
                                            id: "actions2",
                                            accessor: (d) => d.submissionId,
                                            Cell: row => <div>
                                                <a style={{ ...actionStyle, 
                                                    ...(row.value === this.props.selectedId 
                                                        ? { textDecoration: "underline", color: "#005aa7" } 
                                                        : {  }) 
                                                }}
                                                    onClick={(e) => {
                                                        if(this.props.submissionStates[row.value]
                                                            && this.props.submissionStates[row.value].isLoading)
                                                            return;
                                                        
                                                        this.props.openDenyDialog(row.value)
                                                    }}>refuser</a>
                                            </div>
                                        }
                                    ] as Array<Column>}
                                    data={this.props.submissions.filter(x => x.status === "Created")} />
                            </div>
                            <div>
                                {this.selectedSubmission
                                    && <div className="container-fluid" style={{ maxWidth: 1200, margin: "auto", paddingTop: 20 }}>

                                        <h2 style={{ textAlign: "center", marginBottom: 40 }}>
                                            Demande de {this.getSubmissionMode(this.selectedSubmission.type)} pour {this.getSubmissionType(this.selectedSubmission.entityType)}
                                        </h2>

                                        {this.selectedSubmission.entityType === "Product"
                                            && <SubmissionProductForm
                                                updateFormValue={(form, member, value) => { }}
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionProduct(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })}
                                                onSelectStoreId={(storeId) => { }}
                                                onSelectSupplierId={(value) => { }}
                                                onSelectPriceNameId={(value) => { }} />}

                                        {this.selectedSubmission.entityType === "Family"
                                            && <SubmissionFamilyForm
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionFamily(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}

                                        {this.selectedSubmission.entityType === "SubFamily"
                                            && <SubmissionSubFamilyForm
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionSubFamily(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}

                                        {this.selectedSubmission.entityType === "SupplierMain"
                                            && <SubmissionSupplierMainForm
                                                openSupplierAdvert={() => { }}
                                                closeSupplierAdvert={() => { }}
                                                openSupplierDelivery={() => { }}
                                                closeSupplierDelivery={() => { }}
                                                openSupplierPayment={() => { }}
                                                closeSupplierPayment={() => { }}
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionSupplierMain(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}
                                        {this.selectedSubmission.entityType === "SupplierSub"
                                            && <SubmissionSupplierSubForm
                                                openSupplierAdvert={() => { }}
                                                closeSupplierAdvert={() => { }}
                                                openSupplierDelivery={() => { }}
                                                closeSupplierDelivery={() => { }}
                                                openSupplierPayment={() => { }}
                                                closeSupplierPayment={() => { }}
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionSupplierSub(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}

                                        {this.selectedSubmission.entityType === "Promotion"
                                            && <SubmissionPromotionForm
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionPromotion(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}

                                        {this.selectedSubmission.entityType === "Currency"
                                            && <SubmissionCurrencyForm
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionCurrency(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}

                                        {this.selectedSubmission.entityType === "Menu"
                                            && <SubmissionMenuForm
                                                resetDefaultPriceValue={(form, member, value) => { }}
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionMenu(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}

                                        {this.selectedSubmission.entityType === "MenuCategory"
                                            && <SubmissionMenuCategoryForm
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionMenuCategory(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}

                                        {this.selectedSubmission.entityType === "PriceName"
                                            && <SubmissionPriceNameForm
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionPriceName(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}

                                        {this.selectedSubmission.entityType === "Unit"
                                            && <SubmissionUnitForm
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionUnit(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}

                                        {this.selectedSubmission.entityType === "Vat"
                                            && <SubmissionVatForm
                                                actionName={this.selectedSubmission.type === "Create"
                                                    ? "Valider la création"
                                                    : "Valider la modification"}
                                                onSubmit={(values) => this.props.requestAcceptSubmissionVat(new Date().getTime(), {
                                                    model: values,
                                                    submissionId: this.props.selectedId
                                                })} />}
                                    </div>}
                            </div>
                            <SubmissionDenyDialog />
                        </div>
                    </TabContent>
                    <TabContent for="help">
                        <div style={{ padding: 10 }}>
                            {<div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />}
                        </div>
                    </TabContent>
                </ConnectedTabs>
                
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.submission,
    helpHtml: state.seed.seed.submissionHelp
} as SubmissionsOwnProps), {
        ...SubmissionStore.actionCreators,
    })(Submissions);