type EntityHelp = { [key: string]: JSX.Element | string };

export const productHelp = (): EntityHelp => ({
    "name": "Le nom du produit",
    "code": "Le code mnémonique à 4 chiffres du produit",
    "imageId": "L'image du produit, choisissez un fichier puis cliquer sur le boutton de téléchargement",
    "stability": "Si le produit est stable lors de la livraison ou non",
    "width": "Largeur du produit dans sa position de présentation",
    "height": "Hauteur du produit dans sa position de présentation",
    "depth": "Profondeur du produit dans sa position de présentation",
    "volume": "Volume/poids du produit",
    "volumeUnitId": "Unité do volume/poids du produit",
    "relativeUnitId": "Unité de comparaison pour la vente (ex: Litre pour COCA 33cl : 3.6€/L)",
    "expirityType": "Le type de date : DLC / DDM ou Aucun (sans dlc)",
    "expirityDays": "Le nombre de jours avant la date",
    "defaultSupplierId": "Source d'appro par défault, utilisée lorsque'aucune source n'est séléctionnée",
    "defaultPriceNameId": "Prix par défault, utilisé lorsque le produit n'est pas en promotion",
    "storeItemTags": "Tag permettant de rechercher un produit plus facilement"
});

export const productSubFamiliesHelp = (): EntityHelp => ({
    "order": "Valeur permettant d'ordonner le produit dans sa sous-famille, par orde croissant"
});

export const productVatsHelp = (): EntityHelp => ({
    "countryId": "Pays pour cette TVA, sera appliquer aux magasins correspondant"
});

export const productMenuCategoriesHelp = (): EntityHelp => ({
});

export const productSuppliersHelp = (): EntityHelp => ({
    "supplierSubId": "(optionnel) Branche/franchise de la source",
    "productSupplierStores": "(optionnel) Magasin(s) où importer la source, aucun pour être global",
    "packCondition": "Nombre d'unité dans le pack de la commande",
    "deliveryCondition": "Nombre minimum de pack a commander",
    "reference": "Référence de la commande auprès de la source",
    "buyPriceNoVat": "Prix du pack HT",
    "vatId": "TVA de vente",
    "packWeight": "Poids du pack en Kg",
    "packVolume": "Volume du pack en dm³"
});

export const productBarcodesHelp = (): EntityHelp => ({
    "value": "Code barre du produit, composé de 8 ou 13 chiffres"
});

export const priceHelp = (): EntityHelp => ({
    "priceStores": "(optional) Magasin(s) sur lequel appliquer ce prix, aucun pour être global, un prix sur un magasin est prioritaire par rapport à un prix global."
});

export const supplierHelp = (): EntityHelp => ({
    "deliveryFeeFix": "Saisir une valeur en €",
    "deliveryFeeVar": "Frais de variable basé sur l'unité",
    "deliveryFeeVarUnitId": "Unité du frais de livraison variable",
});

export const supplierFamilyHelp = (): EntityHelp => ({
    "family": "Nom du rayon du produit concerné",
    "dayOfWeek": "Jour de la semaine",
    "priorNotice": "Préavis de commande"
});

export const supplierVolumeDiscountHelp = (): EntityHelp => ({
    "volume": "Volume/poids/montant pour recevoir la remise",
    "unitId": "Unité du volume, si la remise est basée sur un montant, ce champ doit rester vide",
    "currencyUnitId": "Devise du montant, si la remise est basée sur une unité, ce champ doit rester vide"
});

export const storeItemStorePaymentHelp = (): EntityHelp => ({
    "paymentType": "Type de paiement à activé/désactivé",
    "availability": "Activé/désactivé le type de paiement",
    "storeId": "Magasin concerné"
});

export const menuStoreHelp = (): EntityHelp => ({
});

export const menuCategoryStoreHelp = (): EntityHelp => ({
});