import * as React from 'react';
import ReactModal from 'react-modal';

interface DialogModalProps {
    contentStyle?: any;
    width?: number;
    height?: number;
    isOpen: boolean;
    contentLabel: string;
    children: any;
    onRequestClose: () => void;
}

export default class DialogModal extends React.Component<DialogModalProps, {}> {
    private contentStyle = {};

    constructor(props) {
        super(props);
    }

    onPropsUpdate(props: DialogModalProps) {
        let contentStyle = {
            ...ReactModal.defaultStyles.content
        };

        if (props.width) {
            contentStyle = {
                ...contentStyle,
                left: "50%",
                marginLeft: -(props.width / 2),
                width: props.width
            }
        }
        if (props.height) {
            contentStyle = {
                ...contentStyle,
                top: "50%",
                marginTop: -(props.height / 2),
                height: props.height
            }
        }
        this.contentStyle = contentStyle;
    }

    componentWillMount() {
        this.onPropsUpdate(this.props); 
    }

    componentWillReceiveProps(nextProps: DialogModalProps) {
        if (this.props.width !== nextProps.width
            || this.props.height !== nextProps.height)
            this.onPropsUpdate(nextProps); 
    }


    public render() {
        return (
            <ReactModal
                isOpen={this.props.isOpen}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => this.props.onRequestClose()}
                style={{
                    ...ReactModal.defaultStyles,
                    overlay: {
                        ...ReactModal.defaultStyles.overlay,
                        backgroundColor: 'rgba(120, 120, 120, 0.50)',
                        zIndex: 999
                    },
                    content: { ...this.contentStyle, ...this.props.contentStyle }
                }}
                contentLabel={this.props.contentLabel}>
                <div>
                    <button
                        style={{ float: "right" }}
                        className="btn btn-sm btn-gray"
                        onClick={(e) => { this.props.onRequestClose(); e.preventDefault(); }}
                    >
                        <span className="">X</span>
                    </button>
                </div>
                <div className="clearfix"></div>
                <div style={{ marginTop: 20 }}>
                    {this.props.children}
                </div>
                </ReactModal>
            );
    }
}