import * as React from "react";
import * as Api from "../api/api";
import * as SupplierAdvertStore from "../store/SupplierAdvert";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import { CreateSupplierAdvertForm, UpdateSupplierAdvertForm } from "./SupplierAdvertForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { validateGridUpdates, supplierAdvertValidation } from "../utils/validation";
import { canCrude } from "../utils/userRights";

type SupplierAdvertsProps = SupplierAdvertsOwnProps
    & SupplierAdvertStore.SupplierAdvertState
    & typeof SupplierAdvertStore.actionCreators
    & typeof TabsStore.actionCreators;

interface SupplierAdvertsOwnProps {
    account: Api.AccountModel;
}
const name = "supplierAdverts";
const Table: new () => EntityTable<Api.SupplierAdvertModel> = EntityTable as any;

class SupplierAdverts extends React.Component<SupplierAdvertsProps, {}> {

    update(props: SupplierAdvertsProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps: SupplierAdvertsProps) {
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Publicité sur le lieu de vente</h2>
                <CrudeLayout
                    name={name}
                    createForm={<div style={{ maxWidth: 600 }}>
                        <h2>Créer</h2>
                        <CreateSupplierAdvertForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 600 }}>
                        <h2>Editer</h2>
                        <UpdateSupplierAdvertForm
                            actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    help={<div>Aide!</div>}
                    table={<Table
                        gridKey={name}
                        account={this.props.account}
                        isLoading={this.props.isLoading}
                        data={this.props.entities}
                        idPropertyName={"supplierAdvertId"}
                        updateValidation={(updates) =>
                            validateGridUpdates(updates, supplierAdvertValidation, this.props.entities)}
                        onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                            new Date().getTime(),
                            updates) as any}
                        onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                        onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                        onRequestUpdate={(id) => {
                            this.props.selectUpdateEntity(id);
                            this.props.selectTab(name, "update");
                        }}
                        columns={[
                            {
                                key: "name",
                                name: "Nom",
                                editable: true,
                            }
                        ]} />} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.supplierAdvert,
    account: state.account.currentUser.account
}), { ...TabsStore.actionCreators, ...SupplierAdvertStore.actionCreators } as any)(SupplierAdverts as any);