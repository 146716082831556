import * as Api from '../api/api';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import Tooltip from "./Tooltip";
import * as UserHubStore from "../store/UserHub";
import * as AccountStore from "../store/Account";
import SelectLanguage from './SelectLanguage';
import StoreSelectionDialog from './StoreSelectionDialog';

type UserHubProps = UserHubStore.UserHubState
    & UserHubOwnProps
    & typeof AccountStore.actionCreators
    & typeof UserHubStore.actionCreators;

interface UserHubOwnProps {
    account: Api.AccountModel;
}

class UserHub extends React.Component<UserHubProps, {}> {
    public render() {
        return (
            <div style={{ position: "absolute", top: 0, right: 0 }}>
                {this.props.account
                    && <div style={{
                    width: 360, height: 80,
                    display: "grid",
                    gridTemplateColumns: "repeat(5, 1fr)",
                    gridTemplateRows: "repeat(5, 1fr)"
                }}>
                    <div style={{
                        gridRow: "1 / 4", gridColumn: "1 / 4",
                        display: "flex", flexDirection: "row",
                        alignItems: "center",
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        borderBottomLeftRadius: 10,
                        ...Styles.colors.main2
                    }}>
                        <div style={{
                            flex: "0 0 auto",
                            width: 60,
                            marginLeft: 6,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center"
                        }}>
                            <Tooltip placement={"bottom"}
                                overlay={<div style={{ margin: 10 }}>
                                Sélection des magasins
                            </div>}>
                                <StoreSelectionDialog />
                            </Tooltip>
                        </div>
                        <div style={{
                            flex: "0 0 auto",
                            width: 100,
                            marginLeft: 6
                        }}>
                            <SelectLanguage />
                        </div>
                        <div style={{
                            flex: 1,
                            fontWeight: "bold",
                            textAlign: "center"
                        }}>
                            {this.props.account.username}
                        </div>
                    </div>
                    <div style={{
                        gridRow: "1 / 4", gridColumn: "4 / 6",
                        display: "flex", flexDirection: "row",
                        alignItems: "center",
                        ...Styles.colors.main2
                    }}>
                        <div style={{ width: "100%", textAlign: "center" }}>
                            <button className="btn btn-secondary btn-xs"
                                onClick={(e) => this.props.accountLogout(new Date().getTime())}
                                style={{ color: "black" }}>Logout</button>
                        </div>
                    </div>
                    <div style={{
                        gridRow: "4 / 6", gridColumn: "4 / 6",
                        borderLeft: "1px solid black",
                        borderBottom: "1px solid black",
                        borderBottomLeftRadius: 10,
                        display: "flex", flexDirection: "row",
                        alignItems: "center",
                        ...Styles.colors.main2
                    }}>
                        {this.props.isConnected
                            && <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                <div style={{ order: 0, flex: "0 0 50%", textAlign: "center" }}>
                                    <Tooltip overlay={<div>Utilisateurs connéctés sur le site</div>}>
                                        <div style={{ cursor: "default" }}>{this.props.connectedUsers.length}</div>
                                    </Tooltip>
                                </div>
                                <div style={{ order: 1, flex: "0 0 50%", textAlign: "center" }}>
                                    <div style={{ display: "inline-block", position: "relative" }}>
                                        <Tooltip placement={"bottom"}
                                            style={{ opacity: 1 }}
                                            containerStyle={{ opacity: 1 }}
                                            onVisibleChange={(value) => this.props.updateIsOpen(value)}
                                            trigger={["click"]}
                                            overlay={<div style={{ width: 400 }}>
                                                <div style={{ display: "flex", flexDirection: "column" }}>
                                                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: "100%", flex: "0 0 auto" }}>
                                                        {this.props.connectedUsers.map((user, i) => <div
                                                            key={i}
                                                            style={{
                                                                flex: "0 0 auto", border: "1px solid black",
                                                                padding: "3px 8px", color: user.color, borderRadius: 4,
                                                                marginRight: 6
                                                            }}>
                                                            {user.username}
                                                        </div>)}
                                                    </div>
                                                    <div style={{ flex: "0 0 auto", width: "100%", paddingTop: 5 }}>
                                                        <div style={{ height: 200, overflowY: "scroll" }}>
                                                            {this.props.messages.map((message, i) => <div key={i}
                                                                style={{ display: "flex", flexDirection: "row" }}>
                                                                <div style={{ flex: "0 0 auto", color: message.hubUser.color }}>{message.hubUser.username}: </div>
                                                                <div style={{ flex: 1, whiteSpace: "pre-wrap" }}>
                                                                    {message.message}
                                                                </div>
                                                            </div>)}
                                                        </div>
                                                    </div>
                                                    <div style={{ flex: "0 0 auto", width: "100%", paddingTop: 5, display: "flex", flexDirection: "row" }}>
                                                        <div style={{ flex: 1 }}>
                                                            <textarea value={this.props.message}
                                                                onKeyDown={(e) => {
                                                                    if (e.keyCode === 13 && !e.shiftKey && !e.ctrlKey) {
                                                                        this.props.sendMessage();
                                                                        e.preventDefault();
                                                                        e.stopPropagation();
                                                                        return false;
                                                                    }
                                                                }}
                                                                rows={2} style={{ width: "100%" }}
                                                                onChange={(value) => this.props.updateMessage(value.target.value)}></textarea>
                                                        </div>
                                                        <div style={{ flex: "0 0 auto", paddingBottom: 5 }}>
                                                            <button style={{ height: "100%" }}
                                                                onClick={(e) => this.props.sendMessage()}>Envoyer</button>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>}>
                                            <i className="glyphicon glyphicon-envelope"
                                                style={{ cursor: "pointer" }}></i>
                                        </Tooltip>
                                        {this.props.hasUnseenMessage && <div style={{
                                            position: "absolute",
                                            bottom: 3, right: -3,
                                            height: 6, width: 6,
                                            borderRadius: 6,
                                            backgroundColor: "red"
                                        }}></div>}
                                    </div>
                                </div>
                            </div>}
                    </div>
                    </div>}
                {!this.props.account
                    && <div style={{ marginRight: 50 }}>
                        <SelectLanguage />
                    </div>}
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.userHub,
    account: state.account.currentUser
        ? state.account.currentUser.account
        : null
} as UserHubOwnProps), { ...UserHubStore.actionCreators, ...AccountStore.actionCreators })(UserHub as any);