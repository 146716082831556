import * as React from 'react';
import * as AccountStore from '../store/Account';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import LoginForm from "./LoginForm";

type LoginProps = LoginOwnProps
    & RouteComponentProps<{}>
    & typeof AccountStore.actionCreators;

interface LoginOwnProps {

}

class Login extends React.Component<LoginProps, {}> {
    public render() {
        return (
            <div style={{
                height: "100%", width: "100%",
                display: "flex", flexDirection: "row", alignItems: "center"
            }}>
                <div style={{ width: 500, margin: "auto", paddingBottom: 100 }}>
                    <h1 style={{ textAlign: "center" }}>LGAP</h1>
                    <LoginForm
                        onSubmit={(values) => this.props.requestCreateToken(new Date().getTime(), values)} />
                </div>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({

}), AccountStore.actionCreators)(Login) as any