import * as React from "react";
import * as Api from "../api/api";
import * as _ from "lodash";
import * as ProductStore from "../store/Product";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import DataGrid from "./DataGrid";
import Loader from "./Loader";
import { DropDownPriceNameEditor, DropDownStoreEditor, DropDownCurrencyEditor } from "./DataGridEditor";
import { validateChildGridUpdates, priceValidation } from "../utils/validation";
import { ProductPriceInfo, ProductPriceCalculator } from "../utils/priceUtils";
import { selectedStores } from "../store/Account";

type ProductPriceGridProps = ProductPriceGridOwnProps
    & ProductStore.ProductState
    & typeof ProductStore.actionCreators;

interface ProductPriceGridOwnProps {
    storeGroups: { [id: number]: Api.StoreGroupModel };
    stores: { [id: number]: Api.StoreModel };
    selectedStores: { [id: number]: Api.StoreModel };
    priceNames: { [id: number]: Api.PriceNameModel };
    vats: { [id: number]: Api.VatModel };
    units: { [id: number]: Api.UnitModel };
    supplierMains: { [id: number]: Api.SupplierModel };
    supplierSubs: { [id: number]: Api.SupplierModel };
    currencies: { [id: number]: Api.CurrencyModel };
    filteredProducts: Array<Api.ProductModel>;
}

class ProductPriceGrid extends React.Component<ProductPriceGridProps, {}> {
    _data: Array<Api.PriceModel & ProductPriceInfo>;

    onDataUpdate(props: ProductPriceGridProps) {
        let priceCalculator = new ProductPriceCalculator(
            this.props.units, this.props.vats,
            _.keyBy(_.values(this.props.supplierMains)
                .concat(_.values(this.props.supplierSubs)), x => x.supplierId), this.props.currencies
        );

        this._data = props.filteredProducts
            .map(x => x.prices
                .filter(y => y.priceStores.length === 0
                    || y.priceStores.some(z => this.props.selectedStores[z.storeId] ? true : false))
                .map(y => {
                return {
                    ...y,
                    ...priceCalculator.calculate({
                        defaultSupplierId: this.props.filter.supplierId || x.defaultSupplierId,
                        productSuppliers: x.productSuppliers,
                        productVats: x.productVats,
                        relativeUnitId: x.relativeUnitId,
                        volume: x.volume,
                        volumeUnitId: x.volumeUnitId
                    }, y)
                };
            }))
            .reduce((a, b) => a.concat(b), []);
    }

    componentWillMount() {
        this.onDataUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: ProductPriceGridProps) {
        if (this.props.filteredProducts !== nextProps.filteredProducts
            || this.props.selectedStores !== nextProps.selectedStores) {
            this.onDataUpdate(nextProps);
        }
    }

    onBulkUpdate(edits: { [id: number]: object }): Promise<any> {
        let updates = {};

        let editsByParentId = _.groupBy(Object.keys(edits)
            .map(x => ({
                ...this._data.find(y => y.priceId === parseInt(x)),
                ...edits[x]
            } as Api.PriceModel)), x => x.storeItemId);

        _.keys(editsByParentId)
            .forEach(x => {
                editsByParentId[x] = editsByParentId[x]
                    .concat(this.props.entities[parseInt(x)].prices.filter(y => !edits[y.priceId]));
            });

        _.keys(editsByParentId)
            .forEach(x => {
                updates[x] = {
                    prices: editsByParentId[x]
                };
            });
       
        return this.props.requestBulkUpdate(new Date().getTime(),
            updates) as any;
    }

    formatPrice(value: any): string {
        if (value.toFixed)
            return value.toFixed(2);

        return value;
    }

    public render() {
        return (
            <div style={{ position: "relative" }}>
                <DataGrid
                    data={this._data}
                    selectableColumns={[
                        "storeItemId", "priceNameId",
                        "currencyId", "storeGroupId",
                        "value", "unitPrice",
                        "noVatUnitBuyPrice",
                        "grossMargin", "markup"
                    ]}
                    updateValidation={(updates) => validateChildGridUpdates(
                        updates,
                        priceValidation,
                        this.props.entities,
                        this._data,
                        x => x.priceId,
                        x => x.storeItemId)}
                    columns={[
                        {
                            key: "storeItemId",
                            name: "Produit",
                            editable: false,
                            formatter: ({ value }) => <div>{this.props.entities[value].name}</div>
                        },
                        {
                            key: "priceNameId",
                            name: "Tarif",
                            editable: true,
                            editor: <DropDownPriceNameEditor />,
                            formatter: ({ value }) => <div>{this.props.priceNames[value]
                                && this.props.priceNames[value].name}</div>
                        },
                        {
                            key: "currencyId",
                            name: "Devise",
                            editable: true,
                            editor: <DropDownCurrencyEditor />,
                            formatter: ({ value }) => <div>{this.props.currencies[value]
                                && this.props.currencies[value].name}</div>
                        },
                        {
                            key: "priceStores",
                            name: "Magasins",
                            editable: false,
                            formatter: ({ value }) => <div>{(value as Array<Api.PriceStoreModel>)
                                .map(x => this.props.stores[x.storeId].name)
                                .join(" | ")}</div>
                        },
                        {
                            key: "value",
                            name: "PV TTC",
                            editable: true,
                            formatter: ({ value, row }) => <div>{this.formatPrice(value)} {"€"}</div>
                        },
                        {
                            key: "noVatUnitBuyPrice",
                            name: "PAHT",
                            editable: false,
                            formatter: ({ value }) => <div>{this.formatPrice(value)} {"€"}</div>
                        },
                        {
                            key: "grossMargin",
                            name: "Marge brute",
                            editable: false,
                            formatter: ({ value }) => <div>{this.formatPrice(value)} {"€"}</div>
                        },
                        {
                            key: "markup",
                            name: "Taux de marque",
                            editable: false,
                            formatter: ({ value }) => <div>{this.formatPrice(value)} %</div>
                        }
                    ]}
                    gridKey={"productPrices"}
                    idSelector={(x) => (x as Api.PriceModel).priceId}
                    onUpdateRows={(edits) => this.onBulkUpdate(edits)}
            />
                <Loader isVisible={this.props.isLoading} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.product,
    storeGroups: state.seed.seed.storeGroups,
    stores: state.seed.seed.stores,
    selectedStores: selectedStores(state),
    priceNames: state.priceName.entities,
    currencies: state.currency.entities,
    supplierMains: state.supplierMain.entities,
    supplierSubs: state.supplierSub.entities,
    units: state.unit.entities,
    vats: state.vat.entities,
    filteredProducts: ProductStore.productFilteredSelector(state)
} as ProductPriceGridOwnProps), ProductStore.actionCreators as any)(ProductPriceGrid as any);