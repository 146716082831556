import * as Api from "../api/api";
import {  ApplicationState } from './';
import * as CrudeStore from "./Crude";

export const key: string = "currency";

export type CurrencyState = CrudeStore.EntityCrudeState<Api.CurrencyModel, Api.CurrencyFilter>;

export const configuration: CrudeStore.CrudeStoreConfiguration<Api.CurrencyModel, Api.CurrencyFilter> = {
    key: key,
    storeSelector: x => x.currency,
    idSelector: x => x.currencyId,
    requestEntitiesFetch: (model, options) => {
        let api = new Api.CurrencyApi();
        return api.getEntities(model, options);
    },
    requestCreateFetch: (model, options) => {
        let api = new Api.CurrencyApi();
        return api.create(model, options);
    },
    requestUpdateFetch: (model, options) => {
        let api = new Api.CurrencyApi();
        return api.update(model, options);
    },
    requestDeleteFetch: (model, options) => {
        let api = new Api.CurrencyApi();
        return api._delete(model, options);
    },
    requestBulkUpdateFetch: (models, options) => {
        let api = new Api.CurrencyApi();
        return api.bulkUpdate(models, options);
    },
    requestSubmitCreateFetch: (models, options) => {
        let api = new Api.CurrencyApi();
        return api.submitCreate(models, options);
    },
    requestSubmitUpdateFetch: (models, options) => {
        let api = new Api.CurrencyApi();
        return api.submitUpdate(models, options);
    },
    requestDependencies: []
}

export const requestEntities = (requestTime: number,
    dispatch: (action: CrudeStore.KnownAction<Api.CurrencyModel, Api.CurrencyFilter>) => void,
    getState: () => ApplicationState) => CrudeStore.requestEntities(configuration, requestTime, dispatch, getState);

export const actionCreators = CrudeStore.getActionCreators<Api.CurrencyModel, Api.CurrencyFilter>(configuration);

export const reducer = CrudeStore.getReducer(key);