import * as React from "react";
import Moment from "moment";
import * as Api from "../api/api";
import * as _ from "lodash";
import * as ProductStore from "../store/Product";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import DataGrid from "./DataGrid";
import Loader from "./Loader";
import {
    DropDownSupplierMainEditor,
    DropDownSupplierSubEditor,
    DropDownCurrencyEditor,
    DropDownVatEditor
} from "./DataGridEditor";
import { validateChildGridUpdates, productSuppliersValidation } from "../utils/validation";
import { selectedStores } from "../store/Account";

type ProductSupplierGridProps = ProductSupplierGridOwnProps
    & ProductStore.ProductState
    & typeof ProductStore.actionCreators;

interface ProductSupplierGridOwnProps {
    supplierMains: { [id: number]: Api.SupplierModel };
    supplierSubs: { [id: number]: Api.SupplierModel };
    currencies: { [id: number]: Api.CurrencyModel };
    vats: { [id: number]: Api.VatModel };
    filteredProducts: Array<Api.ProductModel>;
    stores: { [id: number]: Api.StoreModel };
    selectedStores: { [id: number]: Api.StoreModel };
}

class ProductSupplierGrid extends React.Component<ProductSupplierGridProps, {}> {
    _data: Array<Api.ProductSupplierModel>;

    onDataUpdate(props: ProductSupplierGridProps) {
        this._data = _.sortBy(props.filteredProducts
            .map(x => x.productSuppliers)
            .reduce((a, b) => a.concat(b), [])
            .filter(x => x.productSupplierStores.length === 0
                || x.productSupplierStores
                    .some(y => this.props.selectedStores[y.storeId] ? true : false)), x => x.productId);
    }

    componentWillMount() {
        this.onDataUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: ProductSupplierGridProps) {
        if (this.props.filteredProducts !== nextProps.filteredProducts
            || this.props.selectedStores !== nextProps.selectedStores) {
            this.onDataUpdate(nextProps);
        }
    }

    onBulkUpdate(edits: { [id: number]: object }): Promise<any> {
        let updates = {};

        let editsByParentId = _.groupBy(Object.keys(edits)
            .map(x => ({
                ...this._data.find(y => y.productSupplierId === parseInt(x)),
                ...edits[x]
            } as Api.ProductSupplierModel)), x => x.productId);

        _.keys(editsByParentId)
            .forEach(x => {
                editsByParentId[x] = editsByParentId[x]
                    .concat(this.props.entities[parseInt(x)].productSuppliers.filter(y => !edits[y.productSupplierId]));
            });

        _.keys(editsByParentId)
            .forEach(x => {
                updates[x] = {
                    productSuppliers: editsByParentId[x]
                };
            });

        return this.props.requestBulkUpdate(new Date().getTime(),
            updates) as any;
    }

    public render() {
        return (
            <div style={{ position: "relative" }}>
                <DataGrid
                    data={this._data}
                    selectableColumns={[
                        "productId",
                        "supplierMainId",
                        "supplierSubId",
                        "packCondition",
                        "deliveryCondition",
                        "buyPriceNoVat",
                        "buyPriceCurrencyId",
                        "vatId",
                        "packWeight",
                        "packVolume",
                        "creationDate",
                    ]}
                    columns={[
                        {
                            key: "productId",
                            name: "Produit",
                            editable: false,
                            formatter: ({ value }) => <div>{this.props.entities[value].name}</div>
                        },
                        {
                            key: "productSupplierStores",
                            name: "Magasins",
                            editable: false,
                            formatter: ({ value }) => <div>{(value as Array<Api.ProductSupplierStoreModel>)
                                .map(x => this.props.stores[x.storeId].name)
                                .join(" | ")}</div>
                        },
                        {
                            key: "supplierMainId",
                            name: "Source",
                            editable: true,
                            editor: <DropDownSupplierMainEditor />,
                            formatter: ({ value }) => <div>{this.props.supplierMains[value]
                                && this.props.supplierMains[value].name}</div>
                        },
                        {
                            key: "supplierSubId",
                            name: "Sous-source",
                            editable: true,
                            editor: <DropDownSupplierSubEditor />,
                            formatter: ({ value }) => <div>{this.props.supplierMains[value]
                                && this.props.supplierMains[value].name}</div>
                        },
                        {
                            key: "packCondition",
                            name: "Cdt pack",
                            editable: true
                        },
                        {
                            key: "deliveryCondition",
                            name: "Cdt livraison",
                            editable: true
                        },
                        {
                            key: "reference",
                            name: "Référence",
                            editable: true
                        },
                        {
                            key: "buyPriceNoVat",
                            name: "PAHT pack",
                            editable: true
                        },
                        {
                            key: "buyPriceCurrencyId",
                            name: "Devise pack",
                            editable: true,
                            editor: <DropDownCurrencyEditor />,
                            formatter: ({ value }) => <div>{this.props.currencies[value]
                                && this.props.currencies[value].code}</div>
                        },
                        {
                            key: "vatId",
                            name: "Tva",
                            editable: true,
                            editor: <DropDownVatEditor />,
                            formatter: ({ value }) => <div>{this.props.vats[value]
                                && this.props.vats[value].name}</div>
                        },
                        {
                            key: "packWeight",
                            name: "Poids pack",
                            editable: true
                        },
                        {
                            key: "packVolume",
                            name: "Volume pack",
                            editable: true
                        },
                        {
                            key: "creationDate",
                            name: "Dernière maj",
                            formatter: ({ value }) => <div>{Moment(value).format("DD/MM/YYYY")}</div>,
                            editable: false
                        }
                    ]}
                    gridKey={"productSuppliers"}
                    idSelector={(x) => (x as Api.ProductSupplierModel).productSupplierId}
                    updateValidation={(updates) => validateChildGridUpdates(
                        updates,
                        productSuppliersValidation,
                        this.props.entities,
                        this._data,
                        x => x.productSupplierId,
                        x => x.productId)}
                    onUpdateRows={(edits) => this.onBulkUpdate(edits)}
                />
                <Loader isVisible={this.props.isLoading} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.product,
    supplierMains: state.supplierMain.entities,
    supplierSubs: state.supplierSub.entities,
    currencies: state.currency.entities,
    vats: state.vat.entities,
    filteredProducts: ProductStore.productFilteredSelector(state),
    stores: state.seed.seed.stores,
    selectedStores: selectedStores(state),
} as ProductSupplierGridProps), ProductStore.actionCreators as any)(ProductSupplierGrid as any);