import * as Api from "../api/api";
import * as React from "react";
import * as _ from "lodash";
import * as DataGridsStore from "../store/DataGrids";
import * as ReactDataGrid from "react-data-grid";
import { filterCaseInsensitive, isClient } from "../utils/utils";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { createSelector } from "reselect";
import DataGrid from "./DataGrid";
import Loader from "./Loader";
import { canCrude } from "../utils/userRights";
import Confirm from "react-confirm-bootstrap";

type EntityTableProps<TEntity extends object> = EntityTableOwnProps<TEntity>;


interface Column<TEntity> {
}

interface EntityTableOwnProps<TEntity extends object> {
    gridKey: string;
    isLoading: boolean;
    idPropertyName: string;
    minWidthPerColumns?: number;
    data: { [id: number]: TEntity };
    dataFiltered?: Array<TEntity>; 
    columns: Array<ReactDataGrid.Column>;
    selectableColumns?: Array<string>;
    account: Api.AccountModel;
    onEntitySelected?: (id: number) => void;
    onRequestDuplicate?: (id: number) => void;
    onRequestUpdate: (id: number) => void;
    onRequestDelete: (id: number) => void;
    updateValidation?: (updates: { [id: number]: object }) => { [id: number]: { [prop: string]: Array<string> } };
    onBulkUpdate: (updates: { [id: number]: object }) => Promise<any>;
}

const actionStyle = { cursor: "pointer" };

export default class EntityTable<TEntity extends object> extends React.Component<EntityTableProps<TEntity>, {}> {
    _data: Array<TEntity & object>;
    _columns: Array<ReactDataGrid.Column & Column<TEntity>>;

    onDataUpdate(props: EntityTableProps<TEntity>) {
        this._data = props.dataFiltered
            || _.values(props.data) as any;
    }

    onColumnsUpdate(props: EntityTableProps<TEntity>) {
        this._columns = [{
            key: this.props.idPropertyName,
            name: "Id",
            width: 50,
            editable: false,
            resizable: false,
            sortable: true
        } as ReactDataGrid.Column].concat(props.columns.concat([{
            key: "_id", //defined in DataGrid
            name: "",
            editable: false,
            resizable: false,
            sortable: false,
            locked: true,
            width: this.props.onRequestDuplicate
                ? 190
                : 130,
            formatter: ({ value }) => canCrude(this.props.account)
                ? <div>
                <a style={actionStyle} onClick={(e) => this.props.onRequestUpdate(value)}>Editer</a>
                    {" "}
                    <Confirm
                        onConfirm={(e) => {
                            this.props.onRequestDelete(value);
                            //e.preventDefault();
                        }}
                        body="Êtes-vous sûre de vouloir supprimer cette élément ?"
                        confirmText="Confirmer"
                        title="Confirmation de supression">
                        <a style={actionStyle}>Supprimer</a>
                    </Confirm>
                {this.props.onRequestDuplicate && " "}
                {this.props.onRequestDuplicate && <a style={actionStyle} onClick={(e) => this.props.onRequestDuplicate(value)}>Dupliquer</a>}
                </div>
                : <div>
                    <a style={actionStyle} onClick={(e) => this.props.onRequestUpdate(value)}>Editer</a>
                    {" "}
                    {this.props.onRequestDuplicate && " "}
                    {this.props.onRequestDuplicate && <a style={actionStyle} onClick={(e) => this.props.onRequestDuplicate(value)}>Dupliquer</a>}
                </div>
        }]));
    }

    componentWillMount() {
        this.onDataUpdate(this.props);
        this.onColumnsUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: EntityTableProps<TEntity>) {
        if (this.props.data !== nextProps.data
            || this.props.dataFiltered !== nextProps.dataFiltered) {
            this.onDataUpdate(nextProps);
        }
        if (this.props.columns !== nextProps.columns) {
            this.onColumnsUpdate(nextProps);
        }
    }

    public render() {
        return (
            <div style={{ position: "relative" }}>
                {isClient()
                    && <DataGrid
                        data={this._data}
                        minWidthPerColumns={this.props.minWidthPerColumns}
                        hideButtons={!canCrude(this.props.account)}
                        columns={this._columns}
                        gridKey={this.props.gridKey}
                        selectableColumns={this.props.selectableColumns}
                        idSelector={(x) => x[this.props.idPropertyName]}
                        updateValidation={this.props.updateValidation}
                        onUpdateRows={(edits) => this.props.onBulkUpdate(edits)}
                    />}
                <Loader isVisible={this.props.isLoading} />
            </div>
            );
    }
}