import * as Api from "../api/api";
import * as _ from "lodash";
import { Action, Reducer, ActionCreator } from 'redux';
import { AppThunkAction, ApplicationState } from './';
import { getDefaultHeaders } from "../utils/utils";
import { ReceiveCurrentUser } from "./Account";
import Throttle from "../utils/throttle";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.
export type DataGridsState = { [key: string]: DataGridState };


export interface DataGridState {
    editStates: { [id: number]: object }
    columnStates: { [key: string]: ColumnState }
    minHeight?: number;
    submitFailed: boolean;
    validationErrors: { [id: number]: { [prop: string]: Array<string> } }
}

interface ColumnState {
    sortValue: SortValues;
    sortTime?: number;
    width?: number;
    visible: boolean;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

type SortValues = "ASC" | "DESC" | "NONE"

interface GridResetEdit {
    type: 'GRID_RESET_EDIT';
    payload: { key: string; }
}

interface GridUpdateColumnSort {
    type: 'GRID_UPDATE_COLUMN_SORT';
    payload: { key: string; columnId: string; value: SortValues; sortTime: number },
}

interface GridUpdateColumnVisible {
    type: 'GRID_UPDATE_COLUMN_VISIBLE';
    payload: { key: string; columnId: string; value: boolean; },
}

interface GridUpdateColumnWidth {
    type: 'GRID_UPDATE_COLUMN_WIDTH';
    payload: { key: string; columnId: string; value: number; },
}

interface GridUpdateRows {
    type: 'GRID_UPDATE_ROWS';
    payload: { key: string; ids: Array<number>; updated: any }
}

interface GridSetValidationErrors {
    type: 'GRID_SET_VALIDATION_ERRORS';
    payload: { key: string; value: { [id: number]: { [prop: string]: Array<string> } } }
}

interface GridSetUpdateSuccess {
    type: 'GRID_SET_UPDATE_SUCESS';
    payload: { key: string; }
};

interface GridSetUpdateFail {
    type: 'GRID_SET_UPDATE_FAIL';
    payload: { key: string; }
};

interface GridUpdateMinHeight {
    type: 'GRID_UPDATE_MIN_HEIGHT';
    payload: { key: string; value: number }
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = GridResetEdit | GridUpdateColumnSort
    | GridUpdateRows | GridUpdateMinHeight
    | GridSetValidationErrors | GridSetUpdateSuccess
    | GridSetUpdateFail | GridUpdateColumnVisible
    | ReceiveCurrentUser | GridUpdateColumnWidth;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

const saveGridStates = (getState: () => ApplicationState) => {
    Throttle.throttle("GRID_SAVE", () => {
        let api = new Api.AccountApi();
        let gridStates: any = {
            ...getState().dataGrids
        };
        _.keys(gridStates).forEach(x => {
            gridStates[x] = (({ columnStates }) => ({
                columnStates
            }))(gridStates[x])
        });

        api.saveGridStates({
            gridStates: gridStates
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) });
    }, 2000);
};

export const actionCreators = {
    updateMinHeight: (key: string, value: number) =>
        <GridUpdateMinHeight>{ type: "GRID_UPDATE_MIN_HEIGHT", payload: { key: key, value: value } },
    resetEdit: (key: string) =>
        <GridResetEdit>{ type: "GRID_RESET_EDIT", payload: { key: key } },
    updateColumnSort: (key: string, columnId: string, value: SortValues) =>
        <GridUpdateColumnSort>{ type: "GRID_UPDATE_COLUMN_SORT", payload: { key: key, columnId: columnId, value: value, sortTime: new Date().getTime() } },
    updateColumnVisible: (key: string, columnId: string, value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "GRID_UPDATE_COLUMN_VISIBLE", payload: { key: key, columnId: columnId, value: value } });
        saveGridStates(getState);
    },
    updateColumnWidth: (key: string, columnId: string, value: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch({ type: "GRID_UPDATE_COLUMN_WIDTH", payload: { key: key, columnId: columnId, value: value } });
        saveGridStates(getState);
    },
    updateRows: (key: string, ids: Array<number>, updated: any) =>
        <GridUpdateRows>{ type: "GRID_UPDATE_ROWS", payload: { key: key, ids: ids, updated: updated } },
    setValidationErrors: (key: string, value: { [id: number]: { [prop: string]: Array<string> } }) =>
        <GridSetValidationErrors>{ type: "GRID_SET_VALIDATION_ERRORS", payload: { key: key, value: value } },
    setSubmitSuccess: (key: string) => <GridSetUpdateSuccess>{
        type: "GRID_SET_UPDATE_SUCESS", payload: { key: key }
    },
    setSubmitFail: (key: string) => <GridSetUpdateFail>{
        type: "GRID_SET_UPDATE_FAIL", payload: { key: key }
    },
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const unloadedGridState: DataGridState = {
    columnStates: {},
    editStates: {},
    minHeight: 350,
    submitFailed: false,
    validationErrors: {}
};

export const unloadedColumnState: ColumnState = {
    sortValue: "NONE",
    visible: true,
};

const unloadedState: DataGridsState = {};

export const applySorting = (data: Array<object>, columnStates: { [key: string]: ColumnState }): Array<object> => {
    let columnSortDatas = _.sortBy(Object.keys(columnStates), (key) => columnStates[key].sortTime);

    let sortedData = data;
    columnSortDatas.forEach(x => {
        let sortValue = columnStates[x].sortValue;
        if (sortValue === "ASC")
            sortedData = _.sortBy(sortedData, (obj) => obj[x]);
        else if (sortValue === "DESC")
            sortedData = _.reverse(_.sortBy(sortedData, (obj) => obj[x]));
    });
    return sortedData;
}

export const reducer: Reducer<DataGridsState> = (state: DataGridsState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "GRID_RESET_EDIT":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key] || unloadedGridState,
                    editStates: {},
                    submitFailed: false,
                    validationErrors: {}
                }
            };
        case "GRID_UPDATE_COLUMN_SORT":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key] || unloadedGridState,
                    columnStates: {
                        ...(state[action.payload.key] || unloadedGridState).columnStates,
                        [action.payload.columnId]: {
                            ...unloadedColumnState,
                            ...(state[action.payload.key] || unloadedGridState).columnStates[action.payload.columnId],
                            sortValue: action.payload.value,
                            sortTime: action.payload.sortTime
                        }
                    }
                }
            };
        case "GRID_UPDATE_COLUMN_WIDTH":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key] || unloadedGridState,
                    columnStates: {
                        ...(state[action.payload.key] || unloadedGridState).columnStates,
                        [action.payload.columnId]: {
                            ...unloadedColumnState,
                            ...(state[action.payload.key] || unloadedGridState).columnStates[action.payload.columnId],
                            width: action.payload.value
                        }
                    }
                }
            };
        case "GRID_UPDATE_COLUMN_VISIBLE":
            let cvEditState = {
                ...(state[action.payload.key] || unloadedGridState).editStates
            };
            let cvValidationErrors = {
                ...(state[action.payload.key] || unloadedGridState).validationErrors
            };

            if (!action.payload.value) {
                _.keys(cvEditState).forEach(x => {
                    let newEdit = {
                        ...cvEditState[x]
                    };
                    delete newEdit[action.payload.columnId];
                    cvEditState[x] = newEdit;
                });
                _.keys(cvValidationErrors).forEach(x => {
                    let newValidation = {
                        ...cvValidationErrors[x]
                    };
                    delete newValidation[action.payload.columnId];
                    cvValidationErrors[x] = newValidation;
                });
            }

            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key] || unloadedGridState,
                    columnStates: {
                        ...(state[action.payload.key] || unloadedGridState).columnStates,
                        [action.payload.columnId]: {
                            ...unloadedColumnState,
                            ...(state[action.payload.key] || unloadedGridState).columnStates[action.payload.columnId],
                            visible: action.payload.value
                        }
                    },
                    editStates: cvEditState,
                    validationErrors: cvValidationErrors
                }
            };
        case "GRID_UPDATE_ROWS":
            let gurEditStates = (state[action.payload.key] || unloadedGridState).editStates;

            action.payload.ids.forEach(id => {
                gurEditStates[id] = {
                    ...gurEditStates[id],
                    ...action.payload.updated
                };
            });

            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key] || unloadedGridState,
                    editStates: gurEditStates
                }
            };
        case "GRID_UPDATE_MIN_HEIGHT":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key] || unloadedGridState,
                    minHeight: action.payload.value
                }
            };
        case "GRID_SET_VALIDATION_ERRORS":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key] || unloadedGridState,
                    validationErrors: action.payload.value
                }
            };
        case "GRID_SET_UPDATE_FAIL":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key] || unloadedGridState,
                    submitFailed: true
                }
            };
        case "GRID_SET_UPDATE_SUCESS":
            return {
                ...state,
                [action.payload.key]: {
                    ...state[action.payload.key] || unloadedGridState,
                    submitFailed: false,
                    validationErrors: {}
                }
            };
        case "RECEIVE_CURRENT_USER":
            if (!action.payload.currentUser || action.error)
                return state;

            let savedState = action.payload.currentUser.account.user.userLgapSettings
                ? JSON.parse(action.payload.currentUser.account.user.userLgapSettings.dataGridsState)
                : undefined;
            _.keys(savedState).forEach(x => {
                savedState[x] = {
                    ...unloadedGridState,
                    ...savedState[x]
                };
                _.keys(savedState[x].columnStates).forEach(y => {
                    savedState[x].columnStates[y] = {
                        ...unloadedColumnState,
                        ...savedState[x].columnStates[y]
                    };
                });
            });

            return {
                ...state,
                ...savedState
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};
