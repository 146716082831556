import * as React from 'react';
import { Route, Switch } from 'react-router';
import { Layout } from './components/Layout';
import Home from './components/Home';
import Login from './components/Login';
import Families from './components/Families';
import SubFamilies from './components/SubFamilies';
import Vats from './components/Vats';
import Units from './components/Units';
import SupplierMains from './components/SupplierMains';
import SupplierSubs from './components/SupplierSubs';
import Promotions from './components/Promotions';
import MenuCategories from './components/MenuCategories';
import Menus from './components/Menus';
import PriceNames from './components/PriceNames';
import Currencies from './components/Currencies';
import Products from './components/Products';
import ExternalProducts from './components/ExternalProducts';
import Transfer from './components/Transfer';
import Submissions from './components/Submissions';
import UserIsAuthenticated from './security/UserIsAuthenticated';
import UserIsNotAuthenticated from './security/UserIsNotAuthenticated';
import './css/site.css';
import 'rc-select/assets/index.css';
import 'rc-menu/assets/index.css';
import 'rc-tooltip/assets/bootstrap_white.css';
import 'react-table/react-table.css';
import 'react-datepicker/dist/react-datepicker.min.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {connectedRouterRedirect} from "redux-auth-wrapper/history4/redirect";
import { allowedStores } from "./store/Account";
import { ApplicationState } from './store';
import * as _ from 'lodash';

const isNotMaster = (state: ApplicationState): boolean => (state.account.currentUser
    && state.account.currentUser.account
    && state.account.currentUser.account.accountRoles.some(y => y.role.name === "Maintenance"
        || y.role.name === "Master"))
    ? false : true;

const HasProductsRedirect = connectedRouterRedirect({
    redirectPath: '/products',
    authenticatedSelector: (state: ApplicationState) => isNotMaster && _.values(allowedStores(state)).find(x => x.storeType != "Ximiti") ? true : false,
    wrapperDisplayName: 'UserHasStoreTypeOther',
    allowRedirectBack: false,
    redirectQueryParamName: null
}) as any;

const HasExternalProductsRedirect = connectedRouterRedirect({
    redirectPath: '/externalproducts',
    authenticatedSelector: (state: ApplicationState) => isNotMaster && _.values(allowedStores(state)).find(x => x.storeType == "Ximiti") ? true : false,
    wrapperDisplayName: 'UserHasStoreTypeXimiti',
    allowRedirectBack: false,
    redirectQueryParamName: null
}) as any;


export default () => (<Layout>
    <Switch>
        <Route exact path='/' component={UserIsAuthenticated(Home)} />
        <Route path="/families" component={UserIsAuthenticated(Families)} />
        <Route path="/subfamilies" component={UserIsAuthenticated(SubFamilies)} />
        <Route path="/vats" component={UserIsAuthenticated(Vats)} />
        <Route path="/units" component={UserIsAuthenticated(Units)} />
        <Route path="/suppliermains" component={UserIsAuthenticated(SupplierMains)} />
        <Route path="/suppliersubs" component={UserIsAuthenticated(SupplierSubs)} />
        <Route path="/promotions" component={UserIsAuthenticated(Promotions)} />
        <Route path="/menucategories" component={UserIsAuthenticated(MenuCategories)} />
        <Route path="/menus" component={UserIsAuthenticated(Menus)} />
        <Route path="/pricenames" component={UserIsAuthenticated(PriceNames)} />
        <Route path="/currencies" component={UserIsAuthenticated(Currencies)} />
        <Route path="/products" component={UserIsAuthenticated(HasExternalProductsRedirect(Products))} />
        <Route path="/externalproducts" component={UserIsAuthenticated(HasProductsRedirect(ExternalProducts))} />
        <Route path="/transfer" component={UserIsAuthenticated(Transfer)} />
        <Route path="/submission" component={UserIsAuthenticated(Submissions)} />
        <Route path="/login" component={UserIsNotAuthenticated(Login)} />
    </Switch>
</Layout>);

