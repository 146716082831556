import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { ProductPriceCalculator } from '../utils/priceUtils';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector, FieldArray
} from 'redux-form';
import ProductCustomizations, { ProductCustomizationsOwnProps } from "./ProductCustomizationsField";
import { FormType } from '../store/Crude';
import Select from "./Select";
import {
    productValidation,
    productWarnings,
} from '../utils/validation';
import {
    productHelp,
} from '../utils/entityHelp';
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import { FieldHelp } from './FieldHelp';
import { Prices, PricesOwnProps } from './PricesForm';
import { diff } from 'deep-object-diff';
import { ProductSuppliers, ProductSuppliersOwnProps } from './ProductSupplierForm';
import { selectedStores } from '../store/Account';
import StoreItemStorePaymentField from './StoreItemStorePaymentField';
import ProductVats, { ProductVatsOwnProps } from "./ProductVats";
import ProductBarcodes, { ProductBarcodesOwnProps } from "./ProductBarcodes";
import ProductMenuCategories, { ProductMenuCategoriesOwnProps } from "./ProductMenuCategories";
import ProductSubFamilies, { ProductSubFamiliesOwnProps } from "./ProductSubFamilies";
import StoreItemTagsField from "./StoreItemTagsField";
import { getText } from '../utils/texts';

type ProductFormProps = InjectedFormProps
    & FormField.FormProps
    & ProductFormOwnProps
    & ProductFormExternalProps;

interface ProductFormExternalProps {
    actionName?: string;
    inputDisabled?: boolean;
    onResetDuplicate?: () => void;
    onSelectSupplierId: (supplierId: number) => void;
    onSelectPriceNameId: (priceNameId: number) => void;
    onSelectStoreId: (storeId: number) => void;
    updateFormValue: (form: string, member: string, value: any) => void;
}

interface ProductFormOwnProps {
    entities: { [id: number]: Api.ProductModel }
    languages: { [id: number]: Api.LanguageModel }
    stores: { [id: number]: Api.StoreModel }
    storeGroups: { [id: number]: Api.StoreGroupModel }
    currencies: { [id: number]: Api.CurrencyModel }
    priceNames: { [id: number]: Api.PriceNameModel }
    families: { [id: number]: Api.FamilyModel }
    subFamilies: { [id: number]: Api.SubFamilyModel }
    vats: { [id: number]: Api.VatModel }
    units: { [id: number]: Api.UnitModel }
    supplierMains: { [id: number]: Api.SupplierModel }
    supplierSubs: { [id: number]: Api.SupplierModel }
    menuCategories: { [id: number]: Api.MenuCategoryModel }
    countries: { [id: number]: Api.CountryModel }
    //Data coming from the form
    allowedStores: Array<number>;
    selectedSupplierId: number;
    selectedPriceNameId: number;
    selectedStoreId: number;
    storeItemId: number;
    productSuppliers: Array<Api.ProductSupplierModel>;
    productBarcodes: Array<Api.ProductBarcodeModel>;
    storeItemStorePayments: Array<Api.StoreItemStorePaymentModel>;
    prices: Array<Api.PriceModel>;
    productCustoms: Array<Api.ProductCustomModel>;
    productVats: Array<Api.ProductVatModel>;
    productSubFamilies: Array<Api.ProductSubFamilyModel>;
    productMenuCategories: Array<Api.ProductMenuCategoryModel>;
    expirityType: Api.ProductModelExpirityTypeEnum;
    name: string;
    defaultCurrencyCode: string;
    volume: number;
    volumeUnitId: number;
    relativeUnitId: number;
    disabled?: boolean;
    formType: FormType;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
}

export const stabilityOptions = (): Array<{ label: string; value: Api.ProductModelStabilityEnum }> => [
    { label: getText("GenStabilityStable"), value: "Stable" },
    { label: getText("GenStabilityUnstable"), value: "Unstable" }
];

export const expirityTypeOptions = (): Array< { label: string; value: Api.ProductModelExpirityTypeEnum } > => ([
    { label: getText("GenExpirityNone"), value: "None" },
    { label: getText("GenExpirityNormal"), value: "Normal" },
    { label: getText("GenExpirityDdm"), value: "Ddm" },
    { label: getText("GenExpirityEggs"), value: "Eggs" }
]);

const getNextCode = (currentCode: string, props: ProductFormProps): string => {
    let code = currentCode || "0000";
    let parsedCode = parseInt(code);
    let products = _.values(props.entities);
    let productLengthFactor = Math.floor(Math.max(products.length.toString().length - 4, 0));
    do {
        parsedCode = (parsedCode + 1) % (10000 * Math.pow(10, productLengthFactor));
        code = _.padStart(parsedCode.toString(), 4 + productLengthFactor, '0');
    } while (products.some(x => x.storeItemId !== props.storeItemId && x.code === code));
    return code;
};

const formTitleStyle = {
    textAlign: "center" as any,
    marginBottom: 40
};

const storeItemTagField = (fields) => <div>
    <StoreItemTagsField
        value={fields.input.value}
        onChange={(value) => fields.input.onChange(value)} />
    <FormField.FieldErrors {...fields.meta} />
</div>


export class ProductForm extends React.Component<ProductFormProps, {}> {
    get availablePriceNames(): Array<{ label: string, value: number }> {
        return _.values(this.props.priceNames)
            .filter(x => this.props.prices.some(y => y.priceNameId === x.priceNameId))
            .map(x => ({ label: x.name, value: x.priceNameId }));
    }

    get availableSuppliers(): Array<{ label: string, value: number }> {
        return _.values(this.props.supplierMains)
            .concat(this.props.supplierSubs)
            .filter(x => this.props.productSuppliers.some(y => y.supplierMainId === x.supplierId))
            .map(x => ({ label: x.name, value: x.supplierId }))
    }

    get selectedPriceNameId(): number {
        return this.props.selectedPriceNameId
            || (this.props.prices.length !== 0
            ? _.sortBy(this.props.prices,
                x => this.props.priceNames[x.priceNameId].name === "TarifPlein"
                    ? 0
                    : (this.props.priceNames[x.priceNameId].type === "Normal"
                    ? 1
                    : 2))[0].priceNameId
                : 0)
    }

    get selectedSupplierId(): number {
        return this.props.selectedSupplierId
            || (this.props.productSuppliers.length !== 0
                ? (this.props.productSuppliers[0].supplierSubId
                    || this.props.productSuppliers[0].supplierMainId)
                : 0)
    }

    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create";
    }

    public render() {
        let productPriceCalculator = new ProductPriceCalculator(
            this.props.units,
            this.props.vats,
            _.keyBy(_.values(this.props.supplierMains)
                .concat(_.values(this.props.supplierSubs)), x => x.supplierId),
            this.props.currencies
        );

        let priceGlobal = _.sortBy(this.props.prices, x => x.priceNameId !== this.selectedPriceNameId)[0];
        let globalCurrencyId = priceGlobal ? priceGlobal.currencyId : 1;
        let productPriceInfoGlobal = productPriceCalculator.calculate({
            productSuppliers: this.props.productSuppliers,
            productVats: this.props.productVats,
            relativeUnitId: this.props.relativeUnitId,
            volume: this.props.volume,
            volumeUnitId: this.props.volumeUnitId
        }, priceGlobal
            , _.sortBy(this.props.productSuppliers
                .filter(x => (x.supplierMainId === this.selectedSupplierId
                    || x.supplierSubId === this.selectedSupplierId)), x => x.buyPriceCurrencyId !== globalCurrencyId)[0]);

        let priceSpec = _.sortBy(this.props.prices
            .filter(x => x.priceNameId === this.selectedPriceNameId)
            , x => x.priceStores.length == 0
                || x.priceStores.some(y => y.storeId !== this.props.selectedStoreId))[0];
        let specCurrencyId = priceSpec ? priceSpec.currencyId : 1;
        let productPriceInfoSpecific = productPriceCalculator.calculate({
            productSuppliers: this.props.productSuppliers,
            productVats: this.props.productVats,
            relativeUnitId: this.props.relativeUnitId,
            volume: this.props.volume,
            volumeUnitId: this.props.volumeUnitId
        }, priceSpec
            , _.sortBy(this.props.productSuppliers
                .filter(x => x.supplierMainId === this.selectedSupplierId
                    || x.supplierSubId === this.selectedSupplierId)
                , x => (x.productSupplierStores.length === 0
                    || x.productSupplierStores.some(y => y.storeId !== this.props.selectedStoreId))
                    || x.buyPriceCurrencyId !== specCurrencyId)[0]);


        return (
            <FormField.BaseForm {...this.props} className="">
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                        textAlign: "center",
                        border: "1px solid black"
                    }}>Données globales</legend>
                    <div className="form-horizontal">
                        <h3 className="h3" style={formTitleStyle}>Informations produit</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label ">{"Nom:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("name")}>
                                        <FieldHelp help={productHelp()["name"]}>
                                            <Field className="form-control"
                                                validate={productValidation["name"]}
                                                name="name"
                                                disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                component={FormField.renderField}
                                                type="text" />
                                        </FieldHelp>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Code:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("code")}>
                                        <FieldHelp help={productHelp()["code"]}>
                                            <Field className="form-control"
                                                validate={productValidation["code"]}
                                                name="code"
                                                component={(fields) => <div>
                                                    <div style={{ display: "flex" }}>
                                                        <input {...fields.input}
                                                            disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                            className="form-control"
                                                            type="text" style={{ flex: 1 }} />
                                                        <button className="btn btn-sm btn-secondary" style={{ flex: "0 0 50px" }}
                                                            type={"button"}
                                                            disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                            onClick={(e) => {
                                                                fields.input.onChange(getNextCode(fields.input.value, this.props));
                                                            }}>
                                                            <i className="glyphicon glyphicon-refresh"></i>
                                                        </button>
                                                    </div>
                                                    <FormField.FieldErrors {...fields.meta} />
                                                </div>}
                                                type="text" />
                                        </FieldHelp>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Image:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("imageId")}>
                                        <FieldHelp help={productHelp()["imageId"]}>
                                            <Field className="form-control"
                                                validate={productValidation["imageId"]}
                                                name="imageId"
                                                fieldKey={"productImagineId-" + this.props.formType}
                                                height={170}
                                                disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                acceptProp={".png, .jpg, .jpeg"}
                                                component={FormField.ImageField}
                                                />
                                        </FieldHelp>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Largeur:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("width")}>
                                        <FieldHelp help={productHelp()["width"]}>
                                            <Field className="form-control"
                                                validate={productValidation["width"]}
                                                name="width"
                                                disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                unit={"mm"}
                                                component={FormField.NumberField} />
                                        </FieldHelp>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Hauteur:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("height")}>
                                        <FieldHelp help={productHelp()["height"]}>
                                            <Field className="form-control"
                                                validate={productValidation["height"]}
                                                disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                name="height"
                                                unit={"mm"}
                                                component={FormField.NumberField} />
                                        </FieldHelp>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Profondeur:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("depth")}>
                                        <FieldHelp help={productHelp()["depth"]}>
                                            <Field className="form-control"
                                                validate={productValidation["depth"]}
                                                disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                name="depth"
                                                unit={"mm"}
                                                component={FormField.NumberField} />
                                        </FieldHelp>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Quantité / unité:"}</label>
                                    <div className="col-sm-5" style={this.getFieldStyle("volume")}>
                                        <FieldHelp help={productHelp()["volume"]}>
                                            <Field className="form-control"
                                                validate={productValidation["volume"]}
                                                disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                name="volume"
                                                component={FormField.NumberField} />
                                        </FieldHelp>
                                    </div>
                                    <div className="col-sm-3" style={this.getFieldStyle("volumeUnitId")}>
                                        <FieldHelp help={productHelp()["volumeUnitId"]}>
                                            <Field className="form-control"
                                                validate={productValidation["volumeUnitId"]}
                                                disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                name="volumeUnitId"
                                                component={FormField.getSelectField(_.values(this.props.units)
                                                    .map(x => ({ label: x.code, value: x.unitId })))} />
                                        </FieldHelp>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Stabilité:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("sability")}>
                                        <FieldHelp help={productHelp()["stability"]}>
                                            <Field className="form-control"
                                                disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                validate={productValidation["stability"]}
                                                name="stability"
                                                component={FormField.getSelectField(stabilityOptions())} />
                                        </FieldHelp>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Type date:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("expirityType")}>
                                        <FieldHelp help={productHelp()["expirityType"]}>
                                            <Field className="form-control"
                                                disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                validate={productValidation["expirityType"]}
                                                name="expirityType"
                                                afterChange={(value: Api.ProductModelExpirityTypeEnum) => {
                                                    if (value === "Eggs") {
                                                        this.props.updateFormValue(this.props.form, "expirityDays", 7);
                                                    }
                                                }}
                                                component={FormField.getSelectField(expirityTypeOptions())} />
                                        </FieldHelp>
                                    </div>
                                </div>
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Nb jours / date:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("expirityDays")}>
                                        {this.props.expirityType !== "Eggs"
                                            && <FieldHelp help={productHelp()["expirityDays"]}>
                                                <Field className="form-control"
                                                    disabled={this.props.inputDisabled
                                                        || this.props.expirityType === "None"
                                                        || this.globalFieldDisabled}
                                                    validate={productValidation["expirityDays"]}
                                                    name="expirityDays"
                                                    component={FormField.NumberField} />
                                            </FieldHelp>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="h3" style={formTitleStyle}>Sous-familles et catégories menu</h3>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Sous familles:"}</label>
                                    <div className="col-sm-10" style={this.getFieldStyle("productSubFamilies")}>
                                        <FieldArray
                                            name="productSubFamilies"
                                            component={ProductSubFamilies}
                                            validate={productValidation["productSubFamilies"]}
                                            props={{
                                                storeItemId: this.props.storeItemId,
                                                subFamilies: this.props.subFamilies,
                                                families: this.props.families,
                                                disabled: this.props.inputDisabled
                                                    || this.globalFieldDisabled,
                                                dataType: "Global",
                                                stores: this.props.stores,
                                                allowedStores: this.props.allowedStores,
                                                productSubFamilies: this.props.productSubFamilies
                                            } as ProductSubFamiliesOwnProps} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label">{"Categories menu:"}</label>
                                    <div className="col-sm-8" style={this.getFieldStyle("productMenuCategories")}>
                                        <FieldArray
                                            name="productMenuCategories"
                                            component={ProductMenuCategories}
                                            validate={[]}
                                            props={{
                                                storeItemId: this.props.storeItemId,
                                                menuCategories: this.props.menuCategories,
                                                disabled: this.props.inputDisabled
                                                    || this.globalFieldDisabled,
                                                dataType: "Global",
                                                stores: this.props.stores,
                                                allowedStores: this.props.allowedStores,
                                                productMenuCategories: this.props.productMenuCategories
                                            } as ProductMenuCategoriesOwnProps} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <h3 className="h3" style={formTitleStyle}>Informations complémentaires</h3>
                        <div className="form-group row">
                            <div className="col-md-3"></div>
                            <div className="col-md-9">
                                <a href={"https://fr.openfoodfacts.org/cgi/search.pl?search_terms=" +
                                    encodeURIComponent(this.props.name
                                        ? this.props.name.split(" ").slice(0, 2).join(" ")
                                        : "")}
                                    target="_blank">Food facts</a>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label">{"Commentaire:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("comment")}>
                                <FieldHelp help={productHelp()["comment"]}>
                                    <Field className="form-control"
                                        disabled={this.props.inputDisabled
                                            || this.globalFieldDisabled}
                                        validate={productValidation["comment"]}
                                        name="comment"
                                        component={FormField.rteField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label">{"Tags:"}</label>
                            <div className="col-sm-9">
                                <FieldHelp help={productHelp()["storeItemTags"]}>
                                    <Field className="form-control"
                                        disabled={this.props.inputDisabled
                                            || this.globalFieldDisabled}
                                        validate={productValidation["storeItemTags"]}
                                        name="storeItemTags"
                                        component={storeItemTagField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Options:"}</label>
                            <div className="col-sm-9">
                                <div>
                                    <FieldHelp help={productHelp()["hmiVisibility"]}>
                                        <Field
                                            disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                            validate={productValidation["hmiVisibility"]}
                                            name="hmiVisibility"
                                            label={"Visible sur l'IHM"}
                                            checkedValue={"Visible"}
                                            unCheckedValue={"Hide"}
                                            component={FormField.CheckBoxField} />
                                    </FieldHelp>
                                </div>
                                <div>
                                    <FieldHelp help={productHelp()["smartPhoneClientVisibility"]}>
                                        <Field
                                            disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                            validate={productValidation["smartPhoneClientVisibility"]}
                                            name="smartPhoneClientVisibility"
                                            label={"Visible sur l'application smartphone client"}
                                            checkedValue={"Visible"}
                                            unCheckedValue={"Hide"}
                                            component={FormField.CheckBoxField} />
                                    </FieldHelp>
                                </div>
                                <div>
                                    <FieldHelp help={productHelp()["smartPhoneAdminVisibility"]}>
                                        <Field
                                            disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                            validate={productValidation["smartPhoneAdminVisibility"]}
                                            name="smartPhoneAdminVisibility"
                                            label={"Visible sur l'application smartphone admin"}
                                            checkedValue={"Visible"}
                                            unCheckedValue={"Hide"}
                                            component={FormField.CheckBoxField} />
                                    </FieldHelp>
                                </div>
                                <div>
                                    <FieldHelp help={productHelp()["storeItemStorePayments"]}>
                                        <Field
                                            disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                            validate={productValidation["storeItemStorePayments"]}
                                            name="storeItemStorePayments"
                                            paymentType={"MealVoucher" as Api.StoreItemStorePaymentModelPaymentTypeEnum}
                                            component={StoreItemStorePaymentField} />
                                    </FieldHelp>
                                </div>
                                <div>
                                    <FieldHelp help={productHelp()["option1"]}>
                                        <Field
                                            disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                            validate={productValidation["option1"]}
                                            name="option1"
                                            label={"Option 1"}
                                            component={FormField.CheckBoxField} />
                                    </FieldHelp>
                                </div>
                                <div>
                                    <FieldHelp help={productHelp()["ageRestriction"]}>
                                        <Field
                                            disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                            validate={productValidation["ageRestriction"]}
                                            name="ageRestriction"
                                            label={"Restriction d'âge"}
                                            component={FormField.CheckBoxField} />
                                    </FieldHelp>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Code barres:"}</label>
                            <div className="col-sm-6" style={this.getFieldStyle("productBarCodes")}>
                                <FieldArray
                                    name="productBarcodes"
                                    component={ProductBarcodes}
                                    validate={productValidation["productBarCodes"]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        productBarcodes: this.props.productBarcodes,
                                        disabled: this.props.inputDisabled || this.globalFieldDisabled
                                    } as ProductBarcodesOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Customisations:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("productCustoms")}>
                                <FieldArray
                                    validate={[]}
                                    name="productCustoms"
                                    component={ProductCustomizations}
                                    props={{
                                        languages: this.props.languages,
                                        storeGroups: this.props.storeGroups,
                                        disabled: this.props.inputDisabled || this.globalFieldDisabled,
                                        entityCustoms: this.props.productCustoms,
                                        dataType: "Global",
                                        defaultName: this.props.name,
                                        allowedStoreGroup: _.values(this.props.storeGroups)
                                            .filter(x => this.props.allowedStores.some(y => this.props.stores[y].storeGroupId === x.storeGroupId))
                                            .map(x => x.storeGroupId)
                                    } as ProductCustomizationsOwnProps} />
                            </div>
                        </div>
                        <h3 className="h3" style={formTitleStyle}>Informations tarifaires</h3>
                        {this.props.currencies
                            && _.values(this.props.currencies).length !== 0
                            && <div className="form-group row" style={{
                                marginBottom: 20
                            }}>
                                <label className="col-sm-3 control-label ">{"Marge:"}</label>
                                <div className="col-sm-9" style={{ padding: 5, border: "1px solid", borderRadius: 10 }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <label className="col-md-5 control-label">{"Tarif de calcul:"}</label>
                                                <div className="col-md-7">
                                                    <Select options={_.sortBy(this.availablePriceNames, x => _.deburr(x.label))}
                                                        value={this.selectedPriceNameId}
                                                        onChange={(priceNameId) => this.props.onSelectPriceNameId(priceNameId)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <label className="col-md-5 control-label">{"Source de calcul:"}</label>
                                                <div className="col-md-7">
                                                    <Select options={_.sortBy(this.availableSuppliers, x => _.deburr(x.label))}
                                                        value={this.selectedSupplierId}
                                                        onChange={(supplierId) => this.props.onSelectSupplierId(supplierId)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-0">
                                            <div className="row">
                                                <label className="col-md-5 control-label">{"PV Unitaire:"}</label>
                                                <div className="col-md-7">
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div className="form-control-static" style={{ order: 1, flex: "0 0 auto" }}>
                                                            {productPriceInfoGlobal.unitPrice.toFixed(2)} {this.props.currencies[globalCurrencyId].symbol} /
                                                    </div>
                                                        <div style={{ ...{ order: 2, flex: 1 }, ...this.getFieldStyle("relativeUnitId") }}>
                                                            <FieldHelp help={productHelp()["relativeUnitId"]}>
                                                                <Field className="form-control"
                                                                    validate={productValidation["relativeUnitId"]}
                                                                    name="relativeUnitId"
                                                                    disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                                                    component={FormField.getSelectField(
                                                                        _.values(this.props.units)
                                                                            .map(x => ({ label: x.code, value: x.unitId })))} />
                                                            </FieldHelp>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <label className="col-md-7 control-label">{"PV TTC:"}</label>
                                                <div className="col-md-5 form-control-static">
                                                    {productPriceInfoGlobal.price.toFixed(2)} {this.props.currencies[globalCurrencyId].symbol}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="row">
                                                <label className="col-md-7 control-label">{"PAHT:"}</label>
                                                <div className="col-md-5 form-control-static">
                                                    {productPriceInfoGlobal.noVatUnitBuyPrice.toFixed(2)} {this.props.currencies[globalCurrencyId].symbol}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <label className="col-md-7 control-label">{"Marge brute:"}</label>
                                                <div className="col-md-5 form-control-static">
                                                    {productPriceInfoGlobal.grossMargin.toFixed(2)} {this.props.currencies[globalCurrencyId].symbol}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <label className="col-md-7 control-label">{"Taux de marque:"}</label>
                                                <div className="col-md-5 form-control-static">
                                                    <FieldHelp help={"Part de la marge commerciale dans le prix de vente d'une marchandise"}>
                                                        {productPriceInfoGlobal.markup.toFixed(2)} %
                                                </FieldHelp>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Tarifs de vente:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("prices")}>
                                <FieldArray
                                    name="prices"
                                    component={Prices}
                                    validate={productValidation["prices"]}
                                    warn={productWarnings["prices"]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        prices: this.props.prices,
                                        priceNames: this.props.priceNames,
                                        currencies: this.props.currencies,
                                        stores: this.props.stores,
                                        disabled: this.props.inputDisabled || this.globalFieldDisabled,
                                        allowedStores: [],
                                        dataType: "Global",
                                        defaultCurrencyCode: this.props.defaultCurrencyCode,
                                        onPriceNameIdChange: (member, value) => this.props.updateFormValue(this.props.form, `${member}.value`, value)
                                    } as PricesOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Tarif de vente par défaut:"}</label>
                            <div className="col-sm-6" style={this.getFieldStyle("defaultPriceNameId")}>
                                <FieldHelp help={productHelp()["defaultPriceNameId"]}>
                                    <Field className="form-control"
                                        validate={productValidation["defaultPriceNameId"]}
                                        name="defaultPriceNameId"
                                        disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                        component={FormField.getSelectField(this.availablePriceNames)} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Sources:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("productSuppliers")}>
                                <FieldArray
                                    name="productSuppliers"
                                    component={ProductSuppliers}
                                    validate={productValidation["productSuppliers"]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        supplierMains: this.props.supplierMains,
                                        supplierSubs: this.props.supplierSubs,
                                        currencies: this.props.currencies,
                                        vats: this.props.vats,
                                        productSuppliers: this.props.productSuppliers,
                                        stores: this.props.stores,
                                        disabled: this.props.inputDisabled || this.globalFieldDisabled,
                                        allowedStores: this.props.allowedStores,
                                        dataType: "Global",
                                        onSupplierMainIdChange: (member, value) => this.props.updateFormValue(this.props.form, `${member}`, value)
                                    } as ProductSuppliersOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Source par défaut:"}</label>
                            <div className="col-sm-6" style={this.getFieldStyle("defaultSupplierId")}>
                                <FieldHelp help={productHelp()["defaultSupplierId"]}>
                                    <Field className="form-control"
                                        validate={productValidation["defaultSupplierId"]}
                                        name="defaultSupplierId"
                                        disabled={this.props.inputDisabled || this.globalFieldDisabled}
                                        component={FormField.getSelectField(this.availableSuppliers)} />
                                </FieldHelp>
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                        textAlign: "center",
                        border: "1px solid black"
                    }}>Données par magasin</legend>
                    <div className="form-group row">
                        <label className="col-sm-3 control-label ">{"TVA:"}</label>
                        <div className="col-sm-9" style={this.getFieldStyle("productVats")}>
                            <FieldArray
                                name="productVats"
                                component={ProductVats}
                                validate={productValidation["productVats"]}
                                props={{
                                    storeItemId: this.props.storeItemId,
                                    vats: this.props.vats,
                                    countries: this.props.countries,
                                    disabled: this.props.inputDisabled,
                                    updateDisabled: this.globalFieldDisabled,
                                    productVats: this.props.productVats
                                } as ProductVatsOwnProps} />
                        </div>
                    </div>
                    <div className="form-horizontal">
                        <h3 className="h3" style={formTitleStyle}>Informations produit</h3>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label">{"Sous familles:"}</label>
                            <div className="col-sm-10" style={this.getFieldStyle("productSubFamilies")}>
                                <FieldArray
                                    name="productSubFamilies"
                                    component={ProductSubFamilies}
                                    validate={productValidation["productSubFamilies"]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        subFamilies: this.props.subFamilies,
                                        families: this.props.families,
                                        disabled: this.props.inputDisabled,
                                        dataType: "Specific",
                                        stores: this.props.stores,
                                        allowedStores: this.props.allowedStores,
                                        productSubFamilies: this.props.productSubFamilies
                                    } as ProductSubFamiliesOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label">{"Categories menu:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("productMenuCategories")}>
                                <FieldArray
                                    name="productMenuCategories"
                                    component={ProductMenuCategories}
                                    validate={[]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        menuCategories: this.props.menuCategories,
                                        disabled: this.props.inputDisabled,
                                        dataType: "Specific",
                                        stores: this.props.stores,
                                        allowedStores: this.props.allowedStores,
                                        productMenuCategories: this.props.productMenuCategories
                                    } as ProductMenuCategoriesOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Customisations:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("productCustoms")}>
                                <FieldArray
                                    validate={[]}
                                    name="productCustoms"
                                    component={ProductCustomizations}
                                    props={{
                                        languages: this.props.languages,
                                        storeGroups: this.props.storeGroups,
                                        disabled: this.props.inputDisabled,
                                        entityCustoms: this.props.productCustoms,
                                        dataType: "Specific",
                                        defaultName: this.props.name,
                                        allowedStoreGroup: _.values(this.props.storeGroups)
                                            .filter(x => this.props.allowedStores.some(y => this.props.stores[y].storeGroupId === x.storeGroupId))
                                            .map(x => x.storeGroupId)
                                    } as ProductCustomizationsOwnProps} />
                            </div>
                        </div>
                        <h3 className="h3" style={formTitleStyle}>Informations tarifaires</h3>
                        {this.props.currencies
                            && _.values(this.props.currencies).length !== 0
                            && <div className="form-group row" style={{
                                marginBottom: 20
                            }}>
                                <label className="col-sm-3 control-label ">{"Marge:"}</label>
                                <div className="col-sm-9" style={{ padding: 5, border: "1px solid", borderRadius: 10 }}>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <label className="col-md-5 control-label">{"Magasin de calcul:"}</label>
                                                <div className="col-md-7">
                                                    <Select options={_.sortBy(_.values(this.props.stores)
                                                        .filter(x => this.props.allowedStores.some(y => y === x.storeId)), x => _.deburr(x.name).toLowerCase())
                                                        .map(x => ({ label: x.name, value: x.storeId }))}
                                                        value={this.props.selectedStoreId}
                                                        onChange={(storeId) => this.props.onSelectStoreId(storeId)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">

                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="row">
                                                <label className="col-md-5 control-label">{"Tarif de calcul:"}</label>
                                                <div className="col-md-7">
                                                    <Select options={_.sortBy(this.availablePriceNames, x => _.deburr(x.label))}
                                                        value={this.selectedPriceNameId}
                                                        onChange={(priceNameId) => this.props.onSelectPriceNameId(priceNameId)} />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="row">
                                                <label className="col-md-5 control-label">{"Source de calcul:"}</label>
                                                <div className="col-md-7">
                                                    <Select options={_.sortBy(this.availableSuppliers, x => _.deburr(x.label))}
                                                        value={this.selectedSupplierId}
                                                        onChange={(supplierId) => this.props.onSelectSupplierId(supplierId)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-0">
                                            <div className="row">
                                                <label className="col-md-5 control-label">{"PV Unitaire:"}</label>
                                                <div className="col-md-7">
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div className="form-control-static" style={{ order: 1, flex: "0 0 auto" }}>
                                                            {productPriceInfoSpecific.unitPrice.toFixed(2)} {this.props.currencies[specCurrencyId].symbol} /
                                                    </div>
                                                        <div style={{ ...{ order: 2, flex: 1 }, ...this.getFieldStyle("relativeUnitId") }}>
                                                            <FieldHelp help={productHelp()["relativeUnitId"]}>
                                                                <Field className="form-control"
                                                                    validate={productValidation["relativeUnitId"]}
                                                                    name="relativeUnitId"
                                                                    disabled={this.props.inputDisabled}
                                                                    component={FormField.getSelectField(
                                                                        _.values(this.props.units)
                                                                            .map(x => ({ label: x.code, value: x.unitId })))} />
                                                            </FieldHelp>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <label className="col-md-7 control-label">{"PV TTC:"}</label>
                                                <div className="col-md-5 form-control-static">
                                                    {productPriceInfoSpecific.price.toFixed(2)} {this.props.currencies[specCurrencyId].symbol}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="row">
                                                <label className="col-md-7 control-label">{"PAHT:"}</label>
                                                <div className="col-md-5 form-control-static">
                                                    {productPriceInfoSpecific.noVatUnitBuyPrice.toFixed(2)} {this.props.currencies[specCurrencyId].symbol}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <label className="col-md-7 control-label">{"Marge brute:"}</label>
                                                <div className="col-md-5 form-control-static">
                                                    {productPriceInfoSpecific.grossMargin.toFixed(2)} {this.props.currencies[specCurrencyId].symbol}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="row">
                                                <label className="col-md-7 control-label">{"Taux de marque:"}</label>
                                                <div className="col-md-5 form-control-static">
                                                    <FieldHelp help={"Part de la marge commerciale dans le prix de vente d'une marchandise"}>
                                                        {productPriceInfoSpecific.markup.toFixed(2)} %
                                        </FieldHelp>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>}
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Tarifs de vente:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("prices")}>
                                <FieldArray
                                    name="prices"
                                    component={Prices}
                                    validate={productValidation["prices"]}
                                    warn={productWarnings["prices"]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        prices: this.props.prices,
                                        priceNames: this.props.priceNames,
                                        currencies: this.props.currencies,
                                        stores: this.props.stores,
                                        disabled: this.props.inputDisabled,
                                        allowedStores: this.props.allowedStores,
                                        dataType: "Specific",
                                        defaultCurrencyCode: this.props.defaultCurrencyCode,
                                        onPriceNameIdChange: (member, value) => this.props.updateFormValue(this.props.form, `${member}.value`, value)
                                    } as PricesOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Sources:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("productSuppliers")}>
                                <FieldArray
                                    name="productSuppliers"
                                    component={ProductSuppliers}
                                    validate={productValidation["productSuppliers"]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        supplierMains: this.props.supplierMains,
                                        supplierSubs: this.props.supplierSubs,
                                        currencies: this.props.currencies,
                                        vats: this.props.vats,
                                        productSuppliers: this.props.productSuppliers,
                                        stores: this.props.stores,
                                        disabled: this.props.inputDisabled,
                                        allowedStores: this.props.allowedStores,
                                        dataType: "Specific",
                                        onSupplierMainIdChange: (member, value) => this.props.updateFormValue(this.props.form, `${member}`, value)
                                    } as ProductSuppliersOwnProps} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account))
                            && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                                <button className="btn btn-primary btn-lg"
                                    disabled={this.props.submitting
                                        || (this.props.pristine && this.props.formType !== "Submission")
                                        || this.props.disabled}
                                    type={"submit"}>{this.props.actionName}</button>
                                <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        if (this.props.formType === "Create") {
                                            this.props.onResetDuplicate();
                                        }
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>
                            </div>}
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createFormName = 'createProduct';
let createForm = reduxForm({
    form: createFormName,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmitFail: (errors) => FormField.scrollToFirstError(errors, "create")
})(ProductForm) as any;
const createSelector = formValueSelector(createFormName);

let updateFormName = 'updateProduct';
let updateForm = reduxForm({
    form: updateFormName,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmitFail: (errors) => FormField.scrollToFirstError(errors, "update")
})(ProductForm) as any;
const updateSelector = formValueSelector(updateFormName);

let submissionFormName = 'submissionProduct';
let submissionForm = reduxForm({
    form: submissionFormName,
    destroyOnUnmount: false,
    enableReinitialize: true,
    onSubmitFail: (errors) => FormField.scrollToFirstError(errors)
})(ProductForm) as any;
const submissionSelector = formValueSelector(submissionFormName)

export const CreateProductForm = connect((state: ApplicationState) => {
    return {
        entities: state.product.entities,
        storeItemId: 0,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        currencies: state.currency.entities,
        priceNames: state.priceName.entities,
        subFamilies: state.subFamily.entities,
        vats: state.vat.entities,
        families: state.family.entities,
        countries: state.seed.seed.countries,
        supplierMains: state.supplierMain.entities,
        supplierSubs: state.supplierSub.entities,
        units: state.unit.entities,
        menuCategories: state.menuCategory.entities,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        name: createSelector(state, "name"),
        productSuppliers: createSelector(state, "productSuppliers") || [],
        prices: createSelector(state, "prices") || [],
        productCustoms: createSelector(state, "productCustoms") || [],
        productVats: createSelector(state, "productVats") || [],
        productBarcodes: createSelector(state, "productBarcodes") || [],
        productSubFamilies: createSelector(state, "productSubFamilies") || [],
        productMenuCategories: createSelector(state, "productMenuCategories") || [],
        storeItemStorePayments: createSelector(state, "storeItemStorePayments") || [],
        volume: createSelector(state, "volume"),
        volumeUnitId: createSelector(state, "volumeUnitId"),
        relativeUnitId: createSelector(state, "relativeUnitId"),
        expirityType: createSelector(state, "expirityType"),
        selectedStoreId: state.product.selectedStoreId,
        selectedSupplierId: state.product.selectedSupplierId,
        selectedPriceNameId: state.product.selectedPriceNameId,
        defaultCurrencyCode: state.seed.seed.lgapSettings.defaultCurrencyCode,
        formType: "Create",
        initialValues: state.product.createSourceId
            ? {
                storeItemStorePayments: [],
                ...state.product.entities[state.product.createSourceId],
                prices: state.product.entities[state.product.createSourceId].prices
                    .filter(x => !x.priceStores || x.priceStores.length === 0),
                productSuppliers: state.product.entities[state.product.createSourceId].productSuppliers
                    .filter(x => !x.productSupplierStores || x.productSupplierStores.length === 0),
            } as Api.ProductModel
            : {
                storeItemStorePayments: [],
                hmiVisibility: "Visible",
                smartPhoneClientVisibility: "Visible",
                smartPhoneAdminVisibility: "Visible",
                option1: false,
                ageRestriction: false
            } as Api.ProductModel,
        account: state.account.currentUser.account
    } as ProductFormOwnProps;
})(createForm as any) as any as React.ComponentClass<ProductFormExternalProps & { onSubmit: (values: Api.ProductModel) => any }>

export const UpdateProductForm = connect((state: ApplicationState) => {
    return {
        entities: state.product.entities,
        storeItemId: state.product.selectedEntityId,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        currencies: state.currency.entities,
        priceNames: state.priceName.entities,
        subFamilies: state.subFamily.entities,
        vats: state.vat.entities,
        families: state.family.entities,
        countries: state.seed.seed.countries,
        supplierMains: state.supplierMain.entities,
        supplierSubs: state.supplierSub.entities,
        units: state.unit.entities,
        menuCategories: state.menuCategory.entities,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        name: updateSelector(state, "name"),
        productSuppliers: updateSelector(state, "productSuppliers") || [],
        prices: updateSelector(state, "prices") || [],
        productCustoms: updateSelector(state, "productCustoms") || [],
        productVats: updateSelector(state, "productVats") || [],
        productBarcodes: updateSelector(state, "productBarcodes") || [],
        productSubFamilies: updateSelector(state, "productSubFamilies") || [],
        productMenuCategories: updateSelector(state, "productMenuCategories") || [],
        storeItemStorePayments: updateSelector(state, "storeItemStorePayments") || [],
        volume: updateSelector(state, "volume"),
        volumeUnitId: updateSelector(state, "volumeUnitId"),
        relativeUnitId: updateSelector(state, "relativeUnitId"),
        expirityType: updateSelector(state, "expirityType"),
        selectedStoreId: state.product.selectedStoreId,
        selectedSupplierId: state.product.selectedSupplierId,
        selectedPriceNameId: state.product.selectedPriceNameId,
        defaultCurrencyCode: state.seed.seed.lgapSettings.defaultCurrencyCode,
        formType: "Update",
        disabled: !((state.product.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
                .some(x => x.productId === state.product.selectedEntityId)),
        initialValues: {
            storeItemStorePayments: [],
            ...state.product.entities[state.product.selectedEntityId],
        },
        account: state.account.currentUser.account
    } as ProductFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<ProductFormExternalProps & { onSubmit: (values: Api.ProductModel) => any }>

export const SubmissionProductForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        entities: state.product.entities,
        storeItemId: submission.productId || 0,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        currencies: state.currency.entities,
        priceNames: state.priceName.entities,
        subFamilies: state.subFamily.entities,
        vats: state.vat.entities,
        families: state.family.entities,
        countries: state.seed.seed.countries,
        supplierMains: state.supplierMain.entities,
        supplierSubs: state.supplierSub.entities,
        units: state.unit.entities,
        menuCategories: state.menuCategory.entities,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        name: submissionSelector(state, "name"),
        productSuppliers: submissionSelector(state, "productSuppliers") || [],
        prices: submissionSelector(state, "prices") || [],
        productCustoms: submissionSelector(state, "productCustoms") || [],
        productVats: submissionSelector(state, "productVats") || [],
        productBarcodes: submissionSelector(state, "productBarcodes") || [],
        productSubFamilies: submissionSelector(state, "productSubFamilies") || [],
        productMenuCategories: submissionSelector(state, "productMenuCategories") || [],
        storeItemStorePayments: submissionSelector(state, "storeItemStorePayments") || [],
        volume: submissionSelector(state, "volume"),
        volumeUnitId: submissionSelector(state, "volumeUnitId"),
        relativeUnitId: submissionSelector(state, "relativeUnitId"),
        expirityType: submissionSelector(state, "expirityType"),
        selectedStoreId: state.product.selectedStoreId,
        selectedSupplierId: state.product.selectedSupplierId,
        selectedPriceNameId: state.product.selectedPriceNameId,
        defaultCurrencyCode: state.seed.seed.lgapSettings.defaultCurrencyCode,
        formType: "Submission",
        initialValues: {
            storeItemStorePayments: [],
            ...JSON.parse(submission.content),
        },
        account: state.account.currentUser.account,
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.product.entities[submission.productId])
            : {}
    } as ProductFormOwnProps;
})(submissionForm as any) as any as React.ComponentClass<ProductFormExternalProps & { onSubmit: (values: Api.ProductModel) => any }>