import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import {
    Field, WrappedFieldArrayProps
} from 'redux-form';
import {
    menuCategoryMenusValidation
} from "../utils/validation";
import { availableMenuCategories } from '../store/MenuCategory';

type MenuCategoryMenusProps = MenuCategoryMenusOwnProps
    & WrappedFieldArrayProps<Api.MenuCategoryMenuModel>;

export interface MenuCategoryMenusOwnProps {
    storeItemId: number;
    disabled?: boolean;
    allowedStores: Array<number>;
    menuCategories: { [id: number]: Api.MenuCategoryModel }
}

export default class MenuCategoryMenus extends React.Component<MenuCategoryMenusProps, {}> {
    public render() {
        return (<div>
            {this.props.fields.map((member, index) => <div key={index}
                style={{ marginBottom: 5 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <Field className="form-control"
                                validate={menuCategoryMenusValidation["menuCategoryId"]}
                                name={`${member}.menuCategoryId`}
                                disabled={this.props.disabled}
                                component={FormField.getSelectField(
                                    _.values(availableMenuCategories(this.props.menuCategories, this.props.allowedStores))
                                        .map(x => ({ label: x.name, value: x.menuCategoryId })))} />
                        </div>
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                        <Field className="form-control input-sm"
                            style={{ width: 50 }}
                            validate={menuCategoryMenusValidation["order"]}
                            name={`${member}.order`}
                            disabled={this.props.disabled}
                            component={FormField.NumberField} />
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                        <button
                            type="button"
                            style={{ marginLeft: 5 }}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            disabled={this.props.disabled}
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    disabled={this.props.disabled}
                    onClick={() => this.props.fields.push({
                        menuId: this.props.storeItemId
                    })}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}