class ThrottleHandler {
    private throttles = {};

    throttle = (key: string, callback: () => void, limit: number) => {
        if (this.throttles[key]) {
            clearTimeout(this.throttles[key]);
        }
        this.throttles[key] = setTimeout(function () {  
            callback();                 
        }, limit);
    }
}
const handler = new ThrottleHandler();
export default handler;