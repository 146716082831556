import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector, FieldArray,
    WrappedFieldArrayProps
} from 'redux-form';
import { unitValidation } from "../utils/validation";
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import { FormType } from '../store/Crude';
import { diff } from 'deep-object-diff';

type UnitFormProps = InjectedFormProps
    & FormField.FormProps
    & UnitFormExternalProps
    & UnitFormOwnProps;

interface UnitFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.UnitModel) => any;
}

interface UnitFormOwnProps {
    unitId: number;
    code: string;
    units: { [id: number]: Api.UnitModel };
    disabled?: boolean;
    formType: FormType;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
}

const typeOptions: Array<{ label: string; value: Api.UnitModelTypeEnum }> = [
    { label: "Product", value: "Product" },
    { label: "External Product", value: "ExternalProduct" }
];
class UnitForm extends React.Component<UnitFormProps, {}> {
    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create";
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{"Nom:"}</label>
                    <div className="col-sm-8" style={this.getFieldStyle("name")}>
                        <Field className="form-control"
                            validate={unitValidation["name"]}
                            name="name"
                            disabled={this.props.disabled
                                || this.globalFieldDisabled}
                            component={FormField.renderField}
                            type="text" />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{"Type:"}</label>
                    <div className="col-sm-8" style={this.getFieldStyle("type")}>
                        <Field className="form-control"
                            validate={unitValidation["type"]}
                            name="type"
                            disabled={this.props.disabled
                                || this.globalFieldDisabled}
                            component={FormField.getSelectField(typeOptions)} />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{"Conversions:"}</label>
                    <div className="col-sm-8" style={this.getFieldStyle("unitConversionsTo")}>
                        <FieldArray
                            name="unitConversionsTo"
                            component={UnitConversions as any}
                            props={{
                                unitId: this.props.unitId,
                                code: this.props.code,
                                units: this.props.units,
                                disabled: this.props.disabled
                                    || this.globalFieldDisabled
                            }} />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType !== "Create"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

type UnitConversionsProps = UnitConversionsOwnProps
    & WrappedFieldArrayProps;
interface UnitConversionsOwnProps {
    disabled?: boolean;
    unitId: number;
    code: string;
    units: { [id: number]: Api.UnitModel };
}

class UnitConversions extends React.Component<UnitConversionsProps, {}> {
    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => <div key={index}
                    style={{ marginBottom: 5 }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: "0 0 auto" }}>
                            <div className="form-control-static input-sm">1 {this.props.code} = </div>
                        </div>
                        <div style={{ flex: "0 0 auto" }}>
                            <Field className="form-control input-sm"
                                style={{ width: 110 }}
                                disabled={this.props.disabled}
                                validate={FormField.required}
                                name={`${member}.value`}
                                component={FormField.NumberField} />
                        </div>
                        <div style={{ flex: "1" }}>
                            <div style={{ marginLeft: 5 }}>
                                <Field className="form-control"
                                    validate={FormField.required}
                                    disabled={this.props.disabled}
                                    name={`${member}.unitFromId`}
                                    component={FormField.getSelectField(
                                        _.values(this.props.units)
                                            .filter(x => x.unitId !== this.props.unitId)
                                            .map(x => ({ label: x.name, value: x.unitId })))} />
                            </div>
                        </div>
                        <div style={{ flex: "0 0 auto" }}>
                            <button
                                type="button"
                                style={{ marginLeft: 5 }}
                                disabled={this.props.disabled}
                                className="btn btn-secondary btn-sm"
                                title="Remove"
                                onClick={() => this.props.fields.remove(index)}>
                                <i className="glyphicon glyphicon-remove"></i>
                            </button>
                        </div>
                    </div>
                </div>)}
                <FormField.FieldErrors {...this.props.meta} />
                <div style={{ textAlign: "center" }}>
                    <button
                        type="button"
                        disabled={this.props.disabled}
                        className="btn btn-secondary btn-sm"
                        onClick={() => this.props.fields.push({
                            unitToId: this.props.unitId
                        })}>
                        <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                    </button>
                </div>
            </div>
        );
    }
}

let createForm = reduxForm({
    form: 'createUnit',
    destroyOnUnmount: false,
    enableReinitialize: true
})(UnitForm) as any;
const createSelector = formValueSelector('createUnit')

let updateForm = reduxForm({
    form: 'updateUnit',
    destroyOnUnmount: false,
    enableReinitialize: true
})(UnitForm) as any;
const updateSelector = formValueSelector('updateUnit')

let submissionForm = reduxForm({
    form: 'submissionUnit',
    destroyOnUnmount: false,
    enableReinitialize: true
})(UnitForm) as any;
const submissionSelector = formValueSelector('submissionUnit')

export const CreateUnitForm = connect((state: ApplicationState) => {
    return {
        unitId: 0,
        code: createSelector(state, "code"),
        units: state.unit.entities,
        formType: "Create",
        account: state.account.currentUser.account
    } as UnitFormOwnProps;
})(createForm as any) as any as React.ComponentClass<UnitFormExternalProps>;

export const UpdateUnitForm = connect((state: ApplicationState) => {
    return {
        unitId: state.unit.selectedEntityId,
        code: updateSelector(state, "code"),
        units: state.unit.entities,
        formType: "Update",
        disabled: !((state.unit.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
                .some(x => x.unitId === state.unit.selectedEntityId)),
        initialValues: state.unit.entities[state.unit.selectedEntityId],
        account: state.account.currentUser.account
    } as UnitFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<UnitFormExternalProps>;

export const SubmissionUnitForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        unitId: submission.unitId || 0,
        code: submissionSelector(state, "code"),
        units: state.unit.entities,
        formType: "Submission",
        initialValues: JSON.parse(submission.content),
        account: state.account.currentUser.account,
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.unit.entities[submission.unitId])
            : {}
    } as UnitFormOwnProps;
})(submissionForm as any) as any as React.ComponentClass<UnitFormExternalProps>;