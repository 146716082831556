import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import Moment from "moment";
import Tooltip from "./Tooltip";
import StoresSelection from "./StoresSelection";
import { FieldHelp } from './FieldHelp';
import {
    Field, WrappedFieldArrayProps
} from 'redux-form';
import {
    productSuppliersHelp
} from '../utils/entityHelp';
import {
    productSuppliersValidation,
} from '../utils/validation';
import { DataType } from './Home';

type ProductSuppliersProps = ProductSuppliersOwnProps
    & WrappedFieldArrayProps<Api.ProductSupplierModel>;

export interface ProductSuppliersOwnProps {
    storeItemId: number;
    disabled?: boolean;
    productSuppliers: Array<Api.ProductSupplierModel>;
    allowedStores: Array<number>;
    stores: { [id: number]: Api.StoreModel };
    supplierMains: { [id: number]: Api.SupplierModel };
    supplierSubs: { [id: number]: Api.SupplierModel };
    currencies: { [id: number]: Api.CurrencyModel };
    vats: { [id: number]: Api.VatModel };
    dataType: DataType;
    onSupplierMainIdChange: (member: string, value: Api.ProductSupplierModel) => void;
}

const psFieldStyle: React.CSSProperties = {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "6px 8px"
};
const psLabelStyle: React.CSSProperties = {
    textAlign: "right",
    marginRight: 3
}

export const getProductSupplierDefault = (productSupplier: Api.ProductSupplierModel, productSuppliers: Array<Api.ProductSupplierModel>, stores: { [id: number]: Api.StoreModel }): Api.ProductSupplierModel => {
    let similarProductSupplier = _.sortBy(_.sortBy(productSuppliers
        , x => !x.productSupplierStores
            || !productSupplier.productSupplierStores.some(y => x.productSupplierStores.some(z => z.storeId === y.storeId)))
        , x => productSupplier.supplierMainId !== x.supplierMainId);
    if (similarProductSupplier.length === 0)
        return {};

    return similarProductSupplier[0];
}

export class ProductSuppliers extends React.Component<ProductSuppliersProps, {}> {

    //When a price has a store attached out of the use rights
    hasConflictedStores(productSupplier: Api.ProductSupplierModel): boolean {
        return productSupplier.productSupplierStores
            .some(x => this.props.allowedStores.some(y => y === x.storeId))
            && productSupplier.productSupplierStores
                .some(x => !this.props.allowedStores.some(y => y === x.storeId));
    }

    //We delete conflicting price and add two differents
    resolveStoreConflict(index: number): void {
        let baseProductSupplier = this.props.productSuppliers[index];
        let notAllowedStores = baseProductSupplier.productSupplierStores
            .filter(x => !this.props.allowedStores.some(y => y == x.storeId));
        let allowedStores = baseProductSupplier.productSupplierStores
            .filter(x => this.props.allowedStores.some(y => y == x.storeId));

        this.props.fields.remove(index);
        this.props.fields.push({
            ...baseProductSupplier,
            productSupplierId: undefined,
            productSupplierStores: notAllowedStores.map(x => ({
                storeId: x.storeId
            } as Api.ProductSupplierModel))
        });
        this.props.fields.push({
            ...baseProductSupplier,
            productSupplierId: undefined,
            productSupplierStores: allowedStores.map(x => ({
                storeId: x.storeId
            } as Api.ProductSupplierModel))
        });
    }

    public render() {
        return (<div>
            {this.props.fields.map((member, index) => !this.props.productSuppliers[index]
                || ((this.props.dataType === "Global"
                    && this.props.productSuppliers[index].productSupplierStores.length !== 0)
                    || (this.props.dataType === "Specific"
                        && this.props.productSuppliers[index].productSupplierStores.length === 0))
                ? <div key={index}></div>
                : <div
                    key={index}
                    style={{
                        marginBottom: 5, padding: 5,
                        border: "1px solid", borderRadius: 10,
                        flexDirection: "column",
                        alignItems: "stretch",
                        display: this.props.productSuppliers[index]
                            && this.props.productSuppliers[index].productSupplierStores.length
                            && !this.props.productSuppliers[index].productSupplierStores
                                .some(x => this.props.allowedStores
                                    .some(y => y === x.storeId))
                            ? "none"
                            : "flex"
                    }}>
                    {(this.props.productSuppliers[index]
                        && this.hasConflictedStores(this.props.productSuppliers[index]))
                        && <div style={{ flex: "0 0 auto", textAlign: "center" }}>
                            <Tooltip overlay={<div>Un magasin non visible par votre compte est attaché à cette source, cliquez ici pour corriger la situation.</div>}>
                                <button
                                    type="button"
                                    style={{ marginLeft: 5 }}
                                    className="btn btn-secondary btn-sm"
                                    onClick={() => this.resolveStoreConflict(index)}>
                                    <i className="glyphicon glyphicon-refresh"></i>
                                </button>
                            </Tooltip>
                        </div>}
                    <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: 5 }}>
                        <button
                            style={{ flex: "0 0 auto" }}
                            type="button"
                            disabled={this.props.disabled
                                || this.hasConflictedStores(this.props.productSuppliers[index])}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                        <div style={{ flex: 1 }}></div>
                        <div style={{ flex: "0 0 auto", paddingLeft: 5 }}>
                            {this.props.productSuppliers[index]
                                && this.props.productSuppliers[index].creationDate
                                && <div>Dernière maj: {Moment(this.props.productSuppliers[index].creationDate).format("DD/MM/YYYY")}</div>}
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        {this.props.dataType === "Specific"
                            && <div style={psFieldStyle}>
                                <label style={{ flex: 1, ...psLabelStyle }} >{"Magasin:"}</label>
                                <div style={{ flex: 2 }}>
                                    <FieldHelp help={productSuppliersHelp()["productSupplierStores"]}>
                                        <Field
                                            validate={productSuppliersValidation["productSupplierStores"]}
                                            name={`${member}.productSupplierStores`}
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                            component={(props) => <StoresSelection
                                                {...props}
                                                stores={_.sortBy(_.values(this.props.stores)
                                                    .filter(x => this.props.allowedStores.some(y => y === x.storeId)),
                                                    x => _.deburr(x.name))}
                                                disabledEmpty={true}
                                                value={props.input.value}
                                                storeIdsDisabled={[]}
                                                onChange={(value) => props.input.onChange(value)}
                                            />} />
                                    </FieldHelp>
                                </div>
                            </div>}
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >{"Source:"}</label>
                            <div style={{ flex: 2 }}>
                                <Field className="form-control"
                                    afterChange={(value) => this.props.onSupplierMainIdChange(`${member}`
                                        , {
                                            ...this.props.productSuppliers[index]
                                            , ...getProductSupplierDefault({
                                                ...this.props.productSuppliers[index],
                                                supplierMainId: value //It wont be updated yet from the component pov
                                            }, this.props.productSuppliers, this.props.stores)
                                            , supplierMainId: value
                                            , productSupplierStores: this.props.productSuppliers[index].productSupplierStores
                                            , productSupplierId : this.props.productSuppliers[index].productSupplierId
                                        })}
                                    validate={productSuppliersValidation["supplierMainId"]}
                                    name={`${member}.supplierMainId`}
                                    disabled={this.props.disabled
                                        || this.hasConflictedStores(this.props.productSuppliers[index])}
                                    component={FormField.getSelectField(
                                        _.values(this.props.supplierMains)
                                            .map(x => ({ label: x.name, value: x.supplierId })))} />
                            </div>
                        </div>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >{"Sous-source:"}</label>
                            <div style={{ flex: 2 }}>
                                <FieldHelp help={productSuppliersHelp()["supplierSubId"]}>
                                    <Field className="form-control"
                                        validate={productSuppliersValidation["supplierSubId"]}
                                        name={`${member}.supplierSubId`}
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                        component={FormField.getSelectField(
                                            _.values(this.props.supplierSubs)
                                                .filter(x => this.props.productSuppliers[index] && this.props.productSuppliers[index].supplierMainId === x.supplierMainId)
                                                .map(x => ({ label: x.name, value: x.supplierId }))
                                                .concat({ label: "----------", value: null }))} />
                                </FieldHelp>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={psFieldStyle}>
                        </div>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >
                                <Tooltip trigger={["hover"]}
                                    overlay={<div>{"Conditionnement pack"}</div>}>
                                    <div>{"Cdt. pack:"}</div>
                                </Tooltip>
                            </label>
                            <div style={{ flex: 2 }}>
                                <FieldHelp help={productSuppliersHelp()["packCondition"]}>
                                    <Field className="form-control"
                                        validate={productSuppliersValidation["packCondition"]}
                                        name={`${member}.packCondition`}
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                        component={FormField.NumberField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >
                                <Tooltip trigger={["hover"]}
                                    overlay={<div>{"Nb de packs par unité commandée"}</div>}>
                                    <div>{"Cdt. livraison:"}</div>
                                </Tooltip>
                            </label>
                            <div style={{ flex: 2 }}>
                                <FieldHelp help={productSuppliersHelp()["deliveryCondition"]}>
                                    <Field className="form-control"
                                        validate={productSuppliersValidation["deliveryCondition"]}
                                        name={`${member}.deliveryCondition`}
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                        component={FormField.NumberField} />
                                </FieldHelp>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >{"Référence:"}</label>
                            <div style={{ flex: 2 }}>
                                <FieldHelp help={productSuppliersHelp()["reference"]}>
                                    <Field className="form-control"
                                        validate={productSuppliersValidation["reference"]}
                                        name={`${member}.reference`}
                                        type="text"
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                        component={FormField.renderField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >{"PAHT pack:"}</label>
                            <div style={{ flex: 2 }} className="">
                                <FieldHelp help={productSuppliersHelp()["buyPriceNoVat"]}>
                                    <Field className="form-control"
                                        validate={productSuppliersValidation["buyPriceNoVat"]}
                                        name={`${member}.buyPriceNoVat`}
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                        component={FormField.NumberField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >{"PAHT devise:"}</label>
                            <div style={{ flex: 2 }}>
                                <FieldHelp help={productSuppliersHelp()["buyPriceCurrencyId"]}>
                                    <Field className="form-control"
                                        validate={productSuppliersValidation["buyPriceCurrencyId"]}
                                        name={`${member}.buyPriceCurrencyId`}
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                        component={FormField.getSelectField(
                                            _.values(this.props.currencies)
                                                .map(x => ({ label: x.code, value: x.currencyId })))} />
                                </FieldHelp>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >{"TVA:"}</label>
                            <div style={{ flex: 2 }}>
                                <FieldHelp help={productSuppliersHelp()["vatId"]}>
                                    <Field className="form-control"
                                        validate={productSuppliersValidation["vatId"]}
                                        name={`${member}.vatId`}
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                        component={FormField.getSelectField(
                                            _.values(this.props.vats)
                                                .map(x => ({ label: x.name, value: x.vatId })))} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >{"Poids pack:"}</label>
                            <div style={{ flex: 2 }}>
                                <FieldHelp help={productSuppliersHelp()["packWeight"]}>
                                    <Field className="form-control"
                                        validate={productSuppliersValidation["packWeight"]}
                                        unit={"Kg"}
                                        name={`${member}.packWeight`}
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                        component={FormField.NumberField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div style={psFieldStyle}>
                            <label style={{ flex: 1, ...psLabelStyle }} >{"Volume pack:"}</label>
                            <div style={{ flex: 2 }}>
                                <FieldHelp help={productSuppliersHelp()["packVolume"]}>
                                    <Field className="form-control"
                                        validate={productSuppliersValidation["packVolume"]}
                                        unit={"dm³"}
                                        name={`${member}.packVolume`}
                                        disabled={this.props.disabled
                                            || this.hasConflictedStores(this.props.productSuppliers[index])}
                                        component={FormField.NumberField} />
                                </FieldHelp>
                            </div>
                        </div>

                    </div>
                </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    disabled={this.props.disabled}
                    className="btn btn-secondary btn-sm"
                    onClick={() => {
                        let productSupplier = {
                            productId: this.props.storeItemId,
                            productSupplierStores: this.props.dataType === "Global"
                                ? []
                                : [{
                                    storeId: this.props.allowedStores[0],
                                }]
                        } as Api.ProductSupplierModel;

                        productSupplier = {
                            ...getProductSupplierDefault(productSupplier, this.props.productSuppliers, this.props.stores),
                            ...productSupplier,
                            productSupplierId: undefined
                        };

                        this.props.fields.push(productSupplier)
                    }}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}