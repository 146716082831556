import * as React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import * as FormField from './FormField';
import { getText } from '../utils/texts';

type LoginFormProps = InjectedFormProps & LoginFormOwnProps;

interface LoginFormOwnProps {
}

class LoginForm extends React.Component<LoginFormProps, {}> {

    public render() {
        return (
            <form className="form-horizontal"
                style={{ maxWidth: 500 }}
                onSubmit={(e) => {
                    if (e.preventDefault) {
                        e.preventDefault();
                    }
                    return this.props.handleSubmit(e);
                }}>
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{getText("GenUsername")}:</label>
                    <div className="col-sm-8">
                        <Field className="form-control"
                            validate={FormField.required}
                            name="username"
                            component={FormField.renderField}
                            type="text" />
                    </div>
                </div>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{getText("GenPassword")}:</label>
                    <div className="col-sm-8">
                        <Field className="form-control"
                            validate={FormField.required}
                            name="password"
                            component={FormField.renderField}
                            type="password" />
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4"> </div>
                    <div className="col-sm-8" style={{ display: "flex", flexDirection: "row-reverse" }}>
                        <button
                            style={{ paddingLeft: 30, paddingRight: 30 }}
                            type="submit"
                            disabled={this.props.submitting}
                            className="btn btn-lg btn-primary">{getText("GenLogin")}</button>
                    </div>
                </div>
            </form>
        );
    }
}

export default reduxForm({ form: 'login' })(LoginForm) as any;
