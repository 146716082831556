import * as React from "react";
import * as Api from "../api/api";
import * as _ from "lodash";
import * as ProductStore from "../store/Product";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import DataGrid from "./DataGrid";
import Loader from "./Loader";
import { validateChildGridUpdates, productBarCodeValidation } from "../utils/validation";

type ProductBarcodeGridProps = ProductBarcodeGridOwnProps
    & ProductStore.ProductState
    & typeof ProductStore.actionCreators;

interface ProductBarcodeGridOwnProps {
    filteredProducts: Array<Api.ProductModel>;
}

class ProductBarcodeGrid extends React.Component<ProductBarcodeGridProps, {}> {
    _data: Array<Api.ProductBarcodeModel>;

    onDataUpdate(props: ProductBarcodeGridProps) {
        this._data = props.filteredProducts
            .map(x => x.productBarcodes)
            .reduce((a, b) => a.concat(b), []);
    }

    componentWillMount() {
        this.onDataUpdate(this.props);
    }

    componentWillReceiveProps(nextProps: ProductBarcodeGridProps) {
        if (this.props.filteredProducts !== nextProps.filteredProducts) {
            this.onDataUpdate(nextProps);
        }
    }

    onBulkUpdate(edits: { [id: number]: object }): Promise<any> {
        let updates = {};

        let editsByParentId = _.groupBy(Object.keys(edits)
            .map(x => ({
                ...this._data.find(y => y.productBarcodeId === parseInt(x)),
                ...edits[x]
            } as Api.ProductBarcodeModel)), x => x.productId);

        _.keys(editsByParentId)
            .forEach(x => {
                editsByParentId[x] = editsByParentId[x]
                    .concat(this.props.entities[parseInt(x)].productBarcodes.filter(y => !edits[y.productBarcodeId]));
            });

        _.keys(editsByParentId)
            .forEach(x => {
                updates[x] = {
                    productBarcodes: editsByParentId[x]
                };
            });

        return this.props.requestBulkUpdate(new Date().getTime(),
            updates) as any;
    }

    public render() {
        return (
            <div style={{ position: "relative" }}>
                <DataGrid
                    data={this._data}
                    columns={[
                        {
                            key: "productId",
                            name: "Produit",
                            editable: false,
                            formatter: ({ value }) => <div>{this.props.entities[value].name}</div>
                        },
                        {
                            key: "value",
                            name: "Code barre",
                            editable: true
                        },
                    ]}
                    gridKey={"productBarcodes"}
                    idSelector={(x) => (x as Api.ProductBarcodeModel).productBarcodeId}
                    updateValidation={(updates) => validateChildGridUpdates(
                        updates,
                        productBarCodeValidation,
                        this.props.entities,
                        this._data,
                        x => x.productBarcodeId,
                        x => x.productId)}
                    onUpdateRows={(edits) => this.onBulkUpdate(edits)}
                />
                <Loader isVisible={this.props.isLoading} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.product,
    filteredProducts: ProductStore.productFilteredSelector(state)
} as ProductBarcodeGridProps), ProductStore.actionCreators as any)(ProductBarcodeGrid as any);