import * as React from "react";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as PriceNameStore from "../store/PriceName";
import * as CrudeStore from "../store/Crude";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import { CreatePriceNameForm, UpdatePriceNameForm } from "./PriceNameForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { validateGridUpdates, priceNameValidation } from "../utils/validation";
import { canCrude } from "../utils/userRights";

type PriceNamesProps = PriceNamesOwnProps
    & CrudeStore.EntityCrudeState<Api.PriceNameModel, Api.PriceNameFilter>
    & typeof PriceNameStore.actionCreators
    & typeof TabsStore.actionCreators;

interface PriceNamesOwnProps {
    account: Api.AccountModel;
    helpHtml: string;
}
const name = "priceNames";
const Table: new () => EntityTable<Api.PriceNameModel> = EntityTable as any;

class PriceNames extends React.Component<PriceNamesProps, {}> {

    update(props: PriceNamesProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps: PriceNamesProps) {
        //this.update(nextProps);
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Nom de tarif</h2>
                <CrudeLayout
                    name={name}
                    createForm={<div style={{ maxWidth: 600 }}>
                        <h2>Créer</h2>
                        <CreatePriceNameForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 600 }}>
                        <h2>Editer</h2>
                        <UpdatePriceNameForm
                            actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    help={<div>
                        <div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />
                    </div>}
                    table={<Table
                        gridKey={name}
                        account={this.props.account}
                        isLoading={this.props.isLoading}
                        data={this.props.entities}
                        idPropertyName={"priceNameId"}
                        updateValidation={(updates) =>
                            validateGridUpdates(updates, priceNameValidation, this.props.entities)}
                        onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                            new Date().getTime(),
                            updates) as any}
                        onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                        onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                        onRequestUpdate={(id) => {
                            this.props.selectUpdateEntity(id);
                            this.props.selectTab(name, "update");
                        }}
                        columns={[
                        {
                            key: "name",
                            name: "Nom",
                            editable: true,
                        },
                        {
                            key: "type",
                            name: "Type",
                            editable: true,
                        }
                        ]} />} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.priceName,
    account: state.account.currentUser.account,
    helpHtml: state.seed.seed.priceNameHelp
} as PriceNamesOwnProps), { ...TabsStore.actionCreators, ...PriceNameStore.actionCreators } as any)(PriceNames);