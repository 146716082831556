import * as Api from '../api/api';
import * as React from "react";
import * as ReduxForm from "redux-form";
import Select, { Option } from "./Select";
import { FieldErrors } from "./FormField";

type StoresSelectionProps = StoresSelectionOwnProps
    & ReduxForm.WrappedFieldProps;

interface StoresSelectionOwnProps {
    stores: Array<Api.StoreModel>;
    storeIdsDisabled: Array<number>;
    value: Array<Api.ProductSupplierStoreModel | Api.PriceStoreModel>;
    onChange: (value: Array<Api.ProductSupplierStoreModel | Api.PriceStoreModel>) => void;
    style?: React.CSSProperties;
    className?: string;
    disabled?: boolean;
    disabledEmpty?: boolean;
}

export default class StoresSelection extends React.Component<StoresSelectionProps, {}> {

    get value(): Array<Api.ProductSupplierStoreModel | Api.PriceStoreModel> {
        return this.props.value || [];
    }

    public render() {
        return (
            <div style={this.props.style} className={this.props.className}>
                <Select
                    multiple={true}
                    optionComponent={(opt) => <div style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        ...(opt.disabled
                            ? {
                                backgroundColor: "lightgray",
                                color: "white"
                            }
                            : {})
                    }}>
                        <div style={{ flex: "0 0 auto" }}>
                            <div style={{
                                height: 15, width: 15,
                                border: "1px solid black",
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center"
                            }}>
                                <div style={{ width: "100%", textAlign: "center" }}>
                                    <i className="glyphicon glyphicon-ok"
                                        style={{
                                            display: this.value.some(x => x.storeId == opt.value)
                                                ? "inline"
                                                : "none"
                                        }}></i>
                                </div>
                            </div>
                        </div>
                        <div style={{ flex: 1 }}>
                            {opt.label}
                        </div>
                    </div>}
                    options={this.props.stores.map(x => ({
                        label: x.name,
                        value: x.storeId,
                        disabled: this.props.storeIdsDisabled
                            .some(y => y === x.storeId)
                    } as Option))}
                    value={this.value.map(x => x.storeId)}
                    disabled={this.props.disabled}
                    onChange={(value: Array<number>) => {
                        if (!this.props.disabledEmpty || value.length !== 0) {
                            this.props.onChange(this.value.filter(x => value.some(y => y === x.storeId))
                                .concat(value.filter(x => !this.value.some(y => y.storeId === x))
                                    .map(x => ({
                                        storeId: x
                                    } as Api.ProductSupplierStoreModel | Api.PriceStoreModel))))
                        }
                    }} />
                <FieldErrors {...this.props.meta} />
            </div>
            );
    }
}