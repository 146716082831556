import * as React from 'react';
import Moment from "moment";
import * as ReactDatePicker from 'react-datepicker';

interface DatePickerProps {
    date: Moment.Moment;
    maxDate?: Moment.Moment;
    minDate?: Moment.Moment;
    selectDate: (date: Moment.Moment) => void;
    className?: any;
    style?: any;
}

interface DatePickerState {
}

export default class DatePicker extends React.Component<DatePickerProps, DatePickerState> {
    constructor(props) {
        super(props)
        this.handleChange = this.handleChange.bind(this);
    }

    componentWillMount() {
    }

    componentWillReceiveProps(nextProps: DatePickerProps) {
    }

    handleChange(date: Moment.Moment) {
        this.props.selectDate(date);
    }

    render() {
        return <ReactDatePicker.default
            className={this.props.className}
            selected={this.props.date}
            dateFormat='dd DD/MM/YYYY'
            maxDate={this.props.maxDate}
            minDate={this.props.minDate}
            onChange={(date: Moment.Moment) => this.handleChange(date)}
        >
        </ReactDatePicker.default>;
    }
}