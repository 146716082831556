import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import * as CrudeStore from '../store/Crude';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector
} from 'redux-form';
import {
    supplierSubValidation
} from "../utils/validation";
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import {
    supplierHelp,
} from '../utils/entityHelp';
import { FieldHelp } from './FieldHelp';
import { diff } from 'deep-object-diff';

type SupplierSubFormProps = InjectedFormProps
    & FormField.FormProps
    & SupplierSubFormOwnProps
    & SupplierSubFormExternalProps;

interface SupplierSubFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.SupplierModel) => any;

    openSupplierAdvert: () => void;
    closeSupplierAdvert: () => void;

    openSupplierDelivery: () => void;
    closeSupplierDelivery: () => void;

    openSupplierPayment: () => void;
    closeSupplierPayment: () => void;
}

interface SupplierSubFormOwnProps {
    supplierId: number;
    supplierMains: { [id: number]: Api.SupplierModel };
    units: { [id: number]: Api.UnitModel };
    currencies: { [id: number]: Api.CurrencyModel };
    storeGroups: { [id: number]: Api.StoreGroupModel };
    supplierAdverts: { [id: number]: Api.SupplierAdvertModel };
    supplierDeliveries: { [id: number]: Api.SupplierDeliveryModel };
    supplierPayments: { [id: number]: Api.SupplierPaymentModel };
    supplierAdvertIsOpen: boolean;
    supplierPaymentIsOpen: boolean;
    supplierDeliveryIsOpen: boolean;
    disabled?: boolean;
    formType: CrudeStore.FormType;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
}

const formTitleStyle = {
    textAlign: "center" as any,
    marginBottom: 40
};

const buttonDialog = {
    position: "absolute" as any,
    top: 0, right: 0,
    paddingTop: 2,
    fontSize: 18, height: 30
}

class SupplierSubForm extends React.Component<SupplierSubFormProps, {}> {
    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create";
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <h3 className="h3" style={formTitleStyle}>Informations source</h3>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Source:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("supplierMainId")}>
                                <Field className="form-control"
                                    validate={supplierSubValidation["supplierMainId"]}
                                    name="supplierMainId"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.getSelectField(
                                        _.values(this.props.supplierMains)
                                            .map(x => ({ label: x.name, value: x.supplierId })))} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Nom:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("name")}>
                                <Field className="form-control"
                                    validate={supplierSubValidation["name"]}
                                    name="name"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Frais livraison fixe:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("deliveryFeeFix")}>
                                <FieldHelp help={supplierHelp()["deliveryFeeFix"]}>
                                    <Field className="form-control"
                                        validate={supplierSubValidation["deliveryFeeFix"]}
                                        name="deliveryFeeFix"
                                        disabled={this.props.disabled
                                            || this.globalFieldDisabled}
                                        component={FormField.NumberField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Frais livraison variable:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("deliveryFeeVar")}>
                                <FieldHelp help={supplierHelp()["deliveryFeeVar"]}>
                                    <Field className="form-control"
                                    validate={supplierSubValidation["deliveryFeeVar"]}
                                        name="deliveryFeeVar"
                                        disabled={this.props.disabled
                                            || this.globalFieldDisabled}
                                    component={FormField.NumberField} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Frais livraison unité:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("deliveryFeeVarUnitId")}>
                                <FieldHelp help={supplierHelp()["deliveryFeeVarUnitId"]}>
                                    <Field className="form-control"
                                    validate={supplierSubValidation["deliveryFeeVarUnitId"]}
                                        name="deliveryFeeVarUnitId"
                                        disabled={this.props.disabled
                                            || this.globalFieldDisabled}
                                    component={FormField.getSelectField(_.values(this.props.units)
                                        .map(x => ({
                                            label: x.code,
                                            value: x.unitId
                                        })))} />
                                </FieldHelp>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType !== "Create"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}


let createForm = reduxForm({
    form: 'createSupplierSub',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SupplierSubForm) as any;
const createSelector = formValueSelector('createSupplierSub')

let updateForm = reduxForm({
    form: 'updateSupplierSub',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SupplierSubForm) as any;
const updateSelector = formValueSelector('updateSupplierSub')

let submissionForm = reduxForm({
    form: 'submissionSupplierSub',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SupplierSubForm) as any;
const submissionSelector = formValueSelector('submissionSupplierSub')

export const CreateSupplierSubForm = connect((state: ApplicationState) => {
    return {
        units: state.unit.entities,
        currencies: state.currency.entities,
        supplierMains: state.supplierMain.entities,
        storeGroups: state.seed.seed.storeGroups,
        formType: "Create",
        account: state.account.currentUser.account,
        supplierId: createSelector(state, "supplierId"),
        supplierAdverts: state.supplierAdvert.entities,
        supplierDeliveries: state.supplierDelivery.entities,
        supplierPayments: state.supplierPayment.entities,
        supplierAdvertIsOpen: state.supplierAdvert.isOpen,
        supplierPaymentIsOpen: state.supplierPayment.isOpen,
        supplierDeliveryIsOpen: state.supplierDelivery.isOpen
    } as SupplierSubFormOwnProps;
})(createForm as any) as any as React.ComponentClass<SupplierSubFormExternalProps>;

export const UpdateSupplierSubForm = connect((state: ApplicationState) => {
    return {
        units: state.unit.entities,
        currencies: state.currency.entities,
        supplierMains: state.supplierMain.entities,
        storeGroups: state.seed.seed.storeGroups,
        formType: "Update",
        disabled: !((state.supplierSub.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
            .some(x => x.supplierSubId === state.supplierSub.selectedEntityId)),
        initialValues: state.supplierSub.entities[state.supplierSub.selectedEntityId],
        account: state.account.currentUser.account,
        supplierId: updateSelector(state, "supplierId"),
        supplierAdverts: state.supplierAdvert.entities,
        supplierDeliveries: state.supplierDelivery.entities,
        supplierPayments: state.supplierPayment.entities,
        supplierAdvertIsOpen: state.supplierAdvert.isOpen,
        supplierPaymentIsOpen: state.supplierPayment.isOpen,
        supplierDeliveryIsOpen: state.supplierDelivery.isOpen
    } as SupplierSubFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<SupplierSubFormExternalProps>;

export const SubmissionSupplierSubForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        units: state.unit.entities,
        currencies: state.currency.entities,
        supplierMains: state.supplierMain.entities,
        storeGroups: state.seed.seed.storeGroups,
        formType: "Submission",
        initialValues: JSON.parse(submission.content),
        account: state.account.currentUser.account,
        supplierId: submissionSelector(state, "supplierId"),
        supplierAdverts: state.supplierAdvert.entities,
        supplierDeliveries: state.supplierDelivery.entities,
        supplierPayments: state.supplierPayment.entities,
        supplierAdvertIsOpen: state.supplierAdvert.isOpen,
        supplierPaymentIsOpen: state.supplierPayment.isOpen,
        supplierDeliveryIsOpen: state.supplierDelivery.isOpen,
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.supplierSub.entities[submission.supplierSubId])
            : {}
    } as SupplierSubFormOwnProps;
})(submissionForm as any) as any as React.ComponentClass<SupplierSubFormExternalProps>;