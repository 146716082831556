import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import * as CrudeStore from '../store/Crude';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector, FieldArray
} from 'redux-form';
import EntityCustomizationsField, { EntityCustomizationsOwnProps } from "./EntityCustomizationsField";
import { subFamilyValidation } from "../utils/validation";
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import { diff } from 'deep-object-diff';
import { selectedStores } from '../store/Account';

type SubFamilyFormProps = InjectedFormProps
    & FormField.FormProps
    & SubFamilyFormExternalProps
    & SubFamilyFormOwnProps;

interface SubFamilyFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.SubFamilyModel) => any;
}

interface SubFamilyFormOwnProps {
    subFamilyId: number;
    subFamilyCustoms: Array<Api.SubFamilyCustomModel>;
    entities: { [id: number]: Api.SubFamilyModel };
    languages: { [id: number]: Api.LanguageModel }
    storeGroups: { [id: number]: Api.StoreGroupModel }
    stores: { [id: number]: Api.StoreModel }
    disabled?: boolean;
    formType: CrudeStore.FormType;
    families: Array<Api.FamilyModel>;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
    allowedStores: Array<number>;
}

class SubFamilyForm extends React.Component<SubFamilyFormProps, {}> {
    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create";
    }

    public render() {
        return (
            <FormField.BaseForm className="" {...this.props}>
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                    textAlign: "center",
                    border: "1px solid black"
                }}>Données globales</legend>
                    <div className="form-horizontal">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Rayon:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("familyId")}>
                                <Field className="form-control"
                                    validate={subFamilyValidation["familyId"]}
                                    name="familyId"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.getSelectField(this.props.families
                                        .map(x => ({ label: x.name, value: x.familyId })))} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Nom:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("name")}>
                                <Field className="form-control"
                                    validate={subFamilyValidation["name"]}
                                    name="name"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Image:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("imageId")}>
                                <Field className="form-control"
                                    fieldKey={"subFamilyImagineId-" + this.props.formType}
                                    validate={subFamilyValidation["imageId"]}
                                    name="imageId"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    acceptProp={".jpg, .jpeg"}
                                    component={FormField.ImageField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Ordre:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("order")}>
                                <Field className="form-control"
                                    validate={subFamilyValidation["order"]}
                                    name="order"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Customisations:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("subFamilyCustoms")}>
                                <FieldArray 
                                    validate={[]}
                                    name="subFamilyCustoms"
                                    component={EntityCustomizationsField}
                                    props={{
                                        languages: this.props.languages,
                                        storeGroups: this.props.storeGroups,
                                        entityCustoms: this.props.subFamilyCustoms,
                                        disabled: this.props.disabled,
                                        allowedStoreGroup: _.values(this.props.storeGroups)
                                            .filter(x => this.props.allowedStores.some(y => this.props.stores[y].storeGroupId === x.storeGroupId))
                                            .map(x => x.storeGroupId),
                                        dataType: "Global"
                                    } as EntityCustomizationsOwnProps} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                        textAlign: "center",
                        border: "1px solid black"
                    }}>Données par magasin</legend>
                    <div className="form-horizontal">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Customisations:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("subFamilyCustoms")}>
                                <FieldArray 
                                    validate={[]}
                                    name="subFamilyCustoms"
                                    component={EntityCustomizationsField}
                                    props={{
                                        languages: this.props.languages,
                                        storeGroups: this.props.storeGroups,
                                        entityCustoms: this.props.subFamilyCustoms,
                                        disabled: this.props.disabled,
                                        allowedStoreGroup: _.values(this.props.storeGroups)
                                            .filter(x => this.props.allowedStores.some(y => this.props.stores[y].storeGroupId === x.storeGroupId))
                                            .map(x => x.storeGroupId),
                                        dataType: "Specific"
                                    } as EntityCustomizationsOwnProps} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType === "Update"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
            );
    }
}

let createForm = reduxForm({
    form: 'createSubFamily',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SubFamilyForm) as any;
const createSelector = formValueSelector('createSubFamily')

let updateForm = reduxForm({
    form: 'updateSubFamily',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SubFamilyForm) as any;
const updateSelector = formValueSelector('updateSubFamily')

let submissionForm = reduxForm({
    form: 'submissionSubFamily',
    destroyOnUnmount: false,
    enableReinitialize: true
})(SubFamilyForm) as any;
const submissionSelector = formValueSelector('submissionSubFamily')

export const CreateSubFamilyForm = connect((state: ApplicationState) => {
    return {
        subFamilyId: 0,
        entities: state.subFamily.entities,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        formType: "Create",
        families: _.values(state.family.entities),
        account: state.account.currentUser.account,
        subFamilyCustoms: createSelector(state, "subFamilyCustoms")
    } as SubFamilyFormOwnProps;
})(createForm as any) as any as React.ComponentClass<SubFamilyFormExternalProps>;

export const UpdateSubFamilyForm = connect((state: ApplicationState) => {
    return {
        subFamilyId: state.subFamily.selectedEntityId,
        entities: state.subFamily.entities,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        formType: "Update",
        disabled: !((state.subFamily.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
            .some(x => x.subFamilyId === state.subFamily.selectedEntityId)),
        initialValues: state.subFamily.entities[state.subFamily.selectedEntityId],
        families: _.values(state.family.entities),
        account: state.account.currentUser.account,
        subFamilyCustoms: updateSelector(state, "subFamilyCustoms")
    } as SubFamilyFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<SubFamilyFormExternalProps>

export const SubmissionSubFamilyForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        subFamilyId: submission.subFamilyId,
        entities: state.subFamily.entities,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        formType: "Submission",
        initialValues: JSON.parse(submission.content),
        families: _.values(state.family.entities),
        account: state.account.currentUser.account,
        subFamilyCustoms: submissionSelector(state, "subFamilyCustoms"),
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.subFamily.entities[submission.subFamilyId])
            : {}
    } as SubFamilyFormOwnProps;
})(submissionForm as any) as any as React.ComponentClass<SubFamilyFormExternalProps>