import * as React from "react";
import Throttle from '../utils/throttle';
import { Guid } from "../utils/utils";

interface InputNumberProps {
    style?: any;
    className?: string;
    throttle?: number;
    integer?: boolean;
    disabled?: boolean;
    unit?: string;
    value: number;
    name?: string;
    allowEmpty?: boolean;
    onChange: (value: number) => void;
}

interface InputNumberState {
    value: string;
}

export default class InputNumber extends React.Component<InputNumberProps, InputNumberState> {
    constructor(props: InputNumberProps) {
        super(props);
        this.state = {
            value: props.value.toString()
        };
    }

    handleChange(value: string) {
        this.setState({ value: value });

        // let valueToUse = value
        //     ? value.replace(",", ".")
        //     : value;
        // if (valueToUse.endsWith("."))
        //     valueToUse = valueToUse + "0";

        let parsed = this.props.integer
            ? parseInt(value)
            : parseFloat(value);
        if (parsed || parsed === 0) {
            this.props.onChange(parsed);
        } else {
            if (this.props.allowEmpty) {
                this.props.onChange(null);
            }
            else {
                this.props.onChange(0);
            }
        }
    }

    componentWillReceiveProps(nextProps: InputNumberProps) {
        this.state = {
            value: nextProps.value
                ? nextProps.value.toString()
                : ""
        };
    }

    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                <input
                    type="number"
                    min="0"
                    step="any"
                    name={this.props.name}
                    style={{ order: 1, flex: 1,...this.props.style }}
                    className={this.props.className}
                    value={this.state.value}
                    disabled={this.props.disabled}
                    onBlur={(e) => this.handleChange(this.state.value)}
                    onChange={(e) => this.handleChange(e.target.value)} />
                {this.props.unit &&
                    <div style={{ order: 2, flex: "0 0 auto", paddingLeft: 5, paddingRight: 5 }}
                        className="form-control-static">
                        <span> {this.props.unit} </span>
                    </div>}
            </div>
            );
    }
}