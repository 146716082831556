import * as React from "react";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as MenuStore from "../store/Menu";
import * as CrudeStore from "../store/Crude";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import { CreateMenuForm, UpdateMenuForm } from "./MenuForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { validateGridUpdates, menuValidation } from "../utils/validation";
import { canCrude } from "../utils/userRights";
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import { allowedStores } from "../store/Account";

type MenusProps = MenusOwnProps
    & CrudeStore.EntityCrudeState<Api.MenuModel, Api.MenuFilter>
    & typeof MenuStore.actionCreators
    & typeof TabsStore.actionCreators;

interface MenusOwnProps {
    currencies: { [id: number]: Api.CurrencyModel }
    priceNames: { [id: number]: Api.PriceNameModel }
    menuCategories: { [id: number]: Api.MenuCategoryModel }
    account: Api.AccountModel;
    helpHtml: string;
    allowedStores: Array<number>;
}
const name = "menus";
const Table: new () => EntityTable<Api.MenuModel> = EntityTable as any;
const columns = (props: MenusProps) => [
    {
        key: "imageId",
        name: "Image",
        formatter: ({ value }) => <img height={30} src={"/api/Image/GetImageContent?imageId=" + value} />,
        editable: true,
    },
    {
        key: "name",
        name: "Nom",
        editable: true,
    },
    {
        key: "order",
        name: "Ordre",
        editable: true,
    },
    {
        key: "menuCategoryMenus",
        name: "Categories",
        editable: false,
        formatter: ({ value }) => {
            let castedValue = value as Array<Api.MenuCategoryMenuModel>;
            return <div>
                {castedValue.map(x => props.menuCategories[x.menuCategoryId].name).join(" | ")}
            </div>
        }
    },
    {
        key: "prices",
        name: "Tarif",
        editable: false,
        formatter: ({ value }) => {
            let castedValue = value as Array<Api.PriceModel>;
            return <div>
                {castedValue.map(x => props.priceNames[x.priceNameId].name + ": " + x.value).join(" | ")}
            </div>
        }
    },
    {
        key: "storeItemCustoms",
        name: 'Customisations',
        editable: false,
        formatter: ({ value }) => <div>{value.length}</div>
    },
    {
        key: "menuStores",
        name: 'Visible',
        editable: false,
        formatter: ({ value }) => {
            let menuStores = value as Array<Api.MenuStoreModel>;
            return (<div>{props.allowedStores.some(x =>
                menuStores.some(y => (!y.storeId || y.storeId === x) && y.hmiVisibility == "Visible")
                && !menuStores.some(y => y.storeId === x && y.hmiVisibility === "Hide"))
                ? "Visible"
                : "Non visible"}</div>);
        }
    }
];

class Menus extends React.Component<MenusProps, {}> {

    update(props: MenusProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps: MenusProps) {
        //this.update(nextProps);
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Menus</h2>
                <CrudeLayout
                    name={name}
                    createForm={<div style={{ maxWidth: 800 }}>
                        <h2>Créer</h2>
                        <CreateMenuForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            resetDefaultPriceValue={(form, member, value) => this.props.resetDefaultPriceValue(form, member, value)}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 800 }}>
                        <h2>Editer</h2>
                        <UpdateMenuForm
                            actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            resetDefaultPriceValue={(form, member, value) => this.props.resetDefaultPriceValue(form, member, value)}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    help={<div>
                        <div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />
                    </div>}
                    table={
                        <ConnectedTabs name={"menuTableTab"}>
                            <TabLink to="menuGlobal">
                                <div style={{ padding: "5px 10px" }}>Menus globaux</div>
                            </TabLink>
                            <TabLink to="menuSpecific">
                                <div style={{ padding: "5px 10px" }}>Menus par magasin</div>
                            </TabLink>
                            <TabContent for="menuGlobal">
                                <Table
                                    gridKey={name + '-global'}
                                    account={this.props.account}
                                    isLoading={this.props.isLoading}
                                    idPropertyName={"storeItemId"}
                                    updateValidation={(updates) =>
                                        validateGridUpdates(updates, menuValidation, this.props.entities)}
                                    onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                                        new Date().getTime(),
                                        updates) as any}
                                    data={_.keyBy(_.values(this.props.entities)
                                        .filter(x => x.menuStores.some(y => !y.storeId && y.hmiVisibility === "Visible"))
                                        , x => x.storeItemId)}
                                    onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                                    onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                                    onRequestUpdate={(id) => {
                                        this.props.selectUpdateEntity(id);
                                        this.props.selectTab(name, "update");
                                    }}
                                    columns={columns(this.props)} />
                            </TabContent>
                            <TabContent for="menuSpecific">
                                <Table
                                    gridKey={name + '-specific'}
                                    account={this.props.account}
                                    isLoading={this.props.isLoading}
                                    idPropertyName={"storeItemId"}
                                    updateValidation={(updates) =>
                                        validateGridUpdates(updates, menuValidation, this.props.entities)}
                                    onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                                        new Date().getTime(),
                                        updates) as any}
                                    data={_.keyBy(_.values(this.props.entities)
                                        .filter(x => !x.menuStores.some(y => !y.storeId && y.hmiVisibility === "Visible")
                                            && x.menuStores.some(y => this.props.allowedStores.some(z => z === y.storeId)))
                                        , x => x.storeItemId)}
                                    onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                                    onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                                    onRequestUpdate={(id) => {
                                        this.props.selectUpdateEntity(id);
                                        this.props.selectTab(name, "update");
                                    }}
                                    columns={columns(this.props)} />
                            </TabContent>
                        </ConnectedTabs>
                    } />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.menu,
    currencies: state.currency.entities,
    priceNames: state.priceName.entities,
    menuCategories: state.menuCategory.entities,
    account: state.account.currentUser.account,
    helpHtml: state.seed.seed.menuHelp,
    allowedStores: _.values(allowedStores(state)).map(x => x.storeId)
} as MenusProps), { ...TabsStore.actionCreators, ...MenuStore.actionCreators } as any)(Menus);