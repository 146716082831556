import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction } from './';
import { getDefaultHeaders } from '../utils/utils';
import * as Notifications from 'react-notification-system-redux';
import * as UnitStore from "./Unit";
import * as Download from "downloadjs";
import * as MimeTypes from "mime-types";

export interface ExternalProductState {
    isLoading: boolean;
    requestTime?: number;
    externalProducts:  { [id: number]: Api.ExternalProductModel};
    importDataState: {
        isLoading: boolean;
        requestTime?: number;
    };
}

interface ExternalProductsRequestEntities {
    type: "EXTERNAL_PRODUCTS_REQUEST_ENTITIES";
    payload: { requestTime: number }
}
interface ExternalProductsReceiveEntities {
    type: "EXTERNAL_PRODUCTS_RECEIVE_ENTITIES";
    payload: {
        requestTime: number;
        externalProducts?: { [id: number]: Api.ExternalProductModel };
    },
    error?: any
}

interface RequestImportData {
    type: 'REQUEST_IMPORT_DATA';
    payload: { requestTime: number; }
}
interface ReceiveImportData {
    type: 'RECEIVE_IMPORT_DATA';
    payload: { requestTime: number; }
    error?: any;
}

type KnownAction = ExternalProductsRequestEntities
    | ExternalProductsReceiveEntities
    | RequestImportData | ReceiveImportData
    ;

export const actionCreators = {
    requestExternalProducts: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        UnitStore.requestEntities(requestTime, dispatch as any, getState);
        let api = new Api.ExternalProductApi();
       let fetchTask = api.getEntities({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
           .then(externalProducts => {
               dispatch({
                   type: "EXTERNAL_PRODUCTS_RECEIVE_ENTITIES",
                   payload: { requestTime: requestTime, externalProducts: externalProducts }
               });
           })
           .catch(err => {
               dispatch({
                   type: "EXTERNAL_PRODUCTS_RECEIVE_ENTITIES",
                   payload: { requestTime: requestTime },
                   error: err
               });
           });
   
       dispatch({
           type: "EXTERNAL_PRODUCTS_REQUEST_ENTITIES",
           payload: { requestTime: requestTime }
       });
       addTask(fetchTask);
       return fetchTask;
    },
    importData: (requestTime: number, file: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (requestTime === getState().externalProducts.importDataState.requestTime)
             return Promise.reject("Already did");
        
        let formData = new FormData();
        formData.append("file", file);
        let fetchTask = fetch(`/api/Transfer/ImportData?StoreId=${getState().transfer.storeId}`, {
            method: "POST",
            body: formData,
            credentials: "same-origin",
            headers: getDefaultHeaders(getState())
        }).then((response) => {
            if(response.ok){
                dispatch({ type: "RECEIVE_IMPORT_DATA", payload: { requestTime: requestTime } });
                dispatch(Notifications.success({
                    title: 'Succès',
                    message: "Création correcte des données",
                    position: 'tr'
                }) as any);
            }
            else{
                dispatch({ type: "RECEIVE_IMPORT_DATA", payload: { requestTime: requestTime }, error: "Erreur" });
                dispatch(Notifications.error({
                title: 'Erreur',
                message: "Erreur d'insertion de données, vérifiez que vous avez ajouté les unités nécessaires",
                position: 'tr'
            }) as any);
            }
        }).catch(err => {
            dispatch({ type: "RECEIVE_IMPORT_DATA", payload: { requestTime: requestTime }, error: err });
        });
        
        addTask(fetchTask);
        dispatch({ type: "REQUEST_IMPORT_DATA", payload: { requestTime: requestTime }});
        return fetchTask;
    },
    exportExternalProductsCsv: ():  AppThunkAction<KnownAction> => (dispatch, getState) => {
        let fetchTask = fetch('/api/Transfer/ExportExternalProducts', {
            method: "GET",
            credentials: "same-origin",
            headers: getDefaultHeaders(getState())
        }).then(response => response.blob())
            .then(blob => {
                let fileName = `ExternalProducts.csv`;
                return Download(blob,
                    fileName,
                    MimeTypes.lookup(fileName) || "text/plain");
            }).catch(err => {
                console.error(err);
            });
        return fetchTask;
    },
};

const unloadedState: ExternalProductState = {
    isLoading: false,
    externalProducts: [],
    importDataState: {
        isLoading: false
    }
};

export const reducer: Reducer<ExternalProductState> = (state: ExternalProductState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "EXTERNAL_PRODUCTS_REQUEST_ENTITIES":
            return {
                ...state,
                isloading: true,
                requestTime: action.payload.requestTime
            };
        case "EXTERNAL_PRODUCTS_RECEIVE_ENTITIES":
            if (state.requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                isloading: false,
                externalProducts: action.payload.externalProducts,
            };
        case 'REQUEST_IMPORT_DATA':
            return {
                ...state,
                importDataState: {
                    ...state.importDataState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case 'RECEIVE_IMPORT_DATA':
            if (action.payload.requestTime !== state.importDataState.requestTime)
                return state;

            return {
                ...state,
                importDataState: {
                    ...state.importDataState,
                    isLoading: false
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};