import * as React from "react";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as PromotionStore from "../store/Promotion";
import * as CrudeStore from "../store/Crude";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import { CreatePromotionForm, UpdatePromotionForm, typeOptions } from "./PromotionForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { Formatters } from "react-data-grid-addons";
import { DropDownEditor } from "./DataGridEditor";
import { promotionValidation, validateGridUpdates } from "../utils/validation";
import { canCrude } from "../utils/userRights";

type PromotionsProps = PromotionsOwnProps
    & CrudeStore.EntityCrudeState<Api.PromotionModel, Api.PromotionFilter>
    & typeof PromotionStore.actionCreators
    & typeof TabsStore.actionCreators;

interface PromotionsOwnProps {
    account: Api.AccountModel;
    helpHtml: string;
}
const name = "promotions";
const Table: new () => EntityTable<Api.PromotionModel> = EntityTable as any;

const typeMappedOptions = typeOptions.map(x => ({ id: x.value, text: x.label, title: x.label, value: x.value }));

class Promotions extends React.Component<PromotionsProps, {}> {

    update(props: PromotionsProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps: PromotionsProps) {
        //this.update(nextProps);
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Promotions</h2>
                <CrudeLayout
                    name={name}
                    createForm={<div style={{ maxWidth: 800 }}>
                        <h2>Créer</h2>
                        <CreatePromotionForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 800 }}>
                        <h2>Editer</h2>
                        <UpdatePromotionForm
                            actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    help={<div>
                        <div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />
                    </div>}
                    table={<Table
                        gridKey={name}
                        account={this.props.account}
                        isLoading={this.props.isLoading}
                        data={this.props.entities}
                        idPropertyName={"promotionId"}
                        updateValidation={(updates) =>
                            validateGridUpdates(updates, promotionValidation, this.props.entities)}
                        onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                            new Date().getTime(),
                            updates) as any}
                        onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                        onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                        onRequestUpdate={(id) => {
                            this.props.selectUpdateEntity(id);
                            this.props.selectTab(name, "update");
                        }}
                        columns={[
                        {
                            key: "name",
                            name: "Nom",
                            editable: true,
                        },
                        {
                            key: "order",
                            name: "Ordre",
                            editable: true,
                        },
                        {
                            key: "type",
                            name: "Type",
                            editable: true,
                            editor: <DropDownEditor options={typeMappedOptions} />,
                            formatter: ({ value }) => <Formatters.DropDownFormatter options={typeMappedOptions} value={value} />
                        },
                        {
                            key: "imageId",
                            name: "Image",
                            editable: true,
                        },
                        {
                            key: "promotionCustoms",
                            name: 'Customisations',
                            editable: false,
                            formatter: ({ value }) => <div>{value.length}</div>
                        }]} />} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.promotion,
    account: state.account.currentUser.account,
    helpHtml: state.seed.seed.promotionHelp
} as PromotionsOwnProps), { ...TabsStore.actionCreators, ...PromotionStore.actionCreators } as any)(Promotions);