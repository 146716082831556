import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { AppThunkAction, ApplicationState } from './';

export interface SeedState {
    isLoading: boolean;
    requestTime?: number;
    seed: Api.SeedModel;
}

interface RequestSeed {
    type: 'REQUEST_SEED';
    requestTime: number;
}
interface ReceiveSeed {
    type: 'RECEIVE_SEED';
    requestTime: number;
    payload: {
        seed: Api.SeedModel;
    },
    error?: any;
}

export type KnownAction = RequestSeed | ReceiveSeed;

export const requestSeed = (requestTime: number, langCode: string, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    if (requestTime === getState().seed.requestTime)
        return Promise.reject("Already did");

    let api = new Api.SeedApi();
    let fetchTask = api.getSeed({ langCode: langCode }, { credentials: "same-origin" })
        .then(seed => {
            dispatch({
                type: "RECEIVE_SEED",
                requestTime: requestTime,
                payload: {
                    seed: seed
                }
            });
        }).catch(error => {
            dispatch({
                type: "RECEIVE_SEED",
                requestTime: requestTime,
                payload: {
                    seed: {}
                },
                error: error
            });
        });

    //Tell the server to wait for this promise to end
    addTask(fetchTask);
    dispatch({ type: "REQUEST_SEED", requestTime: requestTime });
    return fetchTask;
}

export const actionCreators = {
    requestSeed: (requestTime: number, langCode: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return requestSeed(requestTime, langCode, dispatch, getState);
    }
};

const unloadedState: SeedState = {
    isLoading: false,
    seed: {}
};

export const reducer: Reducer<SeedState> = (state: SeedState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_SEED':
            return {
                ...state,
                isLoading: true,
                requestTime: action.requestTime
            };
        case 'RECEIVE_SEED':
            if (action.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                seed: action.payload.seed
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};