import * as React from "react";

type ImportCSVProps = ImportCSVOwnProps

interface ImportCSVOwnProps {
    uploadFile: (file: File) => void;
    updateData: () => void;
    isLoading: boolean;
}

export default class ImportCSV extends React.Component<ImportCSVProps, {}> {
    fileInput: HTMLInputElement;


    public render() {
        return (
            <div style={{ textAlign: "center"}}>
                <div style={{ textAlign: "center",  marginBottom: 25 }}>
                    <label>
                        <h3> Téléverser le fichier CSV: </h3>
                        <input
                            type="file"
                            accept={".csv, application/vnd.ms-excel"}
                            style={{ order: 0, flex: "0 0 auto", fontSize: 15}}
                            ref={(input) => this.fileInput = input} />
                    </label>
                </div>
                <button className="btn btn-primary btn-lg"
                    disabled = {this.props.isLoading}
                    onClick={(e) => {
                        (this.props.uploadFile(this.fileInput.files.item(0)) as any as Promise<any>)
                        .then(() => {
                            this.fileInput.value = null;
                            this.props.updateData();
                        });
                        e.preventDefault();
                        e.stopPropagation();}
                    }>
                        Téléverser fichier
                </button>
            </div>
        );
    }
}