import * as Api from "../api/api";
import { change } from 'redux-form';
import { AppThunkAction, ApplicationState } from './';
import * as CrudeStore from "./Crude";
import * as PriceNameStore from "./PriceName";
import * as CurrencyStore from "./Currency";
import * as MenuCategoryStore from "./MenuCategory";

export const key: string = "menu";

export type MenuState = CrudeStore.EntityCrudeState<Api.MenuModel, Api.MenuFilter>;

export const configuration: CrudeStore.CrudeStoreConfiguration<Api.MenuModel, Api.MenuFilter> = {
    key: key,
    storeSelector: x => x.menu,
    idSelector: x => x.storeItemId,
    requestEntitiesFetch: (model, options) => {
        let api = new Api.MenuApi();
        return api.getEntities(model, options);
    },
    requestCreateFetch: (model, options) => {
        let api = new Api.MenuApi();
        return api.create(model, options);
    },
    requestUpdateFetch: (model, options) => {
        let api = new Api.MenuApi();
        return api.update(model, options);
    },
    requestDeleteFetch: (model, options) => {
        let api = new Api.MenuApi();
        return api._delete(model, options);
    },
    requestBulkUpdateFetch: (models, options) => {
        let api = new Api.MenuApi();
        return api.bulkUpdate(models, options);
    },
    requestSubmitCreateFetch: (models, options) => {
        let api = new Api.MenuApi();
        return api.submitCreate(models, options);
    },
    requestSubmitUpdateFetch: (models, options) => {
        let api = new Api.MenuApi();
        return api.submitUpdate(models, options);
    },
    requestDependencies: [
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.CurrencyModel, Api.CurrencyFilter>) => void,
            getState: () => ApplicationState) => CurrencyStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.PriceNameModel, Api.PriceNameFilter>) => void,
            getState: () => ApplicationState) => PriceNameStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.MenuCategoryModel, Api.MenuCategoryFilter>) => void,
            getState: () => ApplicationState) => MenuCategoryStore.requestEntities(requestTime, dispatch, getState),
    ]
}

export const requestEntities = (requestTime: number,
    dispatch: (action: CrudeStore.KnownAction<Api.MenuModel, Api.MenuFilter>) => void,
    getState: () => ApplicationState) => CrudeStore.requestEntities(configuration, requestTime, dispatch, getState);

export const actionCreators = {
    ...CrudeStore.getActionCreators<Api.MenuModel, Api.MenuFilter>(configuration),
    resetDefaultPriceValue: (form: string, member: string, value: number): AppThunkAction<any> => (dispatch, getState) => {
        change(form, `${member}.value`, value);
    }
};

export const reducer = CrudeStore.getReducer(key);