import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import {
    Field, reduxForm, FormProps,
    formValueSelector, FieldArray,
    WrappedFieldArrayProps
} from 'redux-form';
import { productBarCodeValidation } from '../utils/validation';
import { productBarcodesHelp } from '../utils/entityHelp';
import { FieldHelp } from './FieldHelp';

type ProductBarcodesProps = ProductBarcodesOwnProps
    & WrappedFieldArrayProps<Api.ProductMenuCategoryModel>;

export interface ProductBarcodesOwnProps {
    storeItemId: number;
    disabled?: boolean;
    productBarcodes: Array<Api.ProductBarcodeModel>;
}

export default class ProductBarcodes extends React.Component<ProductBarcodesProps, {}> {
    public render() {
        return (<div>
            {this.props.fields.map((member, index) => <div key={index}
                style={{ marginBottom: 5 }}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div style={{ flex: "1" }}>
                        <div style={{ marginLeft: 5 }}>
                            <FieldHelp help={productBarcodesHelp()["value"]}>
                                <Field className="form-control"
                                    validate={productBarCodeValidation["value"]}
                                    style={!this.props.productBarcodes[index]
                                        || [8, 13].some(x => this.props.productBarcodes[index].value
                                            && x === this.props.productBarcodes[index].value.length)
                                        ? {}
                                        : { border: "1px solid orange" }}
                                    name={`${member}.value`}
                                    disabled={this.props.disabled}
                                    component={FormField.renderField}
                                    type={"text"} />
                            </FieldHelp>
                        </div>
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                        <button
                            type="button"
                            disabled={this.props.disabled}
                            style={{ marginLeft: 5 }}
                            className="btn btn-secondary btn-sm"
                            title="Remove"
                            onClick={() => this.props.fields.remove(index)}>
                            <i className="glyphicon glyphicon-remove"></i>
                        </button>
                    </div>
                </div>
            </div>)}
            <FormField.FieldErrors {...this.props.meta} />
            <div style={{ textAlign: "center" }}>
                <button
                    type="button"
                    disabled={this.props.disabled}
                    className="btn btn-secondary btn-sm"
                    onClick={() => this.props.fields.push({
                        productId: this.props.storeItemId
                    })}>
                    <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                </button>
            </div>
        </div>);
    }
}