import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import {
    Field, WrappedFieldArrayProps
} from 'redux-form';
import {
    priceValidation,
} from "../utils/validation";
import { priceHelp } from "../utils/entityHelp";
import { FieldHelp } from './FieldHelp';
import StoresSelection from './StoresSelection';
import { DataType } from './Home';
import { PriceStoreModel } from '../api/api';
import Tooltip from "./Tooltip";

type PricesProps = PricesOwnProps
    & WrappedFieldArrayProps<Api.PriceModel>;

export interface PricesOwnProps {
    storeItemId: number;
    prices: Array<Api.PriceModel>;
    allowedStores: Array<number>;
    stores: { [id: number]: Api.StoreModel };
    currencies: { [id: number]: Api.CurrencyModel };
    priceNames: { [id: number]: Api.PriceNameModel };
    dataType: DataType
    disabled?: boolean;
    defaultCurrencyCode: string;
    onPriceNameIdChange: (member: string, value: number) => void;
}

export const getDefaultPriceValue = (price: Api.PriceModel, prices: Array<Api.PriceModel>, stores: { [id: number]: Api.StoreModel }): number => {
    let similarPrices = _.sortBy(prices.filter(x => x.priceNameId === price.priceNameId)
        , x => !x.priceStores
            || !price.priceStores.some(y => x.priceStores.some(z => z.storeId === y.storeId)));
    if (similarPrices.length === 0)
        return 0;

    return similarPrices[0].value;

}

export class Prices extends React.Component<PricesProps, {}> {

    //When a price has a store attached out of the use rights
    hasConflictedStores(price: Api.PriceModel): boolean {
        return price.priceStores
            .some(x => this.props.allowedStores.some(y => y === x.storeId))
            && price.priceStores
                .some(x => !this.props.allowedStores.some(y => y === x.storeId));
    }

    //We delete conflicting price and add two differents
    resolveStoreConflict(index: number): void {
        let basePrice = this.props.prices[index];
        let notAllowedStores = basePrice.priceStores.filter(x => !this.props.allowedStores.some(y => y == x.storeId));
        let allowedStores = basePrice.priceStores.filter(x => this.props.allowedStores.some(y => y == x.storeId));

        this.props.fields.remove(index);
        this.props.fields.push({
            ...basePrice,
            priceId: undefined,
            priceStores: notAllowedStores.map(x => ({
                storeId: x.storeId
            } as Api.PriceStoreModel))
        });
        this.props.fields.push({
            ...basePrice,
            priceId: undefined,
            priceStores: allowedStores.map(x => ({
                storeId: x.storeId
            } as Api.PriceStoreModel))
        });
    }

    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => !this.props.prices[index]
                    || ((this.props.dataType === "Global"
                        && this.props.prices[index].priceStores.length !== 0)
                        || (this.props.dataType === "Specific"
                            && this.props.prices[index].priceStores.length === 0))
                    ? <div key={index}></div>
                    : <div key={index}
                        style={{
                            marginBottom: 5,
                            display: this.props.prices[index]
                                && this.props.prices[index].priceStores.length
                                && !this.props.prices[index].priceStores
                                    .some(x => this.props.allowedStores
                                        .some(y => y === x.storeId))
                                ? "none"
                                : "block"
                        }}>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <div style={{ flex: "0 0 auto" }}>
                                <Field className="form-control input-sm"
                                    style={{ width: 90 }}
                                    disabled={this.props.disabled
                                        || (this.props.prices[index]
                                        && this.hasConflictedStores(this.props.prices[index]))}
                                    validate={priceValidation["value"]}
                                    name={`${member}.value`}
                                    component={FormField.NumberField} />
                            </div>
                            <div style={{ flex: "0 0 70px" }}>
                                <div style={{ marginLeft: 3 }}>
                                    <Field className="form-control"
                                        disabled={this.props.disabled
                                            || (this.props.prices[index]
                                                && this.hasConflictedStores(this.props.prices[index]))}
                                        validate={priceValidation["currencyId"]}
                                        name={`${member}.currencyId`}
                                        component={FormField.getSelectField(
                                            _.values(this.props.currencies)
                                                .map(x => ({ label: x.code, value: x.currencyId })))} />
                                </div>
                            </div>
                            <div style={{ flex: "1" }}>
                                <div style={{ marginLeft: 3 }}>
                                    <Field className="form-control"
                                        disabled={this.props.disabled
                                            || (this.props.prices[index]
                                                && this.hasConflictedStores(this.props.prices[index]))}
                                        validate={priceValidation["priceNameId"]}
                                        name={`${member}.priceNameId`}
                                        afterChange={(value) => this.props.onPriceNameIdChange(`${member}`
                                            , getDefaultPriceValue({
                                                ...this.props.prices[index],
                                                priceNameId: value //It wont be updated yet from the component pov
                                            }, this.props.prices, this.props.stores))}
                                        component={FormField.getSelectField(
                                            _.values(this.props.priceNames)
                                                .map(x => ({ label: x.name, value: x.priceNameId })))} />
                                </div>
                            </div>
                            {this.props.dataType === "Specific"
                                && <div style={{ flex: "1" }}>
                                    <div style={{ marginLeft: 3 }}>
                                        <FieldHelp help={priceHelp()["priceStores"]}>
                                            <Field
                                                validate={priceValidation["priceStores"]}
                                            disabled={this.props.disabled
                                                || (this.props.prices[index]
                                                    && this.hasConflictedStores(this.props.prices[index]))}
                                                name={`${member}.priceStores`}
                                                component={(props) => <StoresSelection
                                                    {...props}
                                                    stores={_.sortBy(_.values(this.props.stores)
                                                        .filter(x => this.props.allowedStores.some(y => y === x.storeId)),
                                                        x => _.deburr(x.name))}
                                                    disabledEmpty={true}
                                                    value={props.input.value}
                                                    storeIdsDisabled={[]}
                                                    onChange={(value) => props.input.onChange(value)}
                                                />} />
                                        </FieldHelp>
                                    </div>
                                </div>}
                            <div style={{ flex: "0 0 auto" }}>
                                <button
                                    type="button"
                                    disabled={this.props.disabled
                                        || (this.props.prices[index]
                                            && this.hasConflictedStores(this.props.prices[index]))}
                                    style={{ marginLeft: 5 }}
                                    className="btn btn-secondary btn-sm"
                                    title="Remove"
                                    onClick={() => this.props.fields.remove(index)}>
                                    <i className="glyphicon glyphicon-remove"></i>
                                </button>
                            </div>
                            {(this.props.prices[index]
                                && this.hasConflictedStores(this.props.prices[index]))
                                && <div style={{ flex: "0 0 auto" }}>
                                <Tooltip overlay={<div>Un magasin non visible par votre compte est attaché à ce prix, cliquez ici pour corriger la situation.</div>}>
                                    <button
                                        type="button"
                                        style={{ marginLeft: 5 }}
                                        className="btn btn-secondary btn-sm"
                                        onClick={() => this.resolveStoreConflict(index)}>
                                        <i className="glyphicon glyphicon-refresh"></i>
                                    </button>
                                </Tooltip>
                                </div>}
                        </div>
                    </div>)}
                <FormField.FieldErrors {...this.props.meta} />
                <div style={{ textAlign: "center" }}>
                    <button
                        type="button"
                        disabled={this.props.disabled}
                        className="btn btn-secondary btn-sm"
                        onClick={() => {
                            let price = {
                                storeItemId: this.props.storeItemId,
                                priceNameId: 1,
                                currencyId: _.sortBy(_.values(this.props.currencies),
                                    x => x.code !== this.props.defaultCurrencyCode)[0].currencyId,
                                priceStores: this.props.dataType === "Global"
                                    ? []
                                    : [{
                                        storeId: this.props.allowedStores[0],
                                    }]
                            } as Api.PriceModel;
                            price.value = getDefaultPriceValue(price, this.props.prices, this.props.stores);
                            this.props.fields.push(price);
                        }}>
                        <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                    </button>
                </div>
            </div>
        );
    }
}