import * as Api from '../api/api';
import * as React from 'react';
import {
    WrappedFieldProps
} from 'redux-form';
import { getText } from '../utils/texts';
import CheckBox from './CheckBox';

type StoreItemStorePaymentFieldProps = StoreItemStorePaymentFieldOwnProps
    & WrappedFieldProps;

export interface StoreItemStorePaymentFieldOwnProps {
    storeItemId: number;
    disabled?: boolean;
    paymentType: Api.StoreItemStorePaymentModelPaymentTypeEnum;
}

export default class StoreItemStorePaymentField extends React.Component<StoreItemStorePaymentFieldProps, {}> {

    public render() {
        return (
            <div>
                <CheckBox disabled={this.props.disabled}
                    label={getText("LgapProduct" + this.props.paymentType.toString() as any)}
                    value={this.props.input.value ? this.props.input.value.some(x => x.paymentType === this.props.paymentType && x.availability === "Available") : false}
                    onChange={(value) => {
                        if (value
                            && !this.props.input.value.some(x => x.paymentType === this.props.paymentType)) {
                            this.props.input.onChange(this.props.input.value.concat([{
                                availability: "Available",
                                paymentType: this.props.paymentType
                            }]));
                        } else {
                            this.props.input.onChange(this.props.input.value.map(x => x.paymentType === this.props.paymentType
                                ? ({
                                    ...x,
                                    availability: value
                                        ? "Available"
                                        : "Unavailable" as Api.StoreItemStorePaymentModelAvailabilityEnum
                                }) : x));
                        }
                    }}
                />
            </div>
        );
    }
}