import * as React from 'react';
import Select from './Select';
import { getCurrentLang, changeLang } from '../utils/language';

export default class SelectLanguage extends React.Component<{}, {}> {
    public render() {
        return (
            <div style={{ width: 100 }}>
                <Select value={getCurrentLang()}
                    onChange={(langCode) => changeLang(langCode)}
                    disableSearch={true}
                    options={[
                        { label: "Français", value: "fr" },
                        { label: "English", value: "en" },
                    ]}
                    optionComponent={(opt) => <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                        <div style={{ flex: 1 }}>
                            {opt.label}
                        </div>
                        <div style={{ flex: 0 }}>
                            <img height={14} src={`/images/flags/${opt.value}.jpg`} />
                        </div>
                    </div>} />
            </div>
        );
    }
}