import * as _ from 'lodash';
import * as React from 'react';
import Moment from 'moment';
import Select, { Option } from './Select';
import * as ReduxForm from 'redux-form';
import InputNumber from './InputNumber';
import ImageUpload from "./ImageUpload";
import { Guid } from '../utils/utils';
import DatePicker from './DatePicker';
import CheckBox from './CheckBox';

//Fixes because @types for redux-form has been made by monkeys
export interface FormProps { };

export const modifiedFieldStyle: React.CSSProperties = {
    border: "2px solid purple",
    padding: 2
}

export const required = value => (value ? undefined : "Ce champ est requis");
export const requiredOrZero = value => (value || value === 0 ? undefined : "Ce champ est requis");
export const requiredNotZero = value => (value && value !== "0" ? undefined : "Ce champ est requis");
export const requiredOne = value => (!value || !value.length || value.length === 0
    ? "Ce champ est requis" : undefined);

export const maxLength = max => value =>
    value && value.length > max ? `Doit être inférieur ou égal à ${max} caractères` : undefined

export const minLength = min => value =>
    value && value.length < min ? `Doit être supérieur ou égal à ${min} caractères` : undefined

const numberRegex = /^[0-9]*$/;
export const onlyNumbers = value =>
    value && numberRegex.test(value) ? undefined : "Veuillez n'utiliser que des chiffres";

const passwordRegex = /^(?=.*[0-9])(?=.*[A-Za-z])/;
export const passwordValid = value =>
    value && passwordRegex.test(value) ? undefined : "Votre mot de passe doit au minimum contenir une lettre et un chiffre";

const phoneRegex = /^[0-9 ]*/;
export const phoneValid = value => !value || phoneRegex.test(value) ? undefined : "Un numéro téléphone ne doit contenir que des nombres";

export const scrollToFirstError = (errors: ReduxForm.FormErrors<{}>, containerClass: string = undefined): void => {
    let errorFields = getErrorFieldNames(errors);

    // Using breakable for loop
    for (let i = 0; i < errorFields.length; i++) {
        const fieldName = `${errorFields[i]}`;
        // Checking if the marker exists in DOM
        let inputElements = document.querySelectorAll(containerClass
            ? `.${containerClass} [name="${fieldName}"]`
            : `[name="${fieldName}"]`);
        if (inputElements.length) {
            //scroller.scrollTo(inputElements[0], { offset: -200, smooth: true });
            if ((inputElements[0] as HTMLInputElement).scrollIntoView) {
                (inputElements[0] as HTMLInputElement).scrollIntoView();
            }
            (inputElements[0] as HTMLInputElement).focus();
            break;
        }
    }
};

export function getErrorFieldNames(obj:any, name = ''): Array<string> {
    const errorArr = [];
    errorArr.push(Object.keys(obj).map((key) => {
        const next = obj[key];
        if (next) {
            if (typeof next === 'string') {
                return name + key;
            }
            // Keep looking
            if (next.map) {
                errorArr.push(next.map((item, index) => item
                    ? getErrorFieldNames(item, `${name}${key}[${index}].`)
                    : []).filter(o => o));
            }
        }
        return null;
    }).filter(o => o));
    return flatten(errorArr);
}

function flatten<T>(arr: Array<T>): Array<T> {
    return arr.reduce((flat, toFlatten) => flat.concat(Array.isArray(toFlatten) ? flatten(toFlatten) : toFlatten), []);
}

export const renderField = ({
    disabled,
    input,
    className,
    style,
    containerStyle,
    type,
    meta
}) => (
        <div style={containerStyle}>
            <div>
                <input {...input} style={style} className={className} type={type} disabled={disabled} />
            </div>
            <FieldErrors {...meta} />
        </div>
    )


export const textAreaField = (row: number, cols: number) => ({
    input,
    className,
    style,
    type,
    meta
}) => (
        <div>
            <div>
                <textarea style={style}
                    name={input.name}
                    value={input.value}
                    onChange={(e) => { input.onChange(e.target.value) }}
                    rows={row} cols={cols} />
            </div>
            <FieldErrors {...meta} />
    </div>
);

export const fileField = (field: any) => (
    <input
        name={field.input.name}
        type="file"
        onChange={(e) => field.input.onChange(e.target.files[0])}
    />
);

export const getSelectField = (options: Array<Option>) => ({
    input,
    disabled,
    className,
    style,
    meta,
    disableAutoSort,
    afterChange
}) => (
        <div style={style}>
            <Select
                options={disableAutoSort
                    ? options
                    : _.sortBy(options, x => _.deburr(x.label))}
                value={input.value}
                disabled={disabled}
                onChange={(value) => {
                    input.onChange(value);
                    if (afterChange) {
                        afterChange(value);
                    }
                }}
            />
            <FieldErrors {...meta} />
        </div>
    );

type NumberFieldProps = NumberFieldOwnProps
    & ReduxForm.WrappedFieldProps;

interface NumberFieldOwnProps {
    className: string;
    unit?: string;
    disabled?: boolean;
    allowEmpty?: boolean;
}

export class NumberField extends React.Component<NumberFieldProps, {}> {
    public render() {
        return (
            <div>
                <div>
                    <InputNumber
                        className={this.props.className}
                        name={this.props.input.name}
                        unit={this.props.unit}
                        value={this.props.input.value}
                        disabled={this.props.disabled}
                        allowEmpty={this.props.allowEmpty}
                        onChange={(value) => this.props.input.onChange(value as any)}
                    />
                </div>
                <FieldErrors {...this.props.meta} />
            </div>
        );
    }
}

type CheckBoxFieldProps = CheckBoxFieldOwnProps
    & ReduxForm.WrappedFieldProps;

interface CheckBoxFieldOwnProps {
    className: string;
    label?: string;
    disabled?: boolean;
    //Used to manage enum with checkbox
    checkedValue?: any;
    unCheckedValue?: any;
}

export class CheckBoxField extends React.Component<CheckBoxFieldProps, {}> {
    public render() {
        return (
            <div>
                <div className={this.props.className}>
                    <CheckBox
                        label={this.props.label}
                        value={this.props.checkedValue
                            ? (this.props.input.value === this.props.checkedValue)
                            : this.props.input.value}
                        disabled={this.props.disabled}
                        onChange={(value) => {
                            if (this.props.checkedValue) {
                                this.props.input.onChange(value
                                    ? this.props.checkedValue
                                    : this.props.unCheckedValue);
                            } else {
                                this.props.input.onChange(value as any);
                            }
                        }}
                    />
                </div>
                <FieldErrors {...this.props.meta} />
            </div>
        );
    }

}

type ImageFieldProps = ImageFieldOwnProps
    & ReduxForm.WrappedFieldProps;

interface ImageFieldOwnProps {
    fieldKey?: string;
    height?: number;
    className: string;
    disabled?: boolean;
    acceptProp?: string;
}

export class ImageField extends React.Component<ImageFieldProps, {}> {
    private guid: string;

    constructor(props: ImageFieldProps) {
        super(props);
        this.guid = Guid.newGuid();
    }

    public render() {
        return <div>
            <div>
                <ImageUpload
                    fieldKey={this.props.fieldKey || this.guid}
                    disabled={this.props.disabled}
                    height={this.props.height}
                    value={this.props.input.value}
                    acceptProp={this.props.acceptProp}
                    onChange={(imageId) => this.props.input.onChange(imageId as any)}
                />
            </div>
            <FieldErrors {...this.props.meta} />
        </div>
    }
}


type DatePickerFieldProps = DatePickerFieldOwnProps
    & ReduxForm.WrappedFieldProps;

interface DatePickerFieldOwnProps {
    minDate?: Date;
    maxDate?: Date;
    className?: string;
}

export class DatePickerField extends React.Component<DatePickerFieldProps, {}> {
    public render() {
        return (
            <div>
                <div>
                    <DatePicker
                        className={this.props.className || "form-control"}
                        date={this.props.input.value ? Moment(this.props.input.value) : Moment()}
                        selectDate={(value) => this.props.input.onChange(value.toDate() as any)}
                        minDate={this.props.minDate ? Moment(this.props.minDate) : null}
                        maxDate={this.props.maxDate ? Moment(this.props.maxDate) : null} />
                </div>
                <FieldErrors {...this.props.meta} />
            </div>
        );
    }

}

export const rteField = ({
    input,
    disabled,
    meta,
    className
}) => {
    return <div>
        <div>
            <textarea
                className={className}
                value={input.value}
                disabled={disabled}
                onChange={(e) => { input.onChange(e.target.value) }}
                rows={5} cols={45} 
                onKeyDown = {(e) => {
                    if(e.key === "Enter"){
                        let pos = e.currentTarget.selectionStart;
                        input.onChange(input.value.substring(0, pos) + "\n" + input.value.substring(pos));
                    }
                }}/>
            
        </div>
        <FieldErrors {...meta} />
    </div>
}


export class BaseForm extends React.Component<ReduxForm.InjectedFormProps<{}, {}, {}> & { className?: string }, {}> {

    submitWrapped(e) {
        let maybePromise = (this.props.handleSubmit(e) as any);
        if (maybePromise.then) {
            let promise = maybePromise as Promise<any>;
            promise
                .then(() => {
                    this.props.reset();
                });
        }
    }

    public render() {
        return (
            <form className={this.props.className || "form-horizontal"}
                onKeyDown={(e) => {
                    if (e.key === "Enter" && this.props.form !== "filterProduct") 
                        e.preventDefault();
                }}
                onSubmit={(e) => {
                if (e.preventDefault) {
                    e.preventDefault();
                }
                return this.submitWrapped(e);
            }}>
                <div className="text-danger" style={{ textAlign: "center" }}>
                    {this.props.error && <span>{this.props.error}</span>}
                </div>
                <div className="text-warning" style={{ textAlign: "center" }}>
                    {this.props.warning && <span>{this.props.warning}</span>}
                </div>
                <div>
                    {this.props.children}
                </div>
            </form>
        );
    }
}

export class FieldErrors extends React.Component<(ReduxForm.FieldArrayMetaProps | ReduxForm.WrappedFieldMetaProps) & { touched?: boolean }, {}> {
    public render() {
        return (
            <div>
                <div className="text-danger">
                    {(this.props.submitFailed || this.props.touched)
                        && (this.props.error && <span>{this.props.error}</span>)}
                </div>
                <div className="text-warning">
                    {this.props.warning && <span>{this.props.warning}</span>}
                </div>
            </div>
            );
    }
}