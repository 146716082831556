import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import {
    Field, WrappedFieldArrayProps
} from 'redux-form';
import { Guid } from '../utils/utils';
import { DataType } from './Home';

type KnownCustomType = Api.FamilyCustomModel
    | Api.SubFamilyCustomModel
    | Api.MenuCustomModel
    | Api.PromotionCustomModel;

type EntityCustomizationsProps = EntityCustomizationsOwnProps
    & WrappedFieldArrayProps<KnownCustomType>;

export interface EntityCustomizationsOwnProps {
    entityCustoms: Array<Api.FamilyCustomModel>
        | Array<Api.PromotionCustomModel>
    | Array<Api.MenuCustomModel>
        | Array<Api.SubFamilyCustomModel>,
    languages: { [id: number]: Api.LanguageModel };
    storeGroups: { [id: number]: Api.StoreGroupModel };
    disabled?: boolean;
    allowedStoreGroup: Array<number>;
    dataType: DataType;
}

export default class EntityCustomizations extends React.Component<EntityCustomizationsProps, {}> {
    guid: string;

    constructor(props: EntityCustomizationsProps) {
        super(props);
        this.guid = Guid.newGuid();
    }

    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => !this.props.entityCustoms[index]
                    || ((this.props.dataType === "Global"
                        && this.props.entityCustoms[index].storeGroupId)
                        || (this.props.dataType === "Specific"
                            && !this.props.entityCustoms[index].storeGroupId))
                    ? <div></div>
                    : <div className="container-fluid"
                        key={index}
                        style={{
                            marginBottom: 5, padding: 5,
                            border: "1px solid", borderRadius: 10,
                            display: this.props.entityCustoms
                                && this.props.entityCustoms[index]
                                && this.props.entityCustoms[index].storeGroupId
                                && !_.values(this.props.allowedStoreGroup)
                                    .some(x => x === this.props.entityCustoms[index].storeGroupId)
                                ? "none" : "block"
                        }}>
                        <div style={{ display: "flex", flexDirection: "row-reverse", marginBottom: 5 }}>
                            <button
                                type="button"
                                disabled={this.props.disabled}
                                className="btn btn-secondary btn-sm"
                                title="Remove"
                                onClick={() => this.props.fields.remove(index)}>
                                <i className="glyphicon glyphicon-remove"></i>
                            </button>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Nom:"}</label>
                            <div className="col-sm-8">
                                <Field className="form-control"
                                    disabled={this.props.disabled}
                                    validate={[]}
                                    name={`${member}.name`}
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Image:"}</label>
                            <div className="col-sm-8">
                                <Field className="form-control"
                                    key={this.guid}
                                    disabled={this.props.disabled}
                                    validate={[]}
                                    name={`${member}.imageId`}
                                    acceptProp={".jpg, .jpeg"}
                                    component={FormField.ImageField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group row">
                                    <label className="col-sm-4 control-label ">{"Langue:"}</label>
                                    <div className="col-sm-8">
                                        <Field className="form-control"
                                            disabled={this.props.disabled}
                                            validate={[]}
                                            name={`${member}.languageId`}
                                            component={FormField.getSelectField(
                                                [{ label: "-- TOUTES --", value: null }]
                                                    .concat(_.values(this.props.languages)
                                                        .map(x => ({ label: x.code, value: x.languageId }))))} />
                                    </div>
                                </div>
                            </div>
                            {this.props.dataType === "Specific"
                                && <div className="col-md-6">
                                    <div className="form-group row">
                                        <label className="col-sm-4 control-label ">{"Magasin:"}</label>
                                        <div className="col-sm-8">
                                            <Field className="form-control"
                                                disabled={this.props.disabled}
                                                validate={[]}
                                                name={`${member}.storeGroupId`}
                                                component={FormField.getSelectField(_.values(this.props.storeGroups)
                                                            .filter(x => this.props.allowedStoreGroup.some(y => y === x.storeGroupId))
                                                            .map(x => ({ label: x.name, value: x.storeGroupId })))} />
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>)}
                <FormField.FieldErrors {...this.props.meta} />
                <div style={{ textAlign: "center" }}>
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        disabled={this.props.disabled}
                        onClick={() => this.props.fields.push(this.props.dataType === "Specific"
                            ? {
                                storeGroupId: this.props.allowedStoreGroup[0]
                            }
                            : {})}>
                        <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                    </button>
                </div>
            </div>
        );
    }
}