let token = null;

export const writeToken = (value: string): void => {
    token = value;
}
export const readToken = (): string => {
    return token;
}

export const clearToken = (): void => {
    token = null;
};