import * as React from "react";
import * as Api from "../api/api";
import * as ProductStore from "../store/Product";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import ProductFilterForm from "./ProductFilterForm";
import {
    CreateProductForm, UpdateProductForm,
    stabilityOptions, expirityTypeOptions
} from "./ProductForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { Formatters } from "react-data-grid-addons";
import { DropDownEditor, DropDownUnitEditor } from "./DataGridEditor";
import ProductPriceGrid from "./ProductPriceGrid";
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import ProductSupplierGrid from "./ProductSupplierGrid";
import { validateGridUpdates, productValidation } from "../utils/validation";
import ProductBarcodeGrid from "./ProductBarcodeGrid";
import { canCrude } from "../utils/userRights";
import keyboard from "../utils/keyboard";

type ProductsProps = ProductsOwnProps
    & ProductStore.ProductState
    & typeof ProductStore.actionCreators
    & typeof TabsStore.actionCreators;

interface ProductsOwnProps {
    filteredProducts: Array<Api.ProductModel>;
    units: { [id: number]: Api.UnitModel };
    account: Api.AccountModel;
    helpHtml: string;
    selectedTab: string;
}
interface ProductsState {
}
const name = "products";
const Table: new () => EntityTable<Api.ProductModel> = EntityTable as any;

const stabilityMappedOptions = stabilityOptions().map(x => ({ id: x.value, text: x.label, title: x.label, value: x.value }));
const expirityTypeMappedOptions = expirityTypeOptions().map(x => ({ id: x.value, text: x.label, title: x.label, value: x.value }));

const tabName = "productTableTab";
const tabProducts = "products";
const tabPrices = "prices";
const tabSuppliers = "suppliers";
const tabProductBarCodes = "productBarcodes";

class Products extends React.Component<ProductsProps, ProductsState> {

    constructor(props: ProductsProps) {
        super(props);
    }

    update(props: ProductsProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);

        keyboard.subscribe({
            uniqName: "productsNav", onKeyDown: (e) => {
                if (this.props.selectedEntityId) {
                    if (e.ctrlKey && e.key === "ArrowLeft")
                        this.props.prevProduct(1);

                    if (e.ctrlKey && e.key === "ArrowRight")
                        this.props.nextProduct(1);
                }
            }
        });
    }

    componentWillUnmount() {
        keyboard.unsubscribe("productsNav");
        this.updateRtf(this.props);
    }

    componentWillReceiveProps(nextProps: ProductsProps) {
        if (this.props.helpHtml !== nextProps.helpHtml) {
            this.updateRtf(nextProps);
        }
    }

    updateRtf(props: ProductsProps) {
        
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Produits</h2>
                <CrudeLayout
                    name={name}
                    filter={<div>
                        <ProductFilterForm
                            onSubmit={(values) => this.props.updaterFilter(values)}
                        />
                    </div>}
                    createForm={<div style={{ maxWidth: 1200 }}>
                        <h2>Créer</h2>
                        <CreateProductForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                            onResetDuplicate={() => this.props.selectCreateSource(null)}
                            onSelectPriceNameId={(priceNameId) => this.props.selectPriceNameId(priceNameId)}
                            onSelectSupplierId={(supplierId) => this.props.selectSupplierId(supplierId)}
                            onSelectStoreId={(storeId) => this.props.selectStoreId(storeId)}
                            updateFormValue={(form, member, value) => this.props.updateFormValue(form, member, value)}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 1200 }}>
                        <h2>Editer</h2>
                        <UpdateProductForm
                            // actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            actionName={ "Sauvegarder" }
                            onSubmit={(values) => {
                                //if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                //else
                                    //return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                            onSelectPriceNameId={(priceNameId) => this.props.selectPriceNameId(priceNameId)}
                            onSelectSupplierId={(supplierId) => this.props.selectSupplierId(supplierId)}
                            onSelectStoreId={(storeId) => this.props.selectStoreId(storeId)}
                            updateFormValue={(form, member, value) => this.props.updateFormValue(form, member, value)}
                        />
                    </div>}
                    help={<div>
                        <div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />
                    </div>}
                    table={
                        <ConnectedTabs name={tabName}>
                            <TabLink to={tabProducts}>
                                <div style={{ padding: "5px 10px" }}>Produits</div>
                            </TabLink>
                            <TabLink to={tabPrices}>
                                <div style={{ padding: "5px 10px" }}>Grille de prix</div>
                            </TabLink>
                            <TabLink to={tabSuppliers}>
                                <div style={{ padding: "5px 10px" }}>Grille d'appro</div>
                            </TabLink>
                            <TabLink to={tabProductBarCodes}>
                                <div style={{ padding: "5px 10px" }}>Code barres</div>
                            </TabLink>
                            <TabContent for={tabProducts}>
                                {(!this.props.selectedTab
                                    || this.props.selectedTab === tabProducts)
                                    && <Table
                                        gridKey={name}
                                        minWidthPerColumns={100}
                                        selectableColumns={[
                                            "stability", "expirityType",
                                            "expirityDays", "width",
                                            "height", "depth",
                                            "volume",
                                            "volumeUnitId",
                                            "relativeUnitId",
                                            "prices",
                                            "productSubFamilies",
                                            "productSuppliers",
                                            "productVats",
                                            "productBarecodes",
                                            "storeItemCustoms"]}
                                        isLoading={this.props.isLoading}
                                        account={this.props.account}
                                        data={this.props.entities}
                                        dataFiltered={this.props.filteredProducts}
                                        idPropertyName={"storeItemId"}
                                        updateValidation={(updates) =>
                                            validateGridUpdates(updates, productValidation, this.props.entities)}
                                        onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                                            new Date().getTime(),
                                            updates) as any}
                                        onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                                        onRequestDuplicate={(id) => {
                                            this.props.selectCreateSource(id);
                                            this.props.selectTab(name, "create");
                                        }}
                                        onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                                        onRequestUpdate={(id) => {
                                            this.props.selectUpdateEntity(id);
                                            this.props.selectTab(name, "update");
                                        }}
                                        columns={[
                                            {
                                                key: "name",
                                                name: "Nom",
                                                editable: true,
                                            },
                                            {
                                                key: "imageId",
                                                name: "Image",
                                                editable: false,
                                                formatter: ({ value }) => <img height={30} src={"/api/Image/GetImageContent?imageId=" + value} />
                                            },
                                            {
                                                key: "code",
                                                name: "Code",
                                                editable: true,
                                            },
                                            {
                                                key: "stability",
                                                name: "Stabilité",
                                                editable: true,
                                                editor: <DropDownEditor options={stabilityMappedOptions} />,
                                                formatter: ({ value }) => <Formatters.DropDownFormatter options={stabilityMappedOptions} value={value} />
                                            },
                                            {
                                                key: "expirityType",
                                                name: "Type date",
                                                editable: true,
                                                editor: <DropDownEditor options={expirityTypeMappedOptions} />,
                                                formatter: ({ value }) => <Formatters.DropDownFormatter options={expirityTypeMappedOptions} value={value} />
                                            },
                                            {
                                                key: "expirityDays",
                                                name: "Jours date",
                                                editable: true,
                                            },
                                            {
                                                key: "width",
                                                name: "Largeur",
                                                editable: true,
                                            },
                                            {
                                                key: "height",
                                                name: "Hauteur",
                                                editable: true,
                                            },
                                            {
                                                key: "depth",
                                                name: "Profondeur",
                                                editable: true,
                                            },
                                            {
                                                key: "volume",
                                                name: "Volume",
                                                editable: true,
                                            },
                                            {
                                                key: "volumeUnitId",
                                                name: "Unité",
                                                editable: true,
                                                editor: <DropDownUnitEditor />,
                                                formatter: ({ value }) => <div>{this.props.units[value]
                                                    && this.props.units[value].name}</div>
                                            },
                                            {
                                                key: "relativeUnitId",
                                                name: "Unité compar.",
                                                editable: true,
                                                editor: <DropDownUnitEditor />,
                                                formatter: ({ value }) => <div>{this.props.units[value]
                                                    && this.props.units[value].name}</div>
                                            },
                                            {
                                                key: "prices",
                                                name: "Nb prix",
                                                width: 80,
                                                sortable: false,
                                                formatter: ({ value }) => <div>{value.length}</div>
                                            },
                                            {
                                                key: "productSubFamilies",
                                                name: "Nb ss-fam",
                                                width: 80,
                                                sortable: false,
                                                formatter: ({ value }) => <div>{value.length}</div>
                                            },
                                            {
                                                key: "productSuppliers",
                                                name: "Nb sources",
                                                width: 80,
                                                sortable: false,
                                                formatter: ({ value }) => <div>{value.length}</div>
                                            },
                                            {
                                                key: "productVats",
                                                name: "Nb tva",
                                                width: 80,
                                                sortable: false,
                                                formatter: ({ value }) => <div>{value.length}</div>
                                            },
                                            {
                                                key: "productBarecodes",
                                                name: "Nb CAB",
                                                width: 80,
                                                sortable: false,
                                                formatter: ({ value }) => <div>{value.length}</div>
                                            },
                                            {
                                                key: "storeItemCustoms",
                                                name: 'Customisations',
                                                editable: false,
                                                formatter: ({ value }) => <div>{value.length}</div>
                                            }]} />}
                            </TabContent>
                            <TabContent for={tabPrices}>
                                {this.props.selectedTab === tabPrices
                                    && <ProductPriceGrid />}
                            </TabContent>
                            <TabContent for={tabSuppliers}>
                                {this.props.selectedTab === tabSuppliers
                                    && <ProductSupplierGrid />}
                            </TabContent>
                            <TabContent for={tabProductBarCodes}>
                                {this.props.selectedTab === tabProductBarCodes
                                    && <ProductBarcodeGrid />}
                            </TabContent>
                        </ConnectedTabs>
                        } />
            </div>
        );
    }
}



export default connect((state: ApplicationState) => ({
    ...state.product,
    filteredProducts: ProductStore.productFilteredSelector(state),
    units: state.unit.entities,
    account: state.account.currentUser.account,
    helpHtml: state.seed.seed.productHelp,
    selectedTab: state.tabs[tabName]
        ? state.tabs[tabName].selectedTab
        : undefined
} as ProductsOwnProps), { ...TabsStore.actionCreators, ...ProductStore.actionCreators } as any)(Products);