import * as React from 'react';
import { connect } from 'react-redux';

import Notifications from 'react-notification-system-redux';
import { ApplicationState } from '../store';

interface NotificatorProps {
    notifications: any;
}

const style = {
    NotificationItem: {
        DefaultStyle: {
        },
        success: { 
        },
        error: {
        }
    }
};

class Notificator extends React.Component<NotificatorProps, {}> {
    public render() {
        return (
            <Notifications
                notifications={this.props.notifications}
                style={style}
            />
        );
    }
}

export default connect(
    (state: ApplicationState) => ({ notifications: state.notifications }), null)(Notificator);