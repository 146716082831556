import { ApplicationState } from "../store/index"
import * as Api from "../api/api";
import { readToken } from "../security/tokenManager";

export const entityRefreshThreshold = 300;
export const authorizationCookie = 'access_token';

export const isClient = (): boolean => {
    return typeof window !== "undefined";
}

export const getDefaultHeaders = (state: ApplicationState): any => {
    return {
        Authorization: 'Bearer ' + readToken()
    };
}

export function filterCaseInsensitive(filter, row) {
    const id = filter.pivotId || filter.id;
    return (
        row[id] ?
            String(row[id].toLowerCase()).startsWith(filter.value.toLowerCase())
            :
            true
    );
}

export class Guid {
    static newGuid() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
            var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }
}