import * as React from "react";
import { TabLink, TabContent } from "react-tabs-redux";
import ConnectedTabs from "./ConnectedTabs";
import { scrollToWhen } from 'react-redux-scroll';
import { KnownAction } from "../store/Crude";
import { CSSProperties } from "react";
import { isClient } from "../utils/utils";

interface CrudeLayoutProps {
    name: string;
    flexDirection?: any;
    filter?: React.ReactNode;
    table: React.ReactNode;
    createForm: React.ReactNode;
    updateForm: React.ReactNode;
    help: React.ReactNode;
}

const tabHeadStyle: CSSProperties = { padding: "5px 20px" };

const isScrollAction = (action: KnownAction<any, any>, props, newState, oldState) => {
    return action.type === "SELECT_UPDATE_ENTITY"
        || action.type === "SELECT_CREATE_SOURCE";
};
    
const ScrollableToTabs =
    scrollToWhen(isScrollAction,
        null, null, null)("div");

export default class CrudeLayout extends React.Component<CrudeLayoutProps, {}> {
    get tabNamespace(): string {
        return this.props.name;
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <div style={{
                    height: "100%", width: "100%",
                    display: "flex", flexDirection: this.props.flexDirection || "column"
                }}>
                    {this.props.filter &&
                        <div style={{ order: 0, flex: "0 0 auto" }}>
                            {this.props.filter}
                        </div>}
                    <div style={{ order: 0, flex: "0 0 auto" }}>
                        {isClient() && this.props.table}
                    </div>
                    <div style={{ order: 1, flex: 1 }}>
                        <ScrollableToTabs />
                        <ConnectedTabs name={this.tabNamespace}>
                            <TabLink to="create">
                                <div style={tabHeadStyle}>Créer</div>
                            </TabLink>
                            <TabLink to="update">
                                <div style={tabHeadStyle}>Editer</div>
                            </TabLink>
                            <TabLink to="help">
                                <div style={tabHeadStyle}>Aide</div>
                            </TabLink>
                            <TabContent for="create">
                                <div style={{ padding: 10 }} className="create">
                                {this.props.createForm}
                                </div>
                            </TabContent>
                            <TabContent for="update">
                                <div style={{ padding: 10 }} className="update">
                                    {this.props.updateForm}
                                </div>
                            </TabContent>
                            <TabContent for="help">
                                <div style={{ padding: 10 }} className="help">
                                    {this.props.help}
                                </div>
                            </TabContent>
                        </ConnectedTabs>
                    </div>
                </div>
            </div>
            );
    }
}