import * as Api from "../api/api";
import Notifications from 'react-notification-system-redux';
import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import { AppThunkAction, ApplicationState } from './';
import { ReceiveSubmitCreateEntity, ReceiveSubmitUpdateEntity } from './Crude';
import { getDefaultHeaders } from "../utils/utils";
import * as CrudeStore from "./Crude";
import * as ProductStore from "./Product";
import * as FamilyStore from "./Family";
import * as SubFamilyStore from "./SubFamily";
import * as PromotionStore from "./Promotion";
import * as SupplierMainStore from "./SupplierMain";
import * as SupplierSubStore from "./SupplierSub";
import * as UnitStore from "./Unit";
import * as VatStore from "./Vat";
import * as CurrencyStore from "./Currency";
import * as PriceNameStore from "./PriceName";
import * as MenuStore from "./Menu";
import * as MenuCategoryStore from "./MenuCategory";

export interface SubmissionState {
    isLoadingEntities: boolean;
    isLoading: boolean;
    requestTime: number;
    submissions: Array<Api.SubmissionModel>;
    submissionStates: { [id: number]: RequestState };
    selectedId: number;
    denyState: {
        isOpen: boolean;
        selectedId: number;
        reasons: Array<string>;
        message: string;
    }
}

interface RequestState {
    isLoading: boolean;
    requestTime: number;
}

interface SetSubmissionEntitiesLoading { type: "SET_SUBMISSION_ENTITIES_LOADING"; payload: { value: boolean; } }

interface RequestSubmissions { type: "REQUEST_SUBMISSIONS"; payload: { requestTime: number; } }
interface ReceiveSubmissions {
    type: "RECEIVE_SUBMISSIONS";
    payload: { requestTime: number; submissions: Array<Api.SubmissionModel> };
    error?: any
}

interface RequestAcceptSubmission { type: "REQUEST_ACCEPT_SUBMISSION"; payload: { requestTime: number; id: number; } }
interface ReceiveAcceptSubmission {
    type: "RECEIVE_ACCEPT_SUBMISSION";
    payload: { requestTime: number; id: number; submission: Api.SubmissionModel; entity: any };
    error?: any
}

interface RequestDenySubmission { type: "REQUEST_DENY_SUBMISSION"; payload: { requestTime: number; id: number; } }
interface ReceiveDenySubmission {
    type: "RECEIVE_DENY_SUBMISSION";
    payload: { requestTime: number; id: number; submission: Api.SubmissionModel };
    error?: any
}

interface SelectSubmission { type: "SELECT_SUBMISSION"; payload: { id: number; } }

interface OpenDenyDialog { type: "OPEN_DENY_DIALOG", payload: { id: number } }
interface CloseDenyDialog { type: "CLOSE_DENY_DIALOG" }
interface UpdateDenyReasons { type: "UPDATE_DENY_REASONS", payload: { value: Array<string> } }
interface UpdateDenyMessage { type: "UPDATE_DENY_MESSAGE", payload: { value: string } }

type KnownAction = RequestSubmissions | ReceiveSubmissions
    | ReceiveSubmitCreateEntity | ReceiveSubmitUpdateEntity
    | RequestAcceptSubmission | ReceiveAcceptSubmission
    | RequestDenySubmission | ReceiveDenySubmission
    | SelectSubmission | SetSubmissionEntitiesLoading
    | OpenDenyDialog | CloseDenyDialog
    | UpdateDenyReasons | UpdateDenyMessage;

type CrudeAction<TEntity> = CrudeStore.RequestCreateEntity<TEntity>
    | CrudeStore.ReceiveCreateEntity<TEntity>
    | CrudeStore.RequestUpdateEntity<TEntity>
    | CrudeStore.ReceiveUpdateEntity<TEntity>;

interface AcceptSubmissionModel<TEntity> {
    submissionId?: number;
    model?: TEntity;
}

interface AcceptSubmissionResultModel<TEntity> {
    submission?: Api.SubmissionModel;
    model?: TEntity;
}

const requestAcceptSubmission = <TEntity>(
    entityKey: string,
    idSelector: (x: TEntity) => number,
    fetchApi: (params: { model: AcceptSubmissionModel<TEntity> }, options: any) => Promise<AcceptSubmissionResultModel<TEntity>>,
    requestTime: number,
    model: AcceptSubmissionModel<TEntity>,
    dispatch: (action: KnownAction | CrudeAction<TEntity>) => void,
    getState: () => ApplicationState): Promise<any> => {
    if (getState().submission.submissionStates[model.submissionId]
        && requestTime === getState().submission.submissionStates[model.submissionId].requestTime)
        return Promise.reject("Already did");

    let fetch = fetchApi({
        model: model
    }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(result => {
            dispatch({
                type: "RECEIVE_ACCEPT_SUBMISSION",
                payload: {
                    requestTime: requestTime,
                    id: model.submissionId,
                    submission: result.submission,
                    entity: result.model
                }
            });
            dispatch(Notifications.success({
                title: 'Succès',
                message: "La demande a bien été éxécuté",
                position: 'tr'
            }));
            if (result.submission.type === "Create") {
                dispatch({
                    type: "REQUEST_CREATE_ENTITY",
                    payload: {
                        key: entityKey,
                        requestTime: requestTime
                    }
                });
                dispatch({
                    type: "RECEIVE_CREATE_ENTITY",
                    payload: {
                        key: entityKey,
                        requestTime: requestTime,
                        entity: result.model,
                        id: idSelector(result.model)
                    }
                });
            }
            else if (result.submission.type === "Update") {
                dispatch({
                    type: "REQUEST_UPDATE_ENTITY",
                    payload: {
                        key: entityKey,
                        requestTime: requestTime,
                        id: idSelector(result.model)
                    }
                });
                dispatch({
                    type: "RECEIVE_UPDATE_ENTITY",
                    payload: {
                        key: entityKey,
                        requestTime: requestTime,
                        entity: result.model,
                        id: idSelector(result.model)
                    }
                });
            }
    
        })
        .catch(err => {
            dispatch({
                type: "RECEIVE_ACCEPT_SUBMISSION",
                payload: {
                    requestTime: requestTime,
                    id: model.submissionId,
                    submission: null,
                    entity: null
                },
                error: err
            });
            dispatch(Notifications.error({
                title: 'Erreur',
                message: 'Erreur lors de l\'acceptation de la demande',
                position: 'tr'
            }));
        });

    dispatch({ type: "REQUEST_ACCEPT_SUBMISSION", payload: { requestTime: requestTime, id: model.submissionId } });
    addTask(fetch);
    return fetch;
}

export const actionCreators = {
    requestSubmissions: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (requestTime === getState().submission.requestTime)
            return Promise.reject("Already did");

        let api = new Api.SubmissionApi();
        let fetch = api.getEntities({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(submissions => {
                dispatch({
                    type: "RECEIVE_SUBMISSIONS",
                    payload: { requestTime: requestTime, submissions: submissions }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_SUBMISSIONS",
                    payload: { requestTime: requestTime, submissions: [] },
                    error: err
                });
                dispatch(Notifications.error({
                    title: 'Erreur',
                    message: 'Erreur lors de la récupération',
                    position: 'tr'
                }));
            });

        dispatch({ type: "REQUEST_SUBMISSIONS", payload: { requestTime: requestTime } });
        addTask(fetch);
        return fetch;
    },
    requestAcceptSubmissionProduct: (requestTime: number, model: Api.AcceptSubmissionModelProductModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(ProductStore.key,
            ProductStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionProduct(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionFamily: (requestTime: number, model: Api.AcceptSubmissionModelFamilyModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(FamilyStore.key,
            FamilyStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionFamily(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionSubFamily: (requestTime: number, model: Api.AcceptSubmissionModelSubFamilyModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(SubFamilyStore.key,
            SubFamilyStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionSubFamily(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionSupplierMain: (requestTime: number, model: Api.AcceptSubmissionModelSupplierModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(SupplierMainStore.key,
            SupplierMainStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionSupplierMain(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionSupplierSub: (requestTime: number, model: Api.AcceptSubmissionModelSupplierModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(SupplierSubStore.key,
            SupplierSubStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionSupplierSub(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionUnit: (requestTime: number, model: Api.AcceptSubmissionModelUnitModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(UnitStore.key,
            UnitStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionUnit(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionPromotion: (requestTime: number, model: Api.AcceptSubmissionModelPromotionModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(PromotionStore.key,
            PromotionStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionPromotion(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionMenu: (requestTime: number, model: Api.AcceptSubmissionModelMenuModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(MenuStore.key,
            MenuStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionMenu(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionVat: (requestTime: number, model: Api.AcceptSubmissionModelVatModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(VatStore.key,
            VatStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionVat(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionCurrency: (requestTime: number, model: Api.AcceptSubmissionModelCurrencyModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(CurrencyStore.key,
            CurrencyStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionCurrency(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionMenuCategory: (requestTime: number, model: Api.AcceptSubmissionModelMenuCategoryModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(MenuCategoryStore.key,
            MenuCategoryStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionMenuCategory(params, options),
            requestTime, model, dispatch, getState);
    },
    requestAcceptSubmissionPriceName: (requestTime: number, model: Api.AcceptSubmissionModelPriceNameModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.SubmissionApi();
        return requestAcceptSubmission(PriceNameStore.key,
            PriceNameStore.configuration.idSelector,
            (params, options) => api.acceptSubmissionPriceName(params, options),
            requestTime, model, dispatch, getState);
    },
    requestDenySubmission: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let id = getState().submission.denyState.selectedId;
        if (getState().submission.submissionStates[id]
            && requestTime === getState().submission.submissionStates[id].requestTime)
            return Promise.reject("Already did");

        let denyState = getState().submission.denyState;
        let api = new Api.SubmissionApi();
        let fetch = api.denySubmission({
            model: {
                submissionId: id,
                message: denyState.message,
                reasons: denyState.reasons
            }
        }, { credentials: "same-origin", headers: getDefaultHeaders(getState()) })
            .then(submission => {
                dispatch({
                    type: "RECEIVE_DENY_SUBMISSION",
                    payload: { requestTime: requestTime, id: id, submission: submission }
                });
            })
            .catch(err => {
                dispatch({
                    type: "RECEIVE_DENY_SUBMISSION",
                    payload: { requestTime: requestTime, id: id, submission: null },
                    error: err
                });
                dispatch(Notifications.error({
                    title: 'Erreur',
                    message: 'Erreur lors de l\'acceptation de la demande',
                    position: 'tr'
                }));
            });

        dispatch({ type: "REQUEST_DENY_SUBMISSION", payload: { requestTime: requestTime, id: id } });
        addTask(fetch);
        return fetch;
    },
    selectSubmission: (id: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let submission = getState().submission.submissions
            .find(x => x.submissionId === id);

        let requestTime = new Date().getTime();
        if (submission.entityType === "Product"
            && (!getState().product.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().product.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            ProductStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "Family"
            && (!getState().family.requestTime
            || 1000 * 60 * 10 < (requestTime - getState().family.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            FamilyStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "SubFamily"
            && (!getState().subFamily.requestTime
            || 1000 * 60 * 10 < (requestTime - getState().subFamily.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            SubFamilyStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "SupplierMain"
            && (!getState().supplierMain.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().supplierMain.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            SupplierMainStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "SupplierSub"
            && (!getState().supplierSub.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().supplierSub.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            SupplierSubStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "PriceName"
            && (!getState().priceName.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().priceName.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            PriceNameStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "Currency"
            && (!getState().currency.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().currency.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            CurrencyStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "Menu"
            && (!getState().menu.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().menu.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            MenuStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "MenuCategory"
            && (!getState().menuCategory.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().menuCategory.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            MenuCategoryStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "Promotion"
            && (!getState().promotion.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().promotion.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            PromotionStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "Unit"
            && (!getState().unit.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().unit.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            UnitStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else if (submission.entityType === "Vat"
            && (!getState().vat.requestTime
                || 1000 * 60 * 10 < (requestTime - getState().vat.requestTime))) {
            dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: true } });
            VatStore.requestEntities(requestTime, dispatch as any, getState)
                .then(() => {
                    dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                })
                .catch(() => {
                    dispatch({ type: "SET_SUBMISSION_ENTITIES_LOADING", payload: { value: false } });
                });
        }
        else {
            dispatch({ type: "SELECT_SUBMISSION", payload: { id: id } });
        }
    },
    openDenyDialog: (submissionId: number) => <OpenDenyDialog>{
        type: "OPEN_DENY_DIALOG", payload: { id: submissionId } },
    closeDenyDialog: () => <CloseDenyDialog>{ type: "CLOSE_DENY_DIALOG" },
    updateDenyReasons: (value: Array<string>) => <UpdateDenyReasons>{
        type: "UPDATE_DENY_REASONS", payload: { value: value }
    },
    updateDenyMessage: (value: string) => <UpdateDenyMessage>{
        type: "UPDATE_DENY_MESSAGE",
        payload: { value: value }
    }
}

const unloadedState: SubmissionState = {
    isLoading: false,
    requestTime: 0,
    submissions: [],
    submissionStates: {},
    selectedId: 0,
    isLoadingEntities: false,
    denyState: {
        isOpen: false,
        selectedId: 0,
        reasons: [],
        message: ""
    }
};

export const reducer: Reducer<SubmissionState> = (state: SubmissionState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_SUBMISSIONS":
            return {
                ...state,
                isLoadig: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_SUBMISSIONS":
            return {
                ...state,
                isLoadig: false,
                submissions: action.payload.submissions
            };
        case "RECEIVE_SUBMIT_CREATE_ENTITY":
            return {
                ...state,
                submissions: action.error
                    ? state.submissions
                    : state.submissions.concat(action.payload.submission)
            };
        case "RECEIVE_SUBMIT_UPDATE_ENTITY":
            return {
                ...state,
                submissions: action.error
                    ? state.submissions
                    : state.submissions.concat(action.payload.submission)
            };
        case "REQUEST_ACCEPT_SUBMISSION":
            return {
                ...state,
                submissionStates: {
                    ...state.submissionStates,
                    [action.payload.id]: {
                        ...state.submissionStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_ACCEPT_SUBMISSION":
            if (state.submissionStates[action.payload.id]
                && state.submissionStates[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                selectedId: null,
                submissionStates: {
                    ...state.submissionStates,
                    [action.payload.id]: {
                        ...state.submissionStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                },
                submissions: action.error
                    ? state.submissions
                    : state.submissions
                        .map(x => x.submissionId === action.payload.id ? action.payload.submission : x)
            };
        case "REQUEST_DENY_SUBMISSION":
            return {
                ...state,
                submissionStates: {
                    ...state.submissionStates,
                    [action.payload.id]: {
                        ...state.submissionStates[action.payload.id],
                        isLoading: true,
                        requestTime: action.payload.requestTime
                    }
                }
            };
        case "RECEIVE_DENY_SUBMISSION":
            if (state.submissionStates[action.payload.id]
                && state.submissionStates[action.payload.id].requestTime !== action.payload.requestTime)
                return state;

            return {
                ...state,
                submissionStates: {
                    ...state.submissionStates,
                    [action.payload.id]: {
                        ...state.submissionStates[action.payload.id],
                        isLoading: false,
                        requestTime: action.payload.requestTime
                    }
                },
                submissions: action.error
                    ? state.submissions
                    : state.submissions
                        .map(x => x.submissionId === action.payload.id
                            ? action.payload.submission
                            : x),
                denyState: action.error
                    ? state.denyState
                    : {
                        ...state.denyState,
                        isOpen: false
                    }
            };
        case "SELECT_SUBMISSION":
            return {
                ...state,
                selectedId: action.payload.id
            };
        case "SET_SUBMISSION_ENTITIES_LOADING":
            return {
                ...state,
                isLoadingEntities: action.payload.value
            };
        case "OPEN_DENY_DIALOG":
            return {
                ...state,
                denyState: {
                    ...state.denyState,
                    selectedId: action.payload.id,
                    isOpen: true,
                    message: "",
                    reasons: []
                }
            };
        case "CLOSE_DENY_DIALOG":
            return {
                ...state,
                denyState: {
                    ...state.denyState,
                    isOpen: false,
                    message: "",
                    reasons: []
                }
            };
        case "UPDATE_DENY_MESSAGE":
            return {
                ...state,
                denyState: {
                    ...state.denyState,
                    message: action.payload.value
                }
            };
        case "UPDATE_DENY_REASONS":
            return {
                ...state,
                denyState: {
                    ...state.denyState,
                    reasons: action.payload.value
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};
