import * as Api from "../api/api";
import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import { sentMessage } from "../signalR/connectedUsers";

export interface UserHubState {
    isConnected: boolean;
    isOpen: boolean;
    hasUnseenMessage: boolean;
    connectedUsers: Array<Api.HubUserModel>;
    messages: Array<Api.UserMessageModel>;
    message: string;
}

interface UserHubConnectionOpened {
    type: "USERHUB_CONNECTION_OPENED",
}

interface UserHubConnectionClosed {
    type: "USERHUB_CONNECTION_CLOSED",
}

interface ReceiveConnectedUsers {
    type: "RECEIVE_CONNECTED_USERS",
    payload: { connectedUsers: Array<Api.HubUserModel> }
}

interface ReceiveNewUserMessage {
    type: "RECEIVE_NEW_USER_MESSAGE",
    payload: { message: Api.UserMessageModel }
}

interface UserHubUpdateMessage {
    type: "USERHUB_UPDATE_MESSAGE",
    payload: { value: string }
}

interface UserHubSendMessage {
    type: "USERHUB_SEND_MESSAGE",
}
interface UserHubUpdateIsOpen {
    type: "USERHUB_UPDATE_ISOPEN";
    payload: { value: boolean }
}

export type KnownAction = ReceiveConnectedUsers
    | UserHubConnectionOpened | UserHubConnectionClosed
    | ReceiveNewUserMessage | UserHubUpdateMessage
    | UserHubSendMessage | UserHubUpdateIsOpen;

//No need I think
export const actionCreators = {
    updateMessage: (value: string) => <UserHubUpdateMessage>{
        type: "USERHUB_UPDATE_MESSAGE",
        payload: { value: value }
    },
    sendMessage: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        sentMessage(getState().userHub.message);
        dispatch({ type: "USERHUB_SEND_MESSAGE" });
    },
    updateIsOpen: (value: boolean) => <UserHubUpdateIsOpen>{
        type: "USERHUB_UPDATE_ISOPEN",
        payload: { value: value }
    }
};


const unloadedState: UserHubState = {
    isConnected: false,
    isOpen: false,
    hasUnseenMessage: false,
    connectedUsers: [],
    messages: [],
    message: "",
};

export const reducer: Reducer<UserHubState> = (state: UserHubState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "RECEIVE_CONNECTED_USERS":
            return {
                ...state,
                connectedUsers: action.payload.connectedUsers
            };
        case "USERHUB_CONNECTION_OPENED":
            return {
                ...state,
                isConnected: true
            };
        case "USERHUB_CONNECTION_CLOSED":
            return {
                ...state,
                isConnected: false
            };
        case "RECEIVE_NEW_USER_MESSAGE":
            return {
                ...state,
                messages: state.messages
                    .concat([action.payload.message]),
                hasUnseenMessage: !state.isOpen
            };
        case "USERHUB_UPDATE_MESSAGE":
            return {
                ...state,
                message: action.payload.value
            };
        case "USERHUB_SEND_MESSAGE":
            return {
                ...state,
                message: ""
            };
        case "USERHUB_UPDATE_ISOPEN":
            return {
                ...state,
                isOpen: action.payload.value,
                hasUnseenMessage: action.payload.value
                    ? false
                    : state.hasUnseenMessage
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};
