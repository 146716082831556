import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as TagStore from '../store/Tag';
import Autocomplete from 'react-autocomplete';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';

type StoreItemTagsFieldProps = TagStore.TagState
    & StoreItemTagsFieldOwnProps
    & StoreItemTagsFieldExternalProps
    & typeof TagStore.actionCreators
    ;

interface StoreItemTagsFieldExternalProps {
    value: Array<Api.StoreItemTagModel>;
    onChange: (value: Array<Api.StoreItemTagModel>) => void;
}

interface StoreItemTagsFieldOwnProps {

}

interface StoreItemTagsFieldState {
    value: string;
}

class StoreItemTagsField extends React.Component<StoreItemTagsFieldProps, StoreItemTagsFieldState> {

    constructor(props: StoreItemTagsFieldProps) {
        super(props);
        this.state = {
            value: ""
        };
    }

    public render() {
        return (
            <div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ flex: "0 0 150px" }}>
                        <Autocomplete value={this.state.value}
                            renderItem={(item, isHighlighted) =>
                                <div style={{ background: isHighlighted ? 'lightgray' : 'white' }}>
                                    {item.value}
                                </div>
                            }
                            menuStyle={{
                                borderRadius: '3px',
                                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                                background: 'rgba(255, 255, 255, 0.9)',
                                padding: '2px 0',
                                fontSize: '90%',
                                position: 'fixed',
                                overflow: 'auto',
                                maxHeight: 200,
                                zIndex: 999
                            }}
                            renderInput={(props) => <input {...props} className="form-control" />}
                            getItemValue={(item) => item.value}
                            items={_.values(this.props.entities).filter(x => x.value.includes(this.state.value || ""))}
                            onChange={(e) => this.setState({ value: e.target.value })}
                            onSelect={(val) => this.setState({ value: val })} />
                    </div>
                    <div style={{ flex: "0 0 auto" }}>
                        <button className="btn btn-sm"
                            type={"button"}
                            onClick={(e) => {
                                let tagFound = _.find(this.props.entities, x => x.value === this.state.value);
                                if (tagFound) {
                                    if (this.props.value && this.props.value.some(x => x.tagId === tagFound.tagId)) {
                                        return;
                                    }
                                    this.props.onChange((this.props.value || []).concat([{ tagId: tagFound.tagId }]));
                                } else {
                                    (this.props.requestCreateTag(new Date().getTime(), { value: this.state.value }) as any as Promise<Api.TagModel>)
                                        .then(tag => {
                                            this.props.onChange((this.props.value || []).concat([{ tagId: tag.tagId }]));
                                        });
                                }
                            }}>Ajouter</button>
                    </div>
                </div>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center", flexWrap: "wrap" }}>
                    {(this.props.value || []).map(x => <div key={x.tagId}
                        style={{
                            border: "1px solid blue",
                            borderRadius: 5,
                            padding: "3px 6px",
                            minWidth: 80,
                            cursor: "pointer",
                            marginRight: 6, marginBottom: 3
                        }}
                        onClick={(e) => this.props.onChange(this.props.value.filter(y => y.tagId !== x.tagId))}>
                        {this.props.entities[x.tagId].value}
                    </div>)}
                </div>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.tag
} as StoreItemTagsFieldOwnProps), {
        ...TagStore.actionCreators
    })(StoreItemTagsField as any) as any as React.ComponentClass<StoreItemTagsFieldExternalProps>;