import * as React from 'react';
import * as ReactSelect from 'rc-select';

type SelectProps = {
    style?: any;
    className?: string;
    options: Array<Option>;
    multiple?: boolean;
    disabled?: boolean;
    disableSearch?: boolean;
    value: any | Array<any>;
    onChange: (value: any | Array<any>) => void;
    optionComponent?: (opt: Option) => JSX.Element | string;
    getInputElement?: () => JSX.Element;
    dropdownRender?: (menu: any, menuProps: any) => JSX.Element;
}

export interface Option {
    label: string;
    value: any;
    disabled?: boolean;
}

const defaultStyle = {
    zIndex: 9999
}

export default class Select extends React.Component<SelectProps, {}> {
    private dropDownStyle = { width: "100%" };

    public constructor(props) {
        super(props);
    }

    updateStyle(props: SelectProps) {
        let style = {
            ...defaultStyle,
            ...this.props.style
        };
        this.dropDownStyle = style;
    }

    componentWillMount() {
        this.updateStyle(this.props);
    }

    componentWillReceiveProps(nextProps: SelectProps) {
        if (this.props.style !== nextProps.style)
            this.updateStyle(nextProps);
    }

    get selectedOptions(): Array<Option> {
        let values = this.props.value as Array<number>;
        if (Array.isArray(values))
            return this.props.options.filter(opt => values.some(va => va === opt.value));

        return this.props.options.filter(opt => opt.value === this.props.value);
    }

    findOptions(labels: Array<string>): Array<Option> {
        return this.props.options.filter(opt => labels.some(lb => lb === opt.label));
    }

    handleChange(label: string | Array<string>) {
        let labels = label as Array<string>;

        if (Array.isArray(labels))
            this.props.onChange(this.findOptions(labels).map(opt => opt.value));
        else {
            this.props.onChange(this.findOptions([label as string])[0].value);
        }
    }

    get mergedProps(): any {
        return {
            ...(this.props.getInputElement
                ? {
                    getInputElement: this.props.getInputElement
                }
                : {}),
            ...(this.props.dropdownRender
                ? {
                    dropdownRender: this.props.dropdownRender
                }
                : {})
        };
    }

    public render() {
        return <ReactSelect.default
            {...this.mergedProps}
            dropDo
            className={this.props.className}
            disabled={this.props.disabled}
            dropdownStyle={this.dropDownStyle}
            dropdownMenuStyle={{maxHeight: "300px"}}
            multiple={this.props.multiple || false}
            showSearch={this.props.disableSearch ? false : true}
            value={!this.props.multiple
                ? (this.selectedOptions.length !== 0 ? this.selectedOptions[0].label : null)
                : this.selectedOptions.map(opt => opt.label)}
            onChange={(label: string | Array<string>) => this.handleChange(label)}>
            {this.props.options.map((opt, index) =>
                <ReactSelect.Option
                    disabled={opt.disabled}
                    key={`${index}-${opt.label}`}
                    value={opt.label}>
                    {this.props.optionComponent
                        ? this.props.optionComponent(opt)
                        : <div style={{ display: "inline-block" }}>
                            {opt.label}
                        </div>}
                </ReactSelect.Option>
                )}
        </ReactSelect.default>
    }
}