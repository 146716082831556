import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import {
    Field,
    WrappedFieldArrayProps
} from 'redux-form';
import {
    menuCategoryStoreValidation,
} from "../utils/validation";
import { menuCategoryStoreHelp } from "../utils/entityHelp";
import { FieldHelp } from './FieldHelp';
import { DataType } from './Home';

type MenuCategoryStoresProps = MenuCategoryStoresOwnProps
    & WrappedFieldArrayProps<Api.MenuCategoryStoreModel>;

export interface MenuCategoryStoresOwnProps {
    menuCategoryId: number;
    menuCategoryStores: Array<Api.MenuCategoryStoreModel>;
    canAccessAllStores: boolean;
    allowedStores: Array<number>;
    stores: { [id: number]: Api.StoreModel };
    disabled?: boolean;
}

export default class MenuCategoryStores extends React.Component<MenuCategoryStoresProps, {}> {

    public render() {
        return (
            <div>
                {this.props.fields.map((member, index) => <div key={index}
                    style={{
                        marginBottom: 5,
                        display: this.props.menuCategoryStores
                            && this.props.menuCategoryStores[index]
                            && (this.props.canAccessAllStores
                                || this.props.allowedStores
                            .some(x => x === this.props.menuCategoryStores[index].storeId))
                            ? "block"
                            : "none"
                    }}>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: 1 }}>
                            <div style={{ marginLeft: 6 }}>
                                <FieldHelp help={menuCategoryStoreHelp()["storeId"]}>
                                    <Field className="form-control"
                                        validate={menuCategoryStoreValidation["storeId"]
                                            .concat(this.props.canAccessAllStores
                                                ? []
                                                : [FormField.required])}
                                        disabled={this.props.disabled}
                                        name={`${member}.storeId`}
                                        component={FormField.getSelectField(
                                            (this.props.canAccessAllStores
                                                ? [{ label: "--- TOUS ---", value: null }]
                                                : []).concat(
                                                    _.values(this.props.stores)
                                                        .filter(x => this.props.allowedStores.some(y => y === x.storeId))
                                                        .map(x => ({ label: x.name, value: x.storeId }))))} />
                                </FieldHelp>
                            </div>
                        </div>
                        <div style={{ flex: "0 0 auto" }}>
                            <button
                                type="button"
                                disabled={this.props.disabled}
                                style={{ marginLeft: 5 }}
                                className="btn btn-secondary btn-sm"
                                title="Remove"
                                onClick={() => this.props.fields.remove(index)}>
                                <i className="glyphicon glyphicon-remove"></i>
                            </button>
                        </div>
                    </div>
                </div>)}
                <FormField.FieldErrors {...this.props.meta} />
                <div style={{ textAlign: "center" }}>
                    <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                        disabled={this.props.disabled}
                        onClick={() => this.props.fields.push({
                            menuCategoryId: this.props.menuCategoryId,
                            hmiVisibility: "Visible",
                            smartPhoneClientVisibility: "Visible",
                            storeId: this.props.canAccessAllStores
                                ? null
                                : (this.props.allowedStores.length
                                    ? this.props.allowedStores[0]
                                    : 0),
                        } as Api.MenuCategoryStoreModel)}>
                        <i className="glyphicon glyphicon-arrow-down"></i> Ajouter <i className="glyphicon glyphicon-arrow-down"></i>
                    </button>
                </div>
            </div>
        );
    }
}