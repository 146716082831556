import * as _ from "lodash";
import * as Api from "../api/api";

export interface ProductPriceInfo {
    price: number;
    unitPrice: number;
    noVatPrice: number;
    deliveryFees: number;
    noVatBuyPrice: number;
    noVatUnitBuyPrice: number;
    grossMargin: number;
    markup: number;
}

export interface ProductData {
    productSuppliers: Array<Api.ProductSupplierModel>;
    productVats: Array<Api.ProductVatModel>;
    volume: number;
    volumeUnitId: number;
    relativeUnitId: number;
    defaultSupplierId?: number;
}

const defaultPriceInfo: ProductPriceInfo = {
    deliveryFees: 0, grossMargin: 0, markup: 0,
    noVatBuyPrice: 0, noVatPrice: 0, noVatUnitBuyPrice: 0,
    price: 0, unitPrice: 0
};

export class ProductPriceCalculator {
    private units: { [id: number]: Api.UnitModel };
    private vats: { [id: number]: Api.VatModel };
    private suppliers: { [id: number]: Api.SupplierModel };
    private currencies: { [id: number]: Api.CurrencyModel };

    constructor(units: { [id: number]: Api.UnitModel },
        vats: { [id: number]: Api.VatModel },
        suppliers: { [id: number]: Api.SupplierModel },
        currencies: { [id: number]: Api.CurrencyModel }) {
        this.updateSeed(units, vats, suppliers, currencies);
    }

    public updateSeed(units: { [id: number]: Api.UnitModel },
        vats: { [id: number]: Api.VatModel },
        suppliers: { [id: number]: Api.SupplierModel },
        currencies: { [id: number]: Api.CurrencyModel }) {
        this.units = units;
        this.vats = vats;
        this.suppliers = suppliers;
        this.currencies = currencies;
    }

    private getConversion(fromUnitId: number, toUnitId: number): number {
        let conversion = this.units[fromUnitId].unitConversionsFrom
            .find(x => x.unitToId === toUnitId);

        return conversion ? conversion.value : 1;
    }

    public calculate(product: ProductData,
        price: Api.PriceModel,
        productSupplier?: Api.ProductSupplierModel): ProductPriceInfo {
        let prodSupplierToUse = productSupplier
            || _.sortBy(product.productSuppliers,
                x => x.supplierMainId !== product.defaultSupplierId
                    || x.supplierSubId !== product.defaultSupplierId)[0];

        if (!prodSupplierToUse
            || product.productVats.length === 0 
            || product.productVats[0].vatId === undefined
            || product.relativeUnitId === undefined 
            || product.volume == undefined 
            || product.volumeUnitId == undefined
            || !price)
            return defaultPriceInfo;

        let vat = this.vats[product.productVats[0].vatId];

        let deliveryFeeVar = 0;
        if (this.suppliers[prodSupplierToUse.supplierSubId
            || prodSupplierToUse.supplierMainId]) {
            deliveryFeeVar = this.suppliers[prodSupplierToUse.supplierSubId
                || prodSupplierToUse.supplierMainId].deliveryFeeVar;
        } else {
            console.error(`Could not find supplier id '${prodSupplierToUse.supplierSubId || prodSupplierToUse.supplierMainId}'`)
        }

        let unitPrice = price.value
            * (this.getConversion(product.volumeUnitId, product.relativeUnitId)
                / product.volume);
        let noVatPrice = price.value / (1 + (vat.value * 0.01));
        let deliveryFees = (prodSupplierToUse.packWeight * deliveryFeeVar)
            / prodSupplierToUse.deliveryCondition;
        let noVatBuyPrice = prodSupplierToUse.buyPriceNoVat;
        let noVatUnitBuyPrice = noVatBuyPrice / prodSupplierToUse.packCondition;
        let grossMargin = noVatPrice - noVatUnitBuyPrice - deliveryFees;
        let markup = grossMargin / noVatPrice * 100;

        return {
            deliveryFees: deliveryFees,
            grossMargin: grossMargin,
            markup: markup,
            noVatBuyPrice: noVatBuyPrice,
            noVatPrice: noVatPrice,
            noVatUnitBuyPrice: noVatUnitBuyPrice,
            unitPrice: unitPrice,
            price: price.value
        };
    }
}