import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import * as CrudeStore from '../store/Crude';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector, FieldArray
} from 'redux-form';
import EntityCustomizationsField, { EntityCustomizationsOwnProps } from "./EntityCustomizationsField";
import {
    menuValidation,
} from "../utils/validation";
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import { Prices, PricesOwnProps } from './PricesForm';
import { diff } from 'deep-object-diff';
import { selectedStores } from '../store/Account';
import MenuStores, { MenuStoresOwnProps } from "./MenuStores";
import MenuCategoryMenus, { MenuCategoryMenusOwnProps } from './MenuCategoryMenus';

type MenuFormProps = InjectedFormProps
    & FormField.FormProps
    & MenuFormExternalProps
    & MenuFormOwnProps;

interface MenuFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.MenuModel) => any;
    resetDefaultPriceValue: (form: string, member: string, value: number) => void;
}

interface MenuFormOwnProps {
    entities: { [id: number]: Api.MenuModel };
    languages: { [id: number]: Api.LanguageModel }
    storeGroups: { [id: number]: Api.StoreGroupModel }
    stores: { [id: number]: Api.StoreModel }
    currencies: { [id: number]: Api.CurrencyModel }
    priceNames: { [id: number]: Api.PriceNameModel }
    menuCategories: { [id: number]: Api.MenuCategoryModel }
    allowedStores: Array<number>;
    storeItemId: number;
    disabled?: boolean;
    defaultCurrencyCode: string;
    formType: CrudeStore.FormType;
    account: Api.AccountModel;
    prices: Array<Api.PriceModel>;
    menuCustoms: Array<Api.MenuCustomModel>;
    menuStores: Array<Api.MenuStoreModel>;
    fieldInformations?: { [props: string]: string };
}

class MenuForm extends React.Component<MenuFormProps, {}> {
    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get availablePriceNames(): Array<{ label: string, value: number }> {
        return _.values(this.props.priceNames)
            .filter(x => this.props.prices.some(y => y.priceNameId === x.priceNameId))
            .map(x => ({ label: x.name, value: x.priceNameId }));
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create"
            && this.props.menuStores.some(x => !x.storeId && x.hmiVisibility === "Visible");
    }

    public render() {
        return (
            <FormField.BaseForm className="" {...this.props}>
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                        textAlign: "center",
                        border: "1px solid black"
                    }}>Données globales</legend>
                    <div className="form-horizontal">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Nom:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("name")}>
                                <Field className="form-control"
                                    validate={menuValidation["name"]}
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    name="name"
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Image:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("imageId")}>
                                <Field className="form-control"
                                    fieldKey={"menuImagineId-" + this.props.formType}
                                    validate={menuValidation["imageId"]}
                                    name="imageId"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    acceptProp={".png, .jpg, .jpeg"}
                                    component={FormField.ImageField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Ordre:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("order")}>
                                <Field className="form-control"
                                    validate={menuValidation["order"]}
                                    name="order"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Categories:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("menuCategoryMenus")}>
                                <FieldArray
                                    name="menuCategoryMenus"
                                    component={MenuCategoryMenus}
                                    validate={menuValidation["menuCategoryMenus"]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        menuCategories: this.props.menuCategories,
                                        allowedStores: this.props.allowedStores,
                                        disabled: this.props.disabled
                                            || this.globalFieldDisabled
                                    } as MenuCategoryMenusOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Tarif:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("prices")}>
                                <FieldArray
                                    name="prices"
                                    component={Prices}
                                    validate={menuValidation["prices"]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        prices: this.props.prices,
                                        stores: this.props.stores,
                                        priceNames: this.props.priceNames,
                                        currencies: this.props.currencies,
                                        disabled: this.props.disabled,
                                        allowedStores: this.props.allowedStores,
                                        dataType: "Global",
                                        onPriceNameIdChange: (member, value) => this.props.resetDefaultPriceValue(this.props.form, member, value),
                                        defaultCurrencyCode: this.props.defaultCurrencyCode
                                    } as PricesOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Tarif par défaut:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("defaultPriceNameId")}>
                                <Field className="form-control"
                                    validate={menuValidation["defaultPriceNameId"]}
                                    name="defaultPriceNameId"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.getSelectField(this.availablePriceNames)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Customisations:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("menuCustoms")}>
                                <FieldArray
                                    validate={[]}
                                    name="menuCustoms"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={EntityCustomizationsField}
                                    props={{
                                        languages: this.props.languages,
                                        storeGroups: this.props.storeGroups,
                                        entityCustoms: this.props.menuCustoms,
                                        dataType: "Global",
                                        allowedStoreGroup: this.props.allowedStores,
                                        disabled: this.props.disabled
                                    } as EntityCustomizationsOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Restriction d'âge"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("ageRestriction")}>
                                <Field
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    name="ageRestriction"
                                    component={FormField.CheckBoxField} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                        textAlign: "center",
                        border: "1px solid black"
                    }}>Données par magasin</legend>
                    <div className="form-horizontal">

                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Tarif:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("prices")}>
                                <FieldArray
                                    name="prices"
                                    component={Prices}
                                    validate={menuValidation["prices"]}
                                    props={{
                                        storeItemId: this.props.storeItemId,
                                        prices: this.props.prices,
                                        stores: this.props.stores,
                                        priceNames: this.props.priceNames,
                                        currencies: this.props.currencies,
                                        disabled: this.props.disabled,
                                        allowedStores: this.props.allowedStores,
                                        dataType: "Specific",
                                        defaultCurrencyCode: this.props.defaultCurrencyCode,
                                        onPriceNameIdChange: (member, value) => this.props.resetDefaultPriceValue(this.props.form, member, value)
                                    } as PricesOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Customisations:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("menuCustoms")}>
                                <FieldArray
                                    validate={[]}
                                    name="menuCustoms"
                                    component={EntityCustomizationsField}
                                    props={{
                                        languages: this.props.languages,
                                        storeGroups: this.props.storeGroups,
                                        entityCustoms: this.props.menuCustoms,
                                        dataType: "Specific",
                                        allowedStoreGroup: this.props.allowedStores,
                                        disabled: this.props.disabled
                                    } as EntityCustomizationsOwnProps} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-3 control-label ">{"Magasins:"}</label>
                            <div className="col-sm-9" style={this.getFieldStyle("menuStores")}>
                            <FieldArray
                                    validate={menuValidation["menuStores"].concat(
                                        canCrude(this.props.account)
                                            ? []
                                            : [FormField.requiredOne])}
                                    name="menuStores"
                                    component={MenuStores}
                                    props={{
                                        menuStores: this.props.menuStores,
                                        allowedStores: this.props.allowedStores,
                                        canAccessAllStores: canAccessAllStores(this.props.account),
                                        disabled: this.props.disabled,
                                        menuId: this.props.storeItemId,
                                        stores: this.props.stores
                                    } as MenuStoresOwnProps} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType === "Update"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = reduxForm({
    form: 'createMenu',
    destroyOnUnmount: false,
    enableReinitialize: true
})(MenuForm) as any;
const createSelector = formValueSelector('createMenu')

let updateForm = reduxForm({
    form: 'updateMenu',
    destroyOnUnmount: false,
    enableReinitialize: true
})(MenuForm) as any;
const updateSelector = formValueSelector('updateMenu')

let submissionForm = reduxForm({
    form: 'submissionMenu',
    destroyOnUnmount: false,
    enableReinitialize: true
})(MenuForm) as any;
const submissionSelector = formValueSelector('submissionMenu')

export const CreateMenuForm = connect((state: ApplicationState) => {
    return {
        entities: state.menu.entities,
        storeItemId: 0,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        currencies: state.currency.entities,
        priceNames: state.priceName.entities,
        menuCategories: state.menuCategory.entities,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        defaultCurrencyCode: state.seed.seed.lgapSettings.defaultCurrencyCode,
        formType: "Create",
        account: state.account.currentUser.account,
        prices: createSelector(state, "prices") || [],
        menuStores: createSelector(state, "menuStores") || [],
        menuCustoms: createSelector(state, "menuCustoms") || [],
    } as MenuFormOwnProps;
})(createForm as any) as any as React.ComponentClass<MenuFormExternalProps>;

export const UpdateMenuForm = connect((state: ApplicationState) => {
    return {
        entities: state.menu.entities,
        storeItemId: state.menu.selectedEntityId,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        currencies: state.currency.entities,
        priceNames: state.priceName.entities,
        menuCategories: state.menuCategory.entities,
        defaultCurrencyCode: state.seed.seed.lgapSettings.defaultCurrencyCode,
        formType: "Update",
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        disabled: !((state.menu.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
                .some(x => x.menuId === state.menu.selectedEntityId)),
        initialValues: state.menu.entities[state.menu.selectedEntityId],
        account: state.account.currentUser.account,
        prices: updateSelector(state, "prices") || [],
        menuStores: updateSelector(state, "menuStores") || [],
        menuCustoms: updateSelector(state, "menuCustoms") || [],
    } as MenuFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<MenuFormExternalProps>;

export const SubmissionMenuForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        entities: state.menu.entities,
        storeItemId: submission.menuId,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        currencies: state.currency.entities,
        priceNames: state.priceName.entities,
        menuCategories: state.menuCategory.entities,
        defaultCurrencyCode: state.seed.seed.lgapSettings.defaultCurrencyCode,
        formType: "Submission",
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        initialValues: JSON.parse(submission.content),
        account: state.account.currentUser.account,
        prices: submissionSelector(state, "prices") || [],
        menuStores: submissionSelector(state, "menuStores") || [],
        menuCustoms: submissionSelector(state, "menuCustoms") || [],
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.menu.entities[submission.menuId])
            : {}
    } as MenuFormOwnProps;
})(submissionForm as any) as any as React.ComponentClass<MenuFormExternalProps>;