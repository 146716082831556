/**
 * Neovendis API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

import * as querystring from "querystring";
import * as url from "url";

import * as assign from "core-js/library/fn/object/assign";

interface Dictionary<T> { [index: string]: T; }
export interface FetchAPI { (url: string, init?: any): Promise<any>; }

//const BASE_PATH = "http://lgap.rzf.eu:8038/".replace(/\/+$/, "");
const BASE_PATH = "http://lgap.ximiti.eu/".replace(/\/+$/, "");
//const BASE_PATH = "http://192.168.0.25:8081".replace(/\/+$/, "");
//const BASE_PATH = "https://lgap.724newfood.ch/".replace(/\/+$/, "");

export interface FetchArgs {
    url: string;
    options: any;
}

const isomorphicFetch = fetch;

export class BaseAPI {
    basePath: string;
    fetch: FetchAPI;

    constructor(fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) {
        this.basePath = basePath;
        this.fetch = fetch;
    }
};

export interface AcceptSubmissionModelCurrencyModel {
    "submissionId"?: number;
    "model"?: CurrencyModel;
}

export interface AcceptSubmissionModelFamilyModel {
    "submissionId"?: number;
    "model"?: FamilyModel;
}

export interface AcceptSubmissionModelMenuCategoryModel {
    "submissionId"?: number;
    "model"?: MenuCategoryModel;
}

export interface AcceptSubmissionModelMenuModel {
    "submissionId"?: number;
    "model"?: MenuModel;
}

export interface AcceptSubmissionModelPriceNameModel {
    "submissionId"?: number;
    "model"?: PriceNameModel;
}

export interface AcceptSubmissionModelProductModel {
    "submissionId"?: number;
    "model"?: ProductModel;
}

export interface AcceptSubmissionModelPromotionModel {
    "submissionId"?: number;
    "model"?: PromotionModel;
}

export interface AcceptSubmissionModelSubFamilyModel {
    "submissionId"?: number;
    "model"?: SubFamilyModel;
}

export interface AcceptSubmissionModelSupplierModel {
    "submissionId"?: number;
    "model"?: SupplierModel;
}

export interface AcceptSubmissionModelUnitModel {
    "submissionId"?: number;
    "model"?: UnitModel;
}

export interface AcceptSubmissionModelVatModel {
    "submissionId"?: number;
    "model"?: VatModel;
}

export interface AcceptSubmissionResultModelCurrencyModel {
    "submission"?: SubmissionModel;
    "model"?: CurrencyModel;
}

export interface AcceptSubmissionResultModelFamilyModel {
    "submission"?: SubmissionModel;
    "model"?: FamilyModel;
}

export interface AcceptSubmissionResultModelMenuCategoryModel {
    "submission"?: SubmissionModel;
    "model"?: MenuCategoryModel;
}

export interface AcceptSubmissionResultModelMenuModel {
    "submission"?: SubmissionModel;
    "model"?: MenuModel;
}

export interface AcceptSubmissionResultModelPriceNameModel {
    "submission"?: SubmissionModel;
    "model"?: PriceNameModel;
}

export interface AcceptSubmissionResultModelProductModel {
    "submission"?: SubmissionModel;
    "model"?: ProductModel;
}

export interface AcceptSubmissionResultModelPromotionModel {
    "submission"?: SubmissionModel;
    "model"?: PromotionModel;
}

export interface AcceptSubmissionResultModelSubFamilyModel {
    "submission"?: SubmissionModel;
    "model"?: SubFamilyModel;
}

export interface AcceptSubmissionResultModelSupplierModel {
    "submission"?: SubmissionModel;
    "model"?: SupplierModel;
}

export interface AcceptSubmissionResultModelUnitModel {
    "submission"?: SubmissionModel;
    "model"?: UnitModel;
}

export interface AcceptSubmissionResultModelVatModel {
    "submission"?: SubmissionModel;
    "model"?: VatModel;
}

export interface AccountModel {
    "accountId"?: number;
    "enabled"?: boolean;
    "username"?: string;
    "password"?: string;
    "user"?: UserModel;
    "accountRoles"?: Array<AccountRoleModel>;
    "creationDate"?: Date;
}

export interface AccountRoleModel {
    "accountRoleId"?: number;
    "roleId"?: number;
    "role"?: RoleModel;
}

export interface CountryModel {
    "countryId"?: number;
    "name"?: string;
    "code"?: string;
    "languageId"?: number;
}

export interface CurrencyFilter {
}

export interface CurrencyModel {
    "currencyId"?: number;
    "name"?: string;
    "code"?: string;
    "symbol"?: string;
    "value"?: number;
}

export interface CurrentUserModel {
    "account"?: AccountModel;
    "expirationDate"?: Date;
}

export interface DayStoreDataModel {
    "dayStoreDataId"?: number;
    "dayDate"?: Date;
    "creationDate"?: Date;
    "storeId"?: number;
    "storeOrders"?: Array<StoreOrderModel>;
    "storeStockOperations"?: Array<StoreStockOperationModel>;
    "storeProductReceptions"?: Array<StoreProductReceptionModel>;
    "storeNeoEvents"?: Array<StoreNeoEventModel>;
    "storeMoneyTraffics"?: Array<StoreMoneyTrafficModel>;
    "storeHmiMoneyResets"?: Array<StoreHmiMoneyResetModel>;
    "storeRemoteCollections"?: Array<StoreRemoteCollectionModel>;
    "storeDayDataSnapshot"?: StoreDayDataSnapshotModel;
}

export interface DenySubmissionModel {
    "submissionId"?: number;
    "reasons"?: Array<string>;
    "message"?: string;
}

export interface ExternalProductLanguageNameModel {
    "externalProductLanguageNameId"?: number;
    "externalProductId"?: number;
    "languageId"?: number;
    "name"?: string;
    "comment"?: string;
}

export interface ExternalProductModel { 
    "height"?: number;
    "width"?: number;
    "depth"?: number;
    "expirityDays"?: number;
    "expirityType"?: ExternalProductModelExpirityTypeEnum;
    "stability"?: ExternalProductModelStabilityEnum;
    "volumeUnitId"?: number;
    "relativeUnitId"?: number;
    "productBarcodes"?: Array<ProductBarcodeModel>;
    "productSuppliers"?: Array<ProductSupplierModel>;
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "deliveryType"?: string;
    "hmiVisibility"?: ExternalProductModelHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: ExternalProductModelSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: ExternalProductModelSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "imageId"?: number;
    "image"?: ImageModel;
    "creationDate"?: Date;
    "defaultPriceNameId"?: number;
    "prices"?: Array<PriceModel>;
    "storeItemStorePayments"?: Array<StoreItemStorePaymentModel>;
    "storeItemTags"?: Array<StoreItemTagModel>;
    "remoteId"?: string;
    "practicUnit"?: number;
    "conversionFactor"?: number;
    "externalProductLanguageName"?: Array<ExternalProductLanguageNameModel>;
}

export type ExternalProductModelExpirityTypeEnum = "None" | "Normal" | "Ddm" | "Eggs";
export type ExternalProductModelStabilityEnum = "Unstable" | "KindaStable" | "Stable";
export type ExternalProductModelHmiVisibilityEnum = "Hide" | "Visible";
export type ExternalProductModelSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type ExternalProductModelSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";

export interface FamilyCustomModel {
    "familyId"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface FamilyFilter {
}

export interface FamilyModel {
    "familyId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "order"?: number;
    "creationDate"?: Date;
    "familyCustoms"?: Array<FamilyCustomModel>;
}

export interface HubUserModel {
    "username"?: string;
    "color"?: string;
    "connectionDate"?: Date;
}

export interface ImageModel {
    "imageId"?: number;
    "fileName"?: string;
    "md5"?: string;
    "creationDate"?: Date;
}

export interface LanguageModel {
    "languageId"?: number;
    "name"?: string;
    "code"?: string;
    "creationDate"?: Date;
    "status"?: LanguageStatusEnum;
}

export type LanguageStatusEnum = "Unavailable" | "Available";
export interface LgapSettings {
    "noImageName"?: string;
    "defaultCurrencyCode"?: string;
    "receiptEncryptionKey"?: string;
}

export interface LoginModel {
    "username"?: string;
    "password"?: string;
}

export interface LogModel {
    "content"?: string;
}

export interface MenuCategoryFilter {
}

export interface MenuCategoryMenuModel {
    "menuCategoryMenuId"?: number;
    "order"?: number;
    "menuCategoryId"?: number;
    "menuId"?: number;
}

export interface MenuCategoryModel {
    "menuCategoryId"?: number;
    "name"?: string;
    "menuCategoryStores"?: Array<MenuCategoryStoreModel>;
    "menuCategoryTranslations"?: Array<MenuCategoryTranslationModel>;
}

export interface MenuCategoryStoreModel {
    "menuCategoryStoreId"?: number;
    "menuCategoryId"?: number;
    "storeId"?: number;
}

export interface MenuCategoryTranslationModel {
    "menuCategoryTranslationId"?: number;
    "menuCategoryId"?: number;
    "languageId"?: number;
    "translation"?: string;
}

export interface MenuCustomModel {
    "menuId"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface MenuFilter {
}

export interface MenuModel {
    "hmiEnabled"?: boolean;
    "order"?: number;
    "menuCategoryMenus"?: Array<MenuCategoryMenuModel>;
    "menuStores"?: Array<MenuStoreModel>;
    "menuCustoms"?: Array<MenuCustomModel>;
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "hmiVisibility"?: MenuModelHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: MenuModelSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: MenuModelSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "imageId"?: number;
    "image"?: ImageModel;
    "creationDate"?: Date;
    "defaultPriceNameId"?: number;
    "prices"?: Array<PriceModel>;
    "storeItemStorePayments"?: Array<StoreItemStorePaymentModel>;
    "storeItemTags"?: Array<StoreItemTagModel>;
}

export type MenuModelHmiVisibilityEnum = "Hide" | "Visible";
export type MenuModelSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type MenuModelSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";
export interface MenuStoreModel {
    "menuStoreId"?: number;
    "hmiVisibility"?: MenuStoreModelHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: MenuStoreModelSmartPhoneClientVisibilityEnum;
    "menuId"?: number;
    "storeId"?: number;
}

export type MenuStoreModelHmiVisibilityEnum = "Hide" | "Visible";
export type MenuStoreModelSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export interface PriceModel {
    "priceId"?: number;
    "enabled"?: boolean;
    "value"?: number;
    "creationDate"?: Date;
    "storeItemId"?: number;
    "currencyId"?: number;
    "priceNameId"?: number;
    "priceStores"?: Array<PriceStoreModel>;
}

export interface PriceNameFilter {
}

export interface PriceNameModel {
    "priceNameId"?: number;
    "name"?: string;
    "code"?: string;
    "type"?: PriceNameModelTypeEnum;
    "creationDate"?: Date;
}

export type PriceNameModelTypeEnum = "Normal" | "Offer";
export interface PriceStoreModel {
    "priceStoreId"?: number;
    "priceId"?: number;
    "storeId"?: number;
}

export interface ProductBarcodeModel {
    "productBarcodeId"?: number;
    "productId"?: number;
    "value"?: string;
    "externalProductId"?: number;
}

export interface ProductCustomModel {
    "productId"?: number;
    "comment"?: string;
    "height"?: number;
    "width"?: number;
    "depth"?: number;
    "volume"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface ProductFilter {
}

export interface ProductMenuCategoryModel {
    "productMenuCategoryId"?: number;
    "productId"?: number;
    "menuCategoryId"?: number;
    "productMenuCategoryStores"?: Array<ProductMenuCategoryStoreModel>;
}

export interface ProductMenuCategoryStoreModel {
    "productMenuCategoryStoreId"?: number;
    "storeId"?: number;
    "productMenuCategoryId"?: number;
}

export interface ProductModel {
    "shortName"?: string;
    "stockDaysProjection"?: number;
    "code"?: string;
    "height"?: number;
    "width"?: number;
    "depth"?: number;
    "expirityDays"?: number;
    "expirityOfferDays"?: number;
    "expirityType"?: ProductModelExpirityTypeEnum;
    "stability"?: ProductModelStabilityEnum;
    "volumeUnitId"?: number;
    "volume"?: number;
    "relativeUnitId"?: number;
    "defaultSupplierId"?: number;
    "pmp"?: number;
    "productSubFamilies"?: Array<ProductSubFamilyModel>;
    "productSuppliers"?: Array<ProductSupplierModel>;
    "productMenuCategories"?: Array<ProductMenuCategoryModel>;
    "productVats"?: Array<ProductVatModel>;
    "productBarcodes"?: Array<ProductBarcodeModel>;
    "productCustoms"?: Array<ProductCustomModel>;
    "storeItemId"?: number;
    "enabled"?: boolean;
    "name"?: string;
    "comment"?: string;
    "hmiVisibility"?: ProductModelHmiVisibilityEnum;
    "smartPhoneClientVisibility"?: ProductModelSmartPhoneClientVisibilityEnum;
    "smartPhoneAdminVisibility"?: ProductModelSmartPhoneAdminVisibilityEnum;
    "option1"?: boolean;
    "ageRestriction"?: boolean;
    "imageId"?: number;
    "image"?: ImageModel;
    "creationDate"?: Date;
    "defaultPriceNameId"?: number;
    "prices"?: Array<PriceModel>;
    "storeItemStorePayments"?: Array<StoreItemStorePaymentModel>;
    "storeItemTags"?: Array<StoreItemTagModel>;
}

export type ProductModelExpirityTypeEnum = "None" | "Normal" | "Ddm" | "Eggs";
export type ProductModelStabilityEnum = "Unstable" | "KindaStable" | "Stable";
export type ProductModelHmiVisibilityEnum = "Hide" | "Visible";
export type ProductModelSmartPhoneClientVisibilityEnum = "Hide" | "Visible";
export type ProductModelSmartPhoneAdminVisibilityEnum = "Hide" | "Visible";
export interface ProductSubFamilyModel {
    "productSubFamilyId"?: number;
    "productId"?: number;
    "subFamilyId"?: number;
    "order"?: number;
    "productSubFamilyStores"?: Array<ProductSubFamilyStoreModel>;
}

export interface ProductSubFamilyStoreModel {
    "productSubFamilyStoreId"?: number;
    "productSubFamilyId"?: number;
    "storeId"?: number;
}

export interface ProductSupplierModel {
    "productSupplierId"?: number;
    "enabled"?: boolean;
    "packCondition"?: number;
    "deliveryCondition"?: number;
    "reference"?: string;
    "packWeight"?: number;
    "packVolume"?: number;
    "buyPriceNoVat"?: number;
    "buyPriceCurrencyId"?: number;
    "productId"?: number;
    "externalProductId"?: number;
    "supplierMainId"?: number;
    "supplierSubId"?: number;
    "vatId"?: number;
    "creationDate"?: Date;
    "productSupplierStores"?: Array<ProductSupplierStoreModel>;
}

export interface ProductSupplierStoreModel {
    "productSupplierStoreId"?: number;
    "productSupplierId"?: number;
    "storeId"?: number;
}

export interface ProductVatModel {
    "productVatId"?: number;
    "productId"?: number;
    "vatId"?: number;
    "countryId"?: number;
}

export interface PromotionCustomModel {
    "promotionId"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface PromotionFilter {
}

export interface PromotionModel {
    "promotionId"?: number;
    "enabled"?: boolean;
    "type"?: PromotionModelTypeEnum;
    "name"?: string;
    "order"?: number;
    "creationDate"?: Date;
    "imageId"?: number;
    "image"?: ImageModel;
    "promotionCustoms"?: Array<PromotionCustomModel>;
}

export type PromotionModelTypeEnum = "Displayed" | "NotDisplayed";
export interface RoleModel {
    "roleId"?: number;
    "name"?: string;
}

export interface SeedModel {
    "languages"?: { [key: string]: LanguageModel; };
    "stores"?: { [key: string]: StoreModel; };
    "storeGroups"?: { [key: string]: StoreGroupModel; };
    "countries"?: { [key: string]: CountryModel; };
    "lgapSettings"?: LgapSettings;
    "productHelp"?: string;
    "familyHelp"?: string;
    "subFamilyHelp"?: string;
    "supplierHelp"?: string;
    "promotionHelp"?: string;
    "currencyHelp"?: string;
    "menuHelp"?: string;
    "menuCategoryHelp"?: string;
    "priceNameHelp"?: string;
    "vatHelp"?: string;
    "unitHelp"?: string;
    "submissionHelp"?: string;
}

export interface StoreDayDataSnapshotModel {
    "dayDataSnapshotId"?: number;
    "dayDate"?: Date;
    "creationDate"?: Date;
    "dayProductStocks"?: Array<StoreDayProductStockModel>;
    "dayMoneyStocks"?: Array<StoreDayMoneyStockModel>;
    "dayProductReceptions"?: Array<StoreDayProductReceptionModel>;
}

export interface StoreDayMoneyStockModel {
    "dayMoneyStockId"?: number;
    "quantity"?: number;
    "creationDate"?: Date;
    "dayDataSnapshotId"?: number;
    "moneyId"?: number;
    "hmiMoneyHopperId"?: number;
}

export interface StoreDayProductReceptionModel {
    "dayProductReceptionId"?: number;
    "type"?: StoreDayProductReceptionModelTypeEnum;
    "quantityRemaining"?: number;
    "creationDate"?: Date;
    "productSupplierId"?: number;
    "productId"?: number;
    "externalProductId"?: number;
    "dayDataSnapshotId"?: number;
}

export type StoreDayProductReceptionModelTypeEnum = "Delivery" | "Unstock" | "Unknown";
export interface StoreDayProductStockModel {
    "dayProductStockId"?: number;
    "expirity"?: Date;
    "creationDate"?: Date;
    "productId"?: number;
    "externalProductId"?: number;
    "productRailId"?: number;
    "productSupplierId"?: number;
    "dayDataSnapshotId"?: number;
}

export interface StoreGroupModel {
    "storeGroupId"?: number;
    "name"?: string;
    "creationDate"?: Date;
}

export interface StoreHmiMoneyResetModel {
    "hmiMoneyResetId"?: number;
    "storeAccountId"?: number;
    "creationDate"?: Date;
    "hmiMoneyResetMoneys"?: Array<StoreHmiMoneyResetMoneyModel>;
}

export interface StoreHmiMoneyResetMoneyModel {
    "hmiMoneyResetMoneyId"?: number;
    "quantity"?: number;
    "hmiMoneyResetId"?: number;
    "moneyId"?: number;
}

export interface StoreItemStorePaymentModel {
    "storeItemStorePaymentId"?: number;
    "storeId"?: number;
    "paymentType"?: StoreItemStorePaymentModelPaymentTypeEnum;
    "availability"?: StoreItemStorePaymentModelAvailabilityEnum;
    "storeItemId"?: number;
}

export type StoreItemStorePaymentModelPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export type StoreItemStorePaymentModelAvailabilityEnum = "Available" | "Unavailable";
export interface StoreItemTagModel {
    "storeItemTagId"?: number;
    "tagId"?: number;
    "storeItemId"?: number;
}

export interface StoreModel {
    "storeId"?: number;
    "enabledUpdate"?: boolean;
    "name"?: string;
    "addressId"?: number;
    "latitude"?: number;
    "longitude"?: number;
    "utcOffSet"?: number;
    "currencyId"?: number;
    "storeGroupId"?: number;
    "storeType"?: string;
}

export interface StoreMoneyTrafficModel {
    "storeMoneyTrafficId"?: number;
    "type"?: StoreMoneyTrafficModelTypeEnum;
    "quantity"?: number;
    "moneyId"?: number;
    "hmiMoneyHopperId"?: number;
    "storeOrderPaymentId"?: number;
    "dayStoreDataId"?: number;
}

export type StoreMoneyTrafficModelTypeEnum = "PaymentIn" | "PaymentOut" | "ManagerIn" | "ManagerOut";
export interface StoreNeoEventModel {
    "storeNeoEventId"?: number;
    "message"?: string;
    "eventDate"?: Date;
    "neoEventCodeId"?: number;
    "dayStoreDataId"?: number;
}

export interface StoreOrderItemOrderModel {
    "storeOrderItemOrderId"?: number;
    "storeOrderItemOrderType"?: StoreOrderItemOrderModelStoreOrderItemOrderTypeEnum;
    "orderDate"?: Date;
    "quantity"?: number;
    "storeOrderId"?: number;
    "vatId"?: number;
    "priceId"?: number;
    "price"?: PriceModel;
    "productId"?: number;
    "storeOrderMenuOrderId"?: number;
    "menuCategoryId"?: number;
    "menuId"?: number;
}

export type StoreOrderItemOrderModelStoreOrderItemOrderTypeEnum = "Product" | "Menu";
export interface StoreOrderModel {
    "storeOrderId"?: number;
    "type"?: StoreOrderModelTypeEnum;
    "code"?: string;
    "status"?: StoreOrderModelStatusEnum;
    "receipt"?: string;
    "orderDate"?: Date;
    "dayStoreDataId"?: number;
    "storeOrderPayments"?: Array<StoreOrderPaymentModel>;
    "storeOrderProductDeliveries"?: Array<StoreOrderProductDeliveryModel>;
    "storeOrderItemOrders"?: Array<StoreOrderItemOrderModel>;
}

export type StoreOrderModelTypeEnum = "SaleOrder" | "PreOrder";
export type StoreOrderModelStatusEnum = "Created" | "Delivered" | "Canceled" | "Failed";
export interface StoreOrderPaymentModel {
    "storeOrderPaymentId"?: number;
    "paymentType"?: StoreOrderPaymentModelPaymentTypeEnum;
    "cardType"?: StoreOrderPaymentModelCardTypeEnum;
    "askedSolvency"?: number;
    "solvency"?: number;
    "collection"?: number;
    "status"?: StoreOrderPaymentModelStatusEnum;
    "collectiondDate"?: Date;
    "solvencyRequestDate"?: Date;
    "solvencyValidationDate"?: Date;
    "collectionModule"?: StoreOrderPaymentModelCollectionModuleEnum;
    "collectionLabel"?: string;
    "collectionInfo"?: string;
    "storeOrderId"?: number;
    "storeMoneyTraffics"?: Array<StoreMoneyTrafficModel>;
}

export type StoreOrderPaymentModelPaymentTypeEnum = "Cash" | "Card" | "SmartPhone" | "MealVoucher";
export type StoreOrderPaymentModelCardTypeEnum = "Normal" | "NoContact";
export type StoreOrderPaymentModelStatusEnum = "Success" | "PartialSuccess" | "Failed";
export type StoreOrderPaymentModelCollectionModuleEnum = "IngenicoIup";
export interface StoreOrderProductDeliveryModel {
    "storeOrderProductDeliveryId"?: number;
    "status"?: StoreOrderProductDeliveryModelStatusEnum;
    "deliveryDate"?: Date;
    "storeOrderId"?: number;
    "neoEventCodeId"?: number;
    "productId"?: number;
    "productSupplierId"?: number;
    "storeOrderProductOrderId"?: number;
}

export type StoreOrderProductDeliveryModelStatusEnum = "Ok" | "Failed" | "FailedExtraction" | "FailedRamp" | "FailedSas" | "Partial";
export interface StoreProductReceptionModel {
    "storeProductReceptionId"?: number;
    "quantity"?: number;
    "quantityRemaining"?: number;
    "receptionDate"?: Date;
    "productSupplierId"?: number;
    "productId"?: number;
    "dayStoreDataId"?: number;
}

export interface StoreRemoteCollectionModel {
    "remoteCollectionId"?: number;
    "collectionModule"?: StoreRemoteCollectionModelCollectionModuleEnum;
    "collectionStatus"?: StoreRemoteCollectionModelCollectionStatusEnum;
    "label"?: string;
    "data"?: string;
    "creationDate"?: Date;
}

export type StoreRemoteCollectionModelCollectionModuleEnum = "IngenicoIup";
export type StoreRemoteCollectionModelCollectionStatusEnum = "Ok" | "NotCompleted" | "CallFailed" | "NoCall";
export interface StoreStockOperationModel {
    "storeStockOperationId"?: number;
    "type"?: StoreStockOperationModelTypeEnum;
    "quantityStock"?: number;
    "quantityStored"?: number;
    "operationDate"?: Date;
    "dayStoreDataId"?: number;
    "productId"?: number;
    "productSupplierId"?: number;
}

export type StoreStockOperationModelTypeEnum = "ManagerStockOut" | "ManagerStockIn" | "ManagerStoredIn" | "ManagerStoredOut" | "ExpirityOut";
export interface SubFamilyCustomModel {
    "subFamilyId"?: number;
    "entityCustomizationId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "languageId"?: number;
    "storeGroupId"?: number;
    "accountId"?: number;
}

export interface SubFamilyFilter {
}

export interface SubFamilyModel {
    "subFamilyId"?: number;
    "name"?: string;
    "imageId"?: number;
    "image"?: ImageModel;
    "order"?: number;
    "familyId"?: number;
    "creationDate"?: Date;
    "subFamilyCustoms"?: Array<SubFamilyCustomModel>;
}

export interface SubmissionModel {
    "submissionId"?: number;
    "entityType"?: SubmissionModelEntityTypeEnum;
    "type"?: SubmissionModelTypeEnum;
    "content"?: string;
    "status"?: SubmissionModelStatusEnum;
    "message"?: string;
    "processedDate"?: Date;
    "creationDate"?: Date;
    "userId"?: number;
    "userUsername"?: string;
    "moderatorUsername"?: string;
    "familyId"?: number;
    "promotionId"?: number;
    "subFamilyId"?: number;
    "currencyId"?: number;
    "supplierMainId"?: number;
    "supplierSubId"?: number;
    "vatId"?: number;
    "productId"?: number;
    "menuId"?: number;
    "menuCategoryId"?: number;
    "priceNameId"?: number;
    "unitId"?: number;
    "supplierAdvertId"?: number;
    "supplierDeliveryId"?: number;
    "supplierPaymentId"?: number;
}

export type SubmissionModelEntityTypeEnum = "Product" | "Family" | "SubFamily" | "Promotion" | "SupplierMain" | "SupplierSub" | "Menu" | "MenuCategory" | "Vat" | "Currency" | "PriceName" | "Unit" | "SupplierAdvert" | "SupplierDelivery" | "SupplierPayment";
export type SubmissionModelTypeEnum = "Create" | "Update" | "Delete";
export type SubmissionModelStatusEnum = "Created" | "Accepted" | "Denied" | "Moderator";
export interface SubscriptionModel {
    "subscriptionId"?: number;
    "enabled"?: boolean;
    "userId"?: number;
    "storeId"?: number;
    "creationDate"?: Date;
}

export interface SupplierAdvertFilter {
}

export interface SupplierAdvertModel {
    "supplierAdvertId"?: number;
    "name"?: string;
}

export interface SupplierAdvertSupplierModel {
    "supplierAdvertSupplierId"?: number;
    "supplierId"?: number;
    "supplierAdvertId"?: number;
}

export interface SupplierDeliveryFilter {
}

export interface SupplierDeliveryModel {
    "supplierDeliveryId"?: number;
    "name"?: string;
}

export interface SupplierDeliverySupplierModel {
    "supplierDeliverySupplierId"?: number;
    "supplierId"?: number;
    "supplierDeliveryId"?: number;
}

export interface SupplierDiscountModel {
    "supplierDiscountId"?: number;
    "value"?: number;
    "supplierId"?: number;
    "storeGroupId"?: number;
}

export interface SupplierFamilyRotationModel {
    "supplierFamilyRotationId"?: number;
    "family"?: string;
    "dayOfWeek"?: SupplierFamilyRotationModelDayOfWeekEnum;
    "period"?: number;
    "priorNotice"?: number;
    "supplierId"?: number;
}

export type SupplierFamilyRotationModelDayOfWeekEnum = "Monday" | "Tuesday" | "Wednesday" | "Thursday" | "Friday" | "Saturday" | "Sunday" | "FiveAWeek" | "SixAWeek" | "Everyday";
export interface SupplierMainFilter {
}

export interface SupplierModel {
    "supplierId"?: number;
    "supplierType"?: SupplierModelSupplierTypeEnum;
    "name"?: string;
    "code"?: string;
    "supplierMainId"?: number;
    "deliveryFeeFix"?: number;
    "deliveryFeeVar"?: number;
    "deliveryFeeVarUnitId"?: number;
    "creationDate"?: Date;
    "supplierDiscounts"?: Array<SupplierDiscountModel>;
    "supplierPaymentDeadlines"?: Array<SupplierPaymentDeadlineModel>;
    "supplierPaymentSuppliers"?: Array<SupplierPaymentSupplierModel>;
    "supplierAdvertSuppliers"?: Array<SupplierAdvertSupplierModel>;
    "supplierDeliverySuppliers"?: Array<SupplierDeliverySupplierModel>;
    "supplierFamilyRotations"?: Array<SupplierFamilyRotationModel>;
    "supplierVolumeDiscounts"?: Array<SupplierVolumeDiscountModel>;
}

export type SupplierModelSupplierTypeEnum = "SupplierMain" | "SupplierSub";
export interface SupplierPaymentDeadlineModel {
    "supplierPaymentDeadlineId"?: number;
    "days"?: number;
    "type"?: SupplierPaymentDeadlineModelTypeEnum;
    "dayOfTheMonth"?: number;
    "supplierId"?: number;
}

export type SupplierPaymentDeadlineModelTypeEnum = "Exact" | "EndOfMonth";
export interface SupplierPaymentFilter {
}

export interface SupplierPaymentModel {
    "supplierPaymentId"?: number;
    "name"?: string;
}

export interface SupplierPaymentSupplierModel {
    "supplierPaymentSupplierId"?: number;
    "supplierId"?: number;
    "supplierPaymentId"?: number;
}

export interface SupplierSubFilter {
}

export interface SupplierVolumeDiscountModel {
    "supplierVolumeDiscountId"?: number;
    "volume"?: number;
    "amount"?: number;
    "currencyId"?: number;
    "supplierId"?: number;
    "unitId"?: number;
    "currencyUnitId"?: number;
    "storeGroupId"?: number;
}

export interface TagModel {
    "tagId"?: number;
    "value"?: string;
    "creationDate"?: Date;
}

export interface TokenModel {
    "token"?: string;
    "username"?: string;
    "expirationDate"?: Date;
    "isValid"?: boolean;
}

export interface UnitConversionModel {
    "unitConversionId"?: number;
    "value"?: number;
    "unitFromId"?: number;
    "unitToId"?: number;
}

export interface UnitFilter {
}

export interface UnitModel {
    "unitId"?: number;
    "name"?: string;
    "code"?: string;
    "creationDate"?: Date;
    "type"?: UnitModelTypeEnum;
    "unitConversionsFrom"?: Array<UnitConversionModel>;
    "unitConversionsTo"?: Array<UnitConversionModel>;
}

export type UnitModelTypeEnum = "Product" | "ExternalProduct";

export interface UserLgapSettingsModel {
    "userLgapSettingsId"?: number;
    "dataGridsState"?: string;
    "userId"?: number;
}

export interface UserMessageModel {
    "hubUser"?: HubUserModel;
    "message"?: string;
}

export interface UserModel {
    "userId"?: number;
    "firstName"?: string;
    "lastName"?: string;
    "creationDate"?: Date;
    "userLgapSettings"?: UserLgapSettingsModel;
    "pendingSubmissions"?: Array<SubmissionModel>;
    "subscriptions"?: Array<SubscriptionModel>;
}

export interface VatFilter {
}

export interface VatModel {
    "vatId"?: number;
    "name"?: string;
    "value"?: number;
    "creationDate"?: Date;
}



/**
 * AccountApi - fetch parameter creator
 */
export const AccountApiFetchParamCreator = {
    /**
     * 
     * @param model 
     */
    createToken(params: {  "model"?: LoginModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/CreateToken`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     */
    getCurrentUser(options?: any): FetchArgs {
        const baseUrl = `/api/Account/GetCurrentUser`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     */
    logout(options?: any): FetchArgs {
        const baseUrl = `/api/Account/Logout`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param gridStates 
     */
    saveGridStates(params: {  "gridStates"?: any; }, options?: any): FetchArgs {
        const baseUrl = `/api/Account/SaveGridStates`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["gridStates"]) {
            fetchOptions.body = JSON.stringify(params["gridStates"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * AccountApi - functional programming interface
 */
export const AccountApiFp = {
    /**
     * 
     * @param model 
     */
    createToken(params: { "model"?: LoginModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TokenModel> {
        const fetchArgs = AccountApiFetchParamCreator.createToken(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     */
    getCurrentUser(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CurrentUserModel> {
        const fetchArgs = AccountApiFetchParamCreator.getCurrentUser(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     */
    logout(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.logout(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param gridStates 
     */
    saveGridStates(params: { "gridStates"?: any;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = AccountApiFetchParamCreator.saveGridStates(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * AccountApi - object-oriented interface
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param model 
     */
    createToken(params: {  "model"?: LoginModel; }, options?: any) {
        return AccountApiFp.createToken(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     */
    getCurrentUser(options?: any) {
        return AccountApiFp.getCurrentUser(options)(this.fetch, this.basePath);
    }
    /**
     * 
     */
    logout(options?: any) {
        return AccountApiFp.logout(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param gridStates 
     */
    saveGridStates(params: {  "gridStates"?: any; }, options?: any) {
        return AccountApiFp.saveGridStates(params, options)(this.fetch, this.basePath);
    }
};

/**
 * AccountApi - factory interface
 */
export const AccountApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param model 
         */
        createToken(params: {  "model"?: LoginModel; }, options?: any) {
            return AccountApiFp.createToken(params, options)(fetch, basePath);
        },
        /**
         * 
         */
        getCurrentUser(options?: any) {
            return AccountApiFp.getCurrentUser(options)(fetch, basePath);
        },
        /**
         * 
         */
        logout(options?: any) {
            return AccountApiFp.logout(options)(fetch, basePath);
        },
        /**
         * 
         * @param gridStates 
         */
        saveGridStates(params: {  "gridStates"?: any; }, options?: any) {
            return AccountApiFp.saveGridStates(params, options)(fetch, basePath);
        },
    };
};


/**
 * CurrencyApi - fetch parameter creator
 */
export const CurrencyApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Currency/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<CurrencyModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Currency/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: CurrencyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Currency/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: CurrencyFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/Currency/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: CurrencyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Currency/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: CurrencyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Currency/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: CurrencyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Currency/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * CurrencyApi - functional programming interface
 */
export const CurrencyApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = CurrencyApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<CurrencyModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CurrencyModel>> {
        const fetchArgs = CurrencyApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: CurrencyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CurrencyModel> {
        const fetchArgs = CurrencyApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: CurrencyFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CurrencyModel>> {
        const fetchArgs = CurrencyApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: CurrencyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = CurrencyApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: CurrencyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = CurrencyApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: CurrencyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<CurrencyModel> {
        const fetchArgs = CurrencyApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * CurrencyApi - object-oriented interface
 */
export class CurrencyApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return CurrencyApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<CurrencyModel>; }, options?: any) {
        return CurrencyApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: CurrencyModel; }, options?: any) {
        return CurrencyApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: CurrencyFilter; }, options?: any) {
        return CurrencyApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: CurrencyModel; }, options?: any) {
        return CurrencyApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: CurrencyModel; }, options?: any) {
        return CurrencyApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: CurrencyModel; }, options?: any) {
        return CurrencyApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * CurrencyApi - factory interface
 */
export const CurrencyApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return CurrencyApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<CurrencyModel>; }, options?: any) {
            return CurrencyApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: CurrencyModel; }, options?: any) {
            return CurrencyApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: CurrencyFilter; }, options?: any) {
            return CurrencyApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: CurrencyModel; }, options?: any) {
            return CurrencyApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: CurrencyModel; }, options?: any) {
            return CurrencyApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: CurrencyModel; }, options?: any) {
            return CurrencyApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * DayStoreDataApi - fetch parameter creator
 */
export const DayStoreDataApiFetchParamCreator = {
    /**
     * 
     */
    getAllDayStores(options?: any): FetchArgs {
        const baseUrl = `/api/DayStoreData/GetAllDayStores`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    uploadDayStore(params: {  "model"?: DayStoreDataModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/DayStoreData/UploadDayStore`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * DayStoreDataApi - functional programming interface
 */
export const DayStoreDataApiFp = {
    /**
     * 
     */
    getAllDayStores(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DayStoreDataApiFetchParamCreator.getAllDayStores(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    uploadDayStore(params: { "model"?: DayStoreDataModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = DayStoreDataApiFetchParamCreator.uploadDayStore(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * DayStoreDataApi - object-oriented interface
 */
export class DayStoreDataApi extends BaseAPI {
    /**
     * 
     */
    getAllDayStores(options?: any) {
        return DayStoreDataApiFp.getAllDayStores(options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    uploadDayStore(params: {  "model"?: DayStoreDataModel; }, options?: any) {
        return DayStoreDataApiFp.uploadDayStore(params, options)(this.fetch, this.basePath);
    }
};

/**
 * DayStoreDataApi - factory interface
 */
export const DayStoreDataApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         */
        getAllDayStores(options?: any) {
            return DayStoreDataApiFp.getAllDayStores(options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        uploadDayStore(params: {  "model"?: DayStoreDataModel; }, options?: any) {
            return DayStoreDataApiFp.uploadDayStore(params, options)(fetch, basePath);
        },
    };
};


/**
 * ExportApi - fetch parameter creator
 */
export const ExportApiFetchParamCreator = {
    /**
     * 
     * @param lastImportUId 
     */
    countries(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/Countries`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    families(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/Families`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    images(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/Images`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    languages(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/Languages`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    menuCategories(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/MenuCategories`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    menus(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/Menus`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    priceNames(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/PriceNames`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    products(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/Products`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    subFamilies(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/SubFamilies`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierAdverts(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/SupplierAdverts`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierDeliveries(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/SupplierDeliveries`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierMains(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/SupplierMains`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierPayments(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/SupplierPayments`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierSubs(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/SupplierSubs`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    units(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/Units`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    vats(params: {  "lastImportUId"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Export/Vats`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "lastImportUId": params["lastImportUId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ExportApi - functional programming interface
 */
export const ExportApiFp = {
    /**
     * 
     * @param lastImportUId 
     */
    countries(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<CountryModel>> {
        const fetchArgs = ExportApiFetchParamCreator.countries(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    families(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FamilyModel>> {
        const fetchArgs = ExportApiFetchParamCreator.families(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    images(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ImageModel>> {
        const fetchArgs = ExportApiFetchParamCreator.images(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    languages(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<LanguageModel>> {
        const fetchArgs = ExportApiFetchParamCreator.languages(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    menuCategories(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MenuCategoryModel>> {
        const fetchArgs = ExportApiFetchParamCreator.menuCategories(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    menus(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MenuModel>> {
        const fetchArgs = ExportApiFetchParamCreator.menus(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    priceNames(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PriceNameModel>> {
        const fetchArgs = ExportApiFetchParamCreator.priceNames(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    products(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductModel>> {
        const fetchArgs = ExportApiFetchParamCreator.products(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    subFamilies(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubFamilyModel>> {
        const fetchArgs = ExportApiFetchParamCreator.subFamilies(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierAdverts(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierAdvertModel>> {
        const fetchArgs = ExportApiFetchParamCreator.supplierAdverts(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierDeliveries(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierDeliveryModel>> {
        const fetchArgs = ExportApiFetchParamCreator.supplierDeliveries(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierMains(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierModel>> {
        const fetchArgs = ExportApiFetchParamCreator.supplierMains(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierPayments(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierPaymentModel>> {
        const fetchArgs = ExportApiFetchParamCreator.supplierPayments(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    supplierSubs(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierModel>> {
        const fetchArgs = ExportApiFetchParamCreator.supplierSubs(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    units(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UnitModel>> {
        const fetchArgs = ExportApiFetchParamCreator.units(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param lastImportUId 
     */
    vats(params: { "lastImportUId"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VatModel>> {
        const fetchArgs = ExportApiFetchParamCreator.vats(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ExportApi - object-oriented interface
 */
export class ExportApi extends BaseAPI {
    /**
     * 
     * @param lastImportUId 
     */
    countries(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.countries(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    families(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.families(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    images(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.images(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    languages(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.languages(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    menuCategories(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.menuCategories(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    menus(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.menus(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    priceNames(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.priceNames(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    products(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.products(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    subFamilies(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.subFamilies(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    supplierAdverts(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.supplierAdverts(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    supplierDeliveries(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.supplierDeliveries(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    supplierMains(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.supplierMains(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    supplierPayments(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.supplierPayments(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    supplierSubs(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.supplierSubs(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    units(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.units(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param lastImportUId 
     */
    vats(params: {  "lastImportUId"?: string; }, options?: any) {
        return ExportApiFp.vats(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ExportApi - factory interface
 */
export const ExportApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param lastImportUId 
         */
        countries(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.countries(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        families(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.families(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        images(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.images(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        languages(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.languages(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        menuCategories(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.menuCategories(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        menus(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.menus(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        priceNames(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.priceNames(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        products(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.products(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        subFamilies(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.subFamilies(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        supplierAdverts(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.supplierAdverts(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        supplierDeliveries(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.supplierDeliveries(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        supplierMains(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.supplierMains(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        supplierPayments(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.supplierPayments(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        supplierSubs(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.supplierSubs(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        units(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.units(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param lastImportUId 
         */
        vats(params: {  "lastImportUId"?: string; }, options?: any) {
            return ExportApiFp.vats(params, options)(fetch, basePath);
        },
    };
};

/**
 * ExternalProductApi - fetch parameter creator
 */
export const ExternalProductApiFetchParamCreator = {
    /**
     *  
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/ExternalProduct/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ExternalProductApi - functional programming interface
 */
export const ExternalProductApiFp = {
    /**
     * 
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ExternalProductModel>> {
        const fetchArgs = ExternalProductApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ExternalProductApi - object-oriented interface
 */
export class ExternalProductApi extends BaseAPI {
    /**
     * 
     */
    getEntities(options?: any) {
        return ExternalProductApiFp.getEntities(options)(this.fetch, this.basePath);
    }
};

/**
 * ExternalProductApi - factory interface
 */
export const ExternalProductApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         */
        getEntities(options?: any) {
            return ExternalProductApiFp.getEntities(options)(fetch, basePath);
        },
    }
};

/**
 * FamilyApi - fetch parameter creator
 */
export const FamilyApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Family/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<FamilyModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Family/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: FamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Family/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: FamilyFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/Family/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: FamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Family/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: FamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Family/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: FamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Family/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * FamilyApi - functional programming interface
 */
export const FamilyApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = FamilyApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<FamilyModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FamilyModel>> {
        const fetchArgs = FamilyApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: FamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FamilyModel> {
        const fetchArgs = FamilyApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: FamilyFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<FamilyModel>> {
        const fetchArgs = FamilyApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: FamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = FamilyApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: FamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = FamilyApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: FamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<FamilyModel> {
        const fetchArgs = FamilyApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * FamilyApi - object-oriented interface
 */
export class FamilyApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return FamilyApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<FamilyModel>; }, options?: any) {
        return FamilyApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: FamilyModel; }, options?: any) {
        return FamilyApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: FamilyFilter; }, options?: any) {
        return FamilyApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: FamilyModel; }, options?: any) {
        return FamilyApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: FamilyModel; }, options?: any) {
        return FamilyApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: FamilyModel; }, options?: any) {
        return FamilyApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * FamilyApi - factory interface
 */
export const FamilyApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return FamilyApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<FamilyModel>; }, options?: any) {
            return FamilyApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: FamilyModel; }, options?: any) {
            return FamilyApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: FamilyFilter; }, options?: any) {
            return FamilyApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: FamilyModel; }, options?: any) {
            return FamilyApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: FamilyModel; }, options?: any) {
            return FamilyApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: FamilyModel; }, options?: any) {
            return FamilyApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * ImageApi - fetch parameter creator
 */
export const ImageApiFetchParamCreator = {
    /**
     * 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     */
    create(params: {  "contentType"?: string; "contentDisposition"?: string; "headers"?: { [key: string]: string; }; "length"?: number; "name"?: string; "fileName"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Image/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/x-www-form-urlencoded" };
        fetchOptions.body = querystring.stringify({
            "ContentType": params["contentType"],
            "ContentDisposition": params["contentDisposition"],
            "Headers": params["headers"],
            "Length": params["length"],
            "Name": params["name"],
            "FileName": params["fileName"],
        });
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param imageId 
     */
    getImageContent(params: {  "imageId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Image/GetImageContent`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "imageId": params["imageId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ImageApi - functional programming interface
 */
export const ImageApiFp = {
    /**
     * 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     */
    create(params: { "contentType"?: string; "contentDisposition"?: string; "headers"?: { [key: string]: string; }; "length"?: number; "name"?: string; "fileName"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ImageModel> {
        const fetchArgs = ImageApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param imageId 
     */
    getImageContent(params: { "imageId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ImageApiFetchParamCreator.getImageContent(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ImageApi - object-oriented interface
 */
export class ImageApi extends BaseAPI {
    /**
     * 
     * @param contentType 
     * @param contentDisposition 
     * @param headers 
     * @param length 
     * @param name 
     * @param fileName 
     */
    create(params: {  "contentType"?: string; "contentDisposition"?: string; "headers"?: { [key: string]: string; }; "length"?: number; "name"?: string; "fileName"?: string; }, options?: any) {
        return ImageApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param imageId 
     */
    getImageContent(params: {  "imageId"?: number; }, options?: any) {
        return ImageApiFp.getImageContent(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ImageApi - factory interface
 */
export const ImageApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param contentType 
         * @param contentDisposition 
         * @param headers 
         * @param length 
         * @param name 
         * @param fileName 
         */
        create(params: {  "contentType"?: string; "contentDisposition"?: string; "headers"?: { [key: string]: string; }; "length"?: number; "name"?: string; "fileName"?: string; }, options?: any) {
            return ImageApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param imageId 
         */
        getImageContent(params: {  "imageId"?: number; }, options?: any) {
            return ImageApiFp.getImageContent(params, options)(fetch, basePath);
        },
    };
};


/**
 * LogApi - fetch parameter creator
 */
 export const LogApiFetchParamCreator = {
    /**
     *
     * @param model
     */
    create(params: {  "model"?: LogModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Log/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * LogApi - functional programming interface
 */
export const LogApiFp = {
    /**
     *
     * @param model
     */
    create(params: { "model"?: LogModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = LogApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * LogApi - object-oriented interface
 */
export class LogApi extends BaseAPI {
    /**
     *
     * @param model
     */
    create(params: {  "model"?: LogModel; }, options?: any) {
        return LogApiFp.create(params, options)(this.fetch, this.basePath);
    }
};

/**
 * LogApi - factory interface
 */
export const LogApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         *
         * @param model
         */
        create(params: {  "model"?: LogModel; }, options?: any) {
            return LogApiFp.create(params, options)(fetch, basePath);
        },
    };
};


/**
 * MenuApi - fetch parameter creator
 */
export const MenuApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Menu/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<MenuModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Menu/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: MenuModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Menu/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: MenuFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/Menu/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: MenuModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Menu/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: MenuModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Menu/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: MenuModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Menu/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * MenuApi - functional programming interface
 */
export const MenuApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MenuApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<MenuModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MenuModel>> {
        const fetchArgs = MenuApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: MenuModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MenuModel> {
        const fetchArgs = MenuApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: MenuFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MenuModel>> {
        const fetchArgs = MenuApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: MenuModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = MenuApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: MenuModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = MenuApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: MenuModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MenuModel> {
        const fetchArgs = MenuApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * MenuApi - object-oriented interface
 */
export class MenuApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return MenuApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<MenuModel>; }, options?: any) {
        return MenuApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: MenuModel; }, options?: any) {
        return MenuApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: MenuFilter; }, options?: any) {
        return MenuApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: MenuModel; }, options?: any) {
        return MenuApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: MenuModel; }, options?: any) {
        return MenuApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: MenuModel; }, options?: any) {
        return MenuApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * MenuApi - factory interface
 */
export const MenuApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return MenuApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<MenuModel>; }, options?: any) {
            return MenuApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: MenuModel; }, options?: any) {
            return MenuApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: MenuFilter; }, options?: any) {
            return MenuApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: MenuModel; }, options?: any) {
            return MenuApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: MenuModel; }, options?: any) {
            return MenuApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: MenuModel; }, options?: any) {
            return MenuApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * MenuCategoryApi - fetch parameter creator
 */
export const MenuCategoryApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategory/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<MenuCategoryModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategory/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: MenuCategoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategory/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: MenuCategoryFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategory/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: MenuCategoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategory/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: MenuCategoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategory/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: MenuCategoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/MenuCategory/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * MenuCategoryApi - functional programming interface
 */
export const MenuCategoryApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = MenuCategoryApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<MenuCategoryModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MenuCategoryModel>> {
        const fetchArgs = MenuCategoryApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: MenuCategoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MenuCategoryModel> {
        const fetchArgs = MenuCategoryApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: MenuCategoryFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<MenuCategoryModel>> {
        const fetchArgs = MenuCategoryApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: MenuCategoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = MenuCategoryApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: MenuCategoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = MenuCategoryApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: MenuCategoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<MenuCategoryModel> {
        const fetchArgs = MenuCategoryApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * MenuCategoryApi - object-oriented interface
 */
export class MenuCategoryApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return MenuCategoryApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<MenuCategoryModel>; }, options?: any) {
        return MenuCategoryApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: MenuCategoryModel; }, options?: any) {
        return MenuCategoryApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: MenuCategoryFilter; }, options?: any) {
        return MenuCategoryApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: MenuCategoryModel; }, options?: any) {
        return MenuCategoryApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: MenuCategoryModel; }, options?: any) {
        return MenuCategoryApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: MenuCategoryModel; }, options?: any) {
        return MenuCategoryApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * MenuCategoryApi - factory interface
 */
export const MenuCategoryApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return MenuCategoryApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<MenuCategoryModel>; }, options?: any) {
            return MenuCategoryApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: MenuCategoryModel; }, options?: any) {
            return MenuCategoryApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: MenuCategoryFilter; }, options?: any) {
            return MenuCategoryApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: MenuCategoryModel; }, options?: any) {
            return MenuCategoryApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: MenuCategoryModel; }, options?: any) {
            return MenuCategoryApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: MenuCategoryModel; }, options?: any) {
            return MenuCategoryApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * PriceNameApi - fetch parameter creator
 */
export const PriceNameApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/PriceName/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<PriceNameModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/PriceName/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: PriceNameModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PriceName/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: PriceNameFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/PriceName/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: PriceNameModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PriceName/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: PriceNameModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PriceName/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: PriceNameModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/PriceName/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PriceNameApi - functional programming interface
 */
export const PriceNameApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PriceNameApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<PriceNameModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PriceNameModel>> {
        const fetchArgs = PriceNameApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: PriceNameModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PriceNameModel> {
        const fetchArgs = PriceNameApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: PriceNameFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PriceNameModel>> {
        const fetchArgs = PriceNameApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: PriceNameModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = PriceNameApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: PriceNameModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = PriceNameApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: PriceNameModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PriceNameModel> {
        const fetchArgs = PriceNameApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PriceNameApi - object-oriented interface
 */
export class PriceNameApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return PriceNameApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<PriceNameModel>; }, options?: any) {
        return PriceNameApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: PriceNameModel; }, options?: any) {
        return PriceNameApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: PriceNameFilter; }, options?: any) {
        return PriceNameApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: PriceNameModel; }, options?: any) {
        return PriceNameApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: PriceNameModel; }, options?: any) {
        return PriceNameApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: PriceNameModel; }, options?: any) {
        return PriceNameApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PriceNameApi - factory interface
 */
export const PriceNameApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return PriceNameApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<PriceNameModel>; }, options?: any) {
            return PriceNameApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: PriceNameModel; }, options?: any) {
            return PriceNameApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: PriceNameFilter; }, options?: any) {
            return PriceNameApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: PriceNameModel; }, options?: any) {
            return PriceNameApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: PriceNameModel; }, options?: any) {
            return PriceNameApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: PriceNameModel; }, options?: any) {
            return PriceNameApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * ProductApi - fetch parameter creator
 */
export const ProductApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Product/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<ProductModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Product/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: ProductModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Product/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: ProductFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/Product/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: ProductModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Product/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: ProductModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Product/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: ProductModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Product/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ProductApi - functional programming interface
 */
export const ProductApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ProductApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<ProductModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductModel>> {
        const fetchArgs = ProductApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: ProductModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductModel> {
        const fetchArgs = ProductApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: ProductFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<ProductModel>> {
        const fetchArgs = ProductApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: ProductModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = ProductApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: ProductModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = ProductApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: ProductModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ProductModel> {
        const fetchArgs = ProductApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ProductApi - object-oriented interface
 */
export class ProductApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return ProductApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<ProductModel>; }, options?: any) {
        return ProductApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: ProductModel; }, options?: any) {
        return ProductApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: ProductFilter; }, options?: any) {
        return ProductApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: ProductModel; }, options?: any) {
        return ProductApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: ProductModel; }, options?: any) {
        return ProductApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: ProductModel; }, options?: any) {
        return ProductApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ProductApi - factory interface
 */
export const ProductApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return ProductApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<ProductModel>; }, options?: any) {
            return ProductApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: ProductModel; }, options?: any) {
            return ProductApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: ProductFilter; }, options?: any) {
            return ProductApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: ProductModel; }, options?: any) {
            return ProductApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: ProductModel; }, options?: any) {
            return ProductApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: ProductModel; }, options?: any) {
            return ProductApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * PromotionApi - fetch parameter creator
 */
export const PromotionApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Promotion/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<PromotionModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Promotion/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: PromotionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Promotion/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: PromotionFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/Promotion/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: PromotionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Promotion/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: PromotionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Promotion/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: PromotionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Promotion/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * PromotionApi - functional programming interface
 */
export const PromotionApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = PromotionApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<PromotionModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PromotionModel>> {
        const fetchArgs = PromotionApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: PromotionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PromotionModel> {
        const fetchArgs = PromotionApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: PromotionFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<PromotionModel>> {
        const fetchArgs = PromotionApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: PromotionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = PromotionApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: PromotionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = PromotionApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: PromotionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<PromotionModel> {
        const fetchArgs = PromotionApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * PromotionApi - object-oriented interface
 */
export class PromotionApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return PromotionApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<PromotionModel>; }, options?: any) {
        return PromotionApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: PromotionModel; }, options?: any) {
        return PromotionApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: PromotionFilter; }, options?: any) {
        return PromotionApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: PromotionModel; }, options?: any) {
        return PromotionApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: PromotionModel; }, options?: any) {
        return PromotionApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: PromotionModel; }, options?: any) {
        return PromotionApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * PromotionApi - factory interface
 */
export const PromotionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return PromotionApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<PromotionModel>; }, options?: any) {
            return PromotionApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: PromotionModel; }, options?: any) {
            return PromotionApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: PromotionFilter; }, options?: any) {
            return PromotionApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: PromotionModel; }, options?: any) {
            return PromotionApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: PromotionModel; }, options?: any) {
            return PromotionApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: PromotionModel; }, options?: any) {
            return PromotionApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * ReceiptApi - fetch parameter creator
 */
export const ReceiptApiFetchParamCreator = {
    /**
     * 
     * @param p 
     */
    downloadReceipt(params: {  "p"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Receipt/DownloadReceipt`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "p": params["p"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * ReceiptApi - functional programming interface
 */
export const ReceiptApiFp = {
    /**
     * 
     * @param p 
     */
    downloadReceipt(params: { "p"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = ReceiptApiFetchParamCreator.downloadReceipt(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * ReceiptApi - object-oriented interface
 */
export class ReceiptApi extends BaseAPI {
    /**
     * 
     * @param p 
     */
    downloadReceipt(params: {  "p"?: string; }, options?: any) {
        return ReceiptApiFp.downloadReceipt(params, options)(this.fetch, this.basePath);
    }
};

/**
 * ReceiptApi - factory interface
 */
export const ReceiptApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param p 
         */
        downloadReceipt(params: {  "p"?: string; }, options?: any) {
            return ReceiptApiFp.downloadReceipt(params, options)(fetch, basePath);
        },
    };
};


/**
 * SeedApi - fetch parameter creator
 */
export const SeedApiFetchParamCreator = {
    /**
     * 
     * @param langCode 
     */
    getSeed(params: {  "langCode"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetSeed`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "langCode": params["langCode"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param langCode 
     */
    getTexts(params: {  "langCode"?: string; }, options?: any): FetchArgs {
        const baseUrl = `/api/Seed/GetTexts`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "langCode": params["langCode"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SeedApi - functional programming interface
 */
export const SeedApiFp = {
    /**
     * 
     * @param langCode 
     */
    getSeed(params: { "langCode"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SeedModel> {
        const fetchArgs = SeedApiFetchParamCreator.getSeed(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param langCode 
     */
    getTexts(params: { "langCode"?: string;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: string; }> {
        const fetchArgs = SeedApiFetchParamCreator.getTexts(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SeedApi - object-oriented interface
 */
export class SeedApi extends BaseAPI {
    /**
     * 
     * @param langCode 
     */
    getSeed(params: {  "langCode"?: string; }, options?: any) {
        return SeedApiFp.getSeed(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param langCode 
     */
    getTexts(params: {  "langCode"?: string; }, options?: any) {
        return SeedApiFp.getTexts(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SeedApi - factory interface
 */
export const SeedApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param langCode 
         */
        getSeed(params: {  "langCode"?: string; }, options?: any) {
            return SeedApiFp.getSeed(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param langCode 
         */
        getTexts(params: {  "langCode"?: string; }, options?: any) {
            return SeedApiFp.getTexts(params, options)(fetch, basePath);
        },
    };
};


/**
 * SubFamilyApi - fetch parameter creator
 */
export const SubFamilyApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/SubFamily/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SubFamilyModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/SubFamily/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SubFamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SubFamily/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SubFamilyFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/SubFamily/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SubFamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SubFamily/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SubFamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SubFamily/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SubFamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SubFamily/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SubFamilyApi - functional programming interface
 */
export const SubFamilyApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SubFamilyApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<SubFamilyModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubFamilyModel>> {
        const fetchArgs = SubFamilyApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: SubFamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubFamilyModel> {
        const fetchArgs = SubFamilyApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: SubFamilyFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubFamilyModel>> {
        const fetchArgs = SubFamilyApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: SubFamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SubFamilyApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: SubFamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SubFamilyApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: SubFamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubFamilyModel> {
        const fetchArgs = SubFamilyApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SubFamilyApi - object-oriented interface
 */
export class SubFamilyApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return SubFamilyApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SubFamilyModel>; }, options?: any) {
        return SubFamilyApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SubFamilyModel; }, options?: any) {
        return SubFamilyApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SubFamilyFilter; }, options?: any) {
        return SubFamilyApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SubFamilyModel; }, options?: any) {
        return SubFamilyApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SubFamilyModel; }, options?: any) {
        return SubFamilyApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SubFamilyModel; }, options?: any) {
        return SubFamilyApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SubFamilyApi - factory interface
 */
export const SubFamilyApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return SubFamilyApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<SubFamilyModel>; }, options?: any) {
            return SubFamilyApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: SubFamilyModel; }, options?: any) {
            return SubFamilyApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: SubFamilyFilter; }, options?: any) {
            return SubFamilyApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: SubFamilyModel; }, options?: any) {
            return SubFamilyApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: SubFamilyModel; }, options?: any) {
            return SubFamilyApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: SubFamilyModel; }, options?: any) {
            return SubFamilyApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * SubmissionApi - fetch parameter creator
 */
export const SubmissionApiFetchParamCreator = {
    /**
     * 
     * @param model 
     */
    acceptSubmissionCurrency(params: {  "model"?: AcceptSubmissionModelCurrencyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionCurrency`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionFamily(params: {  "model"?: AcceptSubmissionModelFamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionFamily`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionMenu(params: {  "model"?: AcceptSubmissionModelMenuModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionMenu`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionMenuCategory(params: {  "model"?: AcceptSubmissionModelMenuCategoryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionMenuCategory`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionPriceName(params: {  "model"?: AcceptSubmissionModelPriceNameModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionPriceName`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionProduct(params: {  "model"?: AcceptSubmissionModelProductModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionProduct`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionPromotion(params: {  "model"?: AcceptSubmissionModelPromotionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionPromotion`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionSubFamily(params: {  "model"?: AcceptSubmissionModelSubFamilyModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionSubFamily`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionSupplierMain(params: {  "model"?: AcceptSubmissionModelSupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionSupplierMain`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionSupplierSub(params: {  "model"?: AcceptSubmissionModelSupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionSupplierSub`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionUnit(params: {  "model"?: AcceptSubmissionModelUnitModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionUnit`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionVat(params: {  "model"?: AcceptSubmissionModelVatModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/AcceptSubmissionVat`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    denySubmission(params: {  "model"?: DenySubmissionModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Submission/DenySubmission`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/Submission/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SubmissionApi - functional programming interface
 */
export const SubmissionApiFp = {
    /**
     * 
     * @param model 
     */
    acceptSubmissionCurrency(params: { "model"?: AcceptSubmissionModelCurrencyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelCurrencyModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionCurrency(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionFamily(params: { "model"?: AcceptSubmissionModelFamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelFamilyModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionFamily(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionMenu(params: { "model"?: AcceptSubmissionModelMenuModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelMenuModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionMenu(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionMenuCategory(params: { "model"?: AcceptSubmissionModelMenuCategoryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelMenuCategoryModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionMenuCategory(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionPriceName(params: { "model"?: AcceptSubmissionModelPriceNameModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelPriceNameModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionPriceName(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionProduct(params: { "model"?: AcceptSubmissionModelProductModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelProductModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionProduct(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionPromotion(params: { "model"?: AcceptSubmissionModelPromotionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelPromotionModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionPromotion(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionSubFamily(params: { "model"?: AcceptSubmissionModelSubFamilyModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelSubFamilyModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionSubFamily(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionSupplierMain(params: { "model"?: AcceptSubmissionModelSupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelSupplierModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionSupplierMain(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionSupplierSub(params: { "model"?: AcceptSubmissionModelSupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelSupplierModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionSupplierSub(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionUnit(params: { "model"?: AcceptSubmissionModelUnitModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelUnitModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionUnit(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    acceptSubmissionVat(params: { "model"?: AcceptSubmissionModelVatModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<AcceptSubmissionResultModelVatModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.acceptSubmissionVat(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    denySubmission(params: { "model"?: DenySubmissionModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SubmissionApiFetchParamCreator.denySubmission(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SubmissionModel>> {
        const fetchArgs = SubmissionApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SubmissionApi - object-oriented interface
 */
export class SubmissionApi extends BaseAPI {
    /**
     * 
     * @param model 
     */
    acceptSubmissionCurrency(params: {  "model"?: AcceptSubmissionModelCurrencyModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionCurrency(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionFamily(params: {  "model"?: AcceptSubmissionModelFamilyModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionFamily(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionMenu(params: {  "model"?: AcceptSubmissionModelMenuModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionMenu(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionMenuCategory(params: {  "model"?: AcceptSubmissionModelMenuCategoryModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionMenuCategory(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionPriceName(params: {  "model"?: AcceptSubmissionModelPriceNameModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionPriceName(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionProduct(params: {  "model"?: AcceptSubmissionModelProductModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionProduct(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionPromotion(params: {  "model"?: AcceptSubmissionModelPromotionModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionPromotion(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionSubFamily(params: {  "model"?: AcceptSubmissionModelSubFamilyModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionSubFamily(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionSupplierMain(params: {  "model"?: AcceptSubmissionModelSupplierModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionSupplierMain(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionSupplierSub(params: {  "model"?: AcceptSubmissionModelSupplierModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionSupplierSub(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionUnit(params: {  "model"?: AcceptSubmissionModelUnitModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionUnit(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    acceptSubmissionVat(params: {  "model"?: AcceptSubmissionModelVatModel; }, options?: any) {
        return SubmissionApiFp.acceptSubmissionVat(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    denySubmission(params: {  "model"?: DenySubmissionModel; }, options?: any) {
        return SubmissionApiFp.denySubmission(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     */
    getEntities(options?: any) {
        return SubmissionApiFp.getEntities(options)(this.fetch, this.basePath);
    }
};

/**
 * SubmissionApi - factory interface
 */
export const SubmissionApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param model 
         */
        acceptSubmissionCurrency(params: {  "model"?: AcceptSubmissionModelCurrencyModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionCurrency(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionFamily(params: {  "model"?: AcceptSubmissionModelFamilyModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionFamily(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionMenu(params: {  "model"?: AcceptSubmissionModelMenuModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionMenu(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionMenuCategory(params: {  "model"?: AcceptSubmissionModelMenuCategoryModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionMenuCategory(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionPriceName(params: {  "model"?: AcceptSubmissionModelPriceNameModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionPriceName(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionProduct(params: {  "model"?: AcceptSubmissionModelProductModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionProduct(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionPromotion(params: {  "model"?: AcceptSubmissionModelPromotionModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionPromotion(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionSubFamily(params: {  "model"?: AcceptSubmissionModelSubFamilyModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionSubFamily(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionSupplierMain(params: {  "model"?: AcceptSubmissionModelSupplierModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionSupplierMain(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionSupplierSub(params: {  "model"?: AcceptSubmissionModelSupplierModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionSupplierSub(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionUnit(params: {  "model"?: AcceptSubmissionModelUnitModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionUnit(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        acceptSubmissionVat(params: {  "model"?: AcceptSubmissionModelVatModel; }, options?: any) {
            return SubmissionApiFp.acceptSubmissionVat(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        denySubmission(params: {  "model"?: DenySubmissionModel; }, options?: any) {
            return SubmissionApiFp.denySubmission(params, options)(fetch, basePath);
        },
        /**
         * 
         */
        getEntities(options?: any) {
            return SubmissionApiFp.getEntities(options)(fetch, basePath);
        },
    };
};


/**
 * SupplierAdvertApi - fetch parameter creator
 */
export const SupplierAdvertApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierAdvert/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierAdvertModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierAdvert/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierAdvertModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierAdvert/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierAdvertFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierAdvert/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierAdvertModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierAdvert/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierAdvertModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierAdvert/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierAdvertModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierAdvert/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SupplierAdvertApi - functional programming interface
 */
export const SupplierAdvertApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SupplierAdvertApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<SupplierAdvertModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierAdvertModel>> {
        const fetchArgs = SupplierAdvertApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: SupplierAdvertModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierAdvertModel> {
        const fetchArgs = SupplierAdvertApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: SupplierAdvertFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierAdvertModel>> {
        const fetchArgs = SupplierAdvertApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: SupplierAdvertModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierAdvertApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: SupplierAdvertModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierAdvertApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: SupplierAdvertModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierAdvertModel> {
        const fetchArgs = SupplierAdvertApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SupplierAdvertApi - object-oriented interface
 */
export class SupplierAdvertApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return SupplierAdvertApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierAdvertModel>; }, options?: any) {
        return SupplierAdvertApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierAdvertModel; }, options?: any) {
        return SupplierAdvertApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierAdvertFilter; }, options?: any) {
        return SupplierAdvertApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierAdvertModel; }, options?: any) {
        return SupplierAdvertApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierAdvertModel; }, options?: any) {
        return SupplierAdvertApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierAdvertModel; }, options?: any) {
        return SupplierAdvertApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SupplierAdvertApi - factory interface
 */
export const SupplierAdvertApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return SupplierAdvertApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<SupplierAdvertModel>; }, options?: any) {
            return SupplierAdvertApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: SupplierAdvertModel; }, options?: any) {
            return SupplierAdvertApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: SupplierAdvertFilter; }, options?: any) {
            return SupplierAdvertApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: SupplierAdvertModel; }, options?: any) {
            return SupplierAdvertApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: SupplierAdvertModel; }, options?: any) {
            return SupplierAdvertApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: SupplierAdvertModel; }, options?: any) {
            return SupplierAdvertApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * SupplierDeliveryApi - fetch parameter creator
 */
export const SupplierDeliveryApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierDelivery/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierDeliveryModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierDelivery/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierDeliveryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierDelivery/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierDeliveryFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierDelivery/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierDeliveryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierDelivery/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierDeliveryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierDelivery/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierDeliveryModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierDelivery/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SupplierDeliveryApi - functional programming interface
 */
export const SupplierDeliveryApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SupplierDeliveryApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<SupplierDeliveryModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierDeliveryModel>> {
        const fetchArgs = SupplierDeliveryApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: SupplierDeliveryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierDeliveryModel> {
        const fetchArgs = SupplierDeliveryApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: SupplierDeliveryFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierDeliveryModel>> {
        const fetchArgs = SupplierDeliveryApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: SupplierDeliveryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierDeliveryApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: SupplierDeliveryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierDeliveryApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: SupplierDeliveryModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierDeliveryModel> {
        const fetchArgs = SupplierDeliveryApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SupplierDeliveryApi - object-oriented interface
 */
export class SupplierDeliveryApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return SupplierDeliveryApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierDeliveryModel>; }, options?: any) {
        return SupplierDeliveryApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierDeliveryModel; }, options?: any) {
        return SupplierDeliveryApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierDeliveryFilter; }, options?: any) {
        return SupplierDeliveryApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierDeliveryModel; }, options?: any) {
        return SupplierDeliveryApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierDeliveryModel; }, options?: any) {
        return SupplierDeliveryApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierDeliveryModel; }, options?: any) {
        return SupplierDeliveryApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SupplierDeliveryApi - factory interface
 */
export const SupplierDeliveryApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return SupplierDeliveryApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<SupplierDeliveryModel>; }, options?: any) {
            return SupplierDeliveryApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: SupplierDeliveryModel; }, options?: any) {
            return SupplierDeliveryApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: SupplierDeliveryFilter; }, options?: any) {
            return SupplierDeliveryApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: SupplierDeliveryModel; }, options?: any) {
            return SupplierDeliveryApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: SupplierDeliveryModel; }, options?: any) {
            return SupplierDeliveryApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: SupplierDeliveryModel; }, options?: any) {
            return SupplierDeliveryApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * SupplierMainApi - fetch parameter creator
 */
export const SupplierMainApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierMain/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierMain/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierMain/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierMainFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierMain/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierMain/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierMain/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierMain/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SupplierMainApi - functional programming interface
 */
export const SupplierMainApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SupplierMainApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<SupplierModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierModel>> {
        const fetchArgs = SupplierMainApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: SupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierModel> {
        const fetchArgs = SupplierMainApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: SupplierMainFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierModel>> {
        const fetchArgs = SupplierMainApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: SupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierMainApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: SupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierMainApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: SupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierModel> {
        const fetchArgs = SupplierMainApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SupplierMainApi - object-oriented interface
 */
export class SupplierMainApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return SupplierMainApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierModel>; }, options?: any) {
        return SupplierMainApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierModel; }, options?: any) {
        return SupplierMainApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierMainFilter; }, options?: any) {
        return SupplierMainApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierModel; }, options?: any) {
        return SupplierMainApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierModel; }, options?: any) {
        return SupplierMainApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierModel; }, options?: any) {
        return SupplierMainApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SupplierMainApi - factory interface
 */
export const SupplierMainApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return SupplierMainApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<SupplierModel>; }, options?: any) {
            return SupplierMainApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: SupplierModel; }, options?: any) {
            return SupplierMainApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: SupplierMainFilter; }, options?: any) {
            return SupplierMainApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: SupplierModel; }, options?: any) {
            return SupplierMainApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: SupplierModel; }, options?: any) {
            return SupplierMainApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: SupplierModel; }, options?: any) {
            return SupplierMainApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * SupplierPaymentApi - fetch parameter creator
 */
export const SupplierPaymentApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierPayment/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierPaymentModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierPayment/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierPaymentModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierPayment/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierPaymentFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierPayment/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierPaymentModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierPayment/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierPaymentModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierPayment/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierPaymentModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierPayment/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SupplierPaymentApi - functional programming interface
 */
export const SupplierPaymentApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SupplierPaymentApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<SupplierPaymentModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierPaymentModel>> {
        const fetchArgs = SupplierPaymentApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: SupplierPaymentModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierPaymentModel> {
        const fetchArgs = SupplierPaymentApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: SupplierPaymentFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierPaymentModel>> {
        const fetchArgs = SupplierPaymentApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: SupplierPaymentModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierPaymentApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: SupplierPaymentModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierPaymentApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: SupplierPaymentModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierPaymentModel> {
        const fetchArgs = SupplierPaymentApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SupplierPaymentApi - object-oriented interface
 */
export class SupplierPaymentApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return SupplierPaymentApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierPaymentModel>; }, options?: any) {
        return SupplierPaymentApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierPaymentModel; }, options?: any) {
        return SupplierPaymentApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierPaymentFilter; }, options?: any) {
        return SupplierPaymentApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierPaymentModel; }, options?: any) {
        return SupplierPaymentApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierPaymentModel; }, options?: any) {
        return SupplierPaymentApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierPaymentModel; }, options?: any) {
        return SupplierPaymentApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SupplierPaymentApi - factory interface
 */
export const SupplierPaymentApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return SupplierPaymentApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<SupplierPaymentModel>; }, options?: any) {
            return SupplierPaymentApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: SupplierPaymentModel; }, options?: any) {
            return SupplierPaymentApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: SupplierPaymentFilter; }, options?: any) {
            return SupplierPaymentApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: SupplierPaymentModel; }, options?: any) {
            return SupplierPaymentApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: SupplierPaymentModel; }, options?: any) {
            return SupplierPaymentApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: SupplierPaymentModel; }, options?: any) {
            return SupplierPaymentApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * SupplierSubApi - fetch parameter creator
 */
export const SupplierSubApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierSub/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierSub/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierSub/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierSubFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierSub/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierSub/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierSub/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/SupplierSub/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * SupplierSubApi - functional programming interface
 */
export const SupplierSubApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = SupplierSubApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<SupplierModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierModel>> {
        const fetchArgs = SupplierSubApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: SupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierModel> {
        const fetchArgs = SupplierSubApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: SupplierSubFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<SupplierModel>> {
        const fetchArgs = SupplierSubApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: SupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierSubApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: SupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = SupplierSubApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: SupplierModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SupplierModel> {
        const fetchArgs = SupplierSubApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * SupplierSubApi - object-oriented interface
 */
export class SupplierSubApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return SupplierSubApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<SupplierModel>; }, options?: any) {
        return SupplierSubApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: SupplierModel; }, options?: any) {
        return SupplierSubApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: SupplierSubFilter; }, options?: any) {
        return SupplierSubApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: SupplierModel; }, options?: any) {
        return SupplierSubApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: SupplierModel; }, options?: any) {
        return SupplierSubApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: SupplierModel; }, options?: any) {
        return SupplierSubApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * SupplierSubApi - factory interface
 */
export const SupplierSubApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return SupplierSubApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<SupplierModel>; }, options?: any) {
            return SupplierSubApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: SupplierModel; }, options?: any) {
            return SupplierSubApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: SupplierSubFilter; }, options?: any) {
            return SupplierSubApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: SupplierModel; }, options?: any) {
            return SupplierSubApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: SupplierModel; }, options?: any) {
            return SupplierSubApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: SupplierModel; }, options?: any) {
            return SupplierSubApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * TagApi - fetch parameter creator
 */
export const TagApiFetchParamCreator = {
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: TagModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Tag/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     */
    getEntities(options?: any): FetchArgs {
        const baseUrl = `/api/Tag/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TagApi - functional programming interface
 */
export const TagApiFp = {
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: TagModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<TagModel> {
        const fetchArgs = TagApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     */
    getEntities(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<{ [key: string]: TagModel; }> {
        const fetchArgs = TagApiFetchParamCreator.getEntities(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TagApi - object-oriented interface
 */
export class TagApi extends BaseAPI {
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: TagModel; }, options?: any) {
        return TagApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     */
    getEntities(options?: any) {
        return TagApiFp.getEntities(options)(this.fetch, this.basePath);
    }
};

/**
 * TagApi - factory interface
 */
export const TagApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: TagModel; }, options?: any) {
            return TagApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         */
        getEntities(options?: any) {
            return TagApiFp.getEntities(options)(fetch, basePath);
        },
    };
};


/**
 * TransferApi - fetch parameter creator
 */
export const TransferApiFetchParamCreator = {
    /**
     * 
     * @param storeId 
     */
    downloadImages(params: {  "storeId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Transfer/DownloadImages`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "storeId": params["storeId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param storeId 
     */
    generateV6Xml(params: {  "storeId"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Transfer/GenerateV6Xml`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "storeId": params["storeId"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     */
    notifyModerators(options?: any): FetchArgs {
        const baseUrl = `/api/Transfer/NotifyModerators`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "GET" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * TransferApi - functional programming interface
 */
export const TransferApiFp = {
    /**
     * 
     * @param storeId 
     */
    downloadImages(params: { "storeId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = TransferApiFetchParamCreator.downloadImages(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param storeId 
     */
    generateV6Xml(params: { "storeId"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = TransferApiFetchParamCreator.generateV6Xml(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     */
    notifyModerators(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = TransferApiFetchParamCreator.notifyModerators(options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * TransferApi - object-oriented interface
 */
export class TransferApi extends BaseAPI {
    /**
     * 
     * @param storeId 
     */
    downloadImages(params: {  "storeId"?: number; }, options?: any) {
        return TransferApiFp.downloadImages(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param storeId 
     */
    generateV6Xml(params: {  "storeId"?: number; }, options?: any) {
        return TransferApiFp.generateV6Xml(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     */
    notifyModerators(options?: any) {
        return TransferApiFp.notifyModerators(options)(this.fetch, this.basePath);
    }
};

/**
 * TransferApi - factory interface
 */
export const TransferApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param storeId 
         */
        downloadImages(params: {  "storeId"?: number; }, options?: any) {
            return TransferApiFp.downloadImages(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param storeId 
         */
        generateV6Xml(params: {  "storeId"?: number; }, options?: any) {
            return TransferApiFp.generateV6Xml(params, options)(fetch, basePath);
        },
        /**
         * 
         */
        notifyModerators(options?: any) {
            return TransferApiFp.notifyModerators(options)(fetch, basePath);
        },
    };
};


/**
 * UnitApi - fetch parameter creator
 */
export const UnitApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Unit/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<UnitModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Unit/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: UnitModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Unit/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: UnitFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/Unit/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: UnitModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Unit/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: UnitModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Unit/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: UnitModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Unit/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * UnitApi - functional programming interface
 */
export const UnitApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = UnitApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<UnitModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UnitModel>> {
        const fetchArgs = UnitApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: UnitModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UnitModel> {
        const fetchArgs = UnitApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: UnitFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<UnitModel>> {
        const fetchArgs = UnitApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: UnitModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = UnitApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: UnitModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = UnitApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: UnitModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<UnitModel> {
        const fetchArgs = UnitApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * UnitApi - object-oriented interface
 */
export class UnitApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return UnitApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<UnitModel>; }, options?: any) {
        return UnitApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: UnitModel; }, options?: any) {
        return UnitApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: UnitFilter; }, options?: any) {
        return UnitApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: UnitModel; }, options?: any) {
        return UnitApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: UnitModel; }, options?: any) {
        return UnitApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: UnitModel; }, options?: any) {
        return UnitApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * UnitApi - factory interface
 */
export const UnitApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return UnitApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<UnitModel>; }, options?: any) {
            return UnitApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: UnitModel; }, options?: any) {
            return UnitApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: UnitFilter; }, options?: any) {
            return UnitApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: UnitModel; }, options?: any) {
            return UnitApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: UnitModel; }, options?: any) {
            return UnitApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: UnitModel; }, options?: any) {
            return UnitApiFp.update(params, options)(fetch, basePath);
        },
    };
};


/**
 * VatApi - fetch parameter creator
 */
export const VatApiFetchParamCreator = {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any): FetchArgs {
        const baseUrl = `/api/Vat/Delete`;
        let urlObj = url.parse(baseUrl, true);
        urlObj.query = assign({}, urlObj.query, {
            "id": params["id"],
        });
        let fetchOptions: RequestInit = assign({}, { method: "DELETE" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<VatModel>; }, options?: any): FetchArgs {
        const baseUrl = `/api/Vat/BulkUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["models"]) {
            fetchOptions.body = JSON.stringify(params["models"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: VatModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Vat/Create`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: VatFilter; }, options?: any): FetchArgs {
        const baseUrl = `/api/Vat/GetEntities`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: VatModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Vat/SubmitCreate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: VatModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Vat/SubmitUpdate`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "POST" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: VatModel; }, options?: any): FetchArgs {
        const baseUrl = `/api/Vat/Update`;
        let urlObj = url.parse(baseUrl, true);
        let fetchOptions: RequestInit = assign({}, { method: "PUT" }, options);

        let contentTypeHeader: Dictionary<string> = {};
        contentTypeHeader = { "Content-Type": "application/json" };
        if (params["model"]) {
            fetchOptions.body = JSON.stringify(params["model"] || {});
        }
        if (contentTypeHeader) {
            fetchOptions.headers = assign({}, contentTypeHeader, fetchOptions.headers);
        }
        return {
            url: url.format(urlObj),
            options: fetchOptions,
        };
    },
};

/**
 * VatApi - functional programming interface
 */
export const VatApiFp = {
    /**
     * 
     * @param id 
     */
    _delete(params: { "id"?: number;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<any> {
        const fetchArgs = VatApiFetchParamCreator._delete(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response;
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: { "models"?: Array<VatModel>;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VatModel>> {
        const fetchArgs = VatApiFetchParamCreator.bulkUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    create(params: { "model"?: VatModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VatModel> {
        const fetchArgs = VatApiFetchParamCreator.create(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    getEntities(params: { "model"?: VatFilter;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<Array<VatModel>> {
        const fetchArgs = VatApiFetchParamCreator.getEntities(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitCreate(params: { "model"?: VatModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = VatApiFetchParamCreator.submitCreate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    submitUpdate(params: { "model"?: VatModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<SubmissionModel> {
        const fetchArgs = VatApiFetchParamCreator.submitUpdate(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
    /**
     * 
     * @param model 
     */
    update(params: { "model"?: VatModel;  }, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<VatModel> {
        const fetchArgs = VatApiFetchParamCreator.update(params, options);
        return (fetch: FetchAPI = isomorphicFetch, basePath: string = BASE_PATH) => {
            return fetch(basePath + fetchArgs.url, fetchArgs.options).then((response) => {
                if (response.status >= 200 && response.status < 300) {
                    return response.json();
                } else {
                    throw response;
                }
            });
        };
    },
};

/**
 * VatApi - object-oriented interface
 */
export class VatApi extends BaseAPI {
    /**
     * 
     * @param id 
     */
    _delete(params: {  "id"?: number; }, options?: any) {
        return VatApiFp._delete(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param models 
     */
    bulkUpdate(params: {  "models"?: Array<VatModel>; }, options?: any) {
        return VatApiFp.bulkUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    create(params: {  "model"?: VatModel; }, options?: any) {
        return VatApiFp.create(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    getEntities(params: {  "model"?: VatFilter; }, options?: any) {
        return VatApiFp.getEntities(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitCreate(params: {  "model"?: VatModel; }, options?: any) {
        return VatApiFp.submitCreate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    submitUpdate(params: {  "model"?: VatModel; }, options?: any) {
        return VatApiFp.submitUpdate(params, options)(this.fetch, this.basePath);
    }
    /**
     * 
     * @param model 
     */
    update(params: {  "model"?: VatModel; }, options?: any) {
        return VatApiFp.update(params, options)(this.fetch, this.basePath);
    }
};

/**
 * VatApi - factory interface
 */
export const VatApiFactory = function (fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * 
         * @param id 
         */
        _delete(params: {  "id"?: number; }, options?: any) {
            return VatApiFp._delete(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param models 
         */
        bulkUpdate(params: {  "models"?: Array<VatModel>; }, options?: any) {
            return VatApiFp.bulkUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        create(params: {  "model"?: VatModel; }, options?: any) {
            return VatApiFp.create(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        getEntities(params: {  "model"?: VatFilter; }, options?: any) {
            return VatApiFp.getEntities(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitCreate(params: {  "model"?: VatModel; }, options?: any) {
            return VatApiFp.submitCreate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        submitUpdate(params: {  "model"?: VatModel; }, options?: any) {
            return VatApiFp.submitUpdate(params, options)(fetch, basePath);
        },
        /**
         * 
         * @param model 
         */
        update(params: {  "model"?: VatModel; }, options?: any) {
            return VatApiFp.update(params, options)(fetch, basePath);
        },
    };
};

