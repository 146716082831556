import { Action, Reducer } from 'redux';
import * as Api from "../api/api";
import { getDefaultHeaders } from "../utils/utils";
import { AppThunkAction } from ".";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface ImageState {
    imageStates: { [key: string]: ImageUploadState };
}

export interface ImageUploadState {
    isLoading: boolean;
    requestTime: number;
    imageId: number;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

interface RequestUpdateImage { type: "REQUEST_UPLOAD_IMAGE"; requestTime: number; key: string }
interface ReceiveUpdateImage { type: "RECEIVE_UPLOAD_IMAGE"; requestTime: number; key: string; imageId: number }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestUpdateImage | ReceiveUpdateImage;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestCreateImage: (requestTime: number, key: string, file: File): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (getState().image.imageStates[key]
            && requestTime === getState().image.imageStates[key].requestTime)
            return Promise.reject("already did");

        if (file.size / 1024 / 1024 > 5) {
            alert("File too big, max is 5MB.");
            return Promise.reject("File too big");
        }    

        let img = new Image();
        img.src = window.URL.createObjectURL(file);
        img.onload = () => {
            if(320 > img.width || 320 > img.height || 6000 < img.width || 6000 < img.height){
                alert("File must be between 320px and 6000px");
            }
        }
        
        let formData = new FormData();
        formData.append("file", file);

        let fetchTask = fetch("/api/Image/Create", {
            method: "POST",
            body: formData,
            credentials: "same-origin",
            headers: getDefaultHeaders(getState())
        }).then(response => response.json() as Api.ImageModel)
            .then(image => {
                dispatch({ type: "RECEIVE_UPLOAD_IMAGE", requestTime: requestTime, key: key, imageId: image.imageId });
            }).catch(error => {
                dispatch({ type: "RECEIVE_UPLOAD_IMAGE", requestTime: requestTime, key: key, imageId: null });
            });

        dispatch({ type: "REQUEST_UPLOAD_IMAGE", requestTime: requestTime, key: key });
        return fetchTask;
    }
};

export const unloadedUploadState: ImageUploadState = {
    isLoading: false,
    requestTime: 0,
    imageId: null
};

const unloadedState: ImageState = {
    imageStates: {}
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<ImageState> = (state: ImageState, incomingAction: Action) => {
    let action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_UPLOAD_IMAGE":
            return {
                ...state,
                imageStates: {
                    ...state.imageStates,
                    [action.key]: {
                        ...state.imageStates[action.key],
                        isLoading: true,
                        requestTime: action.requestTime,
                        imageId: null
                    }
                }
            };
        case "RECEIVE_UPLOAD_IMAGE":
            if (!state.imageStates[action.key]
                || state.imageStates[action.key].requestTime !== action.requestTime)
                return state;

            return {
                ...state,
                imageStates: {
                    ...state.imageStates,
                    [action.key]: {
                        ...state.imageStates[action.key],
                        isLoading: false,
                        imageId: action.imageId
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || unloadedState;
};
