import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as Styles from '../styles/baseStyle';
import { RouteComponentProps } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as TransferStore from '../store/Transfer';
import Select from "./Select";
import { connect } from 'react-redux';
import { canCrude } from '../utils/userRights';

type TransferProps = TransferStore.TransferState
    & TransferOwnProps
    & typeof TransferStore.actionCreators;

interface TransferOwnProps {
    account: Api.AccountModel;
    stores: { [id: number]: Api.StoreModel }
}


class Transfer extends React.Component<TransferProps, {}> {

    public render() {
        return <div style={{ width: "100%" }}>
            {canCrude(this.props.account)
                && <div style={{ width: "100%" }}>
                <div style={{ marginBottom: 10 }}>
                    <div style={{ maxWidth: 500, margin: "auto" }}>
                        <div className="row">
                            <label className="control-label col-md-3"></label>
                            <Select options={_.sortBy(_.values(this.props.stores), x => x.name)
                                .map(x => ({ label: x.name, value: x.storeId }))}
                                value={this.props.storeId}
                                onChange={(value) => this.props.updateStoreId(value)} />
                        </div>
                    </div>
                </div>
                <div style={{ textAlign: "center", marginBottom: 50 }}>
                    <h2>Fichier XML V6</h2>
                    <button className="btn btn-primary btn-lg"
                        disabled={this.props.downloadXml.isLoading}
                        onClick={(e) => this.props.requestV6Xml(new Date().getTime())}>
                        Télécharger XML
                </button>
                </div>
                <div style={{ textAlign: "center" }}>
                    <h2>Images</h2>
                    <button className="btn btn-primary btn-lg"
                        disabled={this.props.downloadImages.isLoading}
                        onClick={(e) => this.props.requestImagesZip(new Date().getTime())}>
                        Télécharger ZIP
                </button>
                </div>

                </div>}
            {!canCrude(this.props.account)
                && <div style={{ width: "100%" }}>
                <div style={{ textAlign: "center", marginTop: 50 }}>
                    <h2 style={{ marginBottom: 20 }}>Notifier des changements</h2>

                    <button className="btn btn-primary btn-lg"
                        disabled={this.props.notifyModerators.isLoading
                            || (this.props.notifyModerators.sentTime + 1000 * 60 * 5 <= new Date().getTime())}
                        onClick={(e) => this.props.requestNotifyModerators(new Date().getTime())}>
                            Envoyer un email à l'équipe LGAP
                    </button>
                    </div>
                </div>}
        </div>;
    }
}

//<div style={{ textAlign: "center" }}>
//    <h2>Données de prix</h2>
//    <button className="btn btn-primary btn-lg"
//        disabled={this.props.downloadProductPrices.isLoading}
//        onClick={(e) => this.props.requestDownloadProductPrices(new Date().getTime())}>
//        Telecharger CSV
//                    </button>
//</div>


export default connect((state: ApplicationState) =>
    ({
        ...state.transfer,
        stores: state.seed.seed.stores,
        account: state.account.currentUser
            ? state.account.currentUser.account
            : null
    } as TransferOwnProps), TransferStore.actionCreators)(Transfer)