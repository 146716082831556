import * as React from "react";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as AccountStore from "../store/Account";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import DialogModal from "./DialogModal";
import CheckBox from "./CheckBox";

type StoreSelectionDialogProps = AccountStore.AccountState
    & StoreSelectionDialogOwnProps
    & typeof AccountStore.actionCreators;

interface StoreSelectionDialogOwnProps {
    storeGroups: { [id: number]: Api.StoreGroupModel };
    allowedStores: { [id: number]: Api.StoreModel };
}

class StoreSelectionDialog extends React.Component<StoreSelectionDialogProps, {}>{
    public render() {
        return (
            <div>
                <img src={"/images/storeIcon.png"}
                    height={20}
                    style={{ cursor: "pointer" }}
                    onClick={() => this.props.openSelectionStoreDialog()} />
                <DialogModal isOpen={this.props.storeSelectionState.isOpen}
                    width={740}
                    height={500}
                    contentLabel={"Store selection"}
                    onRequestClose={() => this.props.closeSelectionStoreDialog()}>
                    <div>
                        <h3 style={{ textAlign: "center" }}>Filtre de magasins</h3>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            margin: 10
                        }}>
                            <CheckBox label={"Tout sélectionner"}
                                labelStyle={{ fontWeight: "bold" }}
                                onChange={(value) => this.props.selectAllStores(value)}
                                value={!_.values(this.props.allowedStores)
                                    .some(x => this.props.storeSelectionState.storesState[x.storeId]
                                        && !this.props.storeSelectionState.storesState[x.storeId].isSelected)} />
                        </div>
                        <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
                            {_.values(this.props.storeGroups)
                                .filter(x => _.values(this.props.allowedStores).some(y => y.storeGroupId === x.storeGroupId))
                                .map(storeGroup => <div key={storeGroup.storeGroupId}
                                    style={{
                                        margin: 10,
                                        padding: 10,
                                        width: 200,
                                        border: "1px solid"
                                    }}>
                                    <div>
                                        <CheckBox label={storeGroup.name}
                                            labelStyle={{ fontWeight: "bold" }}
                                            onChange={(value) => this.props.selecteStoreGroup(storeGroup.storeGroupId
                                                , _.values(this.props.allowedStores)
                                                    .filter(x => x.storeGroupId === storeGroup.storeGroupId)
                                                    .map(x => x.storeId)
                                                , value)}
                                            value={!_.values(this.props.allowedStores)
                                                .filter(x => x.storeGroupId === storeGroup.storeGroupId)
                                                .some(x => this.props.storeSelectionState.storesState[x.storeId]
                                                    && !this.props.storeSelectionState.storesState[x.storeId].isSelected)} />
                                    </div>
                                    <div>
                                        {_.values(this.props.allowedStores)
                                            .filter(x => x.storeGroupId === storeGroup.storeGroupId)
                                            .map(store => <div style={{ marginLeft: 6 }} key={store.storeId}>
                                                <CheckBox label={store.name}
                                                    onChange={(value) => this.props.selecteStore(
                                                        store.storeId
                                                        , value)}
                                                    value={!this.props.storeSelectionState.storesState[store.storeId]
                                                        || this.props.storeSelectionState.storesState[store.storeId].isSelected} />
                                            </div>)}
                                    </div>
                                </div>)}
                        </div>
                    </div>
                </DialogModal>
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.account,
    storeGroups: state.seed.seed.storeGroups,
    allowedStores: AccountStore.allowedStores(state)
} as StoreSelectionDialogOwnProps), AccountStore.actionCreators)(StoreSelectionDialog as any);