import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import * as CrudeStore from '../store/Crude';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector, FieldArray
} from 'redux-form';
import EntityCustomizationsField, { EntityCustomizationsOwnProps } from "./EntityCustomizationsField";
import { promotionValidation } from "../utils/validation";
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import { diff } from 'deep-object-diff';
import { selectedStores } from '../store/Account';

type PromotionFormProps = InjectedFormProps
    & FormField.FormProps
    & PromotionFormExternalProps
    & PromotionFormOwnProps;

interface PromotionFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.PromotionModel) => any;
}

interface PromotionFormOwnProps {
    languages: { [id: number]: Api.LanguageModel }
    stores: { [id: number]: Api.StoreModel }
    storeGroups: { [id: number]: Api.StoreGroupModel }
    promotionCustoms: Array<Api.PromotionCustomModel>;
    disabled?: boolean;
    formType: CrudeStore.FormType;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
    allowedStores: Array<number>;
}

export const typeOptions: Array<{ label: string; value: Api.PromotionModelTypeEnum }> = [
    { label: "Visible", value: "Displayed" },
    { label: "Non visible", value: "NotDisplayed" }
];

class PromotionForm extends React.Component<PromotionFormProps, {}> {

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create";
    }

    public render() {
        return (
            <FormField.BaseForm className="" {...this.props}>
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                        textAlign: "center",
                        border: "1px solid black"
                    }}>Données globales</legend>
                    <div className="form-horizontal">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Nom:"}</label>
                            <div className="col-sm-8">
                                <Field className="form-control"
                                    validate={promotionValidation["name"]}
                                    name="name"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Type:"}</label>
                            <div className="col-sm-8">
                                <Field className="form-control"
                                    validate={promotionValidation["type"]}
                                    name="type"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.getSelectField(typeOptions)} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Image:"}</label>
                            <div className="col-sm-8">
                                <Field className="form-control"
                                    key={"productImagineId"}
                                    validate={promotionValidation["imageId"]}
                                    name="imageId"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.ImageField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Ordre:"}</label>
                            <div className="col-sm-8">
                                <Field className="form-control"
                                    validate={promotionValidation["order"]}
                                    name="order"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Customisations:"}</label>
                            <div className="col-sm-8">
                                <FieldArray 
                                    validate={[]}
                                    name="promotionCustoms"
                                    component={EntityCustomizationsField}
                                    props={{
                                        languages: this.props.languages,
                                        storeGroups: this.props.storeGroups,
                                        entityCustoms: this.props.promotionCustoms,
                                        disabled: this.props.disabled
                                            || this.globalFieldDisabled,
                                        allowedStoreGroup: _.values(this.props.storeGroups)
                                            .filter(x => this.props.allowedStores.some(y => this.props.stores[y].storeGroupId === x.storeGroupId))
                                            .map(x => x.storeGroupId),
                                        dataType: "Global"
                                    } as EntityCustomizationsOwnProps} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                        textAlign: "center",
                        border: "1px solid black"
                    }}>Données globales</legend>
                    <div className="form-group row">
                        <label className="col-sm-4 control-label ">{"Customisations:"}</label>
                        <div className="col-sm-8">
                            <FieldArray 
                                validate={[]}
                                name="promotionCustoms"
                                component={EntityCustomizationsField}
                                props={{
                                    languages: this.props.languages,
                                    storeGroups: this.props.storeGroups,
                                    entityCustoms: this.props.promotionCustoms,
                                    disabled: this.props.disabled
                                        || this.globalFieldDisabled,
                                    allowedStoreGroup: _.values(this.props.storeGroups)
                                        .filter(x => this.props.allowedStores.some(y => this.props.stores[y].storeGroupId === x.storeGroupId))
                                        .map(x => x.storeGroupId),
                                    dataType: "Specific"
                                } as EntityCustomizationsOwnProps} />
                        </div>
                    </div>
                </fieldset>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType !== "Create"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = reduxForm({
    form: 'createPromotion',
    destroyOnUnmount: false,
    enableReinitialize: true
})(PromotionForm) as any;
const createSelector = formValueSelector('createPromotion')

let updateForm = reduxForm({
    form: 'updatePromotion',
    destroyOnUnmount: false,
    enableReinitialize: true
})(PromotionForm) as any;
const updateSelector = formValueSelector('updatePromotion')

let submissionForm = reduxForm({
    form: 'submissionPromotion',
    destroyOnUnmount: false,
    enableReinitialize: true
})(PromotionForm) as any;
const submissionSelector = formValueSelector('submissionPromotion')


export const CreatePromotionForm = connect((state: ApplicationState) => {
    return {
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        formType: "Create",
        account: state.account.currentUser.account,
        promotionCustoms: createSelector(state, "promotionCustoms") || [],
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
    } as PromotionFormOwnProps;
})(createForm as any) as any as React.ComponentClass<PromotionFormExternalProps>;

export const UpdatePromotionForm = connect((state: ApplicationState) => {
    return {
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        formType: "Update",
        disabled: !((state.promotion.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
                .some(x => x.promotionId === state.promotion.selectedEntityId)),
        initialValues: state.promotion.entities[state.promotion.selectedEntityId],
        account: state.account.currentUser.account,
        promotionCustoms: updateSelector(state, "promotionCustoms") || [],
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
    } as PromotionFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<PromotionFormExternalProps>;

export const SubmissionPromotionForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        formType: "Submission",
        initialValues: JSON.parse(submission.content),
        account: state.account.currentUser.account,
        promotionCustoms: submissionSelector(state, "promotionCustoms") || [],
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.promotion.entities[submission.promotionId])
            : {},
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
    } as PromotionFormOwnProps;
})(submissionForm as any) as any as React.ComponentClass<PromotionFormExternalProps>;
