import { connectedRouterRedirect } from 'redux-auth-wrapper/history4/redirect'
import { ApplicationState } from '../store/index';

export const isAuthenticated = (state: ApplicationState): boolean => (state.account.currentUser
    && state.account.currentUser.expirationDate
    && new Date() < new Date(state.account.currentUser.expirationDate)
    && state.account.currentUser.account)
    ? true : false;

export default connectedRouterRedirect({
    // The url to redirect user to if they fail
    redirectPath: '/login',
    authenticatedSelector: (state: ApplicationState) => isAuthenticated(state),
    wrapperDisplayName: 'UserIsAuthenticated'
}) as any