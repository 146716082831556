import * as Api from "../api/api";

export const canCrude = (account: Api.AccountModel): boolean => {
    return account
        && account.accountRoles
        && account.accountRoles.some(x => ["Maintenance", "Master"]
            .some(y => y === x.role.name));
};

export const canSubmit = (account: Api.AccountModel): boolean => {
    return !canCrude(account);
};

export const canAccessAllStores = (account: Api.AccountModel): boolean => {
    return account
        && account.accountRoles
        && account.accountRoles.some(x => ["Maintenance", "Master"]
            .some(y => y === x.role.name));
};