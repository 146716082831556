import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector, FieldArray
} from 'redux-form';
import EntityCustomizationsField, { EntityCustomizationsOwnProps } from "./EntityCustomizationsField";
import { familyValidation } from "../utils/validation";
import { FormType } from '../store/Crude';
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import { diff } from 'deep-object-diff';
import { selectedStores } from '../store/Account';

type FamilyFormProps = InjectedFormProps
    & FormField.FormProps
    & FamilyFormExternalProps
    & FamilyFormOwnProps;

interface FamilyFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.FamilyModel) => any;
}

interface FamilyFormOwnProps {
    familyId: number;
    familyCustoms: Array<Api.FamilyCustomModel>;
    entities: { [id: number]: Api.FamilyModel };
    languages: { [id: number]: Api.LanguageModel }
    stores: { [id: number]: Api.StoreModel }
    storeGroups: { [id: number]: Api.StoreGroupModel }
    disabled?: boolean;
    formType: FormType;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
    allowedStores: Array<number>;
}

class FamilyForm extends React.Component<FamilyFormProps, {}> {
    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create";
    }

    public render() {
        return (
            <FormField.BaseForm className="" {...this.props}>
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                        textAlign: "center",
                        border: "1px solid black"
                    }}>Données globales</legend>
                    <div className="form-horizontal">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Nom:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("name")}>
                                <Field className="form-control"
                                    validate={familyValidation["name"]}
                                    name="name"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.renderField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Image:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("imageId")}>
                                <Field className="form-control"
                                    fieldKey={"familyImagineId-" + this.props.formType}
                                    validate={familyValidation["imageId"]}
                                    name="imageId"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    acceptProp={".jpg, .jpeg"}
                                    component={FormField.ImageField}
                                    type="text" />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Ordre:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("order")}>
                                <Field className="form-control"
                                    validate={familyValidation["order"]}
                                    name="order"
                                    disabled={this.props.disabled
                                        || this.globalFieldDisabled}
                                    component={FormField.NumberField} />
                            </div>
                        </div>
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Customisations:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("familyCustoms")}>
                                <FieldArray
                                    validate={[]}
                                    name="familyCustoms"
                                    component={EntityCustomizationsField}
                                    props={{
                                        languages: this.props.languages,
                                        storeGroups: this.props.storeGroups,
                                        entityCustoms: this.props.familyCustoms,
                                        dataType: "Global",
                                        disabled: this.props.disabled,
                                        allowedStoreGroup: _.values(this.props.storeGroups)
                                            .filter(x => this.props.allowedStores.some(y => this.props.stores[y].storeGroupId === x.storeGroupId))
                                            .map(x => x.storeGroupId)
                                    } as EntityCustomizationsOwnProps} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <fieldset style={{ margin: 10, padding: 10, border: "1px solid", paddingRight: 25 }}>
                    <legend style={{
                        textAlign: "center",
                        border: "1px solid black"
                    }}>Données par magasin</legend>
                    <div className="form-horizontal">
                        <div className="form-group row">
                            <label className="col-sm-4 control-label ">{"Customisations:"}</label>
                            <div className="col-sm-8" style={this.getFieldStyle("familyCustoms")}>
                                <FieldArray 
                                    validate={[]}
                                    name="familyCustoms"
                                    component={EntityCustomizationsField}
                                    props={{
                                        languages: this.props.languages,
                                        storeGroups: this.props.storeGroups,
                                        entityCustoms: this.props.familyCustoms,
                                        dataType: "Specific",
                                        disabled: this.props.disabled,
                                        allowedStoreGroup: _.values(this.props.storeGroups)
                                            .filter(x => this.props.allowedStores.some(y => this.props.stores[y].storeGroupId === x.storeGroupId))
                                            .map(x => x.storeGroupId)
                                    } as EntityCustomizationsOwnProps} />
                            </div>
                        </div>
                    </div>
                </fieldset>
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType === "Update"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
            );
    }
}

let createForm = reduxForm({
    form: 'createFamily',
    destroyOnUnmount: false,
    enableReinitialize: true
})(FamilyForm) as any;
const createSelector = formValueSelector('createFamily')

let updateForm = reduxForm({
    form: 'updateFamily',
    destroyOnUnmount: false,
    enableReinitialize: true
})(FamilyForm) as any;
const updateSelector = formValueSelector('updateFamily')

let submissionForm = reduxForm({
    form: 'submissionFamily',
    destroyOnUnmount: false,
    enableReinitialize: true
})(FamilyForm) as any;
const submissionSelector = formValueSelector('submissionFamily')


export const CreateFamilyForm = connect((state: ApplicationState) => {
    return {
        familyId: 0,
        familyCustoms: createSelector(state, "familyCustoms") || [],
        entities: state.family.entities,
        languages: state.seed.seed.languages,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        formType: "Create",
        account: state.account.currentUser.account
    } as FamilyFormOwnProps;
})(createForm as any) as any as React.ComponentClass<FamilyFormExternalProps>

export const UpdateFamilyForm = connect((state: ApplicationState) => {
    return {
        familyId: state.family.selectedEntityId,
        familyCustoms: updateSelector(state, "familyCustoms") || [],
        entities: state.family.entities,
        languages: state.seed.seed.languages,
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        formType: "Update",
        disabled: !((state.family.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
            .some(x => x.familyId === state.family.selectedEntityId)),
        initialValues: state.family.entities[state.family.selectedEntityId],
        account: state.account.currentUser.account
    } as FamilyFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<FamilyFormExternalProps>

export const SubmissionFamilyForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        familyId: submission.familyId,
        familyCustoms: submissionSelector(state, "familyCustoms") || [],
        entities: state.family.entities,
        languages: state.seed.seed.languages,
        allowedStores: _.values(selectedStores(state)).map(x => x.storeId),
        storeGroups: state.seed.seed.storeGroups,
        stores: state.seed.seed.stores,
        formType: "Submission",
        initialValues: JSON.parse(submission.content),
        account: state.account.currentUser.account,
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.family.entities[submission.familyId])
            : {}
    } as FamilyFormOwnProps;
})(submissionForm as any) as any as React.ComponentClass<FamilyFormExternalProps>