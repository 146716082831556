import * as Api from '../api/api';
import * as React from 'react';
import * as Styles from '../styles/baseStyle';
import { NavLink, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import { isAuthenticated } from '../security/UserIsAuthenticated';
import Image from "./Image";
import Tooltip from "./Tooltip";
import { canCrude } from '../utils/userRights';
import * as _ from 'lodash';
import * as AccountStore from "../store/Account";

type NavMenuProps  = NavMenuOwnProps
    & typeof AccountStore.actionCreators;

interface NavMenuOwnProps {
    isAuthenticated: boolean;
    hasPendingsubmissions: boolean;
    account: Api.AccountModel;
    allowedStores: { [id: number]: Api.StoreModel };
}

const navContainerStyle: React.CSSProperties = { flex: "1", padding: 0, alignSelf: "center", width: "100%" };
const navStyle = {
    display: "block",
    width: "100%",
    height: "100%",
    padding: 2
};
const imgStyle = { };

const navActiveStyle = { backgroundColor: "#4189C7" };

interface NavItemProps {
    to: string;
    imgSrc: string;
    tooltip: string | JSX.Element;
    className?: string;
}

class NavItem extends React.Component<NavItemProps, {}> {
    public render() {
        return (
            <Tooltip containerStyle={{ height: "100%", width: "100%" }}
                overlay={<div>{this.props.tooltip}</div>}>
                <NavLink exact to={this.props.to}
                    style={navStyle}
                    activeStyle={navActiveStyle}>
                    <Image fit={"contain"}
                        className={this.props.className}
                        style={imgStyle}
                        src={this.props.imgSrc} />
                </NavLink>
            </Tooltip>
            );
    }
}

class NavMenu extends React.Component<NavMenuProps, {}> {
    get disabledExtProducts() : number{
        if (this.props.account.accountRoles
            .some(y => y.role.name === "Maintenance"
                || y.role.name === "Master")){
            return 1;       
        }
        else if(_.values(this.props.allowedStores).find(x => x.storeType == "Ximiti"))
            return 2;
        else
            return 3;
    }

    public render() {
        return (
            <div style={{
                height: "100%", ...Styles.colors.main2,
                padding: 5, borderRight: "1px solid black"
            }}>
                {this.props.isAuthenticated &&
                    <div style={{
                        display: "flex", flexDirection: "column",
                        height: "80%", flexWrap: "nowrap", alignItems: "center"
                    }}>
                        <div style={navContainerStyle}>
                            <NavItem to={"/"}
                                imgSrc={"/images/home.png"}
                                tooltip={"Accueil"} />
                        </div>
                        {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                        <div style={navContainerStyle}>
                            <NavItem to={"/currencies"}
                                imgSrc={"/images/currencies.png"}
                                tooltip={"Devises"} />
                        </div>
                        }
                         {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                        <div style={navContainerStyle}>
                            <NavItem to={"/vats"}
                                imgSrc={"/images/vats.png"}
                                tooltip={"Taux TVA"} />
                        </div>}
                        <div style={navContainerStyle}>
                            <NavItem to={"/units"}
                                imgSrc={"/images/units.png"}
                            tooltip={<span>Unit&#233;s</span>} />
                        </div>
                        {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                        <div style={navContainerStyle}>
                            <NavItem to={"/families"}
                                imgSrc={"/images/families.png"}
                                tooltip={"Rayons de produits"} />
                        </div>
                        }
                         {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                        <div style={navContainerStyle}>
                            <NavItem to={"/subFamilies"}
                                imgSrc={"/images/subFamilies.png"}
                                tooltip={"Sous-familles de produit"} />
                        </div>
                        }
                        {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                        <div style={navContainerStyle}>
                            <NavItem to={"/suppliermains"}
                                imgSrc={"/images/supplierMains.png"}
                                tooltip={"Source d'appro"} />
                    </div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={navContainerStyle}>
                        <NavItem to={"/suppliersubs"}
                            imgSrc={"/images/supplierSubs.png"}
                            tooltip={"Branche/franchise source d'appro"} />
                    </div>}
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                        <div style={navContainerStyle}>
                            <NavItem to={"/priceNames"}
                                imgSrc={"/images/priceNames.png"}
                                tooltip={"Nom de tarif"} />
                        </div>
                    }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                        <div style={navContainerStyle}>
                            <NavItem to={"/menucategories"}
                                imgSrc={"/images/menuCategories.png"}
                            tooltip={<span>Cat&#233;gories de menu</span>} />
                        </div>
                    }
                     {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                        <div style={navContainerStyle}>
                            <NavItem to={"/menus"}
                                imgSrc={"/images/menus.png"}
                                tooltip={"Menus"} />
                        </div>
                    }       
                        {this.disabledExtProducts == 1 || this.disabledExtProducts == 2 ?  
                        <div style={navContainerStyle}>
                        <NavItem to={"/products"}
                            imgSrc={"/images/products.png"}
                            tooltip={"Produits"} />
                        </div>
                        :
                        <div></div>
                        }                      
                        {this.disabledExtProducts == 1 || this.disabledExtProducts == 3 ?
                        <div style={navContainerStyle}>
                        <NavItem to={"/externalproducts"}
                            imgSrc={"/images/products.png"}
                            tooltip={"Produits Externes"} />
                        </div>
                        :
                        <div></div>
                        }
                    {(this.disabledExtProducts == 1 || this.disabledExtProducts == 2) &&
                    <div style={navContainerStyle}>
                        <NavItem to={"/transfer"}
                            className={this.props.hasPendingsubmissions ? "grow-anim" : ""}
                            imgSrc={"/images/transfer.png"}
                            tooltip={"Transfert"} />
                    </div>
                    }
                    {canCrude(this.props.account) && (this.disabledExtProducts == 1 || this.disabledExtProducts == 2)
                        && <div style={navContainerStyle}>
                        <NavItem to={"/submission"}
                                imgSrc={"/images/submission.png"}
                                tooltip={"Demandes de modifications"} />
                        </div>}
                    </div>}
            </div>);
    }
}

export default connect((state: ApplicationState) => ({
    isAuthenticated: isAuthenticated(state),
    hasPendingsubmissions: state.account.currentUser
        && state.account.currentUser.account.user
        && state.account.currentUser.account.user.pendingSubmissions.length !== 0,
    account: state.account.currentUser
        ? state.account.currentUser.account
        : null,
    allowedStores: AccountStore.allowedStores(state),
} as NavMenuOwnProps), AccountStore.actionCreators, null, { pure: false })(NavMenu)
