import { isClient } from "./utils";

interface Subscriber {
    uniqName: string;
    onKeyDown: (key: KeyDownParams) => any;
}

export interface KeyDownParams {
    key: string;
    ctrlKey: boolean;
    shiftKey: boolean;
}

class KeyboardHandler {
    private subscribers: Array<Subscriber> = [];

    public initialize(): void {
        if (isClient()) {
            window.onkeydown = (e) => {
                this.onKeyDown({
                    key: e.key,
                    ctrlKey: e.ctrlKey,
                    shiftKey: e.shiftKey
                });
            };
        }
    }

    private onKeyDown(key: KeyDownParams): void {
        this.subscribers.forEach(x => {
            x.onKeyDown(key);
        });
    }

    public subscribe(subscriber: Subscriber): void {
        this.subscribers = this.subscribers
            .filter(x => x.uniqName !== subscriber.uniqName);
        this.subscribers.push(subscriber);
    }

    public unsubscribe(uniqName: string): void {
        this.subscribers = this.subscribers
            .filter(x => x.uniqName !== uniqName);
    }
}

const keyboardHandler = new KeyboardHandler();
export default keyboardHandler;