import * as React from "react";
import * as Api from "../api/api";
import * as FamilyStore from "../store/Family";
import * as CrudeStore from "../store/Crude";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import { CreateFamilyForm, UpdateFamilyForm } from "./FamilyForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { validateGridUpdates, familyValidation } from "../utils/validation";
import { canCrude } from "../utils/userRights";

type FamiliesProps = FamiliesOwnProps
    & CrudeStore.EntityCrudeState<Api.FamilyModel, Api.FamilyFilter>
    & typeof FamilyStore.actionCreators
    & typeof TabsStore.actionCreators;

interface FamiliesOwnProps {
    account: Api.AccountModel;
    helpHtml: string;
}
const name = "families";
const Table: new () => EntityTable<Api.FamilyModel> = EntityTable as any;

class Families extends React.Component<FamiliesProps, {}> {

    update(props: FamiliesProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps: FamiliesProps) {
        //this.update(nextProps);
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Rayons de produit</h2>
                <CrudeLayout
                    name={name}
                    createForm={<div style={{ maxWidth: 800 }}>
                        <h2>Créer</h2>
                        <CreateFamilyForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 800 }}>
                        <h2>Editer</h2>
                        <UpdateFamilyForm
                            actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    help={<div>
                            <div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />
                        </div>}
                        table={<Table
                        gridKey={name}
                        account={this.props.account}
                        isLoading={this.props.isLoading}
                        idPropertyName={"familyId"}
                        updateValidation={(updates) =>
                            validateGridUpdates(updates, familyValidation, this.props.entities)}
                        onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                            new Date().getTime(),
                            updates) as any}
                        columns={[
                            {
                                key: "name",
                                name: 'Nom',
                                editable: true,
                            },
                            {
                                key: "imageId",
                                name: 'Image',
                                editable: false,
                                formatter: ({ value }) => <img height={30} src={"/api/Image/GetImageContent?imageId=" + value} />
                            },
                            {
                                key: "order",
                                name: 'Ordre',
                                editable: true,
                            },
                            {
                                key: "familyCustoms",
                                name: 'Customisations',
                                editable: false,
                                formatter: ({ value }) => <div>{value.length}</div>
                            }
                        ]}
                        data={this.props.entities}
                        onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                        onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                        onRequestUpdate={(id) => {
                            this.props.selectUpdateEntity(id);
                            this.props.selectTab(name, "update");
                        }} />} />
            </div>
            );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.family,
    account: state.account.currentUser.account,
    helpHtml: state.seed.seed.familyHelp
} as FamiliesOwnProps), { ...TabsStore.actionCreators, ...FamilyStore.actionCreators } as any)(Families);