import * as React from "react";
import Tooltip from "./Tooltip";

interface FieldHelpProps {
    help: JSX.Element | string;
}
//Add the help tooltip to an input field
export class FieldHelp extends React.Component<FieldHelpProps, {}> {
    public render() {
        return (
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                <div style={{ flex: 1 }}>{this.props.children}</div>
                {this.props.help && <div style={{ flex: "0 0 auto", paddingLeft: 3 }}>
                    <Tooltip overlay={<div>{this.props.help}</div>}>
                        <i className="glyphicon glyphicon-info-sign"></i>
                    </Tooltip>
                </div>}
                </div>
                );
        }
    }
    
