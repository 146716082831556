import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import configureStore from './store/configureStore';
import App from './App';
import registerServiceWorker from './registerServiceWorker';
import * as AccountStore from './store/Account';
import * as SeedStore from './store/Seed';
import { ApplicationState } from './store';
import { getAllTexts, loadTexts } from './utils/texts';
import * as Api from "./api/api";
import {isAuthenticated} from "./security/UserIsAuthenticated";
import {connectUserHub} from "./signalR/connectedUsers";
import {logError} from "./utils/error";
import {writeToken} from "./security/tokenManager";

// Create browser history to use in the Redux store
const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;
const history = createBrowserHistory({ basename: baseUrl });
const initData = (langCode: string, requestTime: number, getState: () => ApplicationState,
                  dispatch: (action: AccountStore.KnownAction
                      | SeedStore.KnownAction) => void): Promise<any> => {

    let requestUserTask = AccountStore.requestCurrentUser(requestTime, dispatch, getState)
        .catch(() => {
            throw "Failed to request current user";
        });
    let requestSeed = SeedStore.requestSeed(requestTime, 'fr', dispatch, getState)
        .catch(() => {
            throw "Failed to request seed";
        });

    let seedApi = new Api.SeedApi();
    let requestTextTask = new Promise((resolve, reject) => {
        seedApi.getTexts({ langCode: langCode })
            .then(texts => {
                loadTexts(texts);
                resolve();
            })
            .catch(err => {
                console.log("ERROR: " + (err.message || JSON.stringify(err)));
                reject("Failed to load language texts");
            });
    });

    return Promise.all([requestUserTask, requestSeed, requestTextTask]);
}

const store = configureStore(history);

window.onerror = function (msg, url, line) {
    logError(msg as any, '');
};
(window as any).store = store;

let token = window.localStorage.getItem("token");
if(token) {
    writeToken(token);
}

initData('FR', new Date().getTime(), store.getState, store.dispatch).then(() => {
    if(isAuthenticated(store.getState())){
        connectUserHub(store.getState, store.dispatch);
    }
    loadTexts(getAllTexts());
    ReactDOM.render(
        <Provider store={store}>
            <ConnectedRouter history={history}>
                <App />
            </ConnectedRouter>
        </Provider>,
        document.getElementById('root'));

    registerServiceWorker();
}).catch(err => {
    console.error(err);
    window.alert("An error has occured while rendering the application, check the logs and the console")
});