import * as SignalR from "@aspnet/signalr";
import * as UserHubStore from "../store/UserHub";
import { ApplicationState } from "../store";
import * as Notifications from 'react-notification-system-redux';
import { readToken } from "../security/tokenManager";

let connection: SignalR.HubConnection = null;

export const connectUserHub = (getState: () => ApplicationState, dispatch: (action: UserHubStore.KnownAction) => void): Promise<void> => {
    connection = new SignalR.HubConnectionBuilder()
        .withUrl("/userHub")
        .build();

    connection.on("SendConnectedUsers", (usersJson) => {
        dispatch({ type: "RECEIVE_CONNECTED_USERS", payload: { connectedUsers: JSON.parse(usersJson) } });
    });

    connection.on("UserSendMessage", (messageJson) => {
        dispatch({ type: "RECEIVE_NEW_USER_MESSAGE", payload: { message: JSON.parse(messageJson) } });
        if (!getState().userHub.isOpen) {
            dispatch(Notifications.info({
                title: 'Message',
                message: 'Vous avez reçu un nouveau message',
                position: 'tc'
            }));
        }
    });

    connection.onclose((err) => {
        dispatch({ type: "USERHUB_CONNECTION_CLOSED" });
    });
    
    return connection.start().then(() => {
        connection.send("UserAuthenticate", readToken());
        dispatch({ type: "USERHUB_CONNECTION_OPENED" });
    });
};

export const sentMessage = (message: string): Promise<void> => {
    if (connection)
        return connection.send("UserSendMessage", message);

    return Promise.reject("No connection");
}