import * as React from 'react';
import NavMenu from "./NavMenu";
import Notificator from './Notificator';
import UserHub from './UserHub';

export class Layout extends React.Component<{}, {}> {
    public render() {
        return <div id="app-element" style={{
            height: "100%", width: "100%",
            minHeight: 750
        }}>
            <div style={{
                zIndex: 1, position: "fixed",
                top: 0, left: 0, bottom: 0, width: 80
            }}>
                <NavMenu />
            </div>
            <div style={{ paddingLeft: 80, height: "100%", width: "100%" }}>
                <UserHub />
                <div style={{ height: "100%", width: "100%", paddingTop: 40 }}>
                    {this.props.children}
                </div>
            </div>
            <Notificator />
        </div>;
    }
}
