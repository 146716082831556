import * as React from "react";
import Moment from "moment";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as SubmissionStore from "../store/Submission";
import { connect } from "react-redux";
import { ApplicationState } from "../store";
import DialogModal from "./DialogModal";
import Select from "./Select";

type SubmissionDenyDialogProps = SubmissionDenyDialogOwnProps
    & SubmissionStore.SubmissionState
    & typeof SubmissionStore.actionCreators;

interface SubmissionDenyDialogOwnProps {

}

class SubmissionDenyDialog extends React.Component<SubmissionDenyDialogProps, {}>{
    public render() {
        return (<DialogModal isOpen={this.props.denyState.isOpen}
            width={600}
            height={420}
            contentLabel={"Deny dialog"}
            onRequestClose={() => this.props.closeDenyDialog()}>
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    alignItems: "center",
                    marginBottom: 6
                }}>
                    <div style={{ flex: "0 0 120px", textAlign: "right", marginRight: 6 }}>
                        Raisons:
                    </div>
                    <div style={{ flex: 1 }}>
                        <Select options={[
                            { label: "Produit déjà existant", value: "LgapDenyAlreadyExist" },
                            { label: "Libellé mal formaté", value: "LgapDenyBadName" },
                            { label: "Photo inappropriée", value: "LgapDenyBadImage" },
                            { label: "Source manquante", value: "LgapDenyNoSupplier" },
                            { label: "Non correspondance des unités", value: "LgapDenyInconsistentPriceUnit" },
                            { label: "Non correspondance du grammage/volume", value: "LgapDenyInconsistentUnit" },
                            { label: "Manque grammage/vol en libellé", value: "LgapDenyUnitNotWellDefined" },
                            { label: "Manque différentiation", value: "LgapDenyIndistinct" },
                            { label: "Autre", value: "LgapDenyOther" },
                        ]} value={this.props.denyState.reasons}
                            onChange={(value) => this.props.updateDenyReasons(value)}
                            multiple />
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 6,
                    width: "100%"
                }}>
                    <div style={{ flex: "0 0 120px", textAlign: "right", marginRight: 6 }}>
                        Message:
                    </div>
                    <div style={{ flex: 1 }}>
                        <textarea rows={8} cols={45}
                            value={this.props.denyState.message}
                            onChange={(e) => this.props.updateDenyMessage(e.target.value)}></textarea>
                    </div>
                </div>
                <div style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    marginBottom: 6,
                    width: "100%"
                }}>
                    <div style={{ flex: "0 0 120px", marginRight: 6 }}>
                    </div>
                    <div style={{ flex: 1 }}>
                        <button disabled={this.props.submissionStates[this.props.denyState.selectedId]
                            && this.props.submissionStates[this.props.denyState.selectedId].isLoading}
                        className="btn btn-primary btn-lg"
                            onClick={() => {
                                this.props.requestDenySubmission(new Date().getTime())
                            }}>
                            Refuser
                        </button>
                    </div>
                </div>
            </div>
        </DialogModal>);
    }
}

export default connect((state: ApplicationState) => ({
    ...state.submission
}), {
        ...SubmissionStore.actionCreators
    })(SubmissionDenyDialog)