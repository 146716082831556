import * as React from "react";
import * as ReactDOM from "react-dom";
import * as _ from "lodash";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
const { editors: { EditorBase } } = require('react-data-grid');

interface DropDownOption { id: string; value: string; text: string; title?: string }
interface DropDownEditorProps { options: Array<DropDownOption>; }

//Options props doesn't update properly, see FamilySelect for entity select exemple
class DropDownEditorClass extends EditorBase {

    getInputNode() {
        return ReactDOM.findDOMNode(this as any) as HTMLInputElement;
    }

    onClick() {
        this.getInputNode().focus();
    }

    onDoubleClick() {
        this.getInputNode().focus();
    }

    public render() {
        return (
            <select
                style={this.getStyle()}
                defaultValue={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.onChange} >
                {this.props.options.map(x => <option key={x.id} value={x.value} title={x.title}>{x.text || x.value}</option>)}
            </select>);
    }
}

interface ConnectedSelectConnectedProps { options: Array<DropDownOption> }
interface ConnectedSelectExternalProps { hasNull?: boolean; style?: any; defaultValue: any; onBlur: (e: any) => void; onChange: (e: any) => void; }
class ConnectedSelect extends React.Component<ConnectedSelectExternalProps & ConnectedSelectConnectedProps, {}> {
    public render() {
        return (
            <select
                style={this.props.style}
                defaultValue={this.props.defaultValue}
                onBlur={this.props.onBlur}
                onChange={this.props.onChange}>
                {(this.props.hasNull
                    ? [{ text: "------", value: "", id: "------" } as DropDownOption]
                    : []).concat(this.props.options).map(x => <option key={x.id} value={x.value} title={x.title}>{x.text || x.value}</option>)}
            </select>);
    }
}

const FamilySelect = connect((state: ApplicationState) => ({
    options: _.values(state.family.entities)
        .map(x => ({
            id: x.familyId.toString(),
            text: x.name,
            title: x.name,
            value: x.familyId.toString()
        } as DropDownOption))
} as ConnectedSelectConnectedProps), null)(ConnectedSelect as any) as any as React.ComponentClass<ConnectedSelectExternalProps>;;

const PriceNameSelect = connect((state: ApplicationState) => ({
    options: _.values(state.priceName.entities)
        .map(x => ({
            id: x.priceNameId.toString(),
            text: x.name,
            title: x.name,
            value: x.priceNameId.toString()
        } as DropDownOption))
} as ConnectedSelectConnectedProps), null)(ConnectedSelect as any) as any as React.ComponentClass<ConnectedSelectExternalProps>;;

const SupplierMainSelect = connect((state: ApplicationState) => ({
    options: _.values(state.supplierMain.entities)
        .map(x => ({
            id: x.supplierId.toString(),
            text: x.name,
            title: x.name,
            value: x.supplierId.toString()
        } as DropDownOption))
} as ConnectedSelectConnectedProps), null)(ConnectedSelect as any) as any as React.ComponentClass<ConnectedSelectExternalProps>;;

const SupplierSubSelect = connect((state: ApplicationState) => ({
    options: _.values(state.supplierSub.entities)
        .map(x => ({
            id: x.supplierId.toString(),
            text: x.name,
            title: x.name,
            value: x.supplierId.toString()
        } as DropDownOption))
} as ConnectedSelectConnectedProps), null)(ConnectedSelect as any) as any as React.ComponentClass<ConnectedSelectExternalProps>;;


const CurrencySelect = connect((state: ApplicationState) => ({
    options: _.values(state.currency.entities)
        .map(x => ({
            id: x.currencyId.toString(),
            text: x.name,
            title: x.name,
            value: x.currencyId.toString()
        } as DropDownOption))
} as ConnectedSelectConnectedProps), null)(ConnectedSelect as any) as any as React.ComponentClass<ConnectedSelectExternalProps>;

const VatSelect = connect((state: ApplicationState) => ({
    options: _.values(state.vat.entities)
        .map(x => ({
            id: x.vatId.toString(),
            text: x.name,
            title: x.name,
            value: x.vatId.toString()
        } as DropDownOption))
} as ConnectedSelectConnectedProps), null)(ConnectedSelect as any) as any as React.ComponentClass<ConnectedSelectExternalProps>;

const UnitSelect = connect((state: ApplicationState) => ({
    options: _.values(state.unit.entities)
        .map(x => ({
            id: x.unitId.toString(),
            text: x.code,
            title: x.code,
            value: x.unitId.toString()
        } as DropDownOption))
} as ConnectedSelectConnectedProps), null)(ConnectedSelect as any) as any as React.ComponentClass<ConnectedSelectExternalProps>;

const StoreSelect = connect((state: ApplicationState) => ({
    options: _.values(state.seed.seed.stores)
        .map(x => ({
            id: x.storeId.toString(),
            text: x.name,
            title: x.name,
            value: x.storeId.toString()
        } as DropDownOption))
} as ConnectedSelectConnectedProps), null)(ConnectedSelect as any) as any as React.ComponentClass<ConnectedSelectExternalProps>;

//React-data-grid has its own shouldcomponentupdate that mess with react-redux connect.
//We have to connect the select and not the editor itself for the select to update
class DropDownFamilyEditorClass extends EditorBase {
    getInputNode() {
        return ReactDOM.findDOMNode(this as any) as HTMLInputElement;
    }
    onClick() {
        this.getInputNode().focus();
    }
    onDoubleClick() {
        this.getInputNode().focus();
    }

    public render() {
        return (
            <FamilySelect
                style={this.getStyle()}
                defaultValue={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.onChange}/>);
    }
}

class DropDownPriceNameEditorClass extends EditorBase {
    getInputNode() {
        return ReactDOM.findDOMNode(this as any) as HTMLInputElement;
    }
    onClick() {
        this.getInputNode().focus();
    }
    onDoubleClick() {
        this.getInputNode().focus();
    }

    public render() {
        return (
            <PriceNameSelect
                style={this.getStyle()}
                defaultValue={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.onChange} />);
    }
}

class DropDownSupplierMainEditorClass extends EditorBase {
    getInputNode() {
        return ReactDOM.findDOMNode(this as any) as HTMLInputElement;
    }
    onClick() {
        this.getInputNode().focus();
    }
    onDoubleClick() {
        this.getInputNode().focus();
    }

    public render() {
        return (
            <SupplierMainSelect
                style={this.getStyle()}
                defaultValue={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.onChange} />);
    }
}

class DropDownSupplierSubEditorClass extends EditorBase {
    getInputNode() {
        return ReactDOM.findDOMNode(this as any) as HTMLInputElement;
    }
    onClick() {
        this.getInputNode().focus();
    }
    onDoubleClick() {
        this.getInputNode().focus();
    }

    public render() {
        return (
            <SupplierMainSelect
                style={this.getStyle()}
                defaultValue={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.onChange} />);
    }
}

class DropDownCurrencyEditorClass extends EditorBase {
    getInputNode() {
        return ReactDOM.findDOMNode(this as any) as HTMLInputElement;
    }
    onClick() {
        this.getInputNode().focus();
    }
    onDoubleClick() {
        this.getInputNode().focus();
    }

    public render() {
        return (
            <CurrencySelect
                style={this.getStyle()}
                defaultValue={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.onChange} />);
    }
}

class DropDownVatEditorClass extends EditorBase {
    getInputNode() {
        return ReactDOM.findDOMNode(this as any) as HTMLInputElement;
    }
    onClick() {
        this.getInputNode().focus();
    }
    onDoubleClick() {
        this.getInputNode().focus();
    }

    public render() {
        return (
            <VatSelect
                style={this.getStyle()}
                defaultValue={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.onChange} />);
    }
}

class DropDownUnitEditorClass extends EditorBase {
    getInputNode() {
        return ReactDOM.findDOMNode(this as any) as HTMLInputElement;
    }
    onClick() {
        this.getInputNode().focus();
    }
    onDoubleClick() {
        this.getInputNode().focus();
    }

    public render() {
        return (
            <UnitSelect
                style={this.getStyle()}
                defaultValue={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.onChange} />);
    }
}

class DropDownStoreEditorClass extends EditorBase {
    getInputNode() {
        return ReactDOM.findDOMNode(this as any) as HTMLInputElement;
    }
    onClick() {
        this.getInputNode().focus();
    }
    onDoubleClick() {
        this.getInputNode().focus();
    }

    public render() {
        return (
            <StoreSelect
                style={this.getStyle()}
                hasNull={true}
                defaultValue={this.props.value}
                onBlur={this.props.onBlur}
                onChange={this.onChange} />);
    }
}

export const DropDownPriceNameEditor = (DropDownPriceNameEditorClass as any) as React.ComponentClass<{}>;
export const DropDownFamilyEditor = (DropDownFamilyEditorClass as any) as React.ComponentClass<{}>;
export const DropDownSupplierMainEditor = (DropDownSupplierMainEditorClass as any) as React.ComponentClass<{}>;
export const DropDownSupplierSubEditor = (DropDownSupplierSubEditorClass as any) as React.ComponentClass<{}>;
export const DropDownCurrencyEditor = (DropDownCurrencyEditorClass as any) as React.ComponentClass<{}>;
export const DropDownVatEditor = (DropDownVatEditorClass as any) as React.ComponentClass<{}>;
export const DropDownUnitEditor = (DropDownUnitEditorClass as any) as React.ComponentClass<{}>;
export const DropDownStoreEditor = (DropDownStoreEditorClass as any) as React.ComponentClass<{}>;

export const DropDownEditor = (DropDownEditorClass as any) as React.ComponentClass<DropDownEditorProps>;