import * as _ from "lodash";
import * as Api from "../api/api";
import * as FormField from "../components/FormField";
import { FormType } from '../store/Crude';

type ValidationMethod<TEntity, TParentEntity> = (value: any, allValues?: TParentEntity, props?: PropsWithEntities<TParentEntity>) => string;
export type EntitiyValidation<TEntity, TParentEntity> = { [key: string]: Array<ValidationMethod<TEntity, TParentEntity>> };

interface PropsWithEntities<TEntity> {
    entities: { [id: number]: TEntity };
    formType: FormType;
}

export const validateGridUpdates = <TEntity>(updates: { [id: number]: object },
    validation: EntitiyValidation<TEntity, TEntity>,
    entities: { [id: number]: TEntity }): { [id: number]: { [prop: string]: Array<string>} } => {
    let errors = {};
    let updatedEntities = { ...entities };
    _.keys(updates).forEach(id => {
        updatedEntities[id] = {
            ...entities[id],
            ...updates[id]
        };
    });
    _.keys(updates).forEach(x => {
        let id = parseInt(x);
        let data = updatedEntities[x];
        _.keys(data).forEach(prop => {
            if (validation[prop] && validation[prop].length > 0) {
                let propErrors = validation[prop]
                    .map(x => x(data[prop], entities[id], { entities: updatedEntities, formType: "Update" }))
                    .filter(x => x).map(x => [x])
                    .reduce((a, b) => a.concat(b), []);
                if (_.values(propErrors).length > 0) {
                    if (!errors[id]) {
                        errors[id] = {};
                    }
                    errors[id][prop] = propErrors;
                }
            }
        });
    });
    return errors;
}

export const validateChildGridUpdates = <TEntity, TParentEntity>(
    updates: { [id: number]: object },
    validation: EntitiyValidation<TEntity, TParentEntity>,
    entities: { [id: number]: TParentEntity },
    dataRows: Array<TEntity>,
    idSelector: (entity: TEntity) => number,
    parentIdSelector: (entity: TEntity) => number): { [id: number]: { [prop: string]: Array<string> } } => {
    let errors = {};

    _.keys(updates).forEach(x => {
        let id = parseInt(x);
        let data = updates[x];
        _.keys(data).forEach(prop => {
            if (validation[prop] && validation[prop].length > 0) {
                let propErrors = validation[prop]
                    .map(x => x(data[prop], entities[parentIdSelector(dataRows.find(y => idSelector(y) === id))], { entities: entities, formType: "Update" }))
                    .filter(x => x).map(x => [x])
                    .reduce((a, b) => a.concat(b), []);
                if (_.values(propErrors).length > 0) {
                    if (!errors[id]) {
                        errors[id] = {};
                    }
                    errors[id][prop] = propErrors;
                }
            }
        });
    });
    return errors;
}


export const productValidation
    : EntitiyValidation<Api.ProductModel, Api.ProductModel> = {
        "name": [
            FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && (x.storeItemId !== allValues.storeItemId || props.formType === "Create"))
                ? "Un produit avec ce nom existe déjà"
                : undefined,
            (value, allValues, props) => value && value.length > 50
                ? "Le nom est trop long (max: 50 chars)"
                : undefined
        ],
        "shortName": [
            //FormField.required,
            //(value, allValues, props) => _.values(props.entities)
            //    .some(x => x.shortName === value && (x.storeItemId !== allValues.storeItemId || props.formType === "Create"))
            //    ? "Un produit avec ce nom court existe déjà"
            //    : undefined
        ],
        "code": [
            FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.code === value && (x.storeItemId !== allValues.storeItemId || props.formType === "Create"))
                ? "Un produit avec ce code existe déjà"
                : undefined
        ],
        "imageId": [
            FormField.required
        ],
        "stability": [
            FormField.required
        ],
        "width": [
            FormField.requiredNotZero
        ],
        "height": [
            FormField.requiredNotZero
        ],
        "depth": [
            FormField.requiredNotZero
        ],
        "volume": [
            FormField.requiredNotZero
        ],
        "volumeUnitId": [
            FormField.required
        ],
        "relativeUnitId": [
            FormField.required
        ],
        "expirityType": [
            FormField.required
        ],
        "expirityDays": [
            FormField.requiredOrZero
        ],
        "productBarCodes": [
            FormField.requiredOne
        ],
        "productSubFamilies": [
             FormField.requiredOne
        //     (value: Array<Api.ProductSubFamilyModel>, allValues, props) => value
        //         .some(x => value.filter(y => y.productSubFamilyStores.length === 0
        //             || y.productSubFamilyStores.some(z => x.productSubFamilyStores.some(a => a.storeId === z.storeId))).length > 8)
        //         ? "Trop de sous-familles (maximum: 8)"
        //         : undefined
        ],
        "productVats": [
            FormField.requiredOne
        ],
        "productSuppliers": [
            FormField.requiredOne
        ],
        "prices": [
            FormField.requiredOne,
            (value: Array<Api.PriceModel>, allValues, props) => value
                .some(x => value.filter(y => y.priceNameId === x.priceNameId
                    && x.currencyId === y.currencyId && ((x.priceStores.length === 0 && y.priceStores.length === 0)
                        || (x.priceStores.some(z => y.priceStores.some(a => a.storeId === z.storeId))))).length > 1)
                ? "Un seul prix par label et magasin autorisé"
                : undefined
        ],
        "productCustoms": [
            (value, allValues, props) => allValues.productCustoms
                .some(x => !x.languageId && !x.storeGroupId)
                ? "Vous devez spécifier une langue ou un magasin"
                : undefined
        ],
        "defaultSupplierId": [
            FormField.required
        ],
        "defaultPriceNameId": [
            FormField.required
        ],
        "storeItemTags": [

        ],
        "comment" : [
            FormField.maxLength(500)
        ]
    };

export const productWarnings
    : EntitiyValidation<Api.ProductModel, Api.ProductModel> = {
        "prices": [
            (value: Array<Api.PriceModel>, allValues, props) => allValues.productSuppliers
                && (allValues.productSuppliers as Array<Api.ProductSupplierModel>)
                .some(x => value && value.some(y => y.value <= x.buyPriceNoVat / x.packCondition))
                ? "(warning) Un prix est inférieur ou égal au prix d'achat"
                : undefined
        ]
    };

export const productBarCodeValidation
    : EntitiyValidation<Api.ProductBarcodeModel, Api.ProductModel> = {
        "value": [
            FormField.required,
            FormField.onlyNumbers,
            FormField.maxLength(13),
            (value, allValues, props) => value && value.length > 13
                ? "Le code barre est trop long (max: 13 chiffres)"
                : undefined
        ]
    };

export const productSubFamiliesValidation
    : EntitiyValidation<Api.ProductSubFamilyModel, Api.ProductModel> = {
        "subFamilyId": [
            FormField.required
        ],
        "order": [
            FormField.requiredOrZero,
            FormField.onlyNumbers
        ]
    };

export const productMenuCategoriesValidation
    : EntitiyValidation<Api.ProductMenuCategoryModel, Api.ProductModel> = {
        "menuCategoryId": [
            FormField.required
        ]
    };

export const productVatsValidation
    : EntitiyValidation<Api.ProductVatModel, Api.ProductModel> = {
        "countryId": [
            FormField.required
        ],
        "vatId": [
            FormField.required
        ]
    };

export const productSuppliersValidation
    : EntitiyValidation<Api.ProductSupplierModel, Api.ProductModel> = {
        "supplierMainId": [
            FormField.required
        ],
        "supplierSubId": [
        ],
        "packCondition": [
            FormField.requiredNotZero
        ],
        "deliveryCondition": [
            FormField.requiredNotZero
        ],
        "reference": [
            FormField.required
        ],
        "buyPriceNoVat": [
            FormField.requiredNotZero
        ],
        "buyPriceCurrencyId": [
            FormField.required
        ],
        "vatId": [
            FormField.required
        ],
        "packWeight": [
            FormField.requiredOrZero
        ],
        "packVolume": [
            FormField.requiredOrZero
        ],
        "productSupplierStores": [
            (value, allValues, props) => {
                return (allValues.productSuppliers || [])
                    .some(x => allValues.productSuppliers.filter(y => (y.productSupplierStores || [])
                        .some(z => (x.productSupplierStores || []).some(xx => xx.storeId === z.storeId))).length > 1) //== because null !== undefined (or 0)
                    ? "Un magasin ne peut avoir qu'une seul source"
                    : undefined
            }
        ]
    };

const platformsPriceNames = [18, 19, 20]; //18:UberEats, 19:Deliveroo, 20:JustEat
export const priceValidation
    : EntitiyValidation<Api.PriceModel, Api.ProductModel> = {
        "value": [
            FormField.required,
            (value, allValues, props) => {
                var platformPrice = allValues.prices
                .some(x => platformsPriceNames.some(y => y === x.priceNameId && x.value == value && (Math.round(x.value * 100) / 100 == x.value)));
                
                return (value
                && Math.round(value * 10) / 10 !== value) && !platformPrice
                ? "Prix invalide"
                : undefined
            }
        ],
        "currencyId": [
            FormField.required
        ],
        "storeGroupId": [
        ],
        "priceNameId": [
            FormField.required,
            (value, allValues, props) => {
                return allValues.prices
                    .some(x => allValues.prices.filter(y => y.priceNameId === x.priceNameId && x.currencyId === y.currencyId
                        && y.priceStores.some(z => x.priceStores.some(xx => xx.storeId === z.storeId))).length > 1) //== because null !== undefined (or 0)
                    ? "Un prix a été définie plusieurs fois"
                    : undefined
            }
        ]
    };


export const currencyValidation
    : EntitiyValidation<Api.CurrencyModel, Api.CurrencyModel> = {
        "name": [FormField.required],
        "code": [FormField.required],
        "symbol": [FormField.required],
        "value": [FormField.required]
    };

export const familyValidation
    : EntitiyValidation<Api.FamilyModel, Api.FamilyModel> = {
        "name": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && (x.familyId !== allValues.familyId || props.formType === "Create"))
                ? "Une famille avec ce nom existe déjà"
                : undefined],
        "imageId": [FormField.required],
        "order": [FormField.requiredOrZero],
        "familyCustoms": [
            (value, allValues, props) => allValues.familyCustoms
                .some(x => !x.languageId && !x.storeGroupId)
                ? "Vous devez spécifier une langue ou un magasin"
                : undefined
        ],
    };

export const menuCategoryValidation
    : EntitiyValidation<Api.MenuCategoryModel, Api.MenuCategoryModel> = {
        "name": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && (x.menuCategoryId !== allValues.menuCategoryId || props.formType === "Create"))
                ? "Une categorie avec ce nom existe déjà"
                : undefined],
        "menuCategoryStores": [
        //     (value, allValues, props) => value
        //         && value.length !== 0
        //         && _.values(props.entities)
        //             .filter(x => x.menuCategoryStores.some(y => value.some(z => z.storeId === y.storeId))).length > 8
        //         ? "Les magasins sont limité à 8 catégories de menu"
        //         : undefined
        ],
    };

export const menuValidation
    : EntitiyValidation<Api.MenuModel, Api.MenuModel> = {
        "name": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && (x.storeItemId !== allValues.storeItemId || props.formType === "Create"))
                ? "Un menu avec ce nom existe déjà"
            : undefined,
            (value, allValues, props) => value && value.length > 50
                ? "Le nom est trop long (max: 50 char)"
                : undefined],
        "imageId": [FormField.required],
        "order": [FormField.requiredOrZero],
        "menuCategoryMenus": [
            FormField.requiredOne,
            (value: Array<Api.MenuCategoryMenuModel>, allValues, props) => value
                && (value.length > 4)
                ? "Vous ne pouvez pas avoir plus de 4 catégories par magasin"
                : undefined
        ],
        "prices": [FormField.requiredOne],
        "defaultPriceNameId": [FormField.required],
        "menuCustoms": [
            (value, allValues, props) => allValues.menuCustoms
                .some(x => !x.languageId && !x.storeGroupId)
                ? "Vous devez spécifier une langue ou un magasin"
                : undefined
        ],
        "menuStores": [FormField.requiredOne
        //     (value, allValues, props) => value
        //         && value.length !== 0
        //         && _.values(props.entities)
        //             .filter(x => x.menuStores.some(y => value.some(z => z.storeId === y.storeId))).length > 8
        //         ? "Les magasins sont limité à 8 menus"
        //         : undefined
        ]
    };

export const menuCategoryMenusValidation
    : EntitiyValidation<Api.MenuCategoryMenuModel, Api.MenuModel> = {
        "menuCategoryId": [
            FormField.required,
            (value, allValues, props) => allValues
                .menuCategoryMenus.some(x => allValues.menuCategoryMenus.filter(y => y.menuCategoryId === x.menuCategoryId).length > 1)
            ? "Une categorie a été incluse plusieurs fois"
                : undefined],
        "order": [
            FormField.requiredOrZero
        ]
    };


export const priceNameValidation
    : EntitiyValidation<Api.PriceNameModel, Api.PriceNameModel> = {
        "name": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && (x.priceNameId !== allValues.priceNameId || props.formType === "Create"))
                ? "Un tarif avec ce nom existe déjà"
                : undefined],
        "code": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.code === value && (x.priceNameId !== allValues.priceNameId || props.formType === "Create"))
                ? "Un tarif avec ce code existe déjà"
                : undefined],
        "type": [FormField.required],
    };

export const promotionValidation
    : EntitiyValidation<Api.PromotionModel, Api.PromotionModel> = {
        "name": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && (x.promotionId !== allValues.promotionId || props.formType === "Create"))
                ? "Une promotion avec ce nom existe déjà"
                : undefined],
        "type": [FormField.required],
        "imageId": [FormField.required],
        "order": [FormField.requiredOrZero],
        "promotionCustoms": [
            (value, allValues, props) => allValues.promotionCustoms
                .some(x => !x.languageId && !x.storeGroupId)
                ? "Vous devez spécifier une langue ou un magasin"
                : undefined
        ],
    };

export const subFamilyValidation
    : EntitiyValidation<Api.SubFamilyModel, Api.SubFamilyModel> = {
        "familyId": [FormField.required],
        "name": [
            FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && x.familyId === allValues.familyId && (x.subFamilyId !== allValues.subFamilyId || props.formType === "Create"))
                ? "Une sous famille avec ce nom existe déjà dans la même famille"
                : undefined
        ],
        "imageId": [FormField.required],
        "order": [FormField.requiredOrZero],
        "subFamilyCustoms": [
            (value, allValues, props) => allValues.subFamilyCustoms
                .some(x => !x.languageId && !x.storeGroupId)
                ? "Vous devez spécifier une langue ou un magasin"
                : undefined
        ],
    };

export const supplierMainValidation
    : EntitiyValidation<Api.SupplierModel, Api.SupplierModel> = {
        "name": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && (x.supplierId !== allValues.supplierId || props.formType === "Create"))
                ? "Une source avec ce nom existe déjà"
                : undefined],
        "deliveryFeeFix": [FormField.requiredOrZero],
        "deliveryFeeVar": [FormField.requiredOrZero],
        "deliveryFeeVarUnitId": [FormField.required],
    };

export const supplierSubValidation
    : EntitiyValidation<Api.SupplierModel, Api.SupplierModel> = {
        "name": [FormField.required,
        (value, allValues, props) => _.values(props.entities)
            .some(x => x.name === value && (x.supplierId !== allValues.supplierId || props.formType === "Create"))
            ? "Une source avec ce nom existe déjà"
            : undefined],
        "deliveryFeeFix": [FormField.requiredOrZero],
        "deliveryFeeVar": [FormField.requiredOrZero],
        "deliveryFeeVarUnitId": [FormField.required],
        "supplierMainId": [FormField.required],
    };

export const supplierAdvertSuppliers
    : EntitiyValidation<Api.SupplierAdvertSupplierModel, Api.SupplierModel> = {
        "supplierAdvertId": [
            FormField.required
        ]
    };

export const supplierPaymentSuppliers
    : EntitiyValidation<Api.SupplierPaymentSupplierModel, Api.SupplierModel> = {
        "supplierPaymentId": [
            FormField.required
        ]
    };

export const supplierDeliverySuppliers
    : EntitiyValidation<Api.SupplierDeliverySupplierModel, Api.SupplierModel> = {
        "supplierDeliveryId": [
            FormField.required
        ]
    };

export const supplierAdvertValidation
    : EntitiyValidation<Api.SupplierAdvertModel, Api.SupplierAdvertModel> = {
        "name": [FormField.required,
        (value, allValues, props) => _.values(props.entities)
            .some(x => x.name === value && (x.supplierAdvertId !== allValues.supplierAdvertId || props.formType === "Create"))
            ? "Une PLV avec ce nom existe déjà"
            : undefined],
    };

export const supplierDeliveryValidation
    : EntitiyValidation<Api.SupplierDeliveryModel, Api.SupplierDeliveryModel> = {
        "name": [FormField.required,
        (value, allValues, props) => _.values(props.entities)
            .some(x => x.name === value && (x.supplierDeliveryId !== allValues.supplierDeliveryId || props.formType === "Create"))
            ? "Un point de livraison avec ce nom existe déjà"
            : undefined],
    };

export const supplierPaymentValidation
    : EntitiyValidation<Api.SupplierPaymentModel, Api.SupplierPaymentModel> = {
        "name": [FormField.required,
        (value, allValues, props) => _.values(props.entities)
            .some(x => x.name === value && (x.supplierPaymentId !== allValues.supplierPaymentId || props.formType === "Create"))
            ? "Un moyen de paiement avec ce nom existe déjà"
            : undefined],
    };

export const supplierFamilyRotationValidation
    : EntitiyValidation<Api.SupplierFamilyRotationModel, Api.SupplierModel> = {
        "family": [FormField.required],
        "dayOfWeek": [FormField.required],
        "period": [FormField.requiredOrZero],
        "priorNotice": [FormField.requiredOrZero]
    };

export const supplierVolumeDiscountValidation
    : EntitiyValidation<Api.SupplierVolumeDiscountModel, Api.SupplierModel> = {
        "volume": [FormField.required],
        "amount": [FormField.required],
        "currencyId": [FormField.required],
        "unitId": [],
        "currencyUnitId": [],
        "storeGroupId": []
    };

export const supplierDiscountValidation
    : EntitiyValidation<Api.SupplierDiscountModel, Api.SupplierModel> = {
        "value": [FormField.required],
        "storeGroupId": [FormField.required],
    };

export const unitValidation
    : EntitiyValidation<Api.UnitModel, Api.UnitModel> = {
        "name": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && (x.unitId !== allValues.unitId || props.formType === "Create"))
                ? "Une unité avec ce nom existe déjà"
                : undefined],
        "code": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.code === value && x.unitId !== allValues.unitId)
                ? "Une unité avec ce code existe déjà"
                : undefined],
        "type": [FormField.required],
    };

export const vatValidation
    : EntitiyValidation<Api.VatModel, Api.VatModel> = {
        "name": [FormField.required,
            (value, allValues, props) => _.values(props.entities)
                .some(x => x.name === value && (x.vatId !== allValues.vatId || props.formType === "Create"))
                ? "Une TVA avec ce nom existe déjà"
                : undefined],
        "value": [
            FormField.requiredOrZero
        ],
    };

export const entityCustomizationValidation
    : EntitiyValidation<Api.ProductCustomModel, any> = {

}

export const storeItemStorePaymentValidation
    : EntitiyValidation<Api.StoreItemStorePaymentModel, Api.ProductModel> = {
    "availability": [FormField.required],
    "storeId": [
        (value, allValues, props) => allValues.storeItemStorePayments
            .some(x => allValues.storeItemStorePayments.filter(y => y.storeId === x.storeId
                && y.paymentType === x.paymentType).length > 1)
        ? "Le type de payment est specifié plus d'une fois pour ce magasin"
        : undefined],
};

export const menuStoreValidation
    : EntitiyValidation<Api.MenuStoreModel, Api.MenuModel> = {
        "storeId": []
    };

export const menuCategoryStoreValidation
    : EntitiyValidation<Api.MenuStoreModel, Api.MenuModel> = {
        "storeId": []
    };