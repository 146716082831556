import * as React from "react";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as MenuCategoryStore from "../store/MenuCategory";
import * as CrudeStore from "../store/Crude";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import { CreateMenuCategoryForm, UpdateMenuCategoryForm } from "./MenuCategoryForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { menuCategoryValidation, validateGridUpdates } from "../utils/validation";
import { canCrude } from "../utils/userRights";
import ConnectedTabs from "./ConnectedTabs";
import { TabLink, TabContent } from "react-tabs-redux";
import { allowedStores } from "../store/Account";

type MenuCategoriesProps = MenuCategoriesOwnProps
    & CrudeStore.EntityCrudeState<Api.MenuCategoryModel, Api.MenuCategoryFilter>
    & typeof MenuCategoryStore.actionCreators
    & typeof TabsStore.actionCreators;

interface MenuCategoriesOwnProps {
    account: Api.AccountModel;
    helpHtml: string;
    allowedStores: Array<number>;
}
const name = "menuCategories";
const Table: new () => EntityTable<Api.MenuCategoryModel> = EntityTable as any;
const columns = (props: MenuCategoriesProps) => [
    {
        key: "name",
        name: "Nom",
        editable: true,
    }];

class MenuCategories extends React.Component<MenuCategoriesProps, {}> {

    update(props: MenuCategoriesProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps: MenuCategoriesProps) {
        //this.update(nextProps);
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Catégories menu</h2>
                <CrudeLayout
                    name={name}
                    createForm={<div style={{ maxWidth: 600 }}>
                        <h2>Créer</h2>
                        <CreateMenuCategoryForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 600 }}>
                        <h2>Editer</h2>
                        <UpdateMenuCategoryForm
                            actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            onSubmit={(values) => {
                                if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    help={<div>
                            <div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />
                    </div>}
                    table={
                        <ConnectedTabs name={"menuCategoryTableTab"}>
                            <TabLink to="menuCategoryGlobal">
                                <div style={{ padding: "5px 10px" }}>Catégories globales</div>
                            </TabLink>
                            <TabLink to="menuCategorySpecific">
                                <div style={{ padding: "5px 10px" }}>Catégories magasins</div>
                            </TabLink>
                            <TabContent for="menuCategoryGlobal">
                                <Table
                                    gridKey={name}
                                    account={this.props.account}
                                    isLoading={this.props.isLoading}
                                    idPropertyName={"menuCategoryId"}
                                    updateValidation={(updates) =>
                                        validateGridUpdates(updates, menuCategoryValidation, this.props.entities)}
                                    onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                                        new Date().getTime(),
                                        updates) as any}
                                    data={_.keyBy(_.values(this.props.entities)
                                        .filter(x => x.menuCategoryStores.length === 0)
                                        , x => x.menuCategoryId)}
                                    onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                                    onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                                    onRequestUpdate={(id) => {
                                        this.props.selectUpdateEntity(id);
                                        this.props.selectTab(name, "update");
                                    }}
                                    columns={columns(this.props)} />
                            </TabContent>
                            <TabContent for="menuCategorySpecific">
                                <Table
                                    gridKey={name}
                                    account={this.props.account}
                                    isLoading={this.props.isLoading}
                                    idPropertyName={"menuCategoryId"}
                                    updateValidation={(updates) =>
                                        validateGridUpdates(updates, menuCategoryValidation, this.props.entities)}
                                    onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                                        new Date().getTime(),
                                        updates) as any}
                                    data={_.keyBy(_.values(this.props.entities)
                                        .filter(x => x.menuCategoryStores.some(y => this.props.allowedStores.some(z => z === y.storeId)))
                                        , x => x.menuCategoryId)}
                                    onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                                    onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                                    onRequestUpdate={(id) => {
                                        this.props.selectUpdateEntity(id);
                                        this.props.selectTab(name, "update");
                                    }}
                                    columns={columns(this.props)} />
                            </TabContent>
                        </ConnectedTabs>
                    } />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.menuCategory,
    account: state.account.currentUser.account,
    helpHtml: state.seed.seed.menuCategoryHelp,
    allowedStores: _.values(allowedStores(state)).map(x => x.storeId)
} as MenuCategoriesOwnProps), { ...TabsStore.actionCreators, ...MenuCategoryStore.actionCreators } as any)(MenuCategories);