import * as Api from "../api/api";
import { ApplicationState } from './';
import * as CrudeStore from "./Crude";
import * as UnitStore from "./Unit";
import * as CurrencyStore from "./Currency";
import * as SupplierAdvertStore from "./SupplierAdvert";
import * as SupplierDeliveryStore from "./SupplierDelivery";
import * as SupplierPaymentStore from "./SupplierPayment";

export const key: string = "supplierMain";

export type SupplierMainState = CrudeStore.EntityCrudeState<Api.SupplierModel, Api.SupplierMainFilter>;

export const configuration: CrudeStore.CrudeStoreConfiguration<Api.SupplierModel, Api.SupplierMainFilter> = {
    key: key,
    storeSelector: x => x.supplierMain,
    idSelector: x => x.supplierId,
    requestEntitiesFetch: (model, options) => {
        let api = new Api.SupplierMainApi();
        return api.getEntities(model, options);
    },
    requestCreateFetch: (model, options) => {
        let api = new Api.SupplierMainApi();
        return api.create(model, options);
    },
    requestUpdateFetch: (model, options) => {
        let api = new Api.SupplierMainApi();
        return api.update(model, options);
    },
    requestDeleteFetch: (model, options) => {
        let api = new Api.SupplierMainApi();
        return api._delete(model, options);
    },
    requestBulkUpdateFetch: (models, options) => {
        let api = new Api.SupplierMainApi();
        return api.bulkUpdate(models, options);
    },
    requestSubmitCreateFetch: (models, options) => {
        let api = new Api.SupplierMainApi();
        return api.submitCreate(models, options);
    },
    requestSubmitUpdateFetch: (models, options) => {
        let api = new Api.SupplierMainApi();
        return api.submitUpdate(models, options);
    },
    requestDependencies: [
        (requestTime: number,
        dispatch: (action: CrudeStore.KnownAction<Api.UnitModel, Api.UnitFilter>) => void,
            getState: () => ApplicationState) => UnitStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.SupplierAdvertModel, Api.SupplierAdvertFilter>) => void,
            getState: () => ApplicationState) => SupplierAdvertStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.SupplierDeliveryModel, Api.SupplierDeliveryFilter>) => void,
            getState: () => ApplicationState) => SupplierDeliveryStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.SupplierPaymentModel, Api.SupplierPaymentFilter>) => void,
            getState: () => ApplicationState) => SupplierPaymentStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.CurrencyModel, Api.CurrencyFilter>) => void,
            getState: () => ApplicationState) => CurrencyStore.requestEntities(requestTime, dispatch, getState),
    ]
}

export const requestEntities = (requestTime: number,
    dispatch: (action: CrudeStore.KnownAction<Api.SupplierModel, Api.SupplierMainFilter>) => void,
    getState: () => ApplicationState) => CrudeStore.requestEntities(configuration, requestTime, dispatch, getState);

export const actionCreators = CrudeStore.getActionCreators<Api.SupplierModel, Api.SupplierMainFilter>(configuration);

export const reducer = CrudeStore.getReducer(key);