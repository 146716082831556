import * as Api from '../api/api';
import * as React from 'react';
import * as _ from 'lodash';
import * as FormField from './FormField';
import { connect } from 'react-redux';
import { ApplicationState } from '../store';
import {
    Field, reduxForm, InjectedFormProps,
    formValueSelector, FieldArray
} from 'redux-form';
import { menuCategoryValidation } from "../utils/validation";
import { canCrude, canSubmit, canAccessAllStores } from '../utils/userRights';
import { FormType } from '../store/Crude';
import { diff } from 'deep-object-diff';
import MenuCategoryStores, { MenuCategoryStoresOwnProps } from './MenuCategoryStores';
import { allowedStores } from '../store/Account';

type MenuCategoryFormProps = InjectedFormProps
    & MenuCategoryFormExternalProps
    & MenuCategoryFormOwnProps;

interface MenuCategoryFormExternalProps {
    actionName: string;
    onSubmit: (values: Api.MenuCategoryModel) => any;
}

interface MenuCategoryFormOwnProps {
    entities: { [id: number]: Api.MenuCategoryModel };
    disabled?: boolean;
    formType: FormType;
    account: Api.AccountModel;
    fieldInformations?: { [props: string]: string };
    menuCategoryId: number;
    stores: { [id: number]: Api.StoreModel };
    allowedStores: Array<number>;
    menuCategoryStores: Array<Api.MenuCategoryStoreModel>;
}

class MenuCategoryForm extends React.Component<MenuCategoryFormProps, {}> {
    getFieldStyle(name: string): React.CSSProperties {
        return this.props.fieldInformations
            ? (this.props.fieldInformations[name]
                ? FormField.modifiedFieldStyle
                : {})
            : {};
    }

    get globalFieldDisabled(): boolean {
        return !canAccessAllStores(this.props.account)
            && this.props.formType !== "Create"
            && this.props.menuCategoryStores.length !== 0
            ;
    }

    public render() {
        return (
            <FormField.BaseForm {...this.props}>
                <div className="form-group row">
                    <label className="col-sm-4 control-label ">{"Nom:"}</label>
                    <div className="col-sm-8" style={this.getFieldStyle("name")}>
                        <Field className="form-control"
                            validate={menuCategoryValidation["name"]}
                            name="name"
                            disabled={this.props.disabled
                                || this.globalFieldDisabled}
                            component={FormField.renderField}
                            type="text" />
                    </div>
                </div>
                {(this.props.formType === "Create"
                    || this.props.menuCategoryStores.length !== 0)
                    && <div className="form-group row">
                        <label className="col-sm-3 control-label ">{"Magasins:"}</label>
                        <div className="col-sm-9" style={this.getFieldStyle("menuCategoryStores")}>
                        <FieldArray
                            validate={menuCategoryValidation["menuCategoryStores"].concat(
                                canCrude(this.props.account)
                                    ? []
                                    : [FormField.requiredOne])}
                                name="menuCategoryStores"
                                component={MenuCategoryStores}
                                props={{
                                    menuCategoryStores: this.props.menuCategoryStores,
                                    allowedStores: this.props.allowedStores,
                                    canAccessAllStores: canAccessAllStores(this.props.account),
                                    disabled: this.props.disabled,
                                    menuCategoryId: this.props.menuCategoryId,
                                    stores: this.props.stores
                                } as MenuCategoryStoresOwnProps} />
                        </div>
                    </div>}
                <div className="form-group row">
                    <div className="col-sm-4"></div>
                    <div className="col-sm-8">
                        {(canCrude(this.props.account) || canSubmit(this.props.account)) && <div style={{ display: "flex", flexDirection: "row-reverse" }}>
                            <button className="btn btn-primary btn-lg"
                                disabled={this.props.submitting
                                    || (this.props.pristine && this.props.formType !== "Submission")
                                    || this.props.disabled
                                    || this.globalFieldDisabled}
                                type={"submit"}>{this.props.actionName}</button>
                            {this.props.formType !== "Create"
                                && <button className="btn btn-secondary"
                                    type={"button"}
                                    style={{ marginRight: 10 }}
                                    onClick={(e) => {
                                        this.props.reset();
                                        e.preventDefault();
                                    }}>{"Reset"}</button>}
                        </div>}
                    </div>
                </div>
            </FormField.BaseForm>
        );
    }
}

let createForm = reduxForm({
    form: 'createMenuCategory',
    destroyOnUnmount: false,
    enableReinitialize: true
})(MenuCategoryForm) as any;
const createSelector = formValueSelector('createMenuCategory')

let updateForm = reduxForm({
    form: 'updateMenuCategory',
    destroyOnUnmount: false,
    enableReinitialize: true
})(MenuCategoryForm) as any;
const updateSelector = formValueSelector('updateMenuCategory')

let submissionForm = reduxForm({
    form: 'submissionMenuCategory',
    destroyOnUnmount: false,
    enableReinitialize: true
})(MenuCategoryForm) as any;
const submissionSelector = formValueSelector('submissionMenuCategory')


export const CreateMenuCategoryForm = connect((state: ApplicationState) => {
    return {
        formType: "Create",
        entities: state.menuCategory.entities,
        account: state.account.currentUser.account,
        stores: state.seed.seed.stores,
        allowedStores: _.values(allowedStores(state)).map(x => x.storeId),
        menuCategoryId: createSelector(state, "menuCategoryId"),
        menuCategoryStores: createSelector(state, "menuCategoryStores") || [],
    } as MenuCategoryFormOwnProps;
})(createForm as any) as any as React.ComponentClass<MenuCategoryFormExternalProps>;

export const UpdateMenuCategoryForm = connect((state: ApplicationState) => {
    return {
        formType: "Update",
        entities: state.menuCategory.entities,
        disabled: !((state.menuCategory.selectedEntityId ? true : false)
            && state.account.currentUser
            && !state.account.currentUser.account.user.pendingSubmissions
                .some(x => x.menuCategoryId === state.menuCategory.selectedEntityId)),
        initialValues: state.menuCategory.entities[state.menuCategory.selectedEntityId],
        account: state.account.currentUser.account,
        stores: state.seed.seed.stores,
        allowedStores: _.values(allowedStores(state)).map(x => x.storeId),
        menuCategoryId: updateSelector(state, "menuCategoryId"),
        menuCategoryStores: updateSelector(state, "menuCategoryStores") || [],
    } as MenuCategoryFormOwnProps;
})(updateForm as any) as any as React.ComponentClass<MenuCategoryFormExternalProps>;

export const SubmissionMenuCategoryForm = connect((state: ApplicationState) => {
    let submission = state.submission.submissions
        .find(x => x.submissionId === state.submission.selectedId)

    return {
        formType: "Submission",
        entities: state.menuCategory.entities,
        initialValues: JSON.parse(submission.content),
        account: state.account.currentUser.account,
        stores: state.seed.seed.stores,
        allowedStores: _.values(allowedStores(state)).map(x => x.storeId),
        menuCategoryId: submissionSelector(state, "menuCategoryId"),
        menuCategoryStores: submissionSelector(state, "menuCategoryStores") || [],
        fieldInformations: submission.type === "Update"
            ? diff(JSON.parse(submission.content), state.menuCategory.entities[submission.menuCategoryId])
            : {}
    } as MenuCategoryFormOwnProps;
})(submissionForm as any) as any as React.ComponentClass<MenuCategoryFormExternalProps>;