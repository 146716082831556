import * as React from "react";
import * as _ from "lodash";
import * as Api from "../api/api";
import * as UnitStore from "../store/Unit";
import * as CrudeStore from "../store/Crude";
import * as TabsStore from "../store/Tabs";
import { entityRefreshThreshold } from "../utils/utils";
import CrudeLayout from "./CrudeLayout";
import EntityTable from "./EntityTable";
import { CreateUnitForm, UpdateUnitForm } from "./UnitForm";
import { ApplicationState } from "../store";
import { connect } from "react-redux";
import { validateGridUpdates, unitValidation } from "../utils/validation";
import { canCrude } from "../utils/userRights";

type UnitsProps = UnitsOwnProps
    & CrudeStore.EntityCrudeState<Api.UnitModel, Api.UnitFilter>
    & typeof UnitStore.actionCreators
    & typeof TabsStore.actionCreators;

interface UnitsOwnProps {
    account: Api.AccountModel;
    helpHtml: string;
}
const name = "units";
const Table: new () => EntityTable<Api.UnitModel> = EntityTable as any;

class Units extends React.Component<UnitsProps, {}> {

    update(props: UnitsProps) {
        if (!this.props.requestTime
            || (new Date().getTime() - this.props.requestTime > entityRefreshThreshold * 1000)) {
            this.props.requestEntities(new Date().getTime());
        }
    }

    componentWillMount() {
        this.update(this.props);
    }

    componentWillReceiveProps(nextProps: UnitsProps) {
        //this.update(nextProps);
    }

    public render() {
        return (
            <div style={{ height: "100%", width: "100%" }}>
                <h2>Unités</h2>
                <CrudeLayout
                    name={name}
                    createForm={<div style={{ maxWidth: 600 }}>
                        <h2>Créer</h2>
                        <CreateUnitForm
                            actionName={canCrude(this.props.account) ? "Créer" : "Demander la création"}
                            onSubmit={(values) => {
                                values.code = values.name;
                                if (canCrude(this.props.account))
                                    return this.props.requestCreate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitCreate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    updateForm={<div style={{ maxWidth: 600 }}>
                        <h2>Editer</h2>
                        <UpdateUnitForm
                            actionName={canCrude(this.props.account) ? "Sauvegarder" : "Demander la Sauvegarde"}
                            onSubmit={(values) => {
                                values.code = values.name;
                                if (canCrude(this.props.account))
                                    return this.props.requestUpdate(new Date().getTime(), values);
                                else
                                    return this.props.requestSubmitUpdate(new Date().getTime(), values);
                            }}
                        />
                    </div>}
                    help={<div>
                        <div dangerouslySetInnerHTML={{ __html: this.props.helpHtml }} />
                    </div>}
                    table={<Table
                        gridKey={name}
                        account={this.props.account}
                        isLoading={this.props.isLoading}
                        data={this.props.entities}
                        idPropertyName={"unitId"}
                        updateValidation={(updates) =>
                            validateGridUpdates(updates, unitValidation, this.props.entities)}
                        onBulkUpdate={(updates) => this.props.requestBulkUpdate(
                            new Date().getTime(),
                            updates) as any}
                        onEntitySelected={(id) => this.props.selectUpdateEntity(id)}
                        onRequestDelete={(id) => this.props.requestDelete(new Date().getTime(), id)}
                        onRequestUpdate={(id) => {
                            this.props.selectUpdateEntity(id);
                            this.props.selectTab(name, "update");
                        }}
                        columns={[
                        {
                            key: "name",
                            name: "Nom",
                            editable: true,
                        },
                        {
                            key: "type",
                            name: "Type",
                            editable: false,
                        },
                        {
                            key: "unitConversionsTo",
                            name: "Conversions",
                            editable: false,
                            formatter: ({ value }) =>
                                <div>
                                    {value.length !== 0
                                        && (value as Array<Api.UnitConversionModel>)
                                        .map(x => x.value + " " + this.props.entities[x.unitFromId].name)
                                            .reduce((a, b) => a + " / " + b)}
                                </div>
                        }]} />} />
            </div>
        );
    }
}

export default connect((state: ApplicationState) => ({
    ...state.unit,
    account: state.account.currentUser.account,
    helpHtml: state.seed.seed.unitHelp
} as UnitsOwnProps), { ...TabsStore.actionCreators, ...UnitStore.actionCreators } as any)(Units);