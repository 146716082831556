import * as Api from "../api/api";
import * as FamilyStore from "./Family";
import { ApplicationState } from './';
import * as CrudeStore from "./Crude";

export const key: string = "subFamily";

export type SubFamilyState = CrudeStore.EntityCrudeState<Api.SubFamilyModel, Api.SubFamilyFilter>;

export const configuration: CrudeStore.CrudeStoreConfiguration<Api.SubFamilyModel, Api.SubFamilyFilter> = {
    key: key,
    storeSelector: x => x.subFamily,
    idSelector: x => x.subFamilyId,
    requestEntitiesFetch: (model, options) => {
        let api = new Api.SubFamilyApi();
        return api.getEntities(model, options);
    },
    requestCreateFetch: (model, options) => {
        let api = new Api.SubFamilyApi();
        return api.create(model, options);
    },
    requestUpdateFetch: (model, options) => {
        let api = new Api.SubFamilyApi();
        return api.update(model, options);
    },
    requestDeleteFetch: (model, options) => {
        let api = new Api.SubFamilyApi();
        return api._delete(model, options);
    },
    requestBulkUpdateFetch: (models, options) => {
        let api = new Api.SubFamilyApi();
        return api.bulkUpdate(models, options);
    },
    requestSubmitCreateFetch: (models, options) => {
        let api = new Api.SubFamilyApi();
        return api.submitCreate(models, options);
    },
    requestSubmitUpdateFetch: (models, options) => {
        let api = new Api.SubFamilyApi();
        return api.submitUpdate(models, options);
    },
    requestDependencies: [(requestTime: number,
        dispatch: (action: CrudeStore.KnownAction<Api.FamilyModel, Api.FamilyFilter>) => void,
        getState: () => ApplicationState) => FamilyStore.requestEntities(requestTime, dispatch, getState)]
}

export const requestEntities = (requestTime: number,
    dispatch: (action: CrudeStore.KnownAction<Api.SubFamilyModel, Api.SubFamilyFilter>) => void,
    getState: () => ApplicationState) => CrudeStore.requestEntities(configuration, requestTime, dispatch, getState);


export const actionCreators = CrudeStore.getActionCreators<Api.SubFamilyModel, Api.SubFamilyFilter>(configuration);

export const reducer = CrudeStore.getReducer(key);