import * as Api from "../api/api";
import * as _ from "lodash";
import { addTask } from '../utils/bugFixes';
import { Action, Reducer } from 'redux';
import { SubmissionError } from 'redux-form';
import { AppThunkAction, ApplicationState } from './';
import { ReceiveSubmitUpdateEntity, ReceiveSubmitCreateEntity } from "./Crude";
import { getDefaultHeaders } from "../utils/utils";
import { isAuthenticated } from "../security/UserIsAuthenticated";
import { connectUserHub } from "../signalR/connectedUsers";
import { writeToken } from "../security/tokenManager";

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface AccountState {
    isLoading: boolean;
    requestTime?: number;
    currentUser: Api.CurrentUserModel;
    createTokenState: {
        isLoading: boolean;
        requestTime?: number;
    },
    storeSelectionState: {
        isOpen: boolean;
        storesState: { [id: number]: { isSelected: boolean; } }
    }
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestCurrentUser {
    type: 'REQUEST_CURRENT_USER';
    payload: {
        requestTime: number;
    }
}
export interface ReceiveCurrentUser {
    type: 'RECEIVE_CURRENT_USER';
    payload: { requestTime: number; currentUser: Api.CurrentUserModel; },
    error?: any
}

interface RequestCreateToken {
    type: 'REQUEST_CREATE_TOKEN';
    payload: {
        requestTime: number;
    }
}
interface ReceiveCreateToken {
    type: 'RECEIVE_CREATE_TOKEN';
    payload: {
        requestTime: number;
    },
    error?: any
}

interface RequestLogout {
    type: 'REQUEST_LOGOUT';
    payload: {
        requestTime: number;
    }
}
export interface ReceiveLogout {
    type: 'RECEIVE_LOGOUT';
    payload: { requestTime: number; },
    error?: any
}

interface OpenStoreSelectionDialog {
    type: 'OPEN_STORE_SELECTION_DIALOG';
}
interface CloseStoreSelectionDialog {
    type: 'CLOSE_STORE_SELECTION_DIALOG';
}
interface StoreSelectAllStores {
    type: 'STORE_SELECT_ALL_STORES';
    payload: { value: boolean; storeIds: Array<number>; }
}
interface StoreSelectStoreGroup {
    type: 'STORE_SELECT_STORE_GROUP';
    payload: { storeGroupId: number; storeIds: Array<number>; value: boolean; }
}
interface StoreSelectStore {
    type: 'STORE_SELECT_STORE';
    payload: { storeId: number; value: boolean; }
}

export interface LoadToken { type: 'LOAD_TOKEN'; payload: { token: string; }}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = RequestCurrentUser | ReceiveCurrentUser
    | RequestCreateToken | ReceiveCreateToken | LoadToken
    | RequestLogout | ReceiveLogout
    | ReceiveSubmitUpdateEntity
    | ReceiveSubmitCreateEntity
    | OpenStoreSelectionDialog
    | CloseStoreSelectionDialog
    | StoreSelectAllStores
    | StoreSelectStoreGroup
    | StoreSelectStore
    ;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const requestCurrentUser = (requestTime: number, dispatch: (action: KnownAction) => void, getState: () => ApplicationState): Promise<any> => {
    if (requestTime === getState().account.requestTime)
        return Promise.reject("Already did");

    let api = new Api.AccountApi();
    let fetchTask = api.getCurrentUser({ credentials: "same-origin", headers: getDefaultHeaders(getState()) })
        .then(currentUser => {
            dispatch({ type: "RECEIVE_CURRENT_USER", payload: { requestTime: requestTime, currentUser: currentUser } });
        }).catch(error => {
            dispatch({ type: "RECEIVE_CURRENT_USER", payload: { requestTime: requestTime, currentUser: null }, error: error });
        });

    addTask(fetchTask);
    dispatch({ type: "REQUEST_CURRENT_USER", payload: { requestTime: requestTime } });
    return fetchTask;
}

export const selectedStores = (state: ApplicationState): { [id: number]: Api.StoreModel } => {
    return _.keyBy(_.values(allowedStores(state))
        .filter(x => !state.account.storeSelectionState.storesState[x.storeId]
            || state.account.storeSelectionState.storesState[x.storeId].isSelected),
        x => x.storeId)
};

export const allowedStores = (state: ApplicationState): { [id: number]: Api.StoreModel } => {
    return _.keyBy(_.values(state.seed.seed.stores)
        .filter(x => state.account.currentUser
            && state.account.currentUser.account
            && (state.account.currentUser.account.accountRoles
                .some(y => y.role.name === "Maintenance"
                    || y.role.name === "Master")
                || state.account.currentUser.account.user.subscriptions
                    .some(y => y.storeId === x.storeId))),
        x => x.storeId);
};

export const actionCreators = {
    requestCurrentUser: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        return requestCurrentUser(requestTime, dispatch, getState);
    },
    requestCreateToken: (requestTime: number, model: Api.LoginModel): AppThunkAction<KnownAction> => (dispatch, getState) => {
        if (getState().account.createTokenState.requestTime === requestTime)
            return Promise.reject("Already did");

        let task = new Promise((resolve, reject) => {
            let api = new Api.AccountApi();
            api.createToken({
                model: model
            }, { credentials: "same-origin" })
                .then(token => {
                    dispatch({
                        type: "RECEIVE_CREATE_TOKEN",
                        payload: { requestTime: requestTime }
                    });
                    window.localStorage.setItem("token", token.token);
                    writeToken(token.token);
                    requestCurrentUser(new Date().getTime(), dispatch, getState)
                        .then(() => {
                            resolve();
                            if (isAuthenticated(getState())) {
                                connectUserHub(getState, dispatch as any);
                            }
                        })
                        .catch(error => reject(error));
                })
                .catch(error => {
                    dispatch({
                        type: "RECEIVE_CREATE_TOKEN",
                        payload: { requestTime: requestTime },
                        error: error
                    });
                    if (error.status === 401) {
                        error.text()
                            .then(text => reject({ message: JSON.parse(text) }));
                    } else {
                        reject(error);
                    }
                });
        }).catch(error => {
            throw new SubmissionError({ _error: error.message || error.statusText });
        });
        addTask(task);
        dispatch({ type: "REQUEST_CREATE_TOKEN", payload: { requestTime: requestTime } });
        return task;
    },
    accountLogout: (requestTime: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let api = new Api.AccountApi();
        let fetch = api.logout({ credentials: "same-origin" })
            .then(() => {
                dispatch({ type: "RECEIVE_LOGOUT", payload: { requestTime: requestTime } });
            })
            .catch(err => {
                dispatch({ type: "RECEIVE_LOGOUT", payload: { requestTime: requestTime }, error: err });
            });
        dispatch({ type: "RECEIVE_LOGOUT", payload: { requestTime: requestTime } });
    },
    openSelectionStoreDialog: () => <OpenStoreSelectionDialog>{ type: "OPEN_STORE_SELECTION_DIALOG" },
    closeSelectionStoreDialog: () => <CloseStoreSelectionDialog>{ type: "CLOSE_STORE_SELECTION_DIALOG" },
    selecteStore: (storeId: number, value: boolean) => <StoreSelectStore>{
        type: "STORE_SELECT_STORE",
        payload: { storeId: storeId, value: value }
    },
    selecteStoreGroup: (storeGroupId: number, storeIds: Array<number>, value: boolean) => <StoreSelectStoreGroup>{
        type: "STORE_SELECT_STORE_GROUP",
        payload: { storeGroupId: storeGroupId, storeIds: storeIds, value: value }
    },
    selectAllStores: (value: boolean): AppThunkAction<KnownAction> => (dispatch, getState) => {
        dispatch(<StoreSelectAllStores>{
            type: "STORE_SELECT_ALL_STORES",
            payload: {
                value: value,
                storeIds: _.values(allowedStores(getState())).map(x => x.storeId)
            }
        });
    } 
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: AccountState = {
    isLoading: false,
    currentUser: null,
    createTokenState: {
        isLoading: false
    },
    storeSelectionState: {
        isOpen: false,
        storesState: {}
    }
};

export const reducer: Reducer<AccountState> = (state: AccountState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "REQUEST_CURRENT_USER":
            return {
                ...state,
                requestTime: action.payload.requestTime,
                isLoading: true
            };
        case "RECEIVE_CURRENT_USER":
            if (action.payload.requestTime !== state.requestTime)
                return state;

            return {
                ...state,
                isLoading: false,
                currentUser: action.payload.currentUser
                    || state.currentUser //Might be better to do that when there is an exception
            };
        case "REQUEST_CREATE_TOKEN":
            return {
                ...state,
                createTokenState: {
                    ...state.createTokenState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "RECEIVE_CREATE_TOKEN":
            if (action.payload.requestTime !== state.createTokenState.requestTime)
                return state;

            return {
                ...state,
                createTokenState: {
                    ...state.createTokenState,
                    isLoading: true,
                    requestTime: action.payload.requestTime
                }
            };
        case "LOAD_TOKEN":
            return {
                ...state,
                token: action.payload.token
            };
        case "REQUEST_LOGOUT":
            return {
                ...state,
                isLoading: true,
                requestTime: action.payload.requestTime
            };
        case "RECEIVE_LOGOUT":
            return {
                ...state,
                isLoading: action.payload.requestTime === state.requestTime
                    ? false : state.isLoading,
                currentUser: action.error
                    ? state.currentUser
                    : null,
            };
        case "RECEIVE_SUBMIT_UPDATE_ENTITY":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    account: {
                        ...state.currentUser.account,
                        user: {
                            ...state.currentUser.account.user,
                            pendingSubmissions: action.error
                                ? state.currentUser.account.user.pendingSubmissions
                                : state.currentUser.account.user.pendingSubmissions.concat([
                                action.payload.submission])
                        }
                    }
                }
            };
        case "RECEIVE_SUBMIT_CREATE_ENTITY":
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    account: {
                        ...state.currentUser.account,
                        user: {
                            ...state.currentUser.account.user,
                            pendingSubmissions: action.error
                                ? state.currentUser.account.user.pendingSubmissions
                                : state.currentUser.account.user.pendingSubmissions.concat([
                                    action.payload.submission])
                        }
                    }
                }
            };
        case "OPEN_STORE_SELECTION_DIALOG":
            return {
                ...state,
                storeSelectionState: {
                    ...state.storeSelectionState,
                    isOpen: true,
                }
            };
        case "CLOSE_STORE_SELECTION_DIALOG":
            return {
                ...state,
                storeSelectionState: {
                    ...state.storeSelectionState,
                    isOpen: false,
                }
            };
        case "STORE_SELECT_ALL_STORES":
            return {
                ...state,
                storeSelectionState: {
                    ...state.storeSelectionState,
                    storesState: _.mapValues(_.keyBy(action.payload.storeIds, x => x), x => ({
                        isSelected: action.payload.value
                    }))
                }
            };
        case "STORE_SELECT_STORE":
            return {
                ...state,
                storeSelectionState: {
                    ...state.storeSelectionState,
                    storesState: {
                        ...state.storeSelectionState.storesState,
                        [action.payload.storeId]: {
                            isSelected: action.payload.value
                        }
                    }
                }
            };
        case "STORE_SELECT_STORE_GROUP":
            let storesState = {};
            action.payload.storeIds.forEach(x => {
                storesState[x] = {
                    isSelected: action.payload.value
                }
            });

            return {
                ...state,
                storeSelectionState: {
                    ...state.storeSelectionState,
                    storesState: {
                        ...state.storeSelectionState.storesState,
                        ...storesState
                    }
                }
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};
