import * as Api from "../api/api";
import * as _ from "lodash";
import * as UnitStore from "./Unit";
import * as VatStore from "./Vat";
import * as FamilyStore from "./Family";
import * as SubFamilyStore from "./SubFamily";
import * as MenuCategoryStore from "./MenuCategory";
import * as PriceNameStore from "./PriceName";
import * as SupplierMainStore from "./SupplierMain";
import * as SupplierSubStore from "./SupplierSub";
import * as CurrencyStore from "./Currency";
import * as CrudeStore from "./Crude";
import * as TagStore from "./Tag";
import { Action, Reducer } from 'redux';
import { AppThunkAction, ApplicationState } from './';
import { createSelector } from "reselect";
import { remove } from "diacritics";

export const key: string = "product";

export type ProductState = CrudeStore.EntityCrudeState<Api.ProductModel, ProductFilterModel>
    & ProductOwnState;

interface ProductOwnState {
    selectedPriceNameId: number;
    selectedSupplierId: number;
    selectedStoreId: number;
}

export interface ProductFilterModel {
    productName?: string;
    includeComments?: boolean;
    familyId?: number;
    subFamilyId?: number;
    supplierId?: number;
}

export const configuration: CrudeStore.CrudeStoreConfiguration<Api.ProductModel, ProductFilterModel> = {
    key: key,
    storeSelector: x => x.product,
    idSelector: x => x.storeItemId,
    requestEntitiesFetch: (model, options) => {
        let api = new Api.ProductApi();
        return api.getEntities(model, options);
    },
    requestCreateFetch: (model, options) => {
        let api = new Api.ProductApi();
        return api.create(model, options);
    },
    requestUpdateFetch: (model, options) => {
        let api = new Api.ProductApi();
        return api.update(model, options);
    },
    requestDeleteFetch: (model, options) => {
        let api = new Api.ProductApi();
        return api._delete(model, options);
    },
    requestBulkUpdateFetch: (models, options) => {
        let api = new Api.ProductApi();
        return api.bulkUpdate(models, options);
    },
    requestSubmitCreateFetch: (models, options) => {
        let api = new Api.ProductApi();
        return api.submitCreate(models, options);
    },
    requestSubmitUpdateFetch: (models, options) => {
        let api = new Api.ProductApi();
        return api.submitUpdate(models, options);
    },
    requestDependencies: [
        (requestTime: number,
        dispatch: (action: CrudeStore.KnownAction<Api.FamilyModel, Api.FamilyFilter>) => void,
            getState: () => ApplicationState) => FamilyStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.SubFamilyModel, Api.SubFamilyFilter>) => void,
            getState: () => ApplicationState) => SubFamilyStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.SupplierModel, Api.SupplierMainFilter>) => void,
            getState: () => ApplicationState) => SupplierMainStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.SupplierModel, Api.SupplierSubFilter>) => void,
            getState: () => ApplicationState) => SupplierSubStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.PriceNameModel, Api.PriceNameFilter>) => void,
            getState: () => ApplicationState) => PriceNameStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.CurrencyModel, Api.CurrencyFilter>) => void,
            getState: () => ApplicationState) => CurrencyStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.MenuCategoryModel, Api.MenuCategoryFilter>) => void,
            getState: () => ApplicationState) => MenuCategoryStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.VatModel, Api.VatFilter>) => void,
            getState: () => ApplicationState) => VatStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: CrudeStore.KnownAction<Api.UnitModel, Api.UnitFilter>) => void,
            getState: () => ApplicationState) => UnitStore.requestEntities(requestTime, dispatch, getState),
        (requestTime: number,
            dispatch: (action: any) => void,
            getState: () => ApplicationState) => TagStore.requestTagEntities(requestTime, dispatch, getState),
    ]
}

export const requestEntities = (requestTime: number,
    dispatch: (action: CrudeStore.KnownAction<Api.ProductModel, ProductFilterModel>) => void,
    getState: () => ApplicationState) => CrudeStore.requestEntities(configuration, requestTime, dispatch, getState);

interface ProductSelectSupplierId {
    type: "PRODUCT_SELECT_SUPPLIER_ID",
    payload: { supplierId: number }
}

interface ProductSelectStoreId {
    type: "PRODUCT_SELECT_STORE_ID",
    payload: { storeId: number }
}

interface ProductSelectPriceNameId {
    type: "PRODUCT_SELECT_PRICENAME_ID",
    payload: { priceNameId: number }
}

interface ProductNextToProductId {
    type: "PRODUCT_NEXT_TO_PRODUCT_ID",
    payload: { id: number }
}

interface ProductPrevToProductId {
    type: "PRODUCT_PREV_TO_PRODUCT_ID",
    payload: { id: number }
}

type KnownAction = ProductSelectSupplierId
    | ProductSelectPriceNameId
    | ProductNextToProductId
    | ProductPrevToProductId
    | ProductSelectStoreId;

const getFilter = (state: ApplicationState) => state.product.filter;
const getEntities = (state: ApplicationState) => state.product.entities;
const getSubFamilies = (state: ApplicationState) => state.subFamily.entities;
const getTags = (state: ApplicationState) => state.tag.entities;

export const productFilteredSelector = createSelector([getFilter, getEntities, getSubFamilies, getTags],
    (filter, entities, subFamilies, tags) => filterEntities(entities, subFamilies, tags, filter));

export const actionCreators = {
    ...CrudeStore.getActionCreators<Api.ProductModel, ProductFilterModel>(configuration),
    selectSupplierId: (supplierId: number) => <ProductSelectSupplierId>{ type: "PRODUCT_SELECT_SUPPLIER_ID", payload: { supplierId: supplierId } },
    selectPriceNameId: (priceNameId: number) => <ProductSelectPriceNameId>{ type: "PRODUCT_SELECT_PRICENAME_ID", payload: { priceNameId: priceNameId } },
    selectStoreId: (storeId: number) => <ProductSelectStoreId>{ type: "PRODUCT_SELECT_STORE_ID", payload: { storeId: storeId } },
    nextProduct: (by: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let filteredProducts = productFilteredSelector(getState());

        let index = filteredProducts.findIndex(x => x.storeItemId === getState().product.selectedEntityId);
        if (index === -1)
            dispatch({ type: "PRODUCT_NEXT_TO_PRODUCT_ID", payload: { id: getState().product.selectedEntityId } })

        index = Math.min(index + by, filteredProducts.length - 1);
        dispatch({ type: "PRODUCT_NEXT_TO_PRODUCT_ID", payload: { id: filteredProducts[index].storeItemId } });
    },
    prevProduct: (by: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
        let filteredProducts = productFilteredSelector(getState());

        let index = filteredProducts.findIndex(x => x.storeItemId === getState().product.selectedEntityId);
        if (index === -1)
            dispatch({ type: "PRODUCT_PREV_TO_PRODUCT_ID", payload: { id: getState().product.selectedEntityId } })

        index = Math.max(index - by, 0);
        dispatch({ type: "PRODUCT_PREV_TO_PRODUCT_ID", payload: { id: filteredProducts[index].storeItemId } });
    }
};

const unloadedState: ProductState = {
    ...CrudeStore.unloadedState,
    selectedSupplierId: null,
    selectedPriceNameId: null,
    selectedStoreId: null
};

export const ownReducer: Reducer<ProductState> = (state: ProductState, incomingAction: Action) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
        case "PRODUCT_SELECT_PRICENAME_ID":
            return {
                ...state,
                selectedPriceNameId: action.payload.priceNameId
            };
        case "PRODUCT_SELECT_SUPPLIER_ID":
            return {
                ...state,
                selectedSupplierId: action.payload.supplierId
            };
        case "PRODUCT_NEXT_TO_PRODUCT_ID":
            return {
                ...state,
                selectedEntityId: action.payload.id
            };
        case "PRODUCT_PREV_TO_PRODUCT_ID":
            return {
                ...state,
                selectedEntityId: action.payload.id
            };
        case "PRODUCT_SELECT_STORE_ID":
            return {
                ...state,
                selectedStoreId: action.payload.storeId
            };
        default:
            // The following line guarantees that every action in the KnownAction union has been covered by a case above
            const exhaustiveCheck: never = action;
    }

    return state || unloadedState;
};

const crudeReducer = CrudeStore.getReducer(key);
//Should work right?
export const reducer = (state: ProductState, action: Action) => crudeReducer(ownReducer(state, action), action);

export const filterEntities = (entities: { [id: number]: Api.ProductModel },
    subFamilies: { [id: number]: Api.SubFamilyModel },
    tags: { [id: number]: Api.TagModel },
    filter: ProductFilterModel): Array<Api.ProductModel> => {
    let tagValues = filter.productName
        ? filter.productName.split(' ')
        : [];
    return _.values(entities)
        .filter(x =>
            (!filter.productName
                || remove(x.name.toLowerCase()).includes(remove(filter.productName.toLowerCase()))
                || remove((x.shortName || "").toLowerCase()).includes(remove(filter.productName.toLowerCase()))
                || (filter.includeComments && remove((x.comment || "").toLocaleLowerCase()).includes(remove(filter.productName.toLowerCase())))
                || (x.storeItemTags && x.storeItemTags.some(y => tagValues.some(z => z === tags[y.tagId].value))))
            && (!filter.familyId
                || x.productSubFamilies.some(y => subFamilies[y.subFamilyId].familyId === filter.familyId))
            && (!filter.familyId
                || !filter.subFamilyId
                || x.productSubFamilies.some(y => y.subFamilyId === filter.subFamilyId))
            && (!filter.supplierId
                || x.productSuppliers.some(y => y.supplierMainId === filter.supplierId
                    || y.supplierSubId === filter.supplierId)));
};